import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogAdmin from './pages/BlogAdmin';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Booking from './pages/Booking';
import DynamicBooking from './pages/DynamicBooking';
import BookingSuccess from './pages/BookingSuccess';
import BookingCancelled from './pages/BookingCancelled';
import Payment from './pages/Payment';
import PatientRegistration from './pages/PatientRegistration';
import Telemedicine from './pages/Telemedicine';
import ChineseTelemedicine from './pages/chinese-telemedicine';
import ChineseHomePage from './pages/ChineseHomePage';
import About from './pages/About';
import Sitemap from './pages/Sitemap';
import MensHealth from './pages/MensHealth';
import ComprehensiveHealthCheck from './pages/ComprehensiveHealthCheck';
import SportsMedicine from './pages/SportsMedicine';
import MentalHealth from './pages/MentalHealth';
import Resources from './pages/Resources';
import Books from './pages/Books';
import NightDoctors from './pages/NightDoctors';
import PrivateServices from './pages/PrivateServices';
import UrgentHouseCallGuide from './pages/guides/UrgentHouseCallGuide';
import ChildhoodVaccinations from './pages/blog/ChildhoodVaccinations';
import ChildhoodRespiratoryIssues from './pages/blog/ChildhoodRespiratoryIssues';
import ManagingChronicConditions from './pages/blog/ManagingChronicConditions';
import ChildNutritionGuidelines from './pages/blog/ChildNutritionGuidelines';
import PreventiveHealthScreenings from './pages/blog/PreventiveHealthScreenings';
import ChildhoodVaccinationFacts from './pages/blog/ChildhoodVaccinationFacts';
import HormonalAcneTreatment from './pages/blog/HormonalAcneTreatment';
import ChildhoodNutrition from './pages/blog/ChildhoodNutrition';
import ChildhoodImmunizations from './pages/blog/ChildhoodImmunizations';
import ChildhoodSkinConditions from './pages/blog/ChildhoodSkinConditions';
import ChildheadInjuries from './pages/blog/ChildheadInjuries';
import IronDeficiencyChildren from './pages/blog/IronDeficiencyChildren';
import MarineInjuriesSydney from './pages/blog/MarineInjuriesSydney';
import UrgentCareAtHome from './pages/blog/UrgentCareAtHome';
import RSVInfection from './pages/blog/RSVInfection';
import PertussisWhoopingCough from './pages/blog/PertussisWhoopingCough';
import HandFootMouthDisease from './pages/blog/HandFootMouthDisease';
import ErectileDysfunction from './pages/blog/ErectileDysfunction';
import BlogArticle from './components/BlogArticle';
import GoogleSheetsSetup from './pages/GoogleSheetsSetup';
import AdminDashboard from './pages/AdminDashboard';
import BlogGenerationDashboard from './pages/admin/BlogGenerationDashboard';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';
import MaleHormonalHealth from './pages/blog/MaleHormonalHealth';
import MensPreventativeHealth from './pages/blog/MensPreventativeHealth';
import ProstateHealth from './pages/blog/ProstateHealth';
import ExecutiveHealthCheck from './pages/blog/ExecutiveHealthCheck';
import TelemedicineRuralRemote from './pages/blog/TelemedicineRuralRemote';
import EasternSuburbs from './pages/locations/EasternSuburbs';
import WaterlooClinic from './pages/locations/WaterlooClinic';
import BondiArea from './pages/locations/BondiArea';
import DoubleBayArea from './pages/locations/DoubleBayArea';
import RandwickArea from './pages/locations/RandwickArea';
import RoseberyWaterlooArea from './pages/locations/RoseberyWaterlooArea';
import SydneyCBDArea from './pages/locations/SydneyCBDArea';
import PrivateJetMedicalServices from './pages/blog/PrivateJetMedicalServices';
import FiveStarHotelMedicalServices from './pages/blog/FiveStarHotelMedicalServices';
import OnSetMedicalServices from './pages/blog/OnSetMedicalServices';
import ExecutiveHealthPrograms from './pages/blog/ExecutiveHealthPrograms';
import YachtMedicalServices from './pages/blog/YachtMedicalServices';
import InternationalMedicalSupport from './pages/blog/InternationalMedicalSupport';
import VIPHomeVisits from './pages/blog/VIPHomeVisits';
import './App.css'

// Enhanced ScrollToTop component to reset scroll position on navigation
function ScrollToTop() {
  const { pathname } = useLocation();
  
  React.useEffect(() => {
    // Scroll to top immediately upon navigation
    window.scrollTo(0, 0);
    
    // Also use a short timeout to ensure scroll happens after any DOM updates
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [pathname]);
  
  return null;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <ScrollToTop />
        <div className="min-h-screen bg-[#1A1A1A] flex flex-col">
          <Navbar />
          <main className="relative z-10 flex-grow">
            <Routes>
              {/* Main Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<PatientRegistration />} />
              <Route path="/dashboard" element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              } />
              <Route path="/booking" element={<Booking />} />
              <Route path="/dynamic-booking" element={<DynamicBooking />} />
              <Route path="/payment/:bookingId" element={<Payment />} />
              <Route path="/booking-success" element={<BookingSuccess />} />
              <Route path="/booking-cancelled" element={<BookingCancelled />} />
              <Route path="/sitemap" element={<Sitemap />} />
              
              {/* Service Pages - SEO Optimized */}
              <Route path="/telemedicine" element={<Telemedicine />} />
              <Route path="/mens-health" element={<MensHealth />} />
              <Route path="/comprehensive-health-check" element={<ComprehensiveHealthCheck />} />
              <Route path="/sports-medicine" element={<SportsMedicine />} />
              <Route path="/mental-health" element={<MentalHealth />} />
              <Route path="/night-doctors" element={<NightDoctors />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/books" element={<Books />} />
              <Route path="/private" element={<PrivateServices />} />
              
              {/* Ultimate Guides */}
              <Route path="/guides/urgent-house-call" element={<UrgentHouseCallGuide />} />
              
              {/* Chinese Language Pages */}
              <Route path="/chinese" element={<ChineseHomePage />} />
              <Route path="/chinese-telemedicine" element={<ChineseTelemedicine />} />
              
              {/* Location Pages */}
              <Route path="/locations/eastern-suburbs" element={<EasternSuburbs />} />
              <Route path="/locations/waterloo-clinic" element={<WaterlooClinic />} />
              <Route path="/locations/bondi-area" element={<BondiArea />} />
              <Route path="/locations/double-bay-area" element={<DoubleBayArea />} />
              <Route path="/locations/randwick-area" element={<RandwickArea />} />
              <Route path="/locations/rosebery-waterloo-area" element={<RoseberyWaterlooArea />} />
              <Route path="/locations/sydney-cbd-area" element={<SydneyCBDArea />} />
              
              {/* Admin Routes */}
              <Route path="/admin/google-sheets-setup" element={
                <PrivateRoute>
                  <GoogleSheetsSetup />
                </PrivateRoute>
              } />
              <Route path="/admin/dashboard" element={
                <PrivateRoute>
                  <AdminDashboard />
                </PrivateRoute>
              } />
              <Route path="/admin/blog" element={
                <PrivateRoute>
                  <BlogAdmin />
                </PrivateRoute>
              } />
              <Route path="/admin/blog-generation" element={
                <PrivateRoute>
                  <BlogGenerationDashboard />
                </PrivateRoute>
              } />
              
              {/* Blog Article Routes */}
              <Route path="/blog/:slug" element={<BlogArticle />} />
              <Route path="/blog/erectile-dysfunction-treatment-sydney" element={<ErectileDysfunction />} />
              <Route path="/blog/male-hormonal-health-testosterone" element={<MaleHormonalHealth />} />
              <Route path="/blog/mens-preventative-health-screenings-sydney" element={<MensPreventativeHealth />} />
              <Route path="/blog/prostate-health-guide-sydney" element={<ProstateHealth />} />
              <Route path="/blog/executive-health-check-sydney" element={<ExecutiveHealthCheck />} />
              <Route path="/blog/urgent-care-at-home" element={<UrgentCareAtHome />} />
              <Route path="/blog/marine-injuries-sydney" element={<MarineInjuriesSydney />} />
              <Route path="/blog/private-jet-medical-services" element={<PrivateJetMedicalServices />} />
              <Route path="/blog/five-star-hotel-medical-services" element={<FiveStarHotelMedicalServices />} />
              <Route path="/blog/on-set-medical-services" element={<OnSetMedicalServices />} />
              <Route path="/blog/executive-health-programs" element={<ExecutiveHealthPrograms />} />
              <Route path="/blog/yacht-medical-services" element={<YachtMedicalServices />} />
              <Route path="/blog/international-medical-support" element={<InternationalMedicalSupport />} />
              <Route path="/blog/vip-home-visits" element={<VIPHomeVisits />} />
              <Route path="/blog/rsv-infection-children" element={<RSVInfection />} />
              <Route path="/blog/pertussis-whooping-cough-children" element={<PertussisWhoopingCough />} />
              <Route path="/blog/hand-foot-mouth-disease-children" element={<HandFootMouthDisease />} />
              <Route path="/blog/childhood-vaccinations" element={<ChildhoodVaccinations />} />
              <Route path="/blog/childhood-respiratory-issues" element={<ChildhoodRespiratoryIssues />} />
              <Route path="/blog/managing-chronic-conditions" element={<ManagingChronicConditions />} />
              <Route path="/blog/child-nutrition-guidelines" element={<ChildNutritionGuidelines />} />
              <Route path="/blog/preventive-health-screenings" element={<PreventiveHealthScreenings />} />
              <Route path="/blog/childhood-vaccination-facts" element={<ChildhoodVaccinationFacts />} />
              <Route path="/blog/hormonal-acne-treatment" element={<HormonalAcneTreatment />} />
              <Route path="/blog/childhood-nutrition" element={<ChildhoodNutrition />} />
              <Route path="/blog/childhood-immunizations" element={<ChildhoodImmunizations />} />
              <Route path="/blog/childhood-skin-conditions" element={<ChildhoodSkinConditions />} />
              <Route path="/blog/child-head-injuries" element={<ChildheadInjuries />} />
              <Route path="/blog/iron-deficiency-children" element={<IronDeficiencyChildren />} />
              <Route path="/blog/telemedicine-rural-remote" element={<TelemedicineRuralRemote />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
