import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { format, startOfMonth, endOfMonth, subMonths } from 'date-fns';

export default function BookingAnalytics() {
  console.log("BookingAnalytics rendering");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [serviceStats, setServiceStats] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [averageBookingValue, setAverageBookingValue] = useState(0);

  useEffect(() => {
    console.log("BookingAnalytics mounted");
    
    const fetchAnalytics = async () => {
      try {
        console.log("Fetching analytics data");
        setLoading(true);
        
        // Get all bookings
        const bookingsRef = collection(db, 'bookings');
        const bookingsSnapshot = await getDocs(bookingsRef);
        console.log("Fetched bookings count:", bookingsSnapshot.docs.length);
        
        const bookings = bookingsSnapshot.docs.map(doc => {
          const data = doc.data();
          // Log any problematic location data
          if (data.location && typeof data.location === 'object') {
            console.log("Booking location data in analytics:", doc.id, data.location);
          }
          return {
            id: doc.id,
            ...data,
            date: data.date?.toDate() // Convert Firestore timestamp to JS Date
          };
        });
        
        // Calculate total revenue and average booking value
        const confirmedBookings = bookings.filter(booking => 
          booking.status === 'confirmed' || booking.status === 'completed'
        );
        console.log("Confirmed bookings count:", confirmedBookings.length);
        
        const revenue = confirmedBookings.reduce((sum, booking) => sum + (booking.price || 0), 0);
        console.log("Total revenue calculated:", revenue);
        setTotalRevenue(revenue);
        
        if (confirmedBookings.length > 0) {
          const avgValue = Math.round(revenue / confirmedBookings.length);
          console.log("Average booking value calculated:", avgValue);
          setAverageBookingValue(avgValue);
        }
        
        // Calculate service type distribution
        console.log("Calculating service distribution");
        const serviceMap = {};
        bookings.forEach(booking => {
          const serviceName = booking.service?.name || booking.serviceType || 'Unknown';
          if (!serviceMap[serviceName]) {
            serviceMap[serviceName] = {
              count: 0,
              revenue: 0
            };
          }
          serviceMap[serviceName].count += 1;
          serviceMap[serviceName].revenue += (booking.price || 0);
        });
        
        const serviceStatsData = Object.keys(serviceMap).map(service => ({
          name: service,
          count: serviceMap[service].count,
          revenue: serviceMap[service].revenue,
          percentage: Math.round((serviceMap[service].count / bookings.length) * 100)
        }));
        
        console.log("Service stats calculated:", serviceStatsData.length);
        setServiceStats(serviceStatsData);
        
        // Calculate monthly stats for the last 6 months
        console.log("Calculating monthly stats");
        const monthlyStatsData = [];
        const today = new Date();
        
        for (let i = 0; i < 6; i++) {
          const monthDate = subMonths(today, i);
          const monthStart = startOfMonth(monthDate);
          const monthEnd = endOfMonth(monthDate);
          
          const monthBookings = bookings.filter(booking => 
            booking.date >= monthStart && booking.date <= monthEnd
          );
          
          const monthRevenue = monthBookings.reduce((sum, booking) => sum + (booking.price || 0), 0);
          
          monthlyStatsData.unshift({
            month: format(monthDate, 'MMM yyyy'),
            count: monthBookings.length,
            revenue: monthRevenue
          });
        }
        
        console.log("Monthly stats calculated:", monthlyStatsData.length);
        setMonthlyStats(monthlyStatsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching analytics:', err);
        setError('Failed to load analytics data');
        setLoading(false);
      }
    };
    
    fetchAnalytics();
    
    return () => {
      console.log("BookingAnalytics unmounting");
    };
  }, []);

  if (loading) {
    console.log("BookingAnalytics is loading");
    return (
      <div className="bg-[#2A2A2A] rounded-lg p-6">
        <div className="text-center">
          <div className="w-12 h-12 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8]">Loading analytics...</p>
        </div>
      </div>
    );
  }

  if (error) {
    console.log("BookingAnalytics encountered an error:", error);
    return (
      <div className="bg-[#2A2A2A] rounded-lg p-6">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  console.log("Rendering BookingAnalytics with data:", {
    monthlyStats: monthlyStats.length,
    serviceStats: serviceStats.length,
    totalRevenue,
    averageBookingValue
  });

  return (
    <div className="bg-[#2A2A2A] rounded-lg p-6">
      <h2 className="text-xl font-bold text-[#DDC6A8] mb-6">Booking Analytics</h2>
      
      {/* Revenue Summary */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-[#1A1A1A] rounded-lg p-4">
          <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Total Revenue</h3>
          <p className="text-3xl font-bold text-[#DDC6A8]">${totalRevenue}</p>
        </div>
        
        <div className="bg-[#1A1A1A] rounded-lg p-4">
          <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Average Booking Value</h3>
          <p className="text-3xl font-bold text-[#DDC6A8]">${averageBookingValue}</p>
        </div>
      </div>
      
      {/* Monthly Stats */}
      <div className="mb-8">
        <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">Monthly Bookings</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-[#DDC6A8]/10">
            <thead className="bg-[#1A1A1A]">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                  Month
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                  Bookings
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                  Revenue
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#DDC6A8]/10">
              {monthlyStats.map((month, index) => (
                <tr key={index} className="hover:bg-[#1A1A1A]/30">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {month.month}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {month.count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    ${month.revenue}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      {/* Service Distribution */}
      <div>
        <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">Service Distribution</h3>
        <div className="space-y-4">
          {serviceStats.map((service, index) => (
            <div key={index} className="bg-[#1A1A1A] rounded-lg p-4">
              <div className="flex justify-between items-center mb-2">
                <h4 className="font-medium text-[#DDC6A8]">{service.name}</h4>
                <span className="text-sm text-[#DDC6A8]/70">{service.count} bookings ({service.percentage}%)</span>
              </div>
              <div className="w-full bg-[#2A2A2A] rounded-full h-2.5">
                <div 
                  className="bg-[#DDC6A8] h-2.5 rounded-full" 
                  style={{ width: `${service.percentage}%` }}
                ></div>
              </div>
              <p className="text-sm text-[#DDC6A8]/70 mt-2">Revenue: ${service.revenue}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
} 