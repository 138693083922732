import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ChildhoodImmunizations = () => {
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Childhood Immunizations in Australia</h2>
        <p className="text-gray-700 mb-4">
          Immunization is one of the most significant public health achievements, protecting children from serious infectious diseases. In Australia, the National Immunisation Program (NIP) provides free vaccines to eligible individuals, ensuring that all children can access protection against preventable diseases.
        </p>
        <p className="text-gray-700 mb-4">
          Vaccination not only protects your child but also contributes to community immunity (also known as 'herd immunity'), which helps protect those who cannot be vaccinated due to age or medical conditions. The Australian Immunisation Schedule is regularly reviewed and updated based on the latest scientific evidence to ensure optimal protection.
        </p>
        <p className="text-gray-700 mb-6">
          This guide outlines the current Australian childhood immunization schedule, addresses common concerns, and provides evidence-based information to help parents make informed decisions about their child's health.
        </p>
      </section>

      <section id="vaccination-schedule">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Australian Childhood Immunisation Schedule</h2>
        <p className="text-gray-700 mb-4">
          The National Immunisation Program Schedule recommends vaccines for children from birth through adolescence. These vaccines are free under Medicare for eligible individuals. Here's a breakdown of the current schedule:
        </p>

        <div className="overflow-x-auto mb-8">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left">Age</th>
                <th className="py-3 px-4 text-left">Recommended Vaccines</th>
                <th className="py-3 px-4 text-left">Diseases Protected Against</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">Birth</td>
                <td className="py-3 px-4">Hepatitis B</td>
                <td className="py-3 px-4">Hepatitis B</td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">2 months</td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Pertussis, Haemophilus influenzae type b, Polio, Pneumococcal, Rotavirus
                </td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Whooping cough, Hib, Polio, Pneumococcal disease, Rotavirus
                </td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">4 months</td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Pertussis, Haemophilus influenzae type b, Polio, Pneumococcal, Rotavirus
                </td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Whooping cough, Hib, Polio, Pneumococcal disease, Rotavirus
                </td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">6 months</td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Pertussis, Haemophilus influenzae type b, Polio, Pneumococcal
                </td>
                <td className="py-3 px-4">
                  Hepatitis B, Diphtheria, Tetanus, Whooping cough, Hib, Polio, Pneumococcal disease
                </td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">12 months</td>
                <td className="py-3 px-4">
                  Measles, Mumps, Rubella, Meningococcal ACWY, Pneumococcal
                </td>
                <td className="py-3 px-4">
                  Measles, Mumps, Rubella, Meningococcal disease (strains A,C,W,Y), Pneumococcal disease
                </td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">18 months</td>
                <td className="py-3 px-4">
                  Diphtheria, Tetanus, Pertussis, Haemophilus influenzae type b, Measles, Mumps, Rubella, Varicella
                </td>
                <td className="py-3 px-4">
                  Diphtheria, Tetanus, Whooping cough, Hib, Measles, Mumps, Rubella, Chickenpox
                </td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">4 years</td>
                <td className="py-3 px-4">
                  Diphtheria, Tetanus, Pertussis, Polio
                </td>
                <td className="py-3 px-4">
                  Diphtheria, Tetanus, Whooping cough, Polio
                </td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">10-13 years (school-based program)</td>
                <td className="py-3 px-4">
                  Human Papillomavirus (HPV), Diphtheria, Tetanus, Pertussis
                </td>
                <td className="py-3 px-4">
                  HPV-related cancers and diseases, Diphtheria, Tetanus, Whooping cough
                </td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">14-16 years</td>
                <td className="py-3 px-4">
                  Meningococcal ACWY
                </td>
                <td className="py-3 px-4">
                  Meningococcal disease (strains A,C,W,Y)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <p className="text-gray-700 mb-4">
          <strong>Note:</strong> The schedule may be subject to updates. Always consult with your healthcare provider or check the Australian Immunisation Handbook for the most current recommendations. Some children with specific health conditions may require additional vaccines or a modified schedule.
        </p>
      </section>

      <section id="vaccine-safety">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Vaccine Safety and Effectiveness</h2>
        <p className="text-gray-700 mb-4">
          Vaccines in Australia undergo rigorous testing for safety and effectiveness before approval. The Therapeutic Goods Administration (TGA) continues to monitor vaccines after they are licensed through various surveillance systems.
        </p>
        
        <div className="bg-blue-50 rounded-xl p-6 mb-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Key Facts About Vaccine Safety</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Vaccines prevent an estimated 2-3 million deaths worldwide each year</li>
            <li>The benefits of vaccination far outweigh the risks</li>
            <li>Serious adverse events following immunization are extremely rare</li>
            <li>Australia's vaccine safety monitoring system is world-class</li>
            <li>All vaccines must pass strict safety testing before approval</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          While mild side effects such as soreness at the injection site, mild fever, or irritability may occur, these are typically short-lived and far less serious than the diseases the vaccines prevent. Serious adverse reactions are very rare, occurring in fewer than one in a million doses.
        </p>
        
        <p className="text-gray-700 mb-6">
          Healthcare providers are required to report adverse events following immunization, which helps in continuous monitoring of vaccine safety. Parents are also encouraged to report any concerning symptoms after vaccination to their healthcare provider.
        </p>
      </section>

      <section id="common-concerns">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Addressing Common Concerns</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {[
            {
              concern: "Too many vaccines at once",
              explanation: "Children's immune systems can handle multiple vaccines. In fact, they encounter thousands of antigens (foreign substances) every day. Vaccines contain only a tiny fraction of the antigens that babies encounter in their environment daily."
            },
            {
              concern: "Vaccine ingredients",
              explanation: "Ingredients in vaccines serve specific purposes like providing immunity, keeping the vaccine safe and effective, or aiding the production process. These ingredients are present in extremely small amounts and have been thoroughly tested for safety."
            },
            {
              concern: "Natural immunity vs. vaccine-induced immunity",
              explanation: "While natural infection may result in stronger immunity in some cases, the risks of the disease itself can be serious and even life-threatening. Vaccines provide protection without the risks associated with the disease."
            },
            {
              concern: "Autism and vaccines",
              explanation: "Extensive scientific research has found no link between vaccines and autism. Multiple studies involving hundreds of thousands of children have consistently shown no association between vaccines and autism spectrum disorders."
            }
          ].map((item, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-3">{item.concern}</h3>
              <p className="text-gray-700">{item.explanation}</p>
            </div>
          ))}
        </div>
        
        <p className="text-gray-700 mb-6">
          It's normal to have questions about vaccines. Speaking with healthcare providers who can provide evidence-based information is always recommended. They can address specific concerns related to your child's individual health circumstances.
        </p>
      </section>

      <section id="immunisation-records">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Tracking Immunisation Records</h2>
        <p className="text-gray-700 mb-4">
          The Australian Immunisation Register (AIR) is a national register that records vaccines given to people of all ages in Australia. It provides:
        </p>
        
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>An Immunisation History Statement for your child</li>
          <li>Information needed for family assistance payments</li>
          <li>Proof of immunisation for childcare and school enrollment</li>
          <li>A tool for healthcare providers to check immunisation status</li>
        </ul>
        
        <div className="bg-indigo-50 rounded-xl p-6 mb-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">How to Access Immunisation Records</h3>
          <p className="text-gray-700 mb-4">You can access your child's immunisation records through:</p>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Medicare online account through myGov</li>
            <li>Express Plus Medicare mobile app</li>
            <li>My Health Record</li>
            <li>Requesting a statement from the Australian Immunisation Register</li>
          </ul>
        </div>
      </section>

      <section id="catch-up-schedules">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Catch-up Vaccination</h2>
        <p className="text-gray-700 mb-4">
          If your child has missed scheduled vaccines, it's not too late to catch up. Healthcare providers can create a personalized catch-up schedule based on your child's age and vaccination history. Most vaccines can be given as part of a catch-up program, and many are provided free under the National Immunisation Program.
        </p>
        
        <p className="text-gray-700 mb-6">
          The Australian Immunisation Handbook provides detailed guidance on catch-up vaccination for healthcare providers. The goal is to bring children up to date with their vaccinations as efficiently as possible while maintaining the effectiveness of each vaccine.
        </p>
      </section>

      <section id="conclusion">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Conclusion</h2>
        <p className="text-gray-700 mb-4">
          Childhood immunization is a crucial part of preventive healthcare in Australia. Following the recommended immunization schedule provides children with the best protection against serious infectious diseases and contributes to public health by maintaining high levels of community immunity.
        </p>
        
        <p className="text-gray-700 mb-4">
          Healthcare providers play a vital role in discussing the benefits and importance of vaccination with parents and addressing any concerns they may have. Evidence-based information and open communication help parents make informed decisions about their child's health.
        </p>
        
        <p className="text-gray-700 mb-6">
          Remember, staying up to date with immunizations is one of the most important things you can do to protect your child's health. If you have any questions or concerns about childhood vaccinations, don't hesitate to speak with your healthcare provider.
        </p>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Assessment',
      description: 'Review of current immunization status and medical history',
      duration: '15-30 minutes'
    },
    {
      stage: 'Vaccination Plan',
      description: 'Development of standard or catch-up immunization schedule as needed',
      duration: '15 minutes'
    },
    {
      stage: 'Administration',
      description: 'Vaccine administration following safety protocols',
      duration: '5-10 minutes per vaccine'
    },
    {
      stage: 'Observation',
      description: 'Post-vaccination monitoring for immediate reactions',
      duration: '15-30 minutes'
    },
    {
      stage: 'Follow-up',
      description: 'Documentation in Australian Immunisation Register and scheduling next vaccines',
      duration: '5-10 minutes'
    }
  ];

  const references = [
    {
      authors: 'Australian Technical Advisory Group on Immunisation (ATAGI)',
      title: 'Australian Immunisation Handbook',
      journal: 'Australian Government Department of Health',
      year: '2023',
      doi: ''
    },
    {
      authors: 'National Centre for Immunisation Research and Surveillance (NCIRS)',
      title: 'Frequently Asked Questions about Vaccination',
      journal: 'NCIRS Fact Sheets',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Royal Australian College of General Practitioners (RACGP)',
      title: 'Guidelines for preventive activities in general practice (The Red Book)',
      journal: 'RACGP',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Services Australia',
      title: 'Australian Immunisation Register',
      journal: 'Australian Government',
      year: '2023',
      doi: ''
    }
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Australian Immunisation Schedule', anchor: 'vaccination-schedule' },
    { title: 'Vaccine Safety and Effectiveness', anchor: 'vaccine-safety' },
    { title: 'Addressing Common Concerns', anchor: 'common-concerns' },
    { title: 'Tracking Immunisation Records', anchor: 'immunisation-records' },
    { title: 'Catch-up Vaccination', anchor: 'catch-up-schedules' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  return (
    <MedicalBlogTemplate
      title="Childhood Immunizations: The Australian Guide"
      subtitle="Understanding the National Immunisation Program and keeping your child protected"
      heroImage="https://images.unsplash.com/photo-1632833239869-a37e3a5806d2"
      publishDate="March 10, 2024"
      readTime={15}
      content={content}
      category="Child Health"
      tags={['Immunization', 'Vaccines', 'Preventive Care', 'Pediatrics', 'Public Health']}
      treatments={treatments}
      references={references}
      showEmergencyBox={false}
      tableOfContents={tableOfContents}
    />
  );
};

export default ChildhoodImmunizations; 