import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../SEO';
import DirectAnswerBlocks from './DirectAnswerBlocks';

/**
 * UltimateGuideTemplate Component
 * 
 * This component provides a structured template for creating "Ultimate Guides"
 * that are optimized for SEO and specifically designed to be featured in
 * Google's AI-generated responses.
 * 
 * The structure follows best practices for comprehensive content that
 * demonstrates E-E-A-T (Experience, Expertise, Authoritativeness, Trustworthiness)
 * and is easily parsable by search engines and AI systems.
 */

const UltimateGuideTemplate = ({
  service,
  title,
  description,
  keywords,
  author = "Dr. Terry Nguyen",
  authorCredentials = "MBBS, MBA, BAppSci (Phty)",
  publishDate,
  lastUpdated,
  tableOfContents,
  introductionContent,
  sectionContent,
  faqs,
  relatedServices,
  cta
}) => {
  // Format dates for display and schema
  const formattedPublishDate = new Date(publishDate).toLocaleDateString('en-AU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  
  const formattedLastUpdated = new Date(lastUpdated).toLocaleDateString('en-AU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // Structured data for article schema
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'MedicalWebPage',
    headline: title,
    description: description,
    author: {
      '@type': 'Person',
      name: author,
      jobTitle: 'Medical Doctor',
      description: authorCredentials
    },
    datePublished: publishDate,
    dateModified: lastUpdated,
    publisher: {
      '@type': 'Organization',
      name: 'Dr. Terry Nguyen Medical Practice',
      logo: {
        '@type': 'ImageObject',
        url: 'https://homedoc.com.au/images/logos/main-logo.png'
      }
    },
    mainEntity: {
      '@type': 'FAQPage',
      mainEntity: faqs.map(faq => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer
        }
      }))
    }
  };

  // Table of Contents ID generator
  const generateTocId = (text) => {
    return text.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <SEO 
        title={title}
        description={description}
        keywords={keywords}
        schema={structuredData}
      />
      
      <div className="max-w-4xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        {/* Article header with metadata */}
        <div className="border-b border-[#DDC6A8]/20 pb-8 mb-10">
          <div className="mb-6">
            <h1 className="text-4xl font-light mb-6 text-white">{title}</h1>
            
            {/* Author info and dates - important for E-E-A-T signals */}
            <div className="flex flex-wrap items-center text-sm text-[#DDC6A8]/70 mb-6">
              <div className="mr-4 mb-2">
                <span className="font-medium text-[#DDC6A8]">Author:</span> {author}, {authorCredentials}
              </div>
              <div className="mr-4 mb-2">
                <span className="font-medium text-[#DDC6A8]">Published:</span> {formattedPublishDate}
              </div>
              <div className="mb-2">
                <span className="font-medium text-[#DDC6A8]">Last Updated:</span> {formattedLastUpdated}
              </div>
            </div>
            
            {/* TLDR Summary - optimized for featured snippets */}
            <div className="bg-[#DDC6A8]/5 p-6 rounded-lg border border-[#DDC6A8]/20 mb-8">
              <h2 className="text-xl font-medium text-white mb-3">Key Takeaways</h2>
              <div className="tldr-summary text-white/90">
                {description}
              </div>
            </div>
          </div>
          
          {/* Table of Contents - structured navigation */}
          <div className="mb-8">
            <h2 className="text-xl font-medium text-white mb-4">Table of Contents</h2>
            <ul className="space-y-2 text-[#DDC6A8]">
              {tableOfContents.map((item, index) => (
                <li key={index}>
                  <a 
                    href={`#${generateTocId(item)}`} 
                    className="hover:text-white transition-colors"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        
        {/* Introduction section */}
        <div className="mb-12">
          <div className="prose prose-invert prose-headings:text-[#DDC6A8] prose-a:text-[#DDC6A8] max-w-none">
            {introductionContent}
          </div>
        </div>
        
        {/* AI-optimized direct answer blocks */}
        <DirectAnswerBlocks service={service} />
        
        {/* Main content sections */}
        <div className="space-y-14 mb-16">
          {sectionContent.map((section, index) => (
            <section 
              key={index} 
              id={generateTocId(tableOfContents[index + 1])} 
              className="scroll-mt-24"
            >
              <h2 className="text-2xl font-light text-white mb-6 pb-2 border-b border-[#DDC6A8]/20">
                {tableOfContents[index + 1]}
              </h2>
              <div className="prose prose-invert prose-headings:text-[#DDC6A8] prose-a:text-[#DDC6A8] max-w-none">
                {section}
              </div>
            </section>
          ))}
        </div>
        
        {/* FAQ Section - structured for schema markup */}
        <div className="mb-16">
          <h2 className="text-2xl font-light text-white mb-8 pb-2 border-b border-[#DDC6A8]/20">
            Frequently Asked Questions
          </h2>
          
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-[#DDC6A8]/10 pb-6 last:border-0">
                <h3 className="text-xl font-medium text-white mb-3">{faq.question}</h3>
                <div className="text-white/90">
                  {faq.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Author credentials section - enhances E-E-A-T */}
        <div className="bg-[#DDC6A8]/5 p-6 rounded-lg border border-[#DDC6A8]/20 mb-12">
          <h2 className="text-xl font-medium text-white mb-4">About the Author</h2>
          <p className="text-white/90 mb-4">
            Dr. Terry Nguyen is a highly qualified medical doctor with extensive experience in house call medicine, telemedicine, and specialized care. With qualifications including MBBS, MBA, and BAppSci (Phty), Dr. Terry combines medical expertise with a patient-centered approach to deliver exceptional care.
          </p>
          <Link to="/about" className="text-[#DDC6A8] hover:text-white transition-colors">
            Learn more about Dr. Terry Nguyen →
          </Link>
        </div>
        
        {/* Related services - internal linking */}
        <div className="mb-12">
          <h2 className="text-2xl font-light text-white mb-6">Related Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {relatedServices.map((service, index) => (
              <Link 
                key={index}
                to={service.path}
                className="p-4 border border-[#DDC6A8]/20 rounded-lg hover:bg-[#DDC6A8]/5 transition-colors group"
              >
                <h3 className="text-lg font-medium text-white mb-2 group-hover:text-[#DDC6A8] transition-colors">
                  {service.title}
                </h3>
                <p className="text-white/70 text-sm">{service.description}</p>
              </Link>
            ))}
          </div>
        </div>
        
        {/* Call to action */}
        <div className={`${cta.bgColor || 'bg-gradient-to-r from-[#DDC6A8]/20 to-[#DDC6A8]/10'} p-8 rounded-lg text-center`}>
          <h2 className="text-2xl font-light text-white mb-4">{cta.heading}</h2>
          <p className="text-white/90 mb-6 max-w-2xl mx-auto">{cta.subheading}</p>
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link
              to={cta.buttonLink}
              className="inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
            >
              {cta.buttonText}
              <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </Link>
            {cta.secondaryText && cta.phoneNumber && (
              <div className="flex items-center">
                <span className="text-[#DDC6A8] mr-2">{cta.secondaryText}</span>
                <a href={`tel:${cta.phoneNumber.replace(/\s/g, '')}`} className="text-white font-medium hover:text-[#DDC6A8] transition-colors">
                  {cta.phoneNumber}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UltimateGuideTemplate; 