import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faPhone, faCalendarCheck, faCheckCircle, faExclamationTriangle, faQuestion, faHospital } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import BookingButton from '../components/BookingButton';

// Styled components
const MainContainer = styled.div`
  font-family: 'Noto Sans SC', sans-serif, 'Noto Sans', sans-serif;
`;

const HeroSection = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/telemedicine-consultation.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 120px 20px;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const Section = styled.section`
  padding: 80px 20px;
`;

const ContentContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 50px;
  font-size: 2rem;
  color: #333;
`;

const Divider = styled.hr`
  max-width: 200px;
  margin: 20px auto 40px;
  border: 0;
  border-top: 3px solid #1e88e5;
`;

const BenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const BenefitCard = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const BenefitTitle = styled.h3`
  color: #1e88e5;
  margin: 15px 0;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ParaTitle = styled.h3`
  color: #1e88e5;
  margin: 25px 0 15px;
  font-size: 1.4rem;
`;

const FAQSection = styled.div`
  margin-top: 40px;
`;

const FAQItem = styled.div`
  margin-bottom: 25px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
`;

const FAQQuestion = styled.h4`
  font-size: 1.2rem;
  color: #1e88e5;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ConclusionBox = styled.div`
  background-color: #e3f2fd;
  border-radius: 10px;
  padding: 30px;
  margin-top: 50px;
  text-align: center;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: #1e88e5;
  color: white;
  padding: 15px 30px;
  margin-top: 20px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
  }
`;

const ChineseTelemedicine = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>中文远程医疗 | 在线视频问诊咨询服务 | Dr. Terry Nguyen</title>
        <meta name="description" content="通过视频或电话获得专业医疗咨询，足不出户就能接受Dr. Terry Nguyen的专业医疗服务。Medicare返款，适用于多种常见症状。" />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap" rel="stylesheet" />
        
        {/* Schema.org structured data for MedicalService */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "MedicalService",
            "name": "远程医疗咨询服务",
            "provider": {
              "@type": "Physician",
              "name": "Dr. Terry Nguyen",
              "description": "澳大利亚注册医生，提供专业医疗服务",
              "medicalSpecialty": "General Practice"
            },
            "description": "通过视频或电话提供专业医疗咨询服务，足不出户就能接受专业医疗服务",
            "availableChannel": {
              "@type": "ServiceChannel",
              "name": "在线预约",
              "availableLanguage": ["en", "zh-CN"]
            },
            "serviceType": "Telemedicine"
          }
        `}</script>
      </Helmet>

      <Navbar />

      <HeroSection>
        <HeroTitle>远程医疗视频问诊</HeroTitle>
        <HeroSubtitle>
          足不出户，通过视频或电话获得Dr. Terry Nguyen的专业医疗咨询，简便、安全、省时
        </HeroSubtitle>
        <BookingButton text="预约远程问诊" />
      </HeroSection>

      <Section>
        <ContentContainer>
          <SectionTitle>远程医疗服务简介</SectionTitle>
          <Divider />
          <p>
            远程医疗(Telemedicine)是通过视频或电话与医生进行咨询的现代医疗服务方式。对于许多常见的健康问题，远程医疗提供了便捷、有效的解决方案，让您无需出门就能获得专业的医疗建议和处方。
          </p>
          <p className="mt-4">
            Dr. Terry Nguyen提供全面的远程医疗服务，包括视频咨询和电话咨询，为您提供与面对面诊疗相同的专业水准和关怀。我们的远程医疗服务特别适合居住在偏远地区、行动不便或时间紧张的患者。
          </p>

          <BenefitsContainer>
            <BenefitCard>
              <FontAwesomeIcon icon={faVideo} size="2x" color="#1e88e5" />
              <BenefitTitle>方便快捷</BenefitTitle>
              <p>无需出门，避免交通和等待时间，随时随地通过电子设备获得医疗咨询</p>
            </BenefitCard>
            <BenefitCard>
              <FontAwesomeIcon icon={faCalendarCheck} size="2x" color="#1e88e5" />
              <BenefitTitle>灵活预约</BenefitTitle>
              <p>提供更灵活的预约时间，包括工作日晚间和周末，适应您的个人日程安排</p>
            </BenefitCard>
            <BenefitCard>
              <FontAwesomeIcon icon={faHospital} size="2x" color="#1e88e5" />
              <BenefitTitle>Medicare返款</BenefitTitle>
              <p>符合条件的远程医疗咨询可通过Medicare获得返款，减轻您的经济负担</p>
            </BenefitCard>
          </BenefitsContainer>
        </ContentContainer>
      </Section>

      <Section style={{ backgroundColor: '#f5f5f5' }}>
        <ContentContainer>
          <SectionTitle>远程医疗适用的症状</SectionTitle>
          <Divider />
          <p>
            远程医疗适用于多种常见症状和疾病的诊断、治疗和随访。以下是一些可以通过远程医疗有效处理的健康问题：
          </p>

          <div className="grid md:grid-cols-2 gap-6 mt-8">
            <div>
              <ParaTitle>常见感染</ParaTitle>
              <ul className="list-disc pl-6">
                <li>普通感冒和流感症状</li>
                <li>咳嗽和喉咙痛</li>
                <li>鼻窦感染</li>
                <li>尿路感染</li>
                <li>眼部感染（如结膜炎）</li>
              </ul>

              <ParaTitle>皮肤问题</ParaTitle>
              <ul className="list-disc pl-6">
                <li>皮疹和湿疹</li>
                <li>蚊虫叮咬</li>
                <li>轻微灼伤</li>
                <li>痤疮</li>
                <li>皮肤过敏反应</li>
              </ul>
            </div>

            <div>
              <ParaTitle>慢性疾病管理</ParaTitle>
              <ul className="list-disc pl-6">
                <li>高血压随访</li>
                <li>糖尿病管理</li>
                <li>哮喘监测</li>
                <li>轻度至中度抑郁和焦虑</li>
                <li>慢性病药物处方续药</li>
              </ul>

              <ParaTitle>一般健康咨询</ParaTitle>
              <ul className="list-disc pl-6">
                <li>医学报告解读</li>
                <li>药物副作用咨询</li>
                <li>一般健康状况评估</li>
                <li>健康生活方式建议</li>
                <li>病假证明</li>
              </ul>
            </div>
          </div>

          <div className="mt-10 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={faExclamationTriangle} size="lg" className="text-yellow-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">注意事项</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    远程医疗不适用于紧急医疗情况，如胸痛、严重呼吸困难、大量出血或严重受伤。如遇此类紧急情况，请立即拨打000或前往最近的急诊室。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ContentContainer>
      </Section>

      <Section>
        <ContentContainer>
          <SectionTitle>预约远程医疗咨询</SectionTitle>
          <Divider />

          <div className="mb-10">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">预约步骤</h3>
            <ol className="list-decimal pl-6 space-y-3">
              <li>点击页面上的"预约远程问诊"按钮</li>
              <li>选择您偏好的咨询时间和日期</li>
              <li>填写必要的个人信息和健康问题描述</li>
              <li>选择视频咨询或电话咨询方式</li>
              <li>确认预约并完成付款</li>
              <li>您将收到预约确认邮件，其中包含详细的连接说明</li>
            </ol>
          </div>

          <div className="mb-10">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">视频咨询准备事项</h3>
            <ul className="list-disc pl-6 space-y-3">
              <li>确保您有稳定的互联网连接</li>
              <li>测试您的摄像头和麦克风是否正常工作</li>
              <li>找一个安静、光线充足的私密空间进行咨询</li>
              <li>准备好您目前正在服用的药物清单</li>
              <li>记录下您的症状、持续时间和任何相关问题</li>
              <li>在预约时间前5-10分钟准备好并登录</li>
            </ul>
          </div>

          <ConclusionBox>
            <h3 className="text-xl font-bold text-gray-800 mb-3">开始您的远程医疗咨询之旅</h3>
            <p>
              远程医疗提供了便捷、高效的医疗咨询方式，帮助您管理健康问题，无需离开家门。Dr. Terry Nguyen致力于通过远程医疗服务为您提供高质量的医疗关怀。
            </p>
            <CTAButton href="/dynamic-booking">立即预约远程咨询</CTAButton>
          </ConclusionBox>
        </ContentContainer>
      </Section>

      <Section style={{ backgroundColor: '#f5f5f5' }}>
        <ContentContainer>
          <SectionTitle>常见问题</SectionTitle>
          <Divider />

          <FAQSection>
            <FAQItem>
              <FAQQuestion>
                <FontAwesomeIcon icon={faQuestion} color="#1e88e5" />
                远程医疗咨询费用是多少？Medicare是否可以返款？
              </FAQQuestion>
              <p>
                我们的远程医疗咨询基础费用为$80-$120，具体取决于咨询时长和复杂程度。对于符合条件的患者，Medicare提供部分返款。预约时，我们的团队将提供详细的费用信息并协助您了解Medicare返款政策。
              </p>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>
                <FontAwesomeIcon icon={faQuestion} color="#1e88e5" />
                远程医疗咨询需要哪些设备？
              </FAQQuestion>
              <p>
                进行视频咨询，您需要具备摄像头、麦克风和稳定互联网连接的设备，如智能手机、平板电脑或电脑。如果您选择电话咨询，只需一部可接听电话的手机即可。我们使用安全、易于使用的视频会议软件，无需复杂的技术知识。
              </p>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>
                <FontAwesomeIcon icon={faQuestion} color="#1e88e5" />
                医生如何通过远程医疗开具处方？
              </FAQQuestion>
              <p>
                咨询结束后，如需处方，Dr. Terry Nguyen可以电子发送处方到您指定的药房，或将处方通过电子邮件发送给您。对于某些药物，可能需要面对面就诊或额外的检查。所有处方均遵循澳大利亚医疗法规和最佳实践。
              </p>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>
                <FontAwesomeIcon icon={faQuestion} color="#1e88e5" />
                远程医疗咨询的隐私和安全如何保障？
              </FAQQuestion>
              <p>
                我们高度重视患者隐私和数据安全。所有远程医疗咨询均使用符合医疗行业标准的加密视频平台进行，确保您的个人健康信息得到全面保护。我们的医疗实践严格遵守澳大利亚隐私法和医疗信息安全标准。
              </p>
            </FAQItem>

            <FAQItem>
              <FAQQuestion>
                <FontAwesomeIcon icon={faQuestion} color="#1e88e5" />
                如何取消或重新安排远程医疗预约？
              </FAQQuestion>
              <p>
                您可以通过我们的在线预约系统或致电我们的诊所取消或重新安排预约。我们要求您至少提前24小时通知，以避免收取取消费用。如有特殊情况，请尽早联系我们，我们将尽力协助您安排更适合的时间。
              </p>
            </FAQItem>
          </FAQSection>
        </ContentContainer>
      </Section>

      <Footer />
    </MainContainer>
  );
};

export default ChineseTelemedicine; 