import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';

const ChildheadInjuries = () => {
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Head Injuries in Children: A Parent's Guide</h2>
        <p className="text-gray-700 mb-4">
          Head injuries in children are a common cause of concern for parents. While most are minor and heal quickly, 
          knowing how to identify when a head injury requires medical attention is essential for every parent and caregiver.
        </p>
        <p className="text-gray-700 mb-6">
          This guide will help you understand different types of head injuries, recognize warning signs, learn appropriate 
          first aid steps, and know when to seek medical care, including through our <Link to="/telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth services</Link>.
        </p>
      </section>
      
      <section id="types">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Types of Head Injuries in Children</h2>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Minor Head Injuries</h3>
        <p className="text-gray-700 mb-4">
          Most childhood head injuries are minor and involve only the scalp. These may include:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2"><strong>Bumps and bruises</strong> - Swelling or bruising to the scalp is common after a knock to the head</li>
          <li className="mb-2"><strong>Scalp wounds</strong> - These often bleed heavily due to the rich blood supply to the scalp</li>
          <li className="mb-2"><strong>Minor concussions</strong> - Brief alteration in mental state or consciousness</li>
        </ul>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">More Serious Head Injuries</h3>
        <p className="text-gray-700 mb-4">
          Though less common, more serious injuries may include:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2"><strong>Moderate to severe concussions</strong> - Longer periods of confusion or memory loss</li>
          <li className="mb-2"><strong>Skull fractures</strong> - Breaks in the skull bone</li>
          <li className="mb-2"><strong>Brain injuries</strong> - Contusions (bruising of brain tissue) or hemorrhage (bleeding)</li>
        </ul>
      </section>
      
      <section id="signs-symptoms">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Signs and Symptoms to Watch For</h2>
        
        <div className="bg-yellow-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-yellow-800 mb-4">Warning Signs Requiring Immediate Medical Attention</h3>
          <ul className="list-disc pl-6 text-yellow-800">
            <li className="mb-2">Unconsciousness (even if brief)</li>
            <li className="mb-2">Seizures or convulsions</li>
            <li className="mb-2">Severe or worsening headache</li>
            <li className="mb-2">Vomiting more than once</li>
            <li className="mb-2">Confusion or disorientation</li>
            <li className="mb-2">Unusual drowsiness or difficulty waking</li>
            <li className="mb-2">Slurred speech</li>
            <li className="mb-2">Weakness or numbness in arms or legs</li>
            <li className="mb-2">Unequal pupil size or abnormal eye movements</li>
            <li className="mb-2">Clear or bloody fluid coming from ears or nose</li>
            <li className="mb-2">Severe cut or wound to the head or face</li>
            <li className="mb-2">Significant change in behavior</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-6">
          For infants and toddlers, also watch for:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Persistent crying or inability to be consoled</li>
          <li className="mb-2">Refusal to eat or nurse</li>
          <li className="mb-2">Bulging fontanelle (soft spot on baby's head)</li>
        </ul>
      </section>
      
      <section id="first-aid">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">First Aid for Head Injuries</h2>
        
        <p className="text-gray-700 mb-4">
          If your child has a head injury, follow these steps:
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">For Minor Head Injuries:</h3>
        <ol className="list-decimal pl-6 mb-6 text-gray-700">
          <li className="mb-3">
            <strong>Apply cold compress</strong> - Use an ice pack wrapped in a cloth for 20 minutes to reduce swelling
          </li>
          <li className="mb-3">
            <strong>Monitor your child</strong> - Watch for any warning signs for the next 24-48 hours
          </li>
          <li className="mb-3">
            <strong>Rest</strong> - Ensure your child gets plenty of rest and avoids physical activities
          </li>
          <li className="mb-3">
            <strong>Pain relief</strong> - Paracetamol may be given for pain relief if needed (follow dosage instructions for your child's age)
          </li>
        </ol>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">For Potentially Serious Head Injuries:</h3>
        <ol className="list-decimal pl-6 mb-6 text-gray-700">
          <li className="mb-3">
            <strong>Call 000</strong> for an ambulance immediately if you observe any of the warning signs listed above
          </li>
          <li className="mb-3">
            <strong>Keep your child still</strong> - Minimize movement, especially of the head and neck
          </li>
          <li className="mb-3">
            <strong>Do not attempt to move them</strong> if you suspect a neck injury, unless absolutely necessary for safety
          </li>
          <li className="mb-3">
            <strong>If unconscious but breathing</strong> - Place in the recovery position while supporting the head and neck
          </li>
          <li className="mb-3">
            <strong>Monitor breathing</strong> - If breathing stops, begin CPR if you are trained to do so
          </li>
          <li className="mb-3">
            <strong>Control bleeding</strong> - Apply gentle pressure with a clean cloth, but don't press on any area where you suspect a skull fracture
          </li>
        </ol>
      </section>
      
      <section id="when-to-seek-medical-care">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">When to Seek Medical Care</h2>
        
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Emergency Department / Call 000</h3>
        <p className="text-gray-700 mb-6">
          Seek immediate emergency care if your child:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Shows any of the warning signs listed above</li>
          <li className="mb-2">Has fallen from a significant height (more than 1 meter for young children)</li>
          <li className="mb-2">Was involved in a high-speed accident</li>
          <li className="mb-2">Has a wound that is large, deep, or contains debris</li>
        </ul>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">GP or Telehealth Consultation</h3>
        <p className="text-gray-700 mb-6">
          Contact your GP or use our <Link to="/telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth service</Link> if:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Your child has had a minor head injury without warning signs but you're concerned</li>
          <li className="mb-2">Your child is under 1 year of age with any head injury</li>
          <li className="mb-2">Your child has ongoing headaches after a head injury</li>
          <li className="mb-2">You notice changes in your child's behavior in the days following a head injury</li>
          <li className="mb-2">Your child is having trouble returning to normal activities</li>
        </ul>
      </section>
      
      <section id="recovery-observation">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Recovery and Observation Period</h2>
        
        <p className="text-gray-700 mb-4">
          After a head injury, an observation period is crucial:
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">The First 24-48 Hours</h3>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Monitor your child's condition closely, especially during the first 24 hours</li>
          <li className="mb-2">It's okay to let your child sleep, but initially check on them every 2-3 hours</li>
          <li className="mb-2">Wake your child briefly to ensure they respond normally and are easily roused</li>
          <li className="mb-2">Keep activities quiet and calm</li>
          <li className="mb-2">Follow any specific instructions provided by your healthcare professional</li>
        </ul>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Returning to Activities</h3>
        <p className="text-gray-700 mb-6">
          After a mild head injury:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Allow 24-48 hours of rest</li>
          <li className="mb-2">Gradually return to normal activities as symptoms improve</li>
          <li className="mb-2">Avoid sports, playground activities, and rough play for at least 1-2 weeks</li>
          <li className="mb-2">Return to school when your child feels ready and symptoms have resolved</li>
          <li className="mb-2">Follow your doctor's specific guidance, especially for concussions</li>
        </ul>
      </section>
      
      <section id="prevention">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Preventing Head Injuries in Children</h2>
        
        <p className="text-gray-700 mb-4">
          Many childhood head injuries can be prevented with appropriate safety measures:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">At Home</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Install safety gates at the top and bottom of stairs</li>
              <li className="mb-2">Ensure windows have secure guards</li>
              <li className="mb-2">Use non-slip mats in bathtubs and showers</li>
              <li className="mb-2">Secure or remove furniture that can tip over</li>
              <li className="mb-2">Keep floors clear of trip hazards</li>
              <li className="mb-2">Supervise young children on playground equipment</li>
            </ul>
          </div>
          
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Recreation & Sport</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Ensure proper-fitting helmets for bicycles, scooters, skateboards, and other wheeled activities</li>
              <li className="mb-2">Use appropriate protective gear for sports</li>
              <li className="mb-2">Teach water safety and supervise children around water</li>
              <li className="mb-2">Choose age-appropriate playground equipment</li>
              <li className="mb-2">Ensure playground surfaces are soft (bark chips, sand, etc.)</li>
            </ul>
          </div>
        </div>
        
        <div className="bg-gray-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Car Safety</h3>
          <ul className="list-disc pl-6 text-gray-700">
            <li className="mb-2">Always use appropriate car seats or boosters based on your child's age, weight, and height</li>
            <li className="mb-2">Ensure car seats are correctly installed</li>
            <li className="mb-2">Children under 12 should ride in the back seat</li>
            <li className="mb-2">Never leave children unattended in vehicles</li>
          </ul>
        </div>
      </section>
      
      <section id="conclusion">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Conclusion</h2>
        
        <p className="text-gray-700 mb-4">
          While head injuries can be frightening, most childhood head injuries are minor and heal completely. Being prepared 
          with the knowledge of how to respond appropriately and when to seek medical attention can make all the difference 
          in ensuring the best outcome for your child.
        </p>
        
        <p className="text-gray-700 mb-4">
          Remember that our medical team is available through both in-person appointments and <Link to="/telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth consultations</Link> if 
          you have concerns about your child's head injury. Trust your instincts as a parent - if you're worried, don't hesitate to seek medical advice.
        </p>
        
        <div className="bg-indigo-50 p-6 rounded-lg mt-8">
          <h3 className="text-xl font-semibold text-indigo-800 mb-4">Need to Consult a Doctor?</h3>
          <p className="text-indigo-700 mb-4">
            If your child has experienced a head injury and you'd like professional advice:
          </p>
          <ul className="list-disc pl-6 text-indigo-700 mb-6">
            <li className="mb-2">Book an in-person appointment through our <Link to="/booking" className="text-indigo-600 hover:text-indigo-800 font-medium">online booking system</Link></li>
            <li className="mb-2">Schedule a <Link to="/telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth consultation</Link> for convenient medical advice</li>
            <li className="mb-2">For emergency situations, always call 000 or go to your nearest emergency department</li>
          </ul>
        </div>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Immediate Response',
      description: 'Apply first aid, assess severity, and determine if emergency care is needed',
      duration: '0-30 minutes after injury'
    },
    {
      stage: 'Initial Monitoring',
      description: 'Close observation for warning signs, application of cold compress for swelling, rest',
      duration: 'First 4-6 hours'
    },
    {
      stage: 'Continued Observation',
      description: 'Ongoing monitoring for new or worsening symptoms, ensuring adequate rest',
      duration: '24-48 hours'
    },
    {
      stage: 'Recovery Period',
      description: 'Gradual return to normal activities as symptoms improve, avoiding physical exertion',
      duration: '3-7 days for minor injuries'
    },
    {
      stage: 'Return to Full Activities',
      description: 'Complete return to normal activities including school and gentle play',
      duration: '1-2 weeks for minor injuries'
    }
  ];

  const references = [
    {
      authors: 'Queensland Health',
      title: 'Minor Head Injury Advice',
      journal: 'Clinical Excellence Queensland',
      year: '2022',
      doi: null
    },
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Head Injury - Advice',
      journal: 'Kids Health Info',
      year: '2020',
      doi: null
    },
    {
      authors: 'Lumba-Brown A, Yeates KO, Sarmiento K, et al.',
      title: 'Centers for Disease Control and Prevention Guideline on the Diagnosis and Management of Mild Traumatic Brain Injury Among Children',
      journal: 'JAMA Pediatrics',
      year: '2018',
      doi: '10.1001/jamapediatrics.2018.2853'
    },
    {
      authors: 'Queensland Paediatric Emergency Care',
      title: 'Head Injury - Emergency management in children',
      journal: 'Queensland Clinical Guidelines',
      year: '2023',
      doi: null
    }
  ];

  const symptoms = [
    'Headache',
    'Dizziness',
    'Nausea or vomiting',
    'Fatigue or drowsiness',
    'Blurry vision',
    'Sensitivity to light or noise',
    'Balance problems',
    'Feeling foggy or groggy',
    'Difficulty concentrating',
    'Confusion or disorientation',
    'Behavior or personality changes',
    'Sleep disturbances'
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Types of Head Injuries', anchor: 'types' },
    { title: 'Signs and Symptoms', anchor: 'signs-symptoms' },
    { title: 'First Aid', anchor: 'first-aid' },
    { title: 'When to Seek Medical Care', anchor: 'when-to-seek-medical-care' },
    { title: 'Recovery and Observation', anchor: 'recovery-observation' },
    { title: 'Prevention', anchor: 'prevention' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  return (
    <MedicalBlogTemplate
      title="Head Injuries in Children: What Parents Need to Know"
      subtitle="A guide to recognizing, responding to, and preventing head injuries in children"
      heroImage="https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3600"
      publishDate="April 2, 2024"
      readTime={12}
      content={content}
      category="Child Health"
      tags={['Head Injury', 'Concussion', 'Child Safety', 'First Aid', 'Pediatrics', 'Telehealth']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      showEmergencyBox={true}
      tableOfContents={tableOfContents}
    />
  );
};

export default ChildheadInjuries; 