import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import 'leaflet/dist/leaflet.css';
import drTerryPhoto from '../assets/images/profile/drterry_hero.png';

// Import placeholder image
import doctorbagImage from '../assets/images/sections/doctorbag.png';

const NightDoctors = () => {
  // SEO structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "LocalBusiness", "Physician"],
    "name": "Dr. Terry Nguyen - Sydney Night Doctor Service",
    "description": "24/7 night doctor home visits across Sydney. After-hours medical care delivered to your home, hotel or accommodation with no waiting. Hotel doctor service available in CBD.",
    "url": "https://homedoc.com.au/night-doctors",
    "telephone": "+61412345678",
    "priceRange": "$$$",
    "availableService": [
      {
        "@type": "MedicalService",
        "name": "After Hours Doctor Service",
        "description": "Medical care delivered to your doorstep outside normal business hours."
      },
      {
        "@type": "MedicalService",
        "name": "Hotel Doctor Service",
        "description": "Medical care for travelers and guests staying in Sydney CBD hotels and accommodations."
      },
      {
        "@type": "MedicalService",
        "name": "Night House Call Service",
        "description": "Urgent medical attention during nighttime hours from 10pm to 8am."
      }
    ],
    "serviceArea": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "-33.8688",
        "longitude": "151.2093"
      },
      "geoRadius": "20km"
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        "opens": "00:00",
        "closes": "23:59"
      }
    ]
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      {/* SEO Component */}
      <SEO 
        title="Sydney Night Doctors | After Hours Home Visits | Hotel Doctor CBD"
        description="I am available 24/7 as a night doctor in Sydney providing after-hours home visits and hotel doctor services in the CBD. Immediate medical attention when you need it most."
        keywords={[
          'night doctor Sydney',
          'after hours doctor Sydney',
          'Sydney night doctors',
          'hotel doctor Sydney CBD',
          'after hours GP Sydney',
          'home doctor service Sydney',
          'after hours medical care',
          'private night doctor',
          'urgent home doctor Sydney',
          'hotel room doctor visit',
          'late night doctor Sydney',
          'midnight doctor service',
          'emergency home doctor'
        ]}
        schema={structuredData}
      />
      
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="relative">
          <div className="h-[60vh] md:h-[70vh] bg-gradient-to-b from-[#1A1A1A]/90 to-[#1A1A1A]/30" style={{
            backgroundImage: `url(${doctorbagImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay'
          }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="max-w-4xl mx-auto px-4 text-center">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-light mb-6 text-white">
                  Sydney Night Doctors
                </h1>
                <p className="text-xl md:text-2xl text-white/90 mb-8">
                  Premium after-hours medical care delivered directly to your home or hotel
                </p>
                <Link
                  to="/dynamic-booking"
                  state={{ service: "Night Doctor Visit" }}
                  className="inline-flex items-center px-8 py-4 text-lg border-2 border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                >
                  Book a Night Doctor
                  <svg className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* 24/7 Availability Banner */}
        <div className="bg-[#DDC6A8] text-[#1A1A1A] py-4">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-2xl font-medium">
              I am available 24/7 for night and after-hours medical care across Sydney
            </h2>
            <p className="mt-2 text-[#1A1A1A]/80 font-medium">
              Immediate attention • No waiting rooms • Direct doctor access • Call now
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="py-16">
          <div className="max-w-4xl mx-auto px-4">
            <div className="prose prose-lg prose-invert max-w-none">
              <h2 className="text-3xl font-light text-[#DDC6A8]">Sydney's Premier Night Doctor Service</h2>
              
              <p className="text-xl">
                Dr. Terry Nguyen provides exceptional after-hours medical care through premium night doctor visits across Sydney. When standard medical practices are closed, Dr. Terry brings comprehensive healthcare directly to your doorstep.
              </p>
              
              <p>
                As Sydney's leading night doctor service, we understand that illness and injury don't follow business hours. Our night doctor service operates from evening through to early morning, ensuring you have access to quality medical care exactly when you need it most.
              </p>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">Hotel Doctor Service in Sydney CBD</h2>
                
                <p>
                  Traveling in an unfamiliar city and feeling unwell can be a distressing experience. Our specialized hotel doctor service provides prompt medical attention for visitors staying in Sydney CBD hotels and accommodations.
                </p>
                
                <p>
                  Dr. Terry offers discreet, professional medical care in the comfort and privacy of your hotel room, eliminating the need to navigate unfamiliar medical facilities while feeling unwell. From business travelers to tourists, our hotel doctor service ensures you receive the same exceptional care you'd expect at home.
                </p>
                
                <div className="bg-[#DDC6A8]/10 p-6 rounded-lg mt-6">
                  <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Hotels We Service in Sydney CBD</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <ul className="space-y-2">
                      <li>• Four Seasons Hotel Sydney</li>
                      <li>• Shangri-La Hotel Sydney</li>
                      <li>• The Langham Sydney</li>
                      <li>• Hyatt Regency Sydney</li>
                      <li>• Hilton Sydney</li>
                      <li>• Sofitel Sydney Darling Harbour</li>
                    </ul>
                    <ul className="space-y-2">
                      <li>• Sheraton Grand Sydney Hyde Park</li>
                      <li>• InterContinental Sydney</li>
                      <li>• The Star Grand Hotel</li>
                      <li>• Park Hyatt Sydney</li>
                      <li>• Westin Sydney</li>
                      <li>• All other CBD accommodations</li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">When to Call a Night Doctor</h2>
                
                <p>
                  Our night doctor service is designed to address urgent but non-life-threatening medical concerns that arise outside normal business hours. Some common reasons to call a night doctor include:
                </p>
                
                <ul className="space-y-2 mt-4">
                  <li>• High fever (especially in children)</li>
                  <li>• Severe cold or flu symptoms</li>
                  <li>• Migraine headaches</li>
                  <li>• Ear, nose, and throat infections</li>
                  <li>• Gastrointestinal issues (vomiting, diarrhea)</li>
                  <li>• Skin infections or rashes</li>
                  <li>• Urinary tract infections</li>
                  <li>• Minor injuries requiring assessment</li>
                  <li>• Asthma or respiratory distress</li>
                  <li>• Prescription refills for essential medications</li>
                </ul>
                
                <div className="bg-[#DDC6A8]/10 p-6 rounded-lg mt-6">
                  <p className="text-[#DDC6A8] font-medium">
                    For life-threatening emergencies including chest pain, severe bleeding, difficulty breathing, or stroke symptoms, please call 000 immediately.
                  </p>
                </div>
                
                <div className="my-8 p-6 border border-[#DDC6A8] rounded-lg bg-gradient-to-r from-[#007BA7]/10 to-[#0D2E5C]/10">
                  <h3 className="text-2xl font-light text-[#DDC6A8] mb-3">Ultimate Guide to Urgent House Call Medical Services</h3>
                  <p className="mb-4">
                    Looking for comprehensive information about house call doctor services? Our detailed guide covers everything you need to know about when to use this service, what conditions are treated, costs, and what to expect during a visit.
                  </p>
                  <Link 
                    to="/guides/urgent-house-call" 
                    className="inline-flex items-center px-6 py-3 text-base border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                  >
                    Read the Complete Guide
                    <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </Link>
                </div>
              </div>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">How Our Night Doctor Service Works</h2>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-6">
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-lg">
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">1. Book a Visit</h3>
                    <p>
                      Request a night doctor visit through our online booking system or by phone. Provide your location and symptoms for an accurate assessment.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-lg">
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">2. Doctor Arrives</h3>
                    <p>
                      Dr. Terry will arrive at your location within the scheduled timeframe, bringing all necessary medical equipment for comprehensive care.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-lg">
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">3. Treatment & Follow-Up</h3>
                    <p>
                      Receive personalized treatment in the comfort of your home or hotel, including prescriptions, referrals, and follow-up care as needed.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">Areas We Serve</h2>
                
                <p>
                  Our night doctor service covers the following areas in Sydney:
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-6">
                  <div>
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">Eastern Suburbs</h3>
                    <ul className="space-y-1">
                      <li>• <Link to="/locations/bondi-area" className="hover:text-white">Bondi & Bondi Junction</Link></li>
                      <li>• <Link to="/locations/double-bay-area" className="hover:text-white">Double Bay & Point Piper</Link></li>
                      <li>• <Link to="/locations/randwick-area" className="hover:text-white">Randwick & Coogee</Link></li>
                      <li>• Woollahra</li>
                      <li>• Rose Bay</li>
                      <li>• Paddington</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">Inner City</h3>
                    <ul className="space-y-1">
                      <li>• <Link to="/locations/sydney-cbd-area" className="hover:text-white">Sydney CBD</Link></li>
                      <li>• Darlinghurst</li>
                      <li>• Surry Hills</li>
                      <li>• Potts Point</li>
                      <li>• Elizabeth Bay</li>
                      <li>• Pyrmont</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-medium text-[#DDC6A8] mb-3">Inner South</h3>
                    <ul className="space-y-1">
                      <li>• <Link to="/locations/rosebery-waterloo-area" className="hover:text-white">Waterloo & Rosebery</Link></li>
                      <li>• Alexandria</li>
                      <li>• Zetland</li>
                      <li>• Redfern</li>
                      <li>• Erskineville</li>
                      <li>• Newtown</li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">Night Doctor Pricing</h2>
                
                <p>
                  Our night doctor service operates on a private billing model, reflecting the premium nature of after-hours care delivered directly to your location. Time-based pricing applies:
                </p>
                
                <div className="overflow-x-auto mt-6">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-[#DDC6A8]/10">
                        <th className="p-3 text-left text-[#DDC6A8] font-medium">Service Time</th>
                        <th className="p-3 text-right text-[#DDC6A8] font-medium">Price Range</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#DDC6A8]/10">
                      <tr>
                        <td className="p-3">Evening (6pm-10pm)</td>
                        <td className="p-3 text-right">$190-$240</td>
                      </tr>
                      <tr className="bg-[#DDC6A8]/5">
                        <td className="p-3">Night (10pm-12am)</td>
                        <td className="p-3 text-right">$210-$260</td>
                      </tr>
                      <tr>
                        <td className="p-3">Late Night/Early Morning (12am-8am)</td>
                        <td className="p-3 text-right">$250-$320</td>
                      </tr>
                      <tr className="bg-[#DDC6A8]/5">
                        <td className="p-3">Hotel Doctor Service (any time)</td>
                        <td className="p-3 text-right">$260-$350</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
                <p className="mt-6 text-sm">
                  *Prices vary based on location, day of the week, and specific medical needs. Payment can be made via credit card, EFTPOS, or direct bank transfer. Private health insurance rebates may apply.
                </p>
              </div>
              
              <div className="mt-12">
                <h2 className="text-3xl font-light text-[#DDC6A8]">Frequently Asked Questions</h2>
                
                <div className="space-y-6 mt-6">
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                    <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How quickly can a night doctor arrive?</h3>
                    <p>
                      For evening appointments (6pm-10pm), Dr. Terry typically arrives within 1-2 hours of booking. For late-night visits (10pm-8am), arrival times depend on current demand and your location, but we prioritize urgent cases. Hotel doctor services in the CBD often have faster response times.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                    <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">What equipment and medications does the night doctor bring?</h3>
                    <p>
                      Dr. Terry carries a comprehensive medical bag equipped with diagnostic tools (stethoscope, otoscope, blood pressure monitor, oximeter, etc.) and a range of commonly needed medications for immediate treatment. For medications requiring ongoing use, electronic prescriptions can be sent to your preferred pharmacy.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                    <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Is Medicare bulk billing available for night doctor visits?</h3>
                    <p>
                      Our night doctor service operates on a private billing model to ensure premium, personalized care with minimal wait times. While Medicare rebates are available for eligible services, we do not offer bulk billing. We provide detailed receipts for Medicare claims and private health insurance.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                    <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Do you provide doctor certificates for work or school?</h3>
                    <p>
                      Yes, Dr. Terry can provide medical certificates for work, school, or university absences during your night doctor visit. These certificates are professionally prepared and include all necessary information required by employers and educational institutions.
                    </p>
                  </div>
                  
                  <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                    <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Can international visitors use the hotel doctor service?</h3>
                    <p>
                      Absolutely. Our hotel doctor service is particularly valuable for international visitors who may be unfamiliar with the Australian healthcare system. Dr. Terry can provide care regardless of your nationality or insurance status. We accept various payment methods, including international credit cards, and can provide documentation for travel insurance claims.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="mt-12 text-center">
                <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Need a Night Doctor in Sydney?</h2>
                <p className="mb-8">
                  Don't wait until morning when you need medical attention now.
                </p>
                <Link
                  to="/dynamic-booking"
                  state={{ service: "Night Doctor Visit" }}
                  className="inline-flex items-center px-8 py-4 bg-[#DDC6A8] text-[#1A1A1A] rounded-full hover:bg-white hover:text-[#1A1A1A] transition-all group shadow-lg"
                >
                  Book a Night Doctor
                  <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NightDoctors;
