import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { db } from '../config/firebase';
import { motion } from 'framer-motion';

// Static blog entries for fallback specific to men's health - update with correct slugs
const menHealthStaticEntries = [
  {
    id: 'static-executive-health-check',
    title: 'Executive Health Checks: Comprehensive Assessments for Busy Professionals in Sydney',
    slug: 'executive-health-check-sydney',
    excerpt: 'Discover how executive health checks in Sydney\'s Eastern Suburbs can help busy professionals maintain optimal health, prevent disease, and maximize performance.',
    category: "Men's Health",
    tags: ["executive health", "health check", "men's health", "preventative care"],
    imageUrl: '/images/drterry_menshealth7.png',
    createdAt: new Date('2024-07-05'),
    isStatic: true
  },
  {
    id: 'static-erectile-dysfunction',
    title: "Erectile Dysfunction: More Than Just a Pill | Men's Health Sydney",
    slug: 'erectile-dysfunction-treatment-sydney',
    excerpt: "Personalized erectile dysfunction treatment in Sydney's Eastern Suburbs with Dr. Terry Nguyen. Confidential consultations, holistic approach, and evidence-based solutions.",
    category: "Men's Health",
    tags: ["erectile dysfunction", "men's health", "sexual health"],
    imageUrl: '/images/erectile-dysfunction-consultation.jpg',
    createdAt: new Date('2024-07-03'),
    isStatic: true
  },
  {
    id: 'static-mens-health-hormones',
    title: 'Male Hormonal Health: Understanding Testosterone and Its Effects',
    slug: 'male-hormonal-health-testosterone',
    excerpt: "Dr. Terry Nguyen explains testosterone's role in men's health, symptoms of low levels, and treatment options available in Sydney's Eastern Suburbs.",
    category: "Men's Health",
    tags: ["testosterone", "hormones", "men's health"],
    imageUrl: '/images/drterry_menshealth1.png',
    createdAt: new Date('2024-07-01'),
    isStatic: true
  },
  {
    id: 'static-mens-health-preventative',
    title: 'Preventative Health Screenings Every Man Should Have',
    slug: 'mens-preventative-health-screenings-sydney',
    excerpt: 'Essential health screenings for men in Sydney - what tests you need at different ages to maintain optimal health and prevent disease.',
    category: "Men's Health",
    tags: ["preventative health", "screening", "men's health"],
    imageUrl: '/images/drterry_menshealth3.png',
    createdAt: new Date('2024-06-30'),
    isStatic: true
  },
  {
    id: 'static-mens-prostate-health',
    title: 'Prostate Health: A Comprehensive Guide for Australian Men',
    slug: 'prostate-health-guide-sydney',
    excerpt: 'Everything Sydney men need to know about prostate health - from prevention to screenings and treatment options with Dr. Terry Nguyen.',
    category: "Men's Health",
    tags: ["prostate health", "prostate cancer", "men's health"],
    imageUrl: '/images/drterry_menshealth5.png',
    createdAt: new Date('2024-06-28'),
    isStatic: true
  }
];

// Static blog entries for Private Doctor Services
const privateDocStaticEntries = [
  {
    id: 'static-private-jet-medical',
    title: 'Private Jet Medical Services: Ensuring Health & Safety During Global Travel',
    slug: 'private-jet-medical-services',
    excerpt: 'Comprehensive medical support for private jet travel worldwide. Learn how our Sydney-based private physician services ensure health safety during international flights.',
    category: "Private Doctor Services",
    tags: ["private jet doctor", "travel medicine", "VIP healthcare", "international medical support"],
    imageUrl: '/images/drtez_private_jet_on_runway_--ar_169_--v_6.1_c580842d-d85f-49b2-9159-a5887075f3b8_3.png',
    createdAt: new Date('2024-07-10'),
    isStatic: true
  },
  {
    id: 'static-production-medical',
    title: 'On-Set Medical Services: Healthcare Support for Film & TV Productions',
    slug: 'on-set-medical-services',
    excerpt: 'Expert medical support for cast and crew during film and television productions in Sydney and internationally. Confidential healthcare for high-profile talent.',
    category: "Private Doctor Services",
    tags: ["production medical services", "on-set doctor", "film industry healthcare", "celebrity doctor"],
    imageUrl: '/images/drtez_Close-up_of_a_luxury_smartphone_with_a_contact_screen_s_3879fbc9-2d8d-4acf-960e-5eb0c9efa2f5_3.png',
    createdAt: new Date('2024-07-07'),
    isStatic: true
  },
  {
    id: 'static-executive-health',
    title: 'Executive Health Programs: Tailored Medical Care for Business Leaders',
    slug: 'executive-health-programs',
    excerpt: 'Comprehensive health assessments and ongoing care designed specifically for executives and business leaders in Sydney. Discreet, efficient healthcare that fits your schedule.',
    category: "Private Doctor Services",
    tags: ["executive health", "corporate wellness", "business leader healthcare", "concierge medicine"],
    imageUrl: '/images/drtez_Modern_coffee_table_with_open_MacBook_stethoscope_and_g_27ec4ad2-c21a-4429-9e1b-379509dd855a_2.png',
    createdAt: new Date('2024-07-05'),
    isStatic: true
  },
  {
    id: 'static-yacht-medical',
    title: 'Yacht Medical Services: Comprehensive Healthcare During Maritime Travel',
    slug: 'yacht-medical-services',
    excerpt: 'Specialized medical care for yacht owners, guests, and crew during maritime journeys. From Sydney Harbour to international waters, our physician services ensure health safety at sea.',
    category: "Private Doctor Services",
    tags: ["yacht doctor", "maritime medicine", "sailing healthcare", "medical support at sea"],
    imageUrl: '/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png',
    createdAt: new Date('2024-07-02'),
    isStatic: true
  },
  {
    id: 'static-concierge-doctor',
    title: 'Concierge Medicine in Sydney: The Future of Personalized Healthcare',
    slug: 'concierge-medicine-sydney',
    excerpt: 'Discover how concierge doctor services in Sydney are transforming healthcare delivery with personalized attention, extended consultations, and 24/7 physician access.',
    category: "Private Doctor Services",
    tags: ["concierge medicine", "personalized healthcare", "retainer medicine", "premium health services"],
    imageUrl: '/images/drtez_Black_leather_doctors_bag_placed_neatly_on_an_Eames_lou_0e8e2185-2e1d-427a-b87e-3dc7effd1ff6_0.png',
    createdAt: new Date('2024-06-28'),
    isStatic: true
  }
];

const CategoryBlogCarousel = ({ category, tags, title = "Related Articles", limit: postLimit = 5 }) => {
  const [posts, setPosts] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    async function fetchPosts() {
      try {
        let postList = [];
        const postsRef = collection(db, 'blog_posts');
        
        // Create base query with category filter if provided
        let baseQuery;
        if (category) {
          baseQuery = query(
            postsRef, 
            where('category', '==', category),
            orderBy('createdAt', 'desc'), 
            limit(postLimit)
          );
        } else if (tags && tags.length > 0) {
          // If no category but tags are provided, query by tags
          // Note: Firestore requires a composite index for array-contains-any with orderBy
          baseQuery = query(
            postsRef, 
            where('tags', 'array-contains-any', tags),
            orderBy('createdAt', 'desc'), 
            limit(postLimit)
          );
        } else {
          // If neither category nor tags are provided, just get recent posts
          baseQuery = query(postsRef, orderBy('createdAt', 'desc'), limit(postLimit));
        }
        
        const querySnapshot = await getDocs(baseQuery);
        postList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // If we have results from Firestore
        if (postList.length > 0) {
          setPosts(postList);
        } else {
          // If no posts in Firestore, use the static entries relevant to category
          console.log(`No posts found in Firestore for category '${category}', using static entries`);
          
          // Use category-specific static entries depending on the requested category
          if (category === "Men's Health" || (tags && tags.some(tag => ["men's health", "male health"].includes(tag.toLowerCase())))) {
            setPosts(menHealthStaticEntries);
          } else if (category === "Private Doctor Services" || (tags && tags.some(tag => ["private doctor", "concierge medicine", "VIP healthcare"].includes(tag.toLowerCase())))) {
            setPosts(privateDocStaticEntries);
          } else {
            // For any other category with no results, still show relevant content based on the category
            if (category === "Private Doctor Services") {
              setPosts(privateDocStaticEntries);
            } else {
              // Default to men's health content
              setPosts(menHealthStaticEntries);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching category blog posts:', error);
        // Use static entries as fallback based on category
        console.log('Error fetching from Firestore, using static blog entries');
        if (category === "Private Doctor Services") {
          setPosts(privateDocStaticEntries);
        } else {
          setPosts(menHealthStaticEntries);
        }
      }
    }

    fetchPosts();
  }, [category, tags, postLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (!carouselRef.current) return;
      
      const scrollLeft = carouselRef.current.scrollLeft;
      const itemWidth = carouselRef.current.offsetWidth;
      const newActiveSlide = Math.round(scrollLeft / itemWidth);
      
      setActiveSlide(newActiveSlide);
    };

    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleDotClick = (index) => {
    if (!carouselRef.current) return;
    
    const itemWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollTo({
      left: itemWidth * index,
      behavior: 'smooth'
    });
  };

  if (posts.length === 0) {
    return null; // Don't render if no posts found
  }

  return (
    <section className="blog-carousel py-16 bg-[#1A1A1A]">
      <div className="container mx-auto px-4 md:px-6">
        <div className="stagger-animation mb-12">
          <motion.h2 
            className="text-3xl font-bold text-white mb-3 tracking-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {title}
          </motion.h2>
        </div>
        
        <div 
          ref={carouselRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide pl-4 md:pl-8 lg:pl-16 -mr-4 md:-mr-8 lg:-mr-16"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {posts.map((post, index) => (
            <div
              key={post.id}
              className="min-w-[85vw] md:min-w-[40vw] lg:min-w-[33vw] flex-shrink-0 snap-center px-2 md:px-3"
            >
              <div className="relative aspect-[3/4] rounded-2xl overflow-hidden bg-[#2A2A2A] shadow-lg group">
                <div className="absolute inset-0">
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                  />
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent">
                  <div className="absolute bottom-0 left-0 p-6 md:p-8 lg:p-10">
                    <div className="flex items-center space-x-2 mb-4">
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-light bg-[#DDC6A8]/20 text-[#DDC6A8]">
                        {post.category}
                      </span>
                      <time className="text-sm text-white/90">
                        {post.createdAt?.toDate ? post.createdAt.toDate().toLocaleDateString() : 
                         post.createdAt instanceof Date ? post.createdAt.toLocaleDateString() : 
                         typeof post.createdAt === 'string' ? new Date(post.createdAt).toLocaleDateString() : 
                         'Undated'}
                      </time>
                    </div>
                    <h3 className="text-2xl md:text-3xl lg:text-4xl font-medium mb-4 !text-white">{post.title}</h3>
                    <p className="text-base md:text-lg !text-white mb-6">{post.excerpt}</p>
                    <Link
                      to={`/blog/${post.slug}`}
                      className="inline-flex items-center px-4 py-2 text-sm md:text-base font-medium border border-white !text-white rounded-full hover:bg-white hover:!text-black transition-all group"
                    >
                      Read Article
                      <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Scroll Indicator */}
        <div className="flex justify-center space-x-2 mt-6">
          {posts.slice(0, Math.min(5, posts.length)).map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full transition-all ${
                activeSlide === index ? 'bg-[#DDC6A8] scale-110' : 'bg-gray-500 hover:bg-gray-400'
              }`}
              aria-label={`Go to slide ${index + 1}`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CategoryBlogCarousel; 