import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function GoogleSheetsSetup() {
  const [sheetId, setSheetId] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        // Fetch Google Sheets settings from Firestore
        const settingsDoc = await getDoc(doc(db, 'settings', 'google_sheets'));
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          setSheetId(data.sheet_id || '');
        }

        // Fetch OAuth settings
        const oauthDoc = await getDoc(doc(db, 'settings', 'google_oauth'));
        if (oauthDoc.exists()) {
          const data = oauthDoc.data();
          setRefreshToken(data.refresh_token || '');
        }
      } catch (err) {
        console.error('Error fetching settings:', err);
        setError('Failed to load settings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    setError('');

    try {
      // Save Google Sheets settings to Firestore
      await setDoc(doc(db, 'settings', 'google_sheets'), {
        sheet_id: sheetId,
        updated_at: new Date()
      });

      setMessage('Settings saved successfully');
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Failed to save settings');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAuthorize = () => {
    window.location.href = '/.netlify/functions/setup-google-oauth';
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8]">Loading settings...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1A1A1A] py-12 px-4">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-[#DDC6A8] mb-8">Google Sheets Integration Setup</h1>
        
        {message && (
          <div className="bg-green-900/20 border border-green-500/20 text-green-400 p-4 rounded-lg mb-6">
            {message}
          </div>
        )}
        
        {error && (
          <div className="bg-red-900/20 border border-red-500/20 text-red-400 p-4 rounded-lg mb-6">
            {error}
          </div>
        )}
        
        <div className="bg-[#2A2A2A] rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold text-[#DDC6A8] mb-4">Step 1: Create a Google Sheet</h2>
          <p className="text-[#DDC6A8]/80 mb-4">
            Create a new Google Sheet with the following columns in the first row:
          </p>
          <div className="bg-[#1A1A1A] p-4 rounded-lg mb-4 overflow-x-auto">
            <code className="text-[#DDC6A8]/90">
              BookingID, Date, Time, Service, PatientName, PatientEmail, PatientPhone, Address, Price, Status, PaymentStatus, PaymentID, Urgency, BookedOn, Notes
            </code>
          </div>
          <p className="text-[#DDC6A8]/80 mb-4">
            After creating the sheet, copy the Sheet ID from the URL. It's the long string between /d/ and /edit in the URL.
          </p>
          <form onSubmit={handleSaveSettings} className="mt-4">
            <div className="mb-4">
              <label htmlFor="sheetId" className="block text-[#DDC6A8] mb-2">Google Sheet ID</label>
              <input
                type="text"
                id="sheetId"
                value={sheetId}
                onChange={(e) => setSheetId(e.target.value)}
                className="w-full p-3 bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded-lg text-[#DDC6A8] focus:outline-none focus:border-[#DDC6A8]"
                placeholder="1BxiMVs0XRA5nFMdKvBdBZjgmUUqptlbs74OgvE2upms"
              />
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg font-semibold hover:bg-[#DDC6A8]/90 transition-colors"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Sheet ID'}
            </button>
          </form>
        </div>
        
        <div className="bg-[#2A2A2A] rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold text-[#DDC6A8] mb-4">Step 2: Authorize Google Sheets Access</h2>
          <p className="text-[#DDC6A8]/80 mb-4">
            Click the button below to authorize the application to access your Google Sheets. You'll be redirected to Google's authorization page.
          </p>
          
          {refreshToken ? (
            <div className="bg-green-900/20 border border-green-500/20 text-green-400 p-4 rounded-lg mb-4">
              <p className="font-semibold">✅ Google Sheets access is authorized</p>
              <p className="text-sm mt-2">Refresh token is already set up.</p>
            </div>
          ) : (
            <button
              onClick={handleAuthorize}
              className="px-6 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-colors"
            >
              Authorize Google Sheets Access
            </button>
          )}
        </div>
        
        <div className="bg-[#2A2A2A] rounded-lg p-6">
          <h2 className="text-xl font-semibold text-[#DDC6A8] mb-4">Step 3: Test the Integration</h2>
          <p className="text-[#DDC6A8]/80 mb-4">
            Once you've completed steps 1 and 2, you can test the integration by making a test booking. The booking details should be automatically added to your Google Sheet.
          </p>
          <a
            href="/dynamic-booking"
            className="inline-block px-6 py-3 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg font-semibold hover:bg-[#DDC6A8]/90 transition-colors"
          >
            Go to Booking Page
          </a>
        </div>
      </div>
    </div>
  );
} 