/**
 * Blog generation utility for creating medical content focused on the Australian healthcare market
 * This helps improve SEO by targeting relevant keywords and providing quality content
 */

// Categories relevant to Australian healthcare
export const BLOG_CATEGORIES = [
  { name: 'Telemedicine', slug: 'telemedicine' },
  { name: 'Family Medicine', slug: 'family-medicine' },
  { name: 'Men\'s Health', slug: 'mens-health' },
  { name: 'Women\'s Health', slug: 'womens-health' },
  { name: 'Pediatrics', slug: 'pediatrics' },
  { name: 'Mental Health', slug: 'mental-health' },
  { name: 'Chronic Disease Management', slug: 'chronic-disease-management' },
  { name: 'Medicare', slug: 'medicare' },
  { name: 'Bulk Billing', slug: 'bulk-billing' },
  { name: 'Preventive Care', slug: 'preventive-care' },
  { name: 'Australian Healthcare System', slug: 'australian-healthcare-system' },
  { name: 'Rural Health', slug: 'rural-health' },
  { name: 'Indigenous Health', slug: 'indigenous-health' },
  { name: 'Nutrition', slug: 'nutrition' },
  { name: 'Private Doctor Services', slug: 'private-doctor-services' }
];

// Trending health topics in Australia
export const TRENDING_TOPICS = [
  { 
    title: 'Navigating Telehealth in Australia: Medicare Benefits & Best Practices',
    category: 'Telemedicine',
    keywords: ['telehealth', 'medicare benefits', 'australia', 'online doctor', 'virtual consultation']
  },
  { 
    title: 'Understanding the New Medicare Mental Health Changes in Australia',
    category: 'Mental Health',
    keywords: ['mental health plan', 'medicare', 'psychology', 'australia', 'bulk billing']
  },
  { 
    title: 'Managing Type 2 Diabetes in Australia: Latest Guidelines',
    category: 'Chronic Disease Management',
    keywords: ['diabetes', 'chronic disease', 'australia', 'management', 'medicare']
  },
  { 
    title: 'Childhood Vaccination Schedule: What Australian Parents Need to Know',
    category: 'Pediatrics',
    keywords: ['vaccines', 'immunization', 'children', 'australia', 'schedule']
  },
  {
    title: 'Bulk Billing Explained: What Services Are Covered for Australian Families',
    category: 'Australian Healthcare System',
    keywords: ['bulk billing', 'medicare', 'australia', 'healthcare', 'GP visit']
  },
  {
    title: 'Men\'s Health Check: Essential Screenings for Australian Men by Age',
    category: 'Men\'s Health', 
    keywords: ['men\'s health', 'screening', 'preventive care', 'australia', 'check-up']
  },
  {
    title: 'Women\'s Health: Key Preventive Screenings Covered by Medicare',
    category: 'Women\'s Health',
    keywords: ['women\'s health', 'screening', 'preventive care', 'australia', 'medicare']
  },
  {
    title: 'Rural Healthcare Access in Australia: Telemedicine Solutions',
    category: 'Rural Health',
    keywords: ['rural health', 'telemedicine', 'australia', 'healthcare access', 'remote care']
  },
  {
    title: 'Seasonal Allergies in Australia: Treatment and Prevention',
    category: 'Family Medicine',
    keywords: ['allergies', 'hay fever', 'australia', 'treatment', 'prevention']
  },
  {
    title: 'Managing Chronic Pain: Australian Treatment Guidelines',
    category: 'Chronic Disease Management',
    keywords: ['chronic pain', 'pain management', 'australia', 'treatment', 'guidelines']
  }
];

// Blog post template structure for SEO-friendly content
export const BLOG_TEMPLATES = {
  standard: {
    sections: [
      { name: 'introduction', wordCount: 150, headingLevel: 2 },
      { name: 'mainPoints', count: 3, wordCount: 300, headingLevel: 2 },
      { name: 'australianContext', wordCount: 200, headingLevel: 2 },
      { name: 'expertAdvice', wordCount: 250, headingLevel: 2 },
      { name: 'conclusion', wordCount: 150, headingLevel: 2 },
      { name: 'callToAction', wordCount: 100, headingLevel: 3 }
    ],
    totalWordCount: 1000,
    imageCount: 2,
    readTime: 5
  },
  comprehensive: {
    sections: [
      { name: 'introduction', wordCount: 200, headingLevel: 2 },
      { name: 'background', wordCount: 300, headingLevel: 2 },
      { name: 'mainPoints', count: 5, wordCount: 1000, headingLevel: 2 },
      { name: 'australianContext', wordCount: 400, headingLevel: 2 },
      { name: 'research', wordCount: 400, headingLevel: 2 },
      { name: 'expertAdvice', wordCount: 300, headingLevel: 2 },
      { name: 'patientPerspective', wordCount: 300, headingLevel: 2 },
      { name: 'conclusion', wordCount: 250, headingLevel: 2 },
      { name: 'callToAction', wordCount: 150, headingLevel: 3 },
      { name: 'references', wordCount: 200, headingLevel: 3 }
    ],
    totalWordCount: 3000,
    imageCount: 5,
    readTime: 15
  },
  quickGuide: {
    sections: [
      { name: 'introduction', wordCount: 100, headingLevel: 2 },
      { name: 'keyPoints', count: 5, wordCount: 500, headingLevel: 3 },
      { name: 'australianContext', wordCount: 150, headingLevel: 2 },
      { name: 'conclusion', wordCount: 100, headingLevel: 2 },
      { name: 'callToAction', wordCount: 50, headingLevel: 3 }
    ],
    totalWordCount: 800,
    imageCount: 1,
    readTime: 4
  }
};

// Function to generate blog post ideas based on Google Trends and Australian health data
export function generateBlogIdeas(category = null, count = 5) {
  let filteredTopics = [...TRENDING_TOPICS];
  
  if (category) {
    filteredTopics = filteredTopics.filter(topic => 
      topic.category.toLowerCase() === category.toLowerCase()
    );
  }
  
  // If we don't have enough topics after filtering, return what we have
  if (filteredTopics.length <= count) {
    return filteredTopics;
  }
  
  // Otherwise, randomly select 'count' topics
  const ideas = [];
  const selectedIndices = new Set();
  
  while (ideas.length < count && ideas.length < filteredTopics.length) {
    const randomIndex = Math.floor(Math.random() * filteredTopics.length);
    
    if (!selectedIndices.has(randomIndex)) {
      selectedIndices.add(randomIndex);
      ideas.push(filteredTopics[randomIndex]);
    }
  }
  
  return ideas;
}

// Function to generate a blog post structure based on a template
export function generateBlogStructure(topicOrTitle, templateName = 'standard') {
  const topic = typeof topicOrTitle === 'string' 
    ? { title: topicOrTitle } 
    : topicOrTitle;
  
  const template = BLOG_TEMPLATES[templateName];
  
  if (!template) {
    throw new Error(`Template "${templateName}" not found`);
  }
  
  const structure = {
    title: topic.title,
    template: templateName,
    sections: template.sections.map(section => ({
      ...section,
      heading: generateSectionHeading(section.name, topic.title)
    })),
    estimatedReadTime: template.readTime,
    targetWordCount: template.totalWordCount,
    recommendedImages: template.imageCount,
    seoKeywords: topic.keywords || []
  };
  
  return structure;
}

// Helper function to generate section headings based on the blog title
function generateSectionHeading(sectionName, blogTitle) {
  const title = blogTitle.replace(/\?/g, '').trim();
  
  switch (sectionName) {
    case 'introduction':
      return 'Introduction';
    case 'mainPoints':
      return 'Key Insights';
    case 'australianContext':
      return 'Australian Healthcare Context';
    case 'expertAdvice':
      return 'Expert Medical Advice';
    case 'conclusion':
      return 'Conclusion';
    case 'callToAction':
      return 'Book Your Consultation Today';
    case 'background':
      return `Background: Understanding ${title.split(':')[0]}`;
    case 'research':
      return 'Latest Research and Evidence';
    case 'patientPerspective':
      return 'Patient Perspectives';
    case 'references':
      return 'References and Additional Resources';
    case 'keyPoints':
      return 'Essential Points to Remember';
    default:
      return sectionName.charAt(0).toUpperCase() + sectionName.slice(1);
  }
}

// Export a default blog content generator function
export default function generateBlogContent(topic, template = 'standard') {
  const structure = generateBlogStructure(topic, template);
  
  return {
    title: structure.title,
    slug: structure.title
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '-'),
    structure,
    // Additional fields would be filled by the content creation process
    excerpt: `Discover everything you need to know about ${structure.title.split(':')[0].toLowerCase()} in this comprehensive guide. Get expert advice on ${topic.keywords ? topic.keywords.join(', ') : 'this important healthcare topic'}.`,
    category: topic.category || BLOG_CATEGORIES[0].name,
    createdAt: new Date(),
    author: 'Dr. Terry Nguyen',
    readTime: structure.estimatedReadTime
  };
} 