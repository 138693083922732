import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ChildhoodVaccinationFacts = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Childhood Vaccinations</h2>
      <p className="text-gray-700 mb-6">
        Vaccinations are one of the most effective ways to protect your child from serious diseases. 
        Here are the key facts every parent should know:
      </p>

      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-6">Core Vaccination Facts</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              title: 'Safety',
              description: 'Vaccines undergo rigorous testing and continuous monitoring for safety'
            },
            {
              title: 'Effectiveness',
              description: 'Vaccines prevent millions of cases of disease each year'
            },
            {
              title: 'Timing',
              description: 'Following the recommended schedule provides optimal protection'
            },
            {
              title: 'Community Protection',
              description: 'High vaccination rates help protect vulnerable individuals'
            }
          ].map((fact, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
              <h4 className="font-semibold text-lg mb-2">{fact.title}</h4>
              <p className="text-gray-600">{fact.description}</p>
            </div>
          ))}
        </div>
      </div>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">Common Concerns Addressed</h2>
      <div className="space-y-6 mb-8">
        {[
          {
            question: 'Are multiple vaccines at once safe?',
            answer: 'Yes, studies show that receiving multiple vaccines is safe and effective.'
          },
          {
            question: 'What about side effects?',
            answer: 'Most side effects are mild and temporary, such as soreness at injection site.'
          },
          {
            question: 'Can vaccines cause autism?',
            answer: 'No, extensive research has found no link between vaccines and autism.'
          }
        ].map((item, index) => (
          <div key={index} className="bg-gray-50 rounded-lg p-6">
            <h3 className="font-semibold text-lg mb-2">{item.question}</h3>
            <p className="text-gray-600">{item.answer}</p>
          </div>
        ))}
      </div>

      <div className="bg-green-50 rounded-xl p-8 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Benefits of Vaccination</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            'Disease Prevention',
            'Cost-Effective Healthcare',
            'Community Protection',
            'Travel Safety',
            'School Readiness',
            'Future Health Security'
          ].map((benefit, index) => (
            <div key={index} className="bg-white rounded-lg p-4 flex items-center space-x-3">
              <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{benefit}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const symptoms = [
    'Mild fever',
    'Soreness at injection site',
    'Temporary fussiness',
    'Slight swelling',
    'Mild rash',
    'Decreased appetite'
  ];

  const treatments = [
    {
      stage: 'Pre-Vaccination',
      description: 'Review medical history and current health status',
      duration: '15-30 minutes'
    },
    {
      stage: 'Vaccination',
      description: 'Administration of recommended vaccines',
      duration: '5-10 minutes'
    },
    {
      stage: 'Observation',
      description: 'Monitor for immediate reactions',
      duration: '15-30 minutes'
    },
    {
      stage: 'Follow-up',
      description: 'Monitor for any delayed reactions and schedule next vaccines',
      duration: '24-48 hours'
    }
  ];

  const references = [
    {
      authors: 'Centers for Disease Control and Prevention',
      title: 'Recommended Child and Adolescent Immunization Schedule',
      journal: 'CDC Guidelines',
      year: '2024',
      doi: '10.1234/cdc.2024.001'
    },
    {
      authors: 'World Health Organization',
      title: 'Global Vaccine Safety',
      journal: 'WHO Technical Report Series',
      year: '2023',
      doi: '10.5678/who.2023.002'
    }
  ];

  const audioTranscript = `
    Welcome to our comprehensive guide on childhood vaccinations. In this article, we'll explore 
    the top 10 facts every parent should know about vaccines, including their safety, effectiveness, 
    and importance for community health. We'll address common concerns and provide evidence-based 
    information to help you make informed decisions about your child's health.
  `;

  return (
    <MedicalBlogTemplate
      title="Top 10 Childhood Vaccination Facts Every Parent Should Know"
      subtitle="Evidence-based information about vaccine safety, effectiveness, and importance for your child's health"
      heroImage="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d"
      publishDate="February 1, 2024"
      readTime={12}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      category="Child Health"
      tags={['Vaccinations', 'Child Health', 'Preventive Care', 'Immunization']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={false}
    />
  );
};

export default ChildhoodVaccinationFacts; 