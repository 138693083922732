import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../config/firebase';
import bgImage from '../assets/images/Inspire2.jpg';

// Static blog entries
const staticBlogEntries = [
  {
    id: 'static-private-jet',
    title: 'Private Jet Medical Services: In-Flight Healthcare for Global Travelers',
    slug: 'private-jet-medical-services',
    excerpt: 'Comprehensive medical support for private aviation clients worldwide. Pre-flight assessments, in-flight care, and international medical coordination for ultra-high-net-worth individuals.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_private_jet_on_runway_--ar_169_--v_6.1_c580842d-d85f-49b2-9159-a5887075f3b8_3.png',
    createdAt: new Date('2024-07-10'),
    isStatic: true
  },
  {
    id: 'static-hotel-medical',
    title: 'Five-Star Hotel Medical Services in Sydney',
    slug: 'five-star-hotel-medical-services',
    excerpt: 'Discreet medical care for guests of Sydney\'s premium hotels. In-room consultations, concierge medication delivery, and coordination with hotel staff for seamless care.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_Modern_five-star_hotel_suite_desk_with_open_leather_fol_a6b8997e-a733-4f52-9ab0-f42800e7e2e0_3.png',
    createdAt: new Date('2024-07-12'),
    isStatic: true
  },
  {
    id: 'static-on-set-medical',
    title: 'On-Set Medical Services for Film & TV Productions',
    slug: 'on-set-medical-services',
    excerpt: 'Specialized medical support for film and television productions in Sydney. Cast insurance clearances, on-set emergency care, and confidential health management for entertainment projects.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_Close-up_of_a_luxury_smartphone_with_a_contact_screen_s_3879fbc9-2d8d-4acf-960e-5eb0c9efa2f5_3.png',
    createdAt: new Date('2024-07-15'),
    isStatic: true
  },
  {
    id: 'static-executive-health',
    title: 'Executive Health Programs: Tailored Medical Care for Business Leaders',
    slug: 'executive-health-programs',
    excerpt: 'Comprehensive health management programs designed specifically for executives and business leaders in Sydney. Proactive assessments, personalized care plans, and 24/7 accessibility.',
    category: 'Private Doctor Services',
    imageUrl: '/images/healthcheck2.png',
    createdAt: new Date('2024-07-18'),
    isStatic: true
  },
  {
    id: 'static-yacht-medical',
    title: 'Yacht Medical Services: Comprehensive Healthcare for Maritime Luxury',
    slug: 'yacht-medical-services',
    excerpt: 'Dedicated physician services for luxury yacht owners, charterers and guests. Advanced medical care tailored for maritime environments with discreet physician accompaniment.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png',
    createdAt: new Date('2024-07-22'),
    isStatic: true
  },
  {
    id: 'static-international-medical',
    title: 'International Medical Support: Comprehensive Global Healthcare Coordination',
    slug: 'international-medical-support',
    excerpt: 'Global healthcare coordination for executives and high-profile individuals traveling internationally. Medical repatriation, emergency response, and worldwide physician network access.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_Panoramic_view_from_inside_a_penthouse_with_soft_mornin_5f661f61-add1-438b-9e3a-b76803127174_0.png',
    createdAt: new Date('2024-07-24'),
    isStatic: true
  },
  {
    id: 'static-vip-home-visits',
    title: 'VIP Home Visits: Premium Medical Care in Your Private Residence',
    slug: 'vip-home-visits',
    excerpt: 'Exclusive home visit medical services for high-profile individuals and families in Sydney. Comprehensive care delivered with discretion in the privacy of your residence.',
    category: 'Private Doctor Services',
    imageUrl: '/images/drtez_Elegant_home_office_with_medical_bag_on_desk_and_Sydney_f6b6d90d-36c4-4de7-97e9-64b1e3afdc30_3.png',
    createdAt: new Date('2024-07-20'),
    isStatic: true
  },
  {
    id: 'static-telemedicine-rural',
    title: 'Telemedicine for Rural & Remote Patients in Australia: Bridging the Healthcare Divide',
    slug: 'telemedicine-rural-remote',
    excerpt: 'How telemedicine is transforming healthcare access for rural and remote Australians. Expert insights on overcoming connectivity challenges and maximizing telehealth benefits.',
    category: 'Telehealth',
    imageUrl: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80',
    createdAt: new Date('2024-06-24'),
    isStatic: true
  },
  {
    id: 'static-erectile-dysfunction',
    title: 'Erectile Dysfunction: More Than Just a Pill | Men\'s Health Sydney',
    slug: 'erectile-dysfunction-treatment-sydney',
    excerpt: 'Personalized erectile dysfunction treatment in Sydney\'s Eastern Suburbs with Dr. Terry Nguyen. Confidential consultations, holistic approach, and evidence-based solutions.',
    category: 'Men\'s Health',
    imageUrl: '/images/erectile-dysfunction-consultation.jpg',
    createdAt: new Date('2024-07-03'),
    isStatic: true
  },
  {
    id: 'static-mens-health-hormones',
    title: 'Male Hormonal Health: Understanding Testosterone and Its Effects',
    slug: 'male-hormonal-health-testosterone',
    excerpt: "Dr. Terry Nguyen explains testosterone's role in men's health, symptoms of low levels, and treatment options available in Sydney's Eastern Suburbs.",
    category: 'Men\'s Health',
    imageUrl: '/images/drterry_menshealth1.png',
    createdAt: new Date('2024-07-01'),
    isStatic: true
  },
  {
    id: 'static-mens-health-preventative',
    title: 'Preventative Health Screenings Every Man Should Have',
    slug: 'mens-preventative-health-screenings-sydney',
    excerpt: 'Essential health screenings for men in Sydney - what tests you need at different ages to maintain optimal health and prevent disease.',
    category: 'Men\'s Health',
    imageUrl: '/images/drterry_menshealth3.png',
    createdAt: new Date('2024-06-30'),
    isStatic: true
  },
  {
    id: 'static-mens-prostate-health',
    title: 'Prostate Health: A Comprehensive Guide for Australian Men',
    slug: 'prostate-health-guide-sydney',
    excerpt: 'Everything Sydney men need to know about prostate health - from prevention to screenings and treatment options with Dr. Terry Nguyen.',
    category: 'Men\'s Health',
    imageUrl: '/images/drterry_menshealth5.png',
    createdAt: new Date('2024-06-28'),
    isStatic: true
  },
  {
    id: 'static-rsv-infection',
    title: 'RSV Infection in Children: Symptoms, Treatment & When to Call a Doctor',
    slug: 'rsv-infection-children',
    excerpt: 'Understanding respiratory syncytial virus (RSV) in children - symptoms, treatments, and when to seek medical care from Sydney\'s leading house call doctors.',
    category: 'Child Health',
    imageUrl: '/images/kidsrsv.png',
    createdAt: new Date('2024-06-25'),
    isStatic: true
  },
  {
    id: 'static-pertussis',
    title: 'Whooping Cough (Pertussis) in Children: Symptoms & Treatment Guide',
    slug: 'pertussis-whooping-cough-children',
    excerpt: 'Expert guide on recognizing and treating pertussis in children, including prevention strategies and when to seek medical attention in Sydney.',
    category: 'Child Health',
    imageUrl: '/images/pertussis-whooping-cough.png',
    createdAt: new Date('2024-06-22'),
    isStatic: true
  },
  {
    id: 'static-hfmd',
    title: 'Hand, Foot and Mouth Disease: A Parent\'s Guide to Symptoms & Treatment',
    slug: 'hand-foot-mouth-disease-children',
    excerpt: 'Essential information about hand, foot and mouth disease in children - symptoms, home care, and when to call a doctor in Sydney\'s Eastern Suburbs.',
    category: 'Child Health',
    imageUrl: '/images/hand-foot-mouth-disease.png',
    createdAt: new Date('2024-06-20'),
    isStatic: true
  },
  {
    id: 'static-urgent-care',
    title: 'Urgent Care at Home in Sydney: What Can Be Treated Without Going to Hospital',
    slug: 'urgent-care-at-home',
    excerpt: 'Expert guide on which urgent medical conditions can be safely treated by a house call doctor in Sydney\'s Eastern Suburbs - avoid unnecessary hospital visits.',
    category: 'Emergency Care',
    imageUrl: '/images/urgent-care-at-home-sydney.png',
    createdAt: new Date('2024-06-17'),
    isStatic: true
  },
  {
    id: 'static-marine-injuries',
    title: 'Marine Injuries in Sydney\'s Eastern Suburbs: Expert Treatment Guide from a House Call Doctor',
    slug: 'marine-injuries-sydney',
    excerpt: 'Learn professional treatment for jellyfish stings, stingrays, sea urchins and other marine injuries common at Bondi, Coogee, and Bronte beaches with our expert house call doctor guide.',
    category: 'Emergency Care',
    imageUrl: '/images/sydney-beaches-marine-injuries.png',
    createdAt: new Date('2024-06-14'),
    isStatic: true
  },
  {
    id: 'static-6',
    title: 'Iron Deficiency in Children: A Guide for Australian Parents',
    slug: 'iron-deficiency-children',
    excerpt: "Understanding, preventing, and treating iron deficiency to support your child's health and development",
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1511688878353-3a2f5be94cd7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3600',
    createdAt: new Date('2024-04-03'),
    isStatic: true // Flag to identify static entries
  },
  {
    id: 'static-5',
    title: 'Head Injuries in Children: What Parents Need to Know',
    slug: 'child-head-injuries',
    excerpt: 'A guide to recognizing, responding to, and preventing head injuries in children',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1604079628040-94301bb21b91?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3600',
    createdAt: new Date('2024-04-02'),
    isStatic: true // Flag to identify static entries
  },
  {
    id: 'static-4',
    title: 'Common Skin Conditions in Children: A Guide for Australian Parents',
    slug: 'childhood-skin-conditions',
    excerpt: 'Recognizing, treating, and preventing common pediatric skin conditions through telehealth and in-person care',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1516627145497-ae6968895b40?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3',
    createdAt: new Date('2024-03-20'),
    isStatic: true // Flag to identify static entries
  },
  {
    id: 'static-1',
    title: 'Childhood Nutrition: Building Healthy Eating Habits',
    slug: 'childhood-nutrition',
    excerpt: 'Expert guidance on nutritional needs and strategies for healthy eating in Australian children',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1604430456280-9062fccc6d37',
    createdAt: new Date('2024-03-15'),
    isStatic: true // Flag to identify static entries
  },
  {
    id: 'static-2',
    title: 'Childhood Immunizations: The Australian Guide',
    slug: 'childhood-immunizations',
    excerpt: 'Understanding the National Immunisation Program and keeping your child protected',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1632833239869-a37e3a5806d2',
    createdAt: new Date('2024-03-10'),
    isStatic: true
  },
  {
    id: 'static-3',
    title: 'Understanding Common Childhood Respiratory Issues',
    slug: 'childhood-respiratory-issues',
    excerpt: 'A comprehensive guide to recognizing, preventing, and managing respiratory conditions in children',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1584820927498-cfe5211fd8bf',
    createdAt: new Date('2024-02-01'),
    isStatic: true
  }
];

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPosts() {
      try {
        console.log('Fetching blog posts from Firestore...');
        const postsRef = collection(db, 'blog_posts');
        const q = query(postsRef, orderBy('createdAt', 'desc'));
        
        console.log('Query:', q);
        const querySnapshot = await getDocs(q);
        
        console.log(`Found ${querySnapshot.docs.length} blog posts`);
        querySnapshot.docs.forEach(doc => {
          const postData = doc.data();
          console.log('Blog post:', doc.id, postData.title, 'Created:', postData.createdAt);
        });
        
        const postList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // Check for potential issues with the blog posts
        if (postList.length === 0) {
          console.warn('No blog posts found. Check Firebase connection and security rules.');
        } else {
          console.log('Blog posts loaded successfully:', postList.length);
          // Log the titles of all posts for easy verification
          console.log('Blog post titles:');
          postList.forEach(post => {
            console.log(`- ${post.title} (${post.category})`);
          });
        }
        
        // If there are posts but they might be missing required fields:
        postList.forEach(post => {
          if (!post.title || !post.content || !post.slug) {
            console.warn('Blog post missing required fields:', post.id);
          }
          
          // Check date format
          if (post.createdAt && !(post.createdAt instanceof Date) && !post.createdAt.toDate) {
            console.warn('Blog post has invalid date format:', post.id, post.createdAt);
          }
        });
        
        // Combine Firestore posts with static blog entries
        // But avoid duplicates by checking slugs
        let allPosts = [...postList];
        
        // Only add static entries that don't have a matching slug in Firestore
        staticBlogEntries.forEach(staticPost => {
          const isDuplicate = postList.some(firestorePost => 
            firestorePost.slug === staticPost.slug
          );
          
          if (!isDuplicate) {
            allPosts.push(staticPost);
          }
        });
        
        // Sort by date (newest first)
        allPosts = allPosts.sort((a, b) => {
          const dateA = a.createdAt?.toDate ? a.createdAt.toDate() : a.createdAt;
          const dateB = b.createdAt?.toDate ? b.createdAt.toDate() : b.createdAt;
          return dateB - dateA;
        });
        
        setPosts(allPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        // If Firestore fails, at least show static entries
        setPosts(staticBlogEntries);
      } finally {
        setLoading(false);
      }
    }

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
        <div className="site-background" />
        <div className="content-wrapper relative z-10">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <p>Loading articles...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="space-y-8">
            <div className="text-center">
              <h1 className="text-4xl font-light text-[#DDC6A8]">Health & Wellness Blog</h1>
              <p className="mt-4 text-xl text-[#DDC6A8]/70">
                Expert insights and advice for your family's health
              </p>
            </div>

            <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {posts.length > 0 ? (
                posts.map((post) => {
                  // Ensure post has all required fields with defaults
                  const safePost = {
                    id: post.id,
                    title: post.title || 'Untitled Post',
                    slug: post.slug || 'untitled-post',
                    excerpt: post.excerpt || 'Read more about this topic...',
                    category: post.category || 'General',
                    imageUrl: post.imageUrl || null,
                    createdAt: post.createdAt,
                    isStatic: post.isStatic || false
                  };
                  
                  // Format date safely
                  let formattedDate = 'Publication date unavailable';
                  try {
                    if (safePost.createdAt?.toDate) {
                      formattedDate = safePost.createdAt.toDate().toLocaleDateString();
                    } else if (safePost.createdAt instanceof Date) {
                      formattedDate = safePost.createdAt.toLocaleDateString();
                    } else if (typeof safePost.createdAt === 'string') {
                      formattedDate = new Date(safePost.createdAt).toLocaleDateString();
                    }
                  } catch (e) {
                    console.error('Error formatting date for post:', safePost.id, e);
                  }
                  
                  return (
                    <div
                      key={safePost.id}
                      className="flex flex-col rounded-lg overflow-hidden border border-[#DDC6A8]/10 bg-[#1A1A1A]/80 backdrop-blur-sm hover:border-[#DDC6A8]/30 transition-all"
                    >
                      {safePost.imageUrl && (
                        <div className="flex-shrink-0">
                          <img
                            className="h-48 w-full object-cover"
                            src={safePost.imageUrl}
                            alt={safePost.title}
                          />
                        </div>
                      )}
                      <div className="flex-1 p-6 flex flex-col justify-between">
                        <div className="flex-1">
                          <div className="flex items-center space-x-2 mb-4">
                            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-light bg-[#DDC6A8]/10 text-[#DDC6A8]">
                              {safePost.category}
                            </span>
                            <time className="text-sm text-[#DDC6A8]/60">
                              {formattedDate}
                            </time>
                          </div>
                          <h2 className="text-xl font-light text-[#DDC6A8] mb-2">
                            {safePost.title}
                          </h2>
                          <p className="text-[#DDC6A8]/70 text-base">
                            {safePost.excerpt}
                          </p>
                        </div>
                        <div className="mt-6">
                          <Link 
                            to={safePost.isStatic ? `/blog/${safePost.slug}` : `/blog/${safePost.slug}`}
                            className="inline-flex items-center px-6 py-2 border border-[#DDC6A8] text-[#DDC6A8] rounded-full text-sm font-light hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                          >
                            Read more
                            <svg 
                              className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              stroke="currentColor"
                            >
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-span-full text-center">
                  <p className="text-[#DDC6A8]/70">No articles available yet.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 