import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TableOfContents = ({ items = [] }) => (
  <div className="sticky top-8 bg-white rounded-xl shadow-lg p-6 mb-8">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">Table of Contents</h3>
    <nav>
      <ul className="space-y-2">
        {items.map((item, index) => (
          <li key={index}>
            <a
              href={`#${item.anchor}`}
              className="text-gray-600 hover:text-indigo-600 transition-colors duration-200 block py-1"
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  </div>
);

const CommentSection = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newComment.trim()) {
      setComments([
        ...comments,
        {
          id: Date.now(),
          text: newComment,
          author: 'Guest User',
          date: new Date().toLocaleDateString()
        }
      ]);
      setNewComment('');
    }
  };

  return (
    <div className="mt-12 border-t border-gray-200 pt-8">
      <h3 className="text-2xl font-bold text-gray-900 mb-8">Comments</h3>
      <form onSubmit={handleSubmit} className="mb-8">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Share your thoughts..."
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-transparent resize-none"
          rows="4"
        />
        <button
          type="submit"
          className="mt-4 bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
        >
          Post Comment
        </button>
      </form>
      <div className="space-y-6">
        {comments.map(comment => (
          <div key={comment.id} className="bg-gray-50 rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <div className="w-10 h-10 bg-indigo-100 rounded-full flex items-center justify-center">
                  <span className="text-indigo-600 font-semibold">
                    {comment.author[0]}
                  </span>
                </div>
                <div>
                  <p className="font-medium text-gray-900">{comment.author}</p>
                  <p className="text-sm text-gray-500">{comment.date}</p>
                </div>
              </div>
            </div>
            <p className="text-gray-600">{comment.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const BlogArticleTemplate = ({
  title = '',
  subtitle = '',
  heroImage = '',
  author = "Dr. Terry Nguyen",
  authorImage = "/images/drterrynguyen_medicaldoctor.png",
  publishDate = '',
  readTime = 5,
  content = null,
  relatedArticles = [],
  category = '',
  tags = [],
  tableOfContents = []
}) => {
  const [showScrollTop, setShowScrollTop] = useState(false);

  // Ensure arrays are actually arrays
  const safeTags = Array.isArray(tags) ? tags : [];
  const safeRelatedArticles = Array.isArray(relatedArticles) ? relatedArticles : [];
  const safeTableOfContents = Array.isArray(tableOfContents) ? tableOfContents : [];

  // Force scroll to top when component mounts
  useEffect(() => {
    // Multiple scroll methods for maximum browser compatibility
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
    // Apply again after a slight delay to ensure it works after any async rendering
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    // Show/hide scroll to top button
    const handleScroll = () => {
      setShowScrollTop(window.pageYOffset > 400);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-white blog-article-page">
      {/* Hero Section */}
      <div className="relative h-[60vh] bg-gray-900">
        {heroImage && (
          <img
            src={heroImage}
            alt={title || 'Blog Article'}
            className="w-full h-full object-cover opacity-40"
          />
        )}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="max-w-4xl mx-auto text-center px-4">
            {category && (
              <span className="inline-block bg-indigo-600 text-white px-4 py-1 rounded-full text-sm mb-4">
                {category}
              </span>
            )}
            <h1 className="text-5xl font-bold text-white mb-6">
              {title || 'Blog Article'}
            </h1>
            {subtitle && (
              <p className="text-xl text-gray-200 max-w-2xl mx-auto">
                {subtitle}
              </p>
            )}
            {safeTags.length > 0 && (
              <div className="mt-6 flex items-center justify-center flex-wrap gap-2">
                {safeTags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-white/10 text-white px-3 py-1 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Article Content */}
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          {/* Sidebar */}
          <div className="lg:col-span-1">
            {safeTableOfContents.length > 0 && (
              <TableOfContents items={safeTableOfContents} />
            )}

            {/* Share Section */}
            <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
              <h3 className="text-lg font-semibold text-gray-900 mb-4">Share</h3>
              <div className="flex space-x-4">
                <button className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                  </svg>
                </button>
                <button className="bg-blue-400 text-white p-2 rounded-full hover:bg-blue-500">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723 10.051 10.051 0 01-3.12 1.184A4.92 4.92 0 0016.327 2a4.935 4.935 0 00-4.93 4.93c0 .39.044.765.126 1.124A14.03 14.03 0 012.43 3.346a4.936 4.936 0 001.524 6.574 4.935 4.935 0 01-2.228-.616v.06a4.935 4.935 0 003.95 4.835 4.928 4.928 0 01-2.224.085 4.935 4.935 0 004.6 3.426 9.884 9.884 0 01-6.115 2.107c-.398 0-.79-.023-1.175-.068A13.98 13.98 0 007.55 22c9.056 0 14-7.5 14-14 0-.214-.005-.428-.015-.64a10.02 10.02 0 002.46-2.548l-.047-.02z" />
                  </svg>
                </button>
                <button className="bg-blue-800 text-white p-2 rounded-full hover:bg-blue-900">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 01-2.063-2.065 2.064 2.064 0 112.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h-.003z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-2">
            <article className="prose prose-lg prose-gray prose-headings:text-gray-900 prose-p:text-gray-800 max-w-none article-content">
              {/* Author Info */}
              <div className="flex items-center space-x-4 mb-8">
                {authorImage && (
                  <img
                    src={authorImage}
                    alt={author || 'Author'}
                    className="w-12 h-12 rounded-full"
                  />
                )}
                <div>
                  <p className="font-medium text-gray-900">{author || 'Author'}</p>
                  <p className="text-sm text-gray-500">Published on {publishDate || 'N/A'} • {readTime} min read</p>
                </div>
              </div>

              {/* Main Content */}
              {content || <p>No content available</p>}

              {/* Call to Action */}
              <div className="bg-gradient-to-r from-purple-500 to-indigo-600 rounded-xl p-8 text-white my-12">
                <h3 className="text-2xl font-bold mb-4">Ready to Take Action?</h3>
                <p className="mb-6">
                  Schedule a consultation with Dr. Terry to discuss your health needs.
                </p>
                <Link
                  to="/booking"
                  className="inline-block bg-white text-indigo-600 px-6 py-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors duration-300"
                >
                  Book Appointment
                </Link>
              </div>

              {/* Comment Section */}
              <CommentSection />
            </article>

            {/* Related Articles */}
            {(() => {
              try {
                // Wrap in a function to enable try/catch
                if (!safeRelatedArticles || safeRelatedArticles.length === 0) {
                  return null;
                }
                
                return (
                  <div className="mt-16">
                    <h3 className="text-2xl font-bold text-gray-900 mb-6">Related Articles</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      {safeRelatedArticles.map((article, index) => {
                        if (!article) {
                          console.error('Found null or undefined related article at index', index);
                          return null;
                        }
                        
                        // Ensure article has all required properties with defaults
                        const safeArticle = {
                          title: article?.title || 'Related article',
                          excerpt: article?.excerpt || 'Read more about this topic',
                          link: article?.link || '#',
                          image: article?.image || '',
                          tags: Array.isArray(article?.tags) ? article.tags : []
                        };
                        
                        return (
                          <Link key={index} to={safeArticle.link} className="group">
                            <div className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                              {safeArticle.image && (
                                <img
                                  src={safeArticle.image}
                                  alt={safeArticle.title}
                                  className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
                                />
                              )}
                              <div className="p-6">
                                <h4 className="text-xl font-semibold text-gray-900 mb-2">{safeArticle.title}</h4>
                                <p className="text-gray-600">{safeArticle.excerpt}</p>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              } catch (error) {
                console.error('Error rendering related articles:', error);
                return null; // Return nothing if there's an error
              }
            })()}
          </div>
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-indigo-600 text-white p-3 rounded-full shadow-lg hover:bg-indigo-700 transition-colors duration-200"
        >
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default BlogArticleTemplate; 