import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './premium-blog.css';

const MedicalDisclaimer = () => (
  <div className="bg-[#2A2A2A]/50 border-l-4 border-[#DDC6A8] p-6 rounded-r-xl mb-8">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-6 w-6 text-[#DDC6A8]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
      </div>
      <div className="ml-4">
        <h4 className="text-sm font-medium text-[#DDC6A8]">Medical Disclaimer</h4>
        <p className="mt-1 text-sm text-[#E8D4BC]">
          This article is for informational purposes only and should not be considered medical advice. 
          Always consult with a qualified healthcare provider for personalized recommendations.
        </p>
      </div>
    </div>
  </div>
);

const ReferencesList = ({ references = [] }) => (
  <div className="bg-[#2A2A2A]/50 rounded-xl p-6 mt-8">
    <h3 className="text-lg font-semibold text-[#DDC6A8] mb-4">References</h3>
    <ol className="list-decimal list-inside space-y-2">
      {references.map((reference, index) => (
        <li key={index} className="text-[#E8D4BC] text-sm">
          {reference.authors && <span className="font-medium">{reference.authors}. </span>}
          {reference.title && <span className="italic">{reference.title}. </span>}
          {reference.journal && <span>{reference.journal}. </span>}
          {reference.year && <span>({reference.year})</span>}
          {reference.doi && (
            <a 
              href={`https://doi.org/${reference.doi}`}
              className="ml-2 text-[#DDC6A8] hover:text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              DOI: {reference.doi}
            </a>
          )}
        </li>
      ))}
    </ol>
  </div>
);

const TableOfContents = ({ items = [] }) => (
  <div className="bg-[#2A2A2A]/50 rounded-xl p-6 mb-8 sticky top-24">
    <h3 className="text-lg font-semibold text-[#DDC6A8] mb-4">Contents</h3>
    <ul className="space-y-2">
      {items.map((item, index) => (
        <li key={index}>
          <a 
            href={`#${item.anchor}`}
            className="text-[#E8D4BC] hover:text-white transition-colors"
          >
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const PremiumBlogTemplate = ({
  title = '',
  subtitle = '',
  heroImage = '',
  author = "Dr. Terry Nguyen",
  authorImage = "/images/drterrynguyen_medicaldoctor.png",
  publishDate = new Date().toLocaleDateString('en-AU'),
  readTime = 5,
  content = null,
  relatedArticles,
  category = 'Private Doctor Services',
  tags = [],
  references = [],
  tableOfContents = [],
  children,
}) => {
  // Ensure all arrays are actually arrays
  const safeTags = Array.isArray(tags) ? tags : [];
  const safeReferences = Array.isArray(references) ? references : [];
  const safeTableOfContents = Array.isArray(tableOfContents) ? tableOfContents : [];

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#E8D4BC]">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src={heroImage} 
            alt={title} 
            className="w-full h-full object-cover brightness-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-[#1A1A1A]/70 to-[#1A1A1A]"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 sm:py-32">
          <div className="max-w-3xl">
            <div className="flex items-center space-x-2 mb-6">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-light bg-[#DDC6A8]/10 text-[#DDC6A8]">
                {category}
              </span>
              <span className="text-sm text-[#E8D4BC]">
                {publishDate} • {readTime} min read
              </span>
            </div>
            
            <h1 className="text-5xl font-light text-white mb-6 leading-tight">{title}</h1>
            {subtitle && <h2 className="text-2xl font-light text-[#F0E6DD] mb-8">{subtitle}</h2>}
            
            <div className="flex items-center">
              <img 
                src={authorImage}
                alt={author}
                className="w-12 h-12 rounded-full object-cover mr-4"
              />
              <div>
                <p className="text-white font-medium">{author}</p>
                <p className="text-[#E8D4BC] text-sm">Medical Director, Private Services</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Sidebar */}
          <div className="lg:w-1/4">
            {safeTableOfContents.length > 0 && <TableOfContents items={safeTableOfContents} />}
            
            <div className="bg-[#2A2A2A]/50 rounded-xl p-6 mb-8">
              <h3 className="text-lg font-semibold text-[#DDC6A8] mb-4">Request a Consultation</h3>
              <p className="text-[#E8D4BC] mb-4 text-sm">
                Experience discreet, personalized medical care tailored to your needs. Request a consultation with Dr. Terry Nguyen.
              </p>
              <Link
                to="/private-consultation"
                className="inline-flex items-center justify-center px-4 py-2 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition w-full text-center"
              >
                Request Private Consultation
              </Link>
            </div>
            
            {safeTags.length > 0 && (
              <div className="bg-[#2A2A2A]/50 rounded-xl p-6 mb-8">
                <h3 className="text-lg font-semibold text-[#DDC6A8] mb-4">Topics</h3>
                <div className="flex flex-wrap gap-2">
                  {safeTags.map((tag, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm text-[#E8D4BC]"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
          
          {/* Main Content */}
          <div className="lg:w-3/4">
            <article className="prose prose-lg max-w-none prose-headings:text-white prose-p:text-[#F0E6DD] prose-strong:text-[#F0E6DD] prose-a:text-[#DDC6A8] prose-a:no-underline hover:prose-a:text-white prose-blockquote:border-[#DDC6A8] prose-blockquote:text-[#F0E6DD] prose-figcaption:text-[#F0E6DD] prose-img:rounded-xl prose-img:shadow-lg prose-li:text-[#F0E6DD] prose-ul:text-[#F0E6DD] prose-ol:text-[#F0E6DD]" 
              style={{
                color: "#F0E6DD", 
                "--tw-prose-body": "#F0E6DD", 
                "--tw-prose-headings": "white", 
                "--tw-prose-lead": "#F0E6DD", 
                "--tw-prose-links": "#DDC6A8", 
                "--tw-prose-bold": "#F0E6DD", 
                "--tw-prose-counters": "#F0E6DD", 
                "--tw-prose-bullets": "#F0E6DD", 
                "--tw-prose-hr": "#F0E6DD", 
                "--tw-prose-quotes": "#F0E6DD", 
                "--tw-prose-quote-borders": "#DDC6A8", 
                "--tw-prose-captions": "#F0E6DD", 
                "--tw-prose-code": "#F0E6DD", 
                "--tw-prose-pre-code": "#F0E6DD", 
                "--tw-prose-pre-bg": "#2A2A2A", 
                "--tw-prose-th-borders": "#DDC6A8", 
                "--tw-prose-td-borders": "#2A2A2A"
              }}>
              <MedicalDisclaimer />
              <div className="premium-blog-content" style={{color: "#F0E6DD"}}>
                {typeof content === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                ) : content ? (
                  content
                ) : children ? (
                  children
                ) : (
                  "No content available"
                )}
              </div>
              {safeReferences.length > 0 && <ReferencesList references={safeReferences} />}
            </article>
          </div>
        </div>
      </div>
      
      {/* Featured Articles */}
      {relatedArticles && (
        <div className="bg-[#2A2A2A]/30 py-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-light text-white mb-12">Related Articles</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {relatedArticles.map((article, index) => (
                <motion.div
                  key={index}
                  className="bg-[#1A1A1A] rounded-xl overflow-hidden shadow-lg group hover:shadow-2xl transition-all duration-300"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className="h-48 overflow-hidden relative">
                    <img 
                      src={article.imageUrl} 
                      alt={article.title} 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent opacity-70"></div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-xl font-light text-[#F0E6DD] mb-3 line-clamp-2">{article.title}</h3>
                    <p className="text-[#E8D4BC] mb-4 line-clamp-3">{article.excerpt}</p>
                    <Link
                      to={`/blog/${article.slug}`}
                      className="inline-flex items-center text-[#DDC6A8] hover:text-white transition-colors"
                    >
                      Read Article
                      <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </Link>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      )}
      
      {/* Call to Action */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="/images/drtez_Close-up_of_a_luxury_smartphone_with_a_contact_screen_s_3879fbc9-2d8d-4acf-960e-5eb0c9efa2f5_3.png" 
            alt="Request private medical consultation" 
            className="w-full h-full object-cover brightness-50"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-[#1A1A1A] to-transparent"></div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="md:max-w-2xl">
            <h2 className="text-3xl font-light text-white mb-4">Experience Personalized Healthcare</h2>
            <p className="text-xl text-[#F0E6DD] mb-8">
              For discreet, exceptional medical care tailored to your lifestyle, schedule a private consultation with Dr. Terry Nguyen.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/private-consultation"
                className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
              >
                Request Private Consultation
              </Link>
              <a
                href="tel:+61000000000" 
                className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#DDC6A8] hover:bg-[#DDC6A8]/10 transition"
              >
                Direct Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumBlogTemplate; 