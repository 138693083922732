import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>About Dr. Terry Nguyen - Sydney House Call Doctor | HomeDoc</title>
        <meta name="description" content="Dr. Terry Nguyen is a Sydney-based private emergency doctor providing urgent care house calls and mental health support across the Eastern Suburbs." />
        <meta name="keywords" content="doctor terry nguyen, sydney house call doctor, paddington doctor, bondi doctor, eastern suburbs doctor, private emergency doctor, urgent care sydney, pediatric doctor, mental health care, sports medicine sydney, MBBS Sydney Medical School, physiotherapist" />
        <link rel="canonical" href="https://homedoc.com.au/about" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Physician",
            "name": "Dr. Terry Nguyen",
            "description": "Sydney-based doctor offering personalized care across pediatrics, urgent care, mental health, and general practice, serving Paddington, Bondi, and Eastern Suburbs.",
            "url": "https://homedoc.com.au/about",
            "image": "https://homedoc.com.au/images/drterrynguyen_medicaldoctor.png",
            "medicalSpecialty": [
              "General Practice",
              "Pediatrics",
              "Sports Medicine",
              "Mental Health"
            ],
            "knowsAbout": [
              "Pediatric Urgent Care",
              "Mental Health",
              "Sports Medicine",
              "Physiotherapy",
              "Home Doctor Services",
              "Holistic Medicine",
              "Eastern and Western Medical Approaches"
            ],
            "alumniOf": [
              {
                "@type": "CollegeOrUniversity",
                "name": "Sydney Medical School, University of Notre Dame",
                "sameAs": "https://www.notredame.edu.au/sydney/schools/medicine"
              },
              {
                "@type": "CollegeOrUniversity",
                "name": "Macquarie Graduate School of Management",
                "sameAs": "https://www.mgsm.edu.au/"
              },
              {
                "@type": "CollegeOrUniversity",
                "name": "University of Sydney",
                "sameAs": "https://www.sydney.edu.au/"
              }
            ],
            "hasCredential": [
              {
                "@type": "EducationalOccupationalCredential",
                "credentialCategory": "degree",
                "name": "MBBS",
                "educationalLevel": "Medical Degree"
              },
              {
                "@type": "EducationalOccupationalCredential",
                "credentialCategory": "degree",
                "name": "MBA",
                "educationalLevel": "Master's Degree"
              },
              {
                "@type": "EducationalOccupationalCredential",
                "credentialCategory": "degree",
                "name": "BAppSci Physiotherapy",
                "educationalLevel": "Bachelor's Degree"
              }
            ],
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sydney",
              "addressRegion": "NSW",
              "addressCountry": "AU",
              "postalCode": "2010"
            },
            "areaServed": {
              "@type": "GeoCircle",
              "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": "-33.886111",
                "longitude": "151.213889"
              },
              "geoRadius": "10000"
            },
            "availableService": [
              {
                "@type": "MedicalService",
                "name": "House Call Medical Services",
                "description": "Emergency and non-emergency medical visits at your home"
              },
              {
                "@type": "MedicalService",
                "name": "Telehealth Consultations",
                "description": "Secure online medical consultations"
              }
            ]
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-5xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">About Dr. Terry Nguyen</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8]/80 mb-6">Sydney House Call Doctor Serving the Eastern Suburbs</h2>
            <p className="text-xl italic text-[#DDC6A8]/80 max-w-3xl mx-auto">
              "The highest quality of care isn't found in the walls of a clinic—it's in the relationship you build with your doctor, and the trust that forms over time."
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-5 gap-12 items-start mb-20">
            <div className="md:col-span-2">
              <img 
                src="/images/drterrynguyen_medicaldoctor.png" 
                alt="Dr. Terry Nguyen, Sydney Medical Doctor" 
                className="rounded-xl shadow-2xl w-full object-cover aspect-[3/4]"
              />
              
              <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl p-6 mt-6">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Education & Qualifications</h3>
                <ul className="space-y-3 text-[#DDC6A8]/80">
                  <li className="flex items-center">
                    <div className="flex-shrink-0 h-5 w-5 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      MBBS - Sydney Medical School, University of Notre Dame
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 h-5 w-5 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      MBA - MGSM at Macquarie University
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="flex-shrink-0 h-5 w-5 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      BAppSci Physiotherapy - University of Sydney
                    </p>
                  </li>
                </ul>
              </div>
              
              <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl p-6 mt-6">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Areas of Expertise</h3>
                <div className="flex flex-wrap gap-2">
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Pediatric Care</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Mental Health</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Sports Medicine</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Urgent Care</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">General Practice</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Physiotherapy</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Disability Care</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Holistic Medicine</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Medical Education</span>
                </div>
              </div>
              
              <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl p-6 mt-6">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Service Areas in Sydney</h3>
                <p className="text-[#DDC6A8]/80 mb-2">Serving patients throughout Sydney's Eastern Suburbs:</p>
                <div className="flex flex-wrap gap-2">
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Paddington</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Bondi</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Coogee</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Waterloo</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Woollahra</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Double Bay</span>
                  <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Darlinghurst</span>
                </div>
              </div>
            </div>
            
            <div className="md:col-span-3 space-y-8">
              <div>
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-4">Sydney's Premium House Call Doctor Service</h2>
                <p className="text-lg text-[#DDC6A8]/90 leading-relaxed mb-4">
                  Dr. Terry Nguyen is a compassionate physician dedicated to providing personalized healthcare that meets each patient where they are—whether at home through our house call service across Sydney's Eastern Suburbs, via our secure telehealth platform, or at our Waterloo clinic in NSW.
                </p>
                
                <p className="text-lg text-[#DDC6A8]/90 leading-relaxed mb-4">
                  With extensive experience across pediatric urgent care, general practice, and mental health, Dr. Nguyen approaches medicine with a philosophy centered on treating the whole person, not just isolated symptoms. This comprehensive approach combines clinical expertise with genuine compassion, creating healthcare experiences that build lasting trust.
                </p>
              </div>

              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-4">A Personal Journey: Bridging Eastern Wisdom and Western Medicine</h2>
                <p className="text-[#DDC6A8]/90 mb-4 leading-relaxed">
                  My journey into medicine began long before my formal education. Growing up with exposure to both Eastern healing traditions and modern medical science, I witnessed firsthand how these seemingly different approaches could complement each other in powerful ways.
                </p>
                <p className="text-[#DDC6A8]/90 mb-4 leading-relaxed">
                  Throughout my career, I've observed how evidence-based Western medicine provides excellent acute intervention, while Eastern approaches often excel at supporting long-term wellbeing through lifestyle, nutrition, and mind-body practices. By thoughtfully integrating both paradigms, I've developed a more comprehensive approach to healthcare that respects scientific rigor while acknowledging the wisdom of traditional healing systems.
                </p>
                <p className="text-[#DDC6A8]/90 leading-relaxed">
                  Today, as both a physician and physiotherapist, I'm committed to practicing medicine that's honest, patient-centered, and continuously evolving. I believe in educating myself relentlessly—whether that's staying current with the latest research publications or learning from the lived experiences of my patients. As a writer and educator, I'm passionate about making medical knowledge accessible and empowering patients to become active participants in their healing journey.
                </p>
              </div>

              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-4">Diverse Medical Experience in Australia and Internationally</h2>
                <p className="text-[#DDC6A8]/90 mb-4">
                  Dr. Nguyen's unique background spans multiple disciplines and settings:
                </p>
                <ul className="space-y-3 text-[#DDC6A8]/80">
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      <span className="text-[#DDC6A8]">Pediatric Urgent Care in Sydney</span> — Specialized experience in addressing children's acute medical needs with a calm, reassuring approach
                    </p>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      <span className="text-[#DDC6A8]">Physiotherapy & Sports Medicine</span> — Qualified physiotherapist with experience working with athletes and sports teams across NSW
                    </p>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="ml-3">
                      <span className="text-[#DDC6A8]">Entertainment Industry Medicine</span> — Served on medical teams for Hollywood productions, providing care for cast and stunt performers
                    </p>
                  </li>
                </ul>
              </div>
              
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6 border border-[#DDC6A8]/20">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-4">HomeDoc in the Press</h2>
                <div className="flex items-center mb-4">
                  <div className="flex-shrink-0 p-2 bg-[#DDC6A8]/10 rounded-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-[#DDC6A8]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-light text-[#DDC6A8]">Featured in PRLog:</h3>
                    <a 
                      href="https://www.prlog.org/13069864-tired-of-waiting-rooms-new-homedoc-service-offers-australians-their-own-private-doctor.html" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-[#DDC6A8]/80 hover:text-[#DDC6A8] flex items-center group"
                    >
                      "Tired of Waiting Rooms? New HomeDoc Service Offers Australians Their Own Private Doctor"
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                    <p className="text-[#DDC6A8]/70 text-sm mt-2">
                      Read our story about bringing personalized medical care back to Australians who need quality healthcare on their terms.
                    </p>
                  </div>
                </div>
              </div>
              
              <div>
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-4">A Personalized Approach to Sydney Healthcare</h2>
                <p className="text-lg text-[#DDC6A8]/90 leading-relaxed mb-4">
                  Whether attending to high-profile individuals requiring discretion or families seeking compassionate pediatric care in Paddington, Bondi, or throughout Sydney's Eastern Suburbs, Dr. Nguyen applies the same principles: listening intently, respecting privacy, and delivering evidence-based care tailored to each person's unique circumstances.
                </p>
                <p className="text-lg text-[#DDC6A8]/90 leading-relaxed">
                  This breadth of experience informs a medical practice that is technically proficient yet deeply human—where cutting-edge knowledge meets genuine care and where each patient is seen as a whole person with unique needs and circumstances.
                </p>
              </div>
            </div>
          </div>
          
          <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 mb-16">
            <h2 className="text-3xl font-light text-[#DDC6A8] text-center mb-8">Our Sydney Medical Practice Mission</h2>
            <p className="text-xl text-[#DDC6A8]/90 leading-relaxed max-w-3xl mx-auto text-center">
              To provide an unparalleled healthcare experience across Sydney that respects your privacy, values your time, and delivers the highest standard of medical care. 
              Our mission is to redefine what healthcare can be in NSW: comprehensive, convenient, and tailored to each individual, 
              with a focus on clarity, compassion, and cutting-edge medical knowledge.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6">
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Personalized Sydney Home Visits</h3>
              <p className="text-[#DDC6A8]/80">
                Every patient across Sydney's Eastern Suburbs receives individualized attention and treatment plans tailored to their specific needs, circumstances, and health goals.
              </p>
              <Link to="/dynamic-booking" className="text-sm text-[#DDC6A8] underline block mt-4 hover:text-[#DDC6A8]/70">Book a personalized consultation</Link>
            </div>
            
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6">
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Sydney Telehealth & House Calls</h3>
              <p className="text-[#DDC6A8]/80">
                Through house calls across Paddington, Bondi, and surrounding areas, telehealth consultations, and Waterloo clinic visits, we ensure healthcare is accessible when and where you need it most.
              </p>
              <Link to="/telemedicine" className="text-sm text-[#DDC6A8] underline block mt-4 hover:text-[#DDC6A8]/70">Learn about our telehealth services</Link>
            </div>
            
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-6">
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Comprehensive Medical Approach</h3>
              <p className="text-[#DDC6A8]/80">
                We address not just immediate symptoms but look at the whole picture—physical, mental, and social factors—that influence your health and wellbeing within the Sydney healthcare context.
              </p>
              <Link to="/blog" className="text-sm text-[#DDC6A8] underline block mt-4 hover:text-[#DDC6A8]/70">Read our health resources</Link>
            </div>
          </div>
          
          <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 mb-16">
            <h2 className="text-3xl font-light text-[#DDC6A8] text-center mb-6">Patient Testimonials</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="p-6 bg-[#1A1A1A]/50 rounded-lg">
                <p className="italic text-[#DDC6A8]/80 mb-4">"Dr. Terry's house call service was absolutely life-changing when my son had a high fever. Having a doctor come to our Paddington home instead of dragging a sick child to a clinic made all the difference. His calm manner and thorough care were exceptional."</p>
                <p className="text-right text-[#DDC6A8]">— Sarah L., Paddington</p>
              </div>
              <div className="p-6 bg-[#1A1A1A]/50 rounded-lg">
                <p className="italic text-[#DDC6A8]/80 mb-4">"After struggling with ongoing health issues and seeing multiple specialists, Dr. Nguyen was the first to take a holistic view of my situation. His comprehensive approach finally led to effective treatment. The convenience of telehealth follow-ups has been invaluable."</p>
                <p className="text-right text-[#DDC6A8]">— Michael T., Bondi</p>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Experience Sydney Healthcare Reimagined</h2>
            <p className="text-lg text-[#DDC6A8]/90 max-w-3xl mx-auto mb-8">
              Whether you need urgent pediatric care in Paddington, ongoing management of a chronic condition in Bondi, or support for your mental health across Sydney's Eastern Suburbs, 
              Dr. Nguyen and the HomeDoc team are committed to providing exceptional care tailored to you.
            </p>
            <div className="flex flex-wrap justify-center gap-4">
              <Link
                to="/dynamic-booking"
                className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
              >
                Book a Consultation
              </Link>
              <Link
                to="/blog"
                className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#DDC6A8] hover:bg-[#DDC6A8]/10 transition"
              >
                Read Our Health Resources
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 