import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { db } from '../config/firebase';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

// Super simple BlogArticle component
const BlogArticle = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Force scroll to top when component mounts or slug changes
  useEffect(() => {
    console.log('BlogArticle: Scrolling to top for slug:', slug);
    
    // Multiple scroll methods for maximum browser compatibility
    window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: 'auto' });
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    
    // Apply again after a slight delay to ensure it works after any async rendering
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    }, 100);
    
    return () => clearTimeout(timeoutId);
  }, [slug]);

  // Fetch blog post
  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        console.log('Fetching blog post with slug:', slug);
        
        // Get blog post by slug
        const postsRef = collection(db, 'blog_posts');
        const q = query(postsRef, where('slug', '==', slug), limit(1));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          console.error('Blog post not found with slug:', slug);
          setError('Blog post not found');
          setLoading(false);
          return;
        }
        
        const postDoc = querySnapshot.docs[0];
        const postData = { id: postDoc.id, ...postDoc.data() };
        
        // Add detailed debugging to see the entire structure
        console.log('===== BLOG POST DEBUG =====');
        console.log('Post ID:', postData.id);
        console.log('Post title:', postData.title);
        console.log('Post slug:', postData.slug);
        console.log('Post category:', postData.category);
        console.log('Post excerpt:', postData.excerpt);
        console.log('Post tags:', postData.tags);
        console.log('Post has content:', !!postData.content);
        console.log('Post content length:', postData.content ? postData.content.length : 0);
        console.log('All post keys:', Object.keys(postData));
        console.log('===== END DEBUG =====');
        
        setPost(postData);
        console.log('Blog post loaded:', postData.title);
        
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError('Failed to load blog post');
      } finally {
        setLoading(false);
      }
    };
    
    if (slug) {
      fetchPost();
    } else {
      setError('Blog post slug is missing');
      setLoading(false);
    }
  }, [slug]);

  // Convert markdown to HTML
  const renderContent = (content) => {
    if (!content) {
      console.error('renderContent called with empty content');
      return { __html: '<p>Content not available. Please check back later.</p>' };
    }
    
    try {
      console.log('Content type:', typeof content);
      console.log('Content starts with:', content.substring(0, 50));
      
      // Remove markdown code fences if present
      let processedContent = content;
      if (typeof content === 'string') {
        // Check for markdown code fences
        if (content.startsWith('```markdown')) {
          console.log('Content has markdown code fences, removing them');
          const endIndex = content.lastIndexOf('```');
          if (endIndex > 10) {
            processedContent = content.substring('```markdown'.length, endIndex).trim();
            console.log('Processed content length after removing fences:', processedContent.length);
          }
        }
        
        // Handle empty content after processing
        if (!processedContent.trim()) {
          console.error('Content is empty after processing');
          return { __html: '<p>Content appears to be empty. Please check back later.</p>' };
        }
        
        // Parse and sanitize markdown
        console.log('Parsing markdown content of length:', processedContent.length);
        const rawHtml = marked.parse(processedContent);
        console.log('Raw HTML generated, length:', rawHtml.length);
        const cleanHtml = DOMPurify.sanitize(rawHtml);
        console.log('Clean HTML generated, length:', cleanHtml.length);
        return { __html: cleanHtml };
      } else {
        console.error('Content is not a string:', typeof content);
        return { __html: '<p>Content format is not supported. Please check back later.</p>' };
      }
    } catch (error) {
      console.error('Error rendering content:', error);
      return { __html: '<p>Error displaying content. Please check back later.</p>' };
    }
  };
  
  // Ensure post has all required fields with defaults if it exists
  const safePost = post ? {
    id: post.id,
    title: post.title || 'Untitled Post',
    content: post.content || '',
    category: post.category || null,
    excerpt: post.excerpt || null,
    tags: Array.isArray(post.tags) ? post.tags : []
  } : null;
  
  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <p className="text-center text-gray-600">Loading article...</p>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-white">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <div className="text-center">
            <h1 className="text-2xl font-light text-gray-900 mb-4">
              {error || 'Blog post not found'}
            </h1>
            <p className="text-gray-600 mb-8">
              The article you're looking for could not be found.
            </p>
            <Link
              to="/blog"
              className="inline-flex items-center px-4 py-2 border border-indigo-500 text-indigo-500 rounded-full text-sm font-medium hover:bg-indigo-50"
            >
              Return to Blog
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // Super simple article display
  try {
    return (
      <div className="min-h-screen bg-white blog-article-page">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <article className="text-gray-900">
            {/* Simple header */}
            <header className="mb-8 text-center">
              {safePost.category && (
                <div className="mb-4">
                  <span className="inline-block bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                    {safePost.category}
                  </span>
                </div>
              )}
              <h1 className="text-3xl font-bold text-gray-900 mb-4">
                {safePost.title}
              </h1>
              {safePost.excerpt && (
                <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                  {safePost.excerpt}
                </p>
              )}
            </header>
            
            {/* Content */}
            <div 
              className="prose prose-lg prose-gray max-w-none mx-auto"
              dangerouslySetInnerHTML={renderContent(safePost.content)} 
            />
            
            {/* Simple footer */}
            <div className="mt-12 text-center">
              <Link
                to="/blog"
                className="inline-flex items-center text-blue-600 hover:text-blue-800"
              >
                <svg className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
                Back to all articles
              </Link>
            </div>
          </article>
        </div>
      </div>
    );
  } catch (error) {
    console.error('Error rendering blog article:', error);
    return (
      <div className="min-h-screen bg-white">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <div className="text-center">
            <h1 className="text-2xl font-light text-gray-900 mb-4">
              Error Displaying Blog Post
            </h1>
            <p className="text-gray-600 mb-8">
              We encountered a technical issue while displaying this article. Please try again later or contact support.
            </p>
            <Link
              to="/blog"
              className="inline-flex items-center px-4 py-2 border border-indigo-500 text-indigo-500 rounded-full text-sm font-medium hover:bg-indigo-50"
            >
              Return to Blog
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default BlogArticle; 