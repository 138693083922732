import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const LanguageButton = styled.button`
  background: ${props => props.active ? '#1A1A1A' : 'transparent'};
  color: ${props => props.active ? '#DDC6A8' : '#777'};
  border: 1px solid ${props => props.active ? '#DDC6A8' : '#555'};
  padding: 4px 8px;
  margin: 0 2px;
  border-radius: 4px;
  font-size: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: ${props => props.active ? 1 : 0.8};

  &:hover {
    background: ${props => props.active ? '#1A1A1A' : 'rgba(221, 198, 168, 0.1)'};
    color: ${props => props.active ? '#DDC6A8' : '#DDC6A8'};
    border-color: #DDC6A8;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    padding: 3px 6px;
  }
`;

// Map of English pages to their Chinese equivalents
const pageMap = {
  // Home page
  '/': '/chinese',
  '/chinese': '/',
  
  // Service pages
  '/night-doctors': '/chinese-nightdoctors',
  '/chinese-nightdoctors': '/night-doctors',
  
  '/telemedicine': '/chinese-telemedicine',
  '/chinese-telemedicine': '/telemedicine',
  
  '/comprehensive-health-check': '/chinese-healthcheck',
  '/chinese-healthcheck': '/comprehensive-health-check',
  
  // Location pages
  '/locations/SydneyCBD': '/chinese-locations/SydneyCBD',
  '/chinese-locations/SydneyCBD': '/locations/SydneyCBD',
  
  '/locations/BondiArea': '/chinese-locations/BondiArea',
  '/chinese-locations/BondiArea': '/locations/BondiArea',
  
  '/locations/Waterloo': '/chinese-locations/Waterloo',
  '/chinese-locations/Waterloo': '/locations/Waterloo',
  
  // Default fallbacks
  'default-to-chinese': '/chinese',
  'default-to-english': '/'
};

const LanguageToggle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  
  // Determine if current page is Chinese
  const isChinesePage = currentPath.includes('chinese');
  
  const switchLanguage = () => {
    // Find the corresponding page in the other language
    const targetPath = pageMap[currentPath] || 
                      (isChinesePage ? pageMap['default-to-english'] : pageMap['default-to-chinese']);
    
    // Navigate to the corresponding page
    navigate(targetPath);
  };
  
  return (
    <ToggleContainer>
      <LanguageButton 
        active={!isChinesePage} 
        onClick={() => isChinesePage && switchLanguage()}
      >
        EN
      </LanguageButton>
      <LanguageButton 
        active={isChinesePage} 
        onClick={() => !isChinesePage && switchLanguage()}
      >
        中文
      </LanguageButton>
    </ToggleContainer>
  );
};

export default LanguageToggle; 