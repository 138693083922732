import React, { useEffect } from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const IronDeficiencyChildren = () => {
  // Add effect to inject schema markup
  useEffect(() => {
    // Create schema script element if it doesn't exist
    if (!document.getElementById('iron-deficiency-schema')) {
      const script = document.createElement('script');
      script.id = 'iron-deficiency-schema';
      script.type = 'application/ld+json';
      script.innerHTML = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "MedicalWebPage",
        "headline": "Iron Deficiency in Children: A Guide for Australian Parents",
        "datePublished": "2024-04-03",
        "image": "https://images.unsplash.com/photo-1511688878353-3a2f5be94cd7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3600",
        "author": {
          "@type": "Person",
          "name": "Dr. Terry Nguyen"
        },
        "publisher": {
          "@type": "Organization",
          "name": "HomeDoc",
          "logo": {
            "@type": "ImageObject",
            "url": "https://homedoc.com.au/logo.png"
          }
        },
        "mainEntity": {
          "@type": "MedicalCondition",
          "name": "Iron Deficiency",
          "alternateName": "Iron Deficiency Anemia in children",
          "description": "Iron deficiency is the most common nutritional deficiency in children worldwide, affecting many Australian children.",
          "typicalTest": [
            "Hemoglobin test",
            "Serum ferritin test",
            "Transferrin saturation test"
          ],
          "possibleTreatment": {
            "@type": "Drug",
            "name": "Iron supplements"
          }
        },
        "about": {
          "@type": "MedicalCondition",
          "name": "Iron Deficiency in Children"
        },
        "specialty": "Pediatrics"
      });
      document.head.appendChild(script);
    }
    
    // Cleanup function to remove the script when component unmounts
    return () => {
      const script = document.getElementById('iron-deficiency-schema');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Iron Deficiency in Children: What Parents Need to Know</h2>
        <p className="text-gray-700 mb-4">
          Iron deficiency is the most common nutritional deficiency in children worldwide, affecting many Australian children. 
          As a parent, understanding how to identify, prevent, and treat iron deficiency is crucial for your child's healthy development.
        </p>
        <p className="text-gray-700 mb-6">
          This guide will help you understand why iron is important, recognize signs of deficiency, learn about iron-rich foods, 
          and know when to consult with a healthcare professional through our <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth services</Link>.
        </p>
      </section>
      
      <section id="importance">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Why Iron is Important for Children</h2>
        
        <p className="text-gray-700 mb-4">
          Iron plays several vital roles in your child's health and development:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2"><strong>Oxygen transport</strong> - Iron is essential for creating hemoglobin, the protein in red blood cells that carries oxygen throughout the body</li>
          <li className="mb-2"><strong>Brain development</strong> - Iron is crucial for proper brain development and cognitive function</li>
          <li className="mb-2"><strong>Immune function</strong> - Iron helps maintain a healthy immune system</li>
          <li className="mb-2"><strong>Energy production</strong> - Iron is necessary for converting food into energy</li>
          <li className="mb-2"><strong>Growth</strong> - Children need iron to support their rapid growth and development</li>
        </ul>
        
        <p className="text-gray-700 mb-4">
          Children who lack sufficient iron may experience developmental delays, behavioral problems, decreased cognitive function, 
          and increased susceptibility to infections. This is why monitoring your child's iron status is so important.
        </p>
      </section>
      
      <section id="risk-factors">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Children at Higher Risk for Iron Deficiency</h2>
        
        <p className="text-gray-700 mb-4">
          Some children are more likely to develop iron deficiency than others:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Age-Related Risks</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Premature or low birth weight babies</li>
              <li className="mb-2">Infants 6-24 months of age (period of rapid growth)</li>
              <li className="mb-2">Toddlers who drink too much cow's milk</li>
              <li className="mb-2">Adolescent girls after starting menstruation</li>
            </ul>
          </div>
          
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Diet-Related Risks</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Exclusive breastfeeding beyond 6 months without iron supplementation</li>
              <li className="mb-2">Late introduction of iron-rich solid foods</li>
              <li className="mb-2">Vegetarian or vegan diets without proper planning</li>
              <li className="mb-2">Consuming more than 500ml of cow's milk daily (can interfere with iron absorption)</li>
              <li className="mb-2">Excessive consumption of tea, which contains tannins that inhibit iron absorption</li>
            </ul>
          </div>
        </div>
        
        <div className="bg-gray-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-gray-800 mb-4">Medical Conditions</h3>
          <ul className="list-disc pl-6 text-gray-700">
            <li className="mb-2">Chronic infections or inflammation</li>
            <li className="mb-2">Gastrointestinal disorders affecting absorption (celiac disease, inflammatory bowel disease)</li>
            <li className="mb-2">Blood loss from injury, surgery, or heavy periods</li>
            <li className="mb-2">Blood loss from intestinal conditions (polyps, parasites)</li>
            <li className="mb-2">Certain medications that interfere with iron absorption</li>
          </ul>
        </div>
      </section>
      
      <section id="signs-symptoms">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Signs and Symptoms of Iron Deficiency</h2>
        
        <p className="text-gray-700 mb-4">
          Iron deficiency develops gradually, and symptoms may not be obvious in the early stages. The progression typically follows these stages:
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Early Iron Deficiency</h3>
        <p className="text-gray-700 mb-4">
          Initially, the body's iron stores (ferritin) become depleted, but hemoglobin levels remain normal. There may be no symptoms at this stage.
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Iron Deficiency Without Anemia</h3>
        <p className="text-gray-700 mb-4">
          As iron deficiency progresses, red blood cell production is affected, but anemia has not yet developed. Symptoms may include:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Decreased attention span</li>
          <li className="mb-2">Reduced learning ability</li>
          <li className="mb-2">Irritability</li>
          <li className="mb-2">Fatigue or weakness</li>
          <li className="mb-2">Pale skin or pale nail beds</li>
        </ul>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Iron Deficiency Anemia</h3>
        <p className="text-gray-700 mb-4">
          In the advanced stage, hemoglobin levels are below normal (anemia). Symptoms may include:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Increased fatigue and weakness</li>
          <li className="mb-2">Pale skin, gums, and nail beds</li>
          <li className="mb-2">Poor appetite</li>
          <li className="mb-2">Rapid heartbeat</li>
          <li className="mb-2">Breathlessness with physical activity</li>
          <li className="mb-2">Frequent infections</li>
          <li className="mb-2">Unusual cravings for non-food items like ice or dirt (pica)</li>
          <li className="mb-2">Developmental delays or behavioral problems</li>
        </ul>
        
        <div className="bg-yellow-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-yellow-800 mb-4">When to See a Doctor</h3>
          <p className="text-yellow-800 mb-4">
            Consult a healthcare provider if your child shows any of these symptoms, especially if they fall into a high-risk group for iron deficiency.
          </p>
          <p className="text-yellow-800">
            Remember that many of these symptoms can also be caused by other conditions, so proper diagnosis is essential.
          </p>
        </div>
      </section>
      
      <section id="diagnosis">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Diagnosing Iron Deficiency</h2>
        
        <p className="text-gray-700 mb-4">
          If you suspect your child may have iron deficiency, your doctor can perform blood tests to check:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2"><strong>Hemoglobin and hematocrit levels</strong> - These measure the amount and percentage of red blood cells</li>
          <li className="mb-2"><strong>Serum ferritin</strong> - This shows the body's iron stores</li>
          <li className="mb-2"><strong>Transferrin saturation</strong> - This shows how much iron is bound to transferrin, a protein that carries iron in the blood</li>
          <li className="mb-2"><strong>Red blood cell size and color</strong> - Iron-deficient red blood cells are smaller and paler than normal</li>
        </ul>
        
        <p className="text-gray-700 mb-4">
          Your doctor may also ask about your child's diet, medical history, and perform a physical examination to check for 
          signs of iron deficiency. In some cases, additional tests might be needed to determine the cause of iron deficiency, 
          especially if blood loss is suspected.
        </p>
      </section>
      
      <section id="prevention">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Preventing Iron Deficiency in Children</h2>
        
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Dietary Recommendations by Age</h3>
        
        <div className="mb-8">
          <h4 className="text-xl font-medium text-gray-800 mb-3">Infants (0-6 months)</h4>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li className="mb-2">Breastfed babies typically get sufficient iron from breast milk for the first 4-6 months</li>
            <li className="mb-2">Iron-fortified formula should be used if not breastfeeding</li>
            <li className="mb-2">Premature infants may need iron supplements - consult with your pediatrician</li>
          </ul>
        </div>
        
        <div className="mb-8">
          <h4 className="text-xl font-medium text-gray-800 mb-3">Infants (6-12 months)</h4>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li className="mb-2">Introduce iron-rich solid foods around 6 months of age</li>
            <li className="mb-2">First foods should include iron-fortified infant cereals, pureed meats, or iron-rich vegetables</li>
            <li className="mb-2">Continue breastfeeding or using iron-fortified formula</li>
            <li className="mb-2">Avoid introducing cow's milk before 12 months of age</li>
          </ul>
        </div>
        
        <div className="mb-8">
          <h4 className="text-xl font-medium text-gray-800 mb-3">Toddlers (1-3 years)</h4>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li className="mb-2">Limit cow's milk to 500ml per day</li>
            <li className="mb-2">Include 2-3 servings of iron-rich foods daily</li>
            <li className="mb-2">Serve iron-rich foods with vitamin C to enhance absorption</li>
            <li className="mb-2">Avoid tea and coffee, which can inhibit iron absorption</li>
          </ul>
        </div>
        
        <div className="mb-8">
          <h4 className="text-xl font-medium text-gray-800 mb-3">School-Age Children and Adolescents</h4>
          <ul className="list-disc pl-6 mb-6 text-gray-700">
            <li className="mb-2">Ensure regular consumption of iron-rich foods</li>
            <li className="mb-2">Pay special attention to adolescent girls after menstruation begins</li>
            <li className="mb-2">Athletes may need additional iron due to increased requirements</li>
          </ul>
        </div>
      </section>
      
      <section id="iron-rich-foods">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Iron-Rich Foods for Children</h2>
        
        <p className="text-gray-700 mb-4">
          There are two types of dietary iron:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2"><strong>Heme iron</strong> - Found in animal foods and is more easily absorbed (15-35% absorption)</li>
          <li className="mb-2"><strong>Non-heme iron</strong> - Found in plant foods and is less well absorbed (2-20% absorption)</li>
        </ul>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Good Sources of Heme Iron</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Red meat (beef, lamb)</li>
              <li className="mb-2">Liver and organ meats</li>
              <li className="mb-2">Poultry (especially dark meat)</li>
              <li className="mb-2">Fish and shellfish</li>
              <li className="mb-2">Egg yolks</li>
            </ul>
          </div>
          
          <div className="bg-gray-50 p-6 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Good Sources of Non-heme Iron</h3>
            <ul className="list-disc pl-6 text-gray-700">
              <li className="mb-2">Iron-fortified cereals and breads</li>
              <li className="mb-2">Legumes (beans, lentils, chickpeas)</li>
              <li className="mb-2">Tofu and tempeh</li>
              <li className="mb-2">Dark green leafy vegetables (spinach, kale)</li>
              <li className="mb-2">Dried fruits (apricots, raisins, prunes)</li>
              <li className="mb-2">Nuts and seeds (especially pumpkin seeds)</li>
            </ul>
          </div>
        </div>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Tips to Increase Iron Absorption</h3>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Serve iron-rich foods together with foods high in vitamin C (citrus fruits, berries, tomatoes, capsicum) to enhance non-heme iron absorption</li>
          <li className="mb-2">Cook in cast iron cookware, which can increase the iron content of foods</li>
          <li className="mb-2">Avoid serving milk, tea, coffee, or antacids with iron-rich meals as they can decrease absorption</li>
          <li className="mb-2">Separate calcium supplements or calcium-rich foods from iron-rich foods by at least 2 hours</li>
        </ul>
        
        <div className="bg-indigo-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-indigo-800 mb-4">Child-Friendly Iron-Rich Meal Ideas</h3>
          <ul className="list-disc pl-6 text-indigo-700">
            <li className="mb-2">Mini meatballs with tomato sauce</li>
            <li className="mb-2">Beef or turkey burger sliders</li>
            <li className="mb-2">Iron-fortified breakfast cereal with vitamin C-rich fruit</li>
            <li className="mb-2">Hummus with carrot sticks and red capsicum</li>
            <li className="mb-2">Lentil or bean soup</li>
            <li className="mb-2">Spinach and cheese quesadillas with salsa</li>
            <li className="mb-2">Tuna or salmon patties</li>
            <li className="mb-2">Iron-fortified pasta with tomato-based sauce</li>
            <li className="mb-2">Smoothies with spinach, berries, and iron-fortified yogurt</li>
          </ul>
        </div>
      </section>
      
      <section id="treatment">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Treating Iron Deficiency</h2>
        
        <p className="text-gray-700 mb-4">
          If your child is diagnosed with iron deficiency, your doctor may recommend:
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Dietary Changes</h3>
        <p className="text-gray-700 mb-6">
          Increasing iron intake through diet is important, but diet alone may not be enough to correct deficiency, especially if anemia is present.
        </p>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">Iron Supplements</h3>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Iron supplements are often prescribed to correct deficiency</li>
          <li className="mb-2">Common forms include iron sulfate, iron gluconate, and iron polysaccharide</li>
          <li className="mb-2">The dose will depend on your child's age, weight, and severity of deficiency</li>
          <li className="mb-2">Supplements should ideally be taken on an empty stomach or with vitamin C to enhance absorption</li>
          <li className="mb-2">Treatment typically continues for 3-6 months to replenish iron stores</li>
          <li className="mb-2">Follow-up testing will determine when supplementation can stop</li>
        </ul>
        
        <div className="bg-yellow-50 p-6 rounded-lg mb-8">
          <h3 className="text-xl font-semibold text-yellow-800 mb-4">Important Safety Notes About Iron Supplements</h3>
          <ul className="list-disc pl-6 text-yellow-800">
            <li className="mb-2">Iron supplements can cause constipation, stomach upset, or dark stools</li>
            <li className="mb-2">Never give iron supplements to a child without medical supervision</li>
            <li className="mb-2">Iron overdose is a leading cause of poisoning deaths in children - keep supplements locked and out of reach</li>
            <li className="mb-2">If you suspect iron overdose, seek emergency care immediately</li>
          </ul>
        </div>
        
        <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Addressing Underlying Causes</h3>
        <p className="text-gray-700 mb-4">
          In some cases, treating the underlying cause of iron deficiency is necessary:
        </p>
        <ul className="list-disc pl-6 mb-6 text-gray-700">
          <li className="mb-2">Testing and treatment for intestinal disorders like celiac disease</li>
          <li className="mb-2">Management of heavy menstrual bleeding in adolescent girls</li>
          <li className="mb-2">Treatment of intestinal parasites or other sources of blood loss</li>
        </ul>
      </section>
      
      <section id="conclusion">
        <h2 className="text-3xl font-bold text-gray-900 mt-12 mb-6">Conclusion</h2>
        
        <p className="text-gray-700 mb-4">
          Iron deficiency is common in children but can be prevented with proper nutrition and awareness. Early recognition 
          and treatment are crucial to prevent long-term effects on growth, development, and learning.
        </p>
        
        <p className="text-gray-700 mb-4">
          If you're concerned about your child's iron status, our medical team is available through both in-person appointments 
          and <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth consultations</Link>. 
          We can help assess your child's risk, arrange appropriate testing if needed, and provide personalized advice on 
          prevention or treatment.
        </p>
        
        <div className="bg-indigo-50 p-6 rounded-lg mt-8">
          <h3 className="text-xl font-semibold text-indigo-800 mb-4">Need to Consult a Doctor?</h3>
          <p className="text-indigo-700 mb-4">
            If you're concerned about your child's iron levels or have questions about their diet:
          </p>
          <ul className="list-disc pl-6 text-indigo-700 mb-6">
            <li className="mb-2">Book an in-person appointment through our <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">online booking system</Link></li>
            <li className="mb-2">Schedule a <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth consultation</Link> for convenient medical advice</li>
            <li className="mb-2">Regular check-ups are a good opportunity to discuss your child's nutritional needs with your doctor</li>
          </ul>
        </div>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Assessment',
      description: 'Doctor evaluates symptoms and orders blood tests to confirm iron deficiency',
      duration: 'Initial consultation'
    },
    {
      stage: 'Dietary Modification',
      description: 'Increasing iron-rich foods in diet and optimizing absorption',
      duration: 'Ongoing'
    },
    {
      stage: 'Iron Supplementation',
      description: 'Oral iron supplements to replenish iron stores',
      duration: '3-6 months typically'
    },
    {
      stage: 'Monitoring Response',
      description: 'Follow-up blood tests to check hemoglobin and iron store levels',
      duration: '1-3 months after starting treatment'
    },
    {
      stage: 'Maintenance',
      description: 'Continued dietary attention to prevent recurrence',
      duration: 'Ongoing'
    }
  ];

  const references = [
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Iron - Information for parents',
      journal: 'Kids Health Info',
      year: '2020',
      doi: null
    },
    {
      authors: 'Queensland Health',
      title: 'Paediatric Iron Deficiency',
      journal: 'Clinical Guidelines',
      year: '2020',
      doi: null
    },
    {
      authors: 'SA Health',
      title: 'Iron Deficiency and Iron Therapy',
      journal: 'SA Health Internet',
      year: '2021',
      doi: null
    },
    {
      authors: 'Georgieff MK, et al.',
      title: 'Iron Deficiency in Infancy and Neurodevelopment',
      journal: 'Pediatrics',
      year: '2019',
      doi: '10.1542/peds.2017-3054'
    }
  ];

  const symptoms = [
    'Fatigue and weakness',
    'Pale skin, gums, and nail beds',
    'Poor appetite',
    'Decreased attention span',
    'Irritability',
    'Rapid heartbeat',
    'Breathlessness with activity',
    'Frequent infections',
    'Developmental delays',
    'Unusual cravings (pica)',
    'Headaches',
    'Dizziness or lightheadedness'
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Why Iron is Important', anchor: 'importance' },
    { title: 'Risk Factors', anchor: 'risk-factors' },
    { title: 'Signs and Symptoms', anchor: 'signs-symptoms' },
    { title: 'Diagnosis', anchor: 'diagnosis' },
    { title: 'Prevention', anchor: 'prevention' },
    { title: 'Iron-Rich Foods', anchor: 'iron-rich-foods' },
    { title: 'Treatment', anchor: 'treatment' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  return (
    <>
      <Helmet>
        <title>Iron Deficiency in Children: A Guide for Australian Parents | HomeDoc</title>
        <meta name="description" content="Learn about identifying, preventing, and treating iron deficiency in children. Expert medical advice from HomeDoc for Australian parents." />
        <meta name="keywords" content="iron deficiency, children, anemia, child nutrition, pediatric health, telehealth, Australia" />
        <link rel="canonical" href="https://homedoc.com.au/blog/iron-deficiency-children" />
      </Helmet>
      
      <MedicalBlogTemplate
        title="Iron Deficiency in Children: A Guide for Australian Parents"
        subtitle="Understanding, preventing, and treating iron deficiency to support your child's health and development"
        heroImage="https://images.unsplash.com/photo-1511688878353-3a2f5be94cd7?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3600"
        publishDate="April 3, 2024"
        readTime={15}
        content={content}
        category="Child Health"
        tags={['Iron Deficiency', 'Child Nutrition', 'Anemia', 'Child Development', 'Pediatric Health', 'Telehealth']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={false}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export default IronDeficiencyChildren; 