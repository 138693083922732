import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion, useScroll, useTransform } from 'framer-motion';
import SEO from '../components/SEO';
import bgImage from '../assets/images/Inspire2.jpg';
import drTerryHero from '../assets/images/profile/drterry_hero.png';
import drTerrySpeaking from '../assets/images/profile/dr_terry_speaking.png';
import Inspire from '../assets/images/Inspire.jpg';
import watch from '../assets/images/sections/watch.png';
import paediatric from '../assets/images/sections/paediatric.png';
import mountainPeak from '../assets/images/sections/mountain-peak.png';
import microscope from '../assets/images/sections/microscope2.png';
import menshealth from '../assets/images/sections/menshealth.png';
import doctorbag from '../assets/images/sections/doctorbag.png';
import logoA from '../assets/images/logos/a.png';
import logoB from '../assets/images/logos/b.png';
import logoC from '../assets/images/logos/c.png';
import logoD from '../assets/images/logos/d.png';
import logoE from '../assets/images/logos/e.png';
import logoF from '../assets/images/logos/f.png';
import logo2 from '../assets/images/logos/2.png';
import logo3 from '../assets/images/logos/3.png';
import logo4 from '../assets/images/logos/4.png';
import logo5 from '../assets/images/logos/5.png';
import mainLogo from '../assets/images/logos/main-logo.png';
import DNASection from '../components/DNASection';
import BlogCarousel from '../components/BlogCarousel';
// Use doctorbag as a placeholder for Eastern Suburbs image
const easternSuburbsImage = doctorbag;

const Home = () => {
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!carouselRef.current) return;
      
      const scrollLeft = carouselRef.current.scrollLeft;
      const itemWidth = carouselRef.current.offsetWidth;
      const newActiveSlide = Math.round(scrollLeft / itemWidth);
      
      setActiveSlide(newActiveSlide);
    };

    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleDotClick = (index) => {
    if (!carouselRef.current) return;
    
    const itemWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollTo({
      left: itemWidth * index,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      const bgElement = document.querySelector('.site-background');
      if (bgElement) {
        bgElement.style.setProperty('--bg-image', `url(${Inspire})`);
        bgElement.classList.add('loaded');
      }
    };

    img.src = Inspire;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, []);

  const healthcareSolutions = [
    {
      image: watch,
      title: "24/7 URGENT CARE",
      subtitle: "Personal, private, powerful",
      description: "Immediate medical attention when you need it most. Available day and night."
    },
    {
      image: paediatric,
      title: "PAEDIATRIC CARE",
      subtitle: "Gentle expertise for little ones",
      description: "Specialized care for children, from newborns to adolescents."
    },
    {
      image: mountainPeak,
      title: "MENTAL HEALTH",
      subtitle: "Your journey to wellness",
      description: "Comprehensive mental health support with dignity and understanding."
    },
    {
      image: microscope,
      title: "HEALTH SCREENING",
      subtitle: "Prevention is better than cure",
      description: "Thorough health assessments using advanced diagnostic tools."
    },
    {
      image: menshealth,
      title: "MEN'S HEALTH",
      subtitle: "Confidential and comprehensive",
      description: "Specialized care addressing unique men's health concerns."
    }
  ];

  // Rich structured data for better SEO
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': ['MedicalBusiness', 'LocalBusiness', 'Physician'],
    name: 'Dr. Terry Nguyen - Home Doctor Service',
    description: 'Expert 24/7 house call doctor in Sydney\'s Eastern Suburbs. After-hours home visits and private telemedicine consultations across Australia.',
    url: 'https://homedoc.com.au',
    logo: 'https://homedoc.com.au/images/logos/main-logo.png',
    image: 'https://homedoc.com.au/images/profile/drterry_hero.png',
    telephone: '+61412345678', // Replace with actual phone
    email: 'info@homedoc.com.au', // Replace with actual email
    priceRange: '$$',
    areaServed: {
      '@type': 'GeoCircle',
      geoMidpoint: {
        '@type': 'GeoCoordinates',
        latitude: '-33.8688',
        longitude: '151.2093'
      },
      geoRadius: '30km'
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '123 Medical Street', // Replace with actual address
      addressLocality: 'Sydney',
      addressRegion: 'NSW',
      postalCode: '2000',
      addressCountry: 'AU'
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '-33.8688',
      longitude: '151.2093'
    },
    sameAs: [
      'https://www.facebook.com/drterrynguyen', // Replace with actual social profiles
      'https://www.instagram.com/drterrynguyen',
      'https://www.linkedin.com/in/drterrynguyen'
    ],
    openingHoursSpecification: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '00:00',
        closes: '23:59'
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Saturday', 'Sunday'],
        opens: '00:00',
        closes: '23:59'
      }
    ],
    medicalSpecialty: [
      'HouseCallMedicine',
      'Telemedicine',
      'FamilyMedicine',
      'PediatricMedicine',
      'AfterHoursCare'
    ],
    availableService: [
      {
        '@type': 'MedicalProcedure',
        name: 'House Call Doctor Service',
        description: '24/7 medical care in the comfort of your own home.'
      },
      {
        '@type': 'MedicalProcedure',
        name: 'Telemedicine Consultation',
        description: 'Virtual medical consultations with experienced doctors.'
      },
      {
        '@type': 'MedicalProcedure',
        name: 'Comprehensive Health Check',
        description: 'Thorough examination and preventive health screening.'
      },
      {
        '@type': 'MedicalProcedure',
        name: 'After Hours Care',
        description: 'Urgent medical care when regular clinics are closed.'
      }
    ]
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <SEO 
        title="House Call Doctor & Telemedicine Sydney | Home Visits | Dr. Terry Nguyen"
        description="Expert 24/7 house call doctor in Sydney's Eastern Suburbs. After-hours home visits and private telemedicine consultations across Australia. Book Dr. Terry Nguyen."
        keywords={[
          'house call doctor Sydney', 
          'home doctor Eastern Suburbs', 
          'after hours doctor Bondi', 
          'private doctor Sydney', 
          'telemedicine Australia',
          'doctor near me', 
          'urgent home visits',
          'private GP house calls',
          'best doctor Sydney',
          'Medicare bulk bill doctor',
          'comprehensive health check'
        ]}
        schema={structuredData}
      />
      
      <div className={`site-background ${isBackgroundLoaded ? 'loaded' : ''}`} />
      
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 py-20 md:py-28">
              {/* Left Column - Content */}
              <div>
                <p className="text-[#DDC6A8]/80 text-lg mb-8 italic">
                  "Excellence in medical care with expertise, discretion, and personal attention"
                </p>
                
                <h1 className="text-4xl font-light mb-4">Dr. Terry Nguyen</h1>
                <div className="text-[#DDC6A8]/80 space-y-1 mb-8">
                  <p>M.B.B.S, M.B.A, BAppSci (Phty)</p>
                  <p>Private Medical Doctor</p>
                  <p>Adult and Paediatric Medicine</p>
                </div>

                <div className="space-y-4">
                  {[
                    { title: 'Urgent House Call', urgent: true, to: '/dynamic-booking' },
                    { title: 'Night Doctor Service', urgent: true, to: '/night-doctors' },
                    { title: 'Urgent Telemedicine Consultation', urgent: true, to: '/dynamic-booking' },
                    { title: 'Private Medical Services', urgent: true, to: '/private' },
                    { title: 'Clinic Appointment', urgent: false, to: '/dynamic-booking' },
                    { title: 'Yearly Health Check', urgent: false, to: '/comprehensive-health-check' },
                    { title: 'Mental Health Consultation', urgent: false, to: '/mental-health' },
                    { title: "Men's Health Consultation", urgent: false, to: '/mens-health' },
                  ].map((service, index) => (
                    <Link
                      key={index}
                      to={service.to}
                      state={{ service: service.title }}
                      className={`group flex items-center justify-between px-6 py-3 rounded-lg border ${
                        service.urgent
                          ? 'border-[#DDC6A8] text-[#DDC6A8] hover:bg-[#DDC6A8] hover:text-[#1A1A1A]'
                          : 'border-[#DDC6A8]/20 text-[#DDC6A8]/80 hover:border-[#DDC6A8] hover:text-[#DDC6A8]'
                      } transition-all`}
                    >
                      <span className="text-sm font-light">{service.title}</span>
                      <svg
                        className="w-4 h-4 group-hover:translate-x-1 transition-transform"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1.5}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </Link>
                  ))}
                </div>
              </div>

              {/* Right Column - Image */}
              <div className="relative">
                <img
                  src={drTerryHero}
                  alt="Dr Terry Nguyen"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>

        {/* 24/7 Availability Banner - Redesigned */}
        <div className="max-w-5xl mx-auto px-4 mb-12 mt-4">
          <div className="bg-gradient-to-r from-[#DDC6A8] to-[#DDC6A8]/90 text-[#1A1A1A] py-6 px-8 rounded-2xl shadow-lg">
            <div className="text-center">
              <h2 className="text-2xl md:text-3xl font-light">
                Dr. Terry is available 24/7 for house calls and after hours home visits
              </h2>
              <p className="mt-3 text-[#1A1A1A]/90 font-medium flex flex-wrap justify-center items-center gap-3">
                <span className="flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Immediate attention
                </span>
                <span className="hidden md:inline">•</span>
                <span className="flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                  No waiting
                </span>
                <span className="hidden md:inline">•</span>
                <span className="flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  Direct doctor access
                </span>
              </p>
              <div className="mt-4">
                <Link 
                  to="/dynamic-booking" 
                  className="inline-flex items-center px-6 py-3 bg-[#1A1A1A] text-[#DDC6A8] rounded-full hover:bg-[#2A2A2A] transition-all group font-medium shadow-md"
                >
                  Book Now
                  <svg className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Healthcare Solutions Carousel */}
        <section className="healthcare-solutions py-8 md:py-12">
          <div className="container mx-auto px-4 md:px-6">
            <div className="stagger-animation max-w-3xl">
              <motion.h2 
                className="text-4xl font-light text-[#DDC6A8] mb-3 tracking-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Modern care. Traditional values.
              </motion.h2>
            </div>
            
            <div 
              ref={carouselRef}
              className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide pl-4 md:pl-8 lg:pl-16 -mr-4 md:-mr-8 lg:-mr-16"
              style={{ scrollSnapType: 'x mandatory' }}
            >
              {[
                {
                  image: watch,
                  title: "24/7 Care",
                  subtitle: "Always on call.",
                  description: "Urgent medical care at your doorstep."
                },
                {
                  image: paediatric,
                  title: "Child Health",
                  subtitle: "Expert pediatric care.",
                  description: "Specialized attention for growing minds."
                },
                {
                  image: mountainPeak,
                  title: "Mental Health",
                  subtitle: "Your mind matters.",
                  description: "Professional support when you need it."
                },
                {
                  image: microscope,
                  title: "Health Screening",
                  subtitle: "Stay ahead.",
                  description: "Advanced diagnostics for prevention."
                },
                {
                  image: menshealth,
                  title: "Men's Health",
                  subtitle: "Confidential care.",
                  description: "Specialized solutions for men."
                }
              ].map((item, index) => (
                <div
                  key={index}
                  className="min-w-[85vw] md:min-w-[60vw] lg:min-w-[40vw] flex-shrink-0 snap-center px-2 md:px-3"
                >
                  <div className="relative aspect-[4/3] md:aspect-[16/9] rounded-2xl overflow-hidden bg-[#1A1A1A] group">
                    <div className="absolute inset-0">
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                      />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                      <div className="absolute bottom-0 left-0 p-6 md:p-8 lg:p-10">
                        <p className="text-sm md:text-base font-medium text-[#DDC6A8] mb-2">{item.subtitle}</p>
                        <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2 text-white">{item.title}</h3>
                        <p className="text-base md:text-lg text-white/90 mb-6">{item.description}</p>
                        <Link
                          to="/dynamic-booking"
                          state={{ service: item.title }}
                          className="inline-flex items-center px-4 py-2 text-sm md:text-base font-medium border border-white text-white rounded-full hover:bg-white hover:text-black transition-all group"
                        >
                          Book Now
                          <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Scroll Indicator */}
            <div className="flex justify-center space-x-2 mt-6">
              {[0, 1, 2, 3, 4].map((index) => (
                <button
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`w-2 h-2 rounded-full transition-all ${
                    activeSlide === index 
                      ? 'bg-[#DDC6A8] scale-110' 
                      : 'bg-[#DDC6A8]/30 hover:bg-[#DDC6A8]/50'
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </section>

        {/* SEO Content Section - Moved up from below */}
        <div className="py-20 bg-[#1A1A1A]/80 border-t border-[#DDC6A8]/10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-light mb-10 text-center text-white">Sydney's Premier House Call Doctor & Telemedicine Service</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <h3 className="text-2xl font-light mb-6 text-white">24/7 House Call Doctor in Sydney's Eastern Suburbs</h3>
                <div className="space-y-4">
                  <p className="text-white opacity-95 font-normal">
                    Dr. Terry Nguyen provides exceptional <strong className="text-white">after-hours doctor home visits</strong> across Sydney's Eastern Suburbs, including Bondi, Double Bay, Randwick, Woollahra and surrounding areas. As a dedicated <strong className="text-white">house call doctor</strong>, Dr. Terry brings comprehensive medical care right to your doorstep.
                  </p>
                  <p className="text-white opacity-95 font-normal">
                    Unlike conventional medical practices, our <strong className="text-white">home doctor service</strong> eliminates the inconvenience of traveling when you're unwell. We offer prompt <strong className="text-white">after-hours doctor visits</strong> when regular clinics are closed, providing continuity of care and peace of mind.
                  </p>
                  <p className="text-white opacity-95 font-normal">
                    Our <Link to="/night-doctors" className="text-[#DDC6A8] hover:underline">night doctor service</Link> is available throughout Sydney, including specialized hotel doctor services for CBD visitors. As a highly qualified <strong className="text-white">private doctor</strong>, Dr. Terry delivers personalized care with discretion and excellence at any hour, day or night.
                  </p>
                  <div className="mt-6">
                    <Link to="/dynamic-booking" className="text-white hover:text-[#DDC6A8] font-medium inline-flex items-center">
                      Book a House Call
                      <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-2xl font-light mb-6 text-white">Telemedicine Services Across Australia</h3>
                <div className="space-y-4">
                  <p className="text-white opacity-95 font-normal">
                    Access expert medical care wherever you are with our premium <strong className="text-white">telemedicine consultations</strong>. Dr. Terry Nguyen provides comprehensive virtual care to patients throughout Australia, combining convenience with exceptional medical expertise.
                  </p>
                  <p className="text-white opacity-95 font-normal">
                    Our <strong className="text-white">telehealth services</strong> are Medicare bulk-billed for eligible patients, making quality healthcare accessible to all. Whether you're in remote areas or simply prefer the convenience of virtual consultations, our telemedicine platform delivers reliable, secure, and confidential care.
                  </p>
                  <p className="text-white opacity-95 font-normal">
                    From urgent medical concerns to ongoing health management, our telemedicine consultations cover all aspects of primary care. Dr. Terry's extensive experience in <strong className="text-white">virtual medicine</strong> ensures you receive the same standard of exceptional care as you would in person.
                  </p>
                  <div className="mt-6">
                    <Link to="/telemedicine" className="text-white hover:text-[#DDC6A8] font-medium inline-flex items-center">
                      Learn More About Telemedicine
                      <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-lg">
                <h3 className="text-xl font-light mb-4 text-white">Comprehensive Health Checks</h3>
                <p className="text-white/90 mb-4">
                  Our detailed health assessments provide a thorough evaluation of your current health status, identifying potential issues before they become serious concerns.
                </p>
                <Link to="/comprehensive-health-check" className="text-white hover:text-[#DDC6A8] text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-lg">
                <h3 className="text-xl font-light mb-4 text-white">Private Medical Services</h3>
                <p className="text-white/90 mb-4">
                  Bespoke medical care for discerning clients, including private jet travel accompaniment, production medical services, and VIP health management.
                </p>
                <Link to="/private" className="text-white hover:text-[#DDC6A8] text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-lg">
                <h3 className="text-xl font-light mb-4 text-white">Men's Health Services</h3>
                <p className="text-white/90 mb-4">
                  Specialized care addressing the unique health concerns of men, provided with discretion and expertise by Dr. Terry Nguyen.
                </p>
                <Link to="/mens-health" className="text-white hover:text-[#DDC6A8] text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
            
            <div className="mt-16">
              <h3 className="text-2xl font-light mb-6 text-center text-white">Serving Sydney's Eastern Suburbs</h3>
              <div className="flex flex-wrap justify-center gap-4 text-center text-sm text-white/80">
                <span className="px-3 py-1 border border-white/20 rounded-full">Bondi</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Double Bay</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Randwick</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Woollahra</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Rose Bay</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Vaucluse</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Paddington</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Coogee</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Bronte</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Point Piper</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Bellevue Hill</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Darling Point</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Elizabeth Bay</span>
                <span className="px-3 py-1 border border-white/20 rounded-full">Potts Point</span>
              </div>
            </div>
          </div>
        </div>

        {/* DNA Section - Moved down from above */}
        <DNASection />

        {/* Blog Carousel Section */}
        <BlogCarousel />

        {/* Features Section */}
        <div className="py-20 border-t border-[#DDC6A8]/10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-[#DDC6A8] font-semibold tracking-wide uppercase">
                Our Services
              </h2>
              <p className="mt-2 text-4xl font-light tracking-tight text-[#DDC6A8] sm:text-5xl">
                Comprehensive Healthcare Solutions
              </p>
              <p className="mt-4 max-w-2xl text-xl text-[#DDC6A8]/70 lg:mx-auto">
                We offer a wide range of medical services to ensure your complete
                well-being.
              </p>
            </div>

            <div className="mt-16">
              <div className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {[
                  {
                    title: 'Urgent Care',
                    description: 'Immediate medical attention when you need it most, available 24/7.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    )
                  },
                  {
                    title: 'Telemedicine',
                    description: 'Professional healthcare consultation from the comfort of your home.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg>
                    )
                  },
                  {
                    title: 'Private Services',
                    description: 'Exclusive medical concierge services for high-profile clients and productions.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4" />
                      </svg>
                    )
                  },
                  {
                    title: 'Health Screening',
                    description: 'Comprehensive health checks for preventive care and early detection.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                      </svg>
                    )
                  },
                  {
                    title: 'Mental Health',
                    description: 'Supportive care for your mental and emotional wellbeing.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                    )
                  },
                  {
                    title: 'Specialized Care',
                    description: 'Expert medical attention for specific health concerns and conditions.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                      </svg>
                    )
                  },
                  {
                    title: 'Preventive Care',
                    description: 'Proactive health measures to prevent potential medical issues.',
                    icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                      </svg>
                    )
                  },
                ].map((feature) => (
                  <div key={feature.title} className="relative group">
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#DDC6A8]/10 text-[#DDC6A8] group-hover:bg-[#DDC6A8]/20 transition-colors">
                      {feature.icon}
                    </div>
                    <div className="ml-16">
                      <h3 className="text-lg font-light text-white">
                        {feature.title}
                      </h3>
                      <p className="mt-2 text-base text-white/90">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Credentials Section */}
        <div className="py-20 border-t border-[#DDC6A8]/10">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-light text-center mb-16 text-[#DDC6A8]">Proudly Australian Trained and Registered</h2>
            <div className="relative overflow-x-auto">
              <div className="flex animate-credentials">
                {/* First set of logos */}
                <div className="flex items-center shrink-0 gap-16">
                  <div className="logo-container">
                    <img src={logo2} alt="Training Institution 2" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo3} alt="Training Institution 3" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo4} alt="Training Institution 4" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo5} alt="Training Institution 5" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                </div>
                {/* Duplicate set for seamless loop */}
                <div className="flex items-center shrink-0 gap-16">
                  <div className="logo-container">
                    <img src={logo2} alt="Training Institution 2" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo3} alt="Training Institution 3" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo4} alt="Training Institution 4" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                  <div className="logo-container">
                    <img src={logo5} alt="Training Institution 5" className="credentials-logo h-[300px] w-[300px] object-contain" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Entertainment Industry Section */}
        <div className="py-20 border-t border-[#DDC6A8]/10">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl font-light text-center mb-16">Trusted Cast and Crew Medical Doctor</h2>
            <div className="relative overflow-x-auto">
              <div className="flex animate-clients">
                {/* First set of logos */}
                <div className="flex items-center shrink-0 gap-16">
                  {[logoA, logoB, logoC, logoD, logoE, logoF].map((logo, index) => (
                    <div key={index} className="logo-container">
                      <img src={logo} alt={`Client ${String.fromCharCode(65 + index)}`} className="credentials-logo h-[300px] w-[300px] object-contain" />
                    </div>
                  ))}
                </div>
                {/* Duplicate set for seamless loop */}
                <div className="flex items-center shrink-0 gap-16">
                  {[logoA, logoB, logoC, logoD, logoE, logoF].map((logo, index) => (
                    <div key={`dup-${index}`} className="logo-container">
                      <img src={logo} alt={`Client ${String.fromCharCode(65 + index)}`} className="credentials-logo h-[300px] w-[300px] object-contain" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <section className="py-16 bg-[#1A1A1A]">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-light text-center mb-12 text-[#DDC6A8]">Client Testimonials</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  text: "Dr. Nguyen's service is second to none. His care and expertise gave me hope when I had none.",
                  author: "Parent"
                },
                {
                  text: "His tailored approach not only healed my body but also gave me the mental strength to get back to what I love.",
                  author: "Professional Athlete"
                },
                {
                  text: "As a performer, privacy and professionalism are crucial, and he provided both without compromise.",
                  author: "Entertainment Professional"
                }
              ].map((testimonial, index) => (
                <div key={index} className="border border-[#DDC6A8]/10 p-8 rounded-lg bg-[#DDC6A8]/5">
                  <p className="text-[#DDC6A8]/80 italic mb-6">{testimonial.text}</p>
                  <p className="text-[#DDC6A8]/60 text-sm">{testimonial.author}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* About Section */}
        <div className="py-20 border-t border-[#DDC6A8]/10">
          <div className="max-w-7xl mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
              <div>
                <h2 className="text-4xl font-light mb-8">
                  "The highest quality of care isn't found in the walls of a clinic—it's in the relationship you build with your doctor"
                </h2>
                <p className="text-[#DDC6A8]/80 mb-8">
                  Dr. Terry Nguyen is a private medical doctor, healthcare consultant, and author who strives to provide high-quality, discreet medical care with a focus on compassion and personal attention.
                </p>
                <Link
                  to="/about"
                  className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                >
                  Learn More About Us
                  <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              <div className="relative">
                <img
                  src={drTerrySpeaking}
                  alt="Dr. Terry Nguyen speaking"
                  className="rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Contact CTA Section */}
        <div className="py-20 border-t border-[#DDC6A8]/10">
          <div className="max-w-3xl mx-auto text-center px-4">
            <h2 className="text-4xl font-light mb-8">Ready to Transform Your Health?</h2>
            <p className="text-[#DDC6A8]/80 mb-12">
              Schedule a consultation with Dr. Terry to begin your journey to optimal health and well-being.
            </p>
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
            >
              Book a Consultation
              <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </Link>
          </div>
        </div>
        
        {/* Service Areas Section */}
        <section className="py-20 bg-gradient-to-b from-[#1A1A1A] to-[#1A1A1A]/95">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-light mb-4 text-white">Service Areas</h2>
              <p className="text-xl text-white/90 max-w-3xl mx-auto">
                Dr. Terry Nguyen provides premium house call services throughout Sydney's most sought-after neighborhoods
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              {/* Eastern Suburbs */}
              <div className="bg-[#DDC6A8]/5 rounded-2xl overflow-hidden group transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
                <Link to="/locations/eastern-suburbs" className="block">
                  <div className="h-56 relative overflow-hidden">
                    <img 
                      src={easternSuburbsImage} 
                      alt="Eastern Suburbs" 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent"></div>
                    <div className="absolute bottom-0 w-full p-4">
                      <h3 className="text-2xl font-light text-white mb-1">Eastern Suburbs</h3>
                      <p className="text-white/90">Complete Eastern Suburbs coverage</p>
                    </div>
                  </div>
                  <div className="p-6">
                    <p className="text-white/90 mb-4">
                      Comprehensive house call services throughout Sydney's Eastern Suburbs, providing premium medical care where timely access matters most.
                    </p>
                    <div className="flex flex-wrap gap-2 mb-4">
                      <Link to="/locations/bondi-area" className="text-sm px-3 py-1 bg-[#DDC6A8]/10 rounded-full hover:bg-[#DDC6A8]/20">Bondi Area</Link>
                      <Link to="/locations/double-bay-area" className="text-sm px-3 py-1 bg-[#DDC6A8]/10 rounded-full hover:bg-[#DDC6A8]/20">Double Bay Area</Link>
                      <Link to="/locations/randwick-area" className="text-sm px-3 py-1 bg-[#DDC6A8]/10 rounded-full hover:bg-[#DDC6A8]/20">Randwick Area</Link>
                    </div>
                    <span className="text-white flex items-center">
                      View all Eastern Suburbs
                      <svg className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              
              {/* Sydney CBD Area */}
              <div className="bg-[#DDC6A8]/5 rounded-2xl overflow-hidden group transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
                <Link to="/locations/sydney-cbd-area" className="block">
                  <div className="h-56 relative overflow-hidden">
                    <img 
                      src={doctorbag} 
                      alt="Sydney CBD Area" 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent"></div>
                    <div className="absolute bottom-0 w-full p-4">
                      <h3 className="text-2xl font-light text-white mb-1">Sydney CBD</h3>
                      <p className="text-white/90">CBD, Darlinghurst, Surry Hills & Potts Point</p>
                    </div>
                  </div>
                  <div className="p-6">
                    <p className="text-white/90 mb-4">
                      Premium house call services in Sydney CBD and inner city areas. Discreet medical care for residents, business travelers, and hotel guests.
                    </p>
                    <span className="text-white flex items-center">
                      View details
                      <svg className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              
              {/* Waterloo Clinic */}
              <div className="bg-[#DDC6A8]/5 rounded-2xl overflow-hidden group transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
                <Link to="/locations/waterloo-clinic" className="block">
                  <div className="h-56 relative overflow-hidden">
                    <img 
                      src={doctorbag} 
                      alt="Waterloo Clinic" 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent"></div>
                    <div className="absolute bottom-0 w-full p-4">
                      <h3 className="text-2xl font-light text-white mb-1">Waterloo Clinic</h3>
                      <p className="text-white/90">Premium Private Medical Clinic</p>
                    </div>
                  </div>
                  <div className="p-6">
                    <p className="text-white/90 mb-4">
                      Experience Sydney's finest private medical clinic in Waterloo. Modern facilities, comprehensive care, and minimal wait times with by-appointment-only service.
                    </p>
                    <div className="flex flex-wrap gap-2 mb-4">
                      <Link to="/locations/rosebery-waterloo-area" className="text-sm px-3 py-1 bg-[#DDC6A8]/10 rounded-full hover:bg-[#DDC6A8]/20">Waterloo/Rosebery House Calls</Link>
                    </div>
                    <span className="text-white flex items-center">
                      View clinic details
                      <svg className="w-4 h-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            
            <div className="mt-16 text-center">
              <Link
                to="/dynamic-booking"
                className="inline-flex items-center px-8 py-4 bg-[#DDC6A8] text-[#1A1A1A] rounded-full hover:bg-white hover:text-[#1A1A1A] transition-all text-lg font-medium shadow-lg"
              >
                Book a House Call
                <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home; 