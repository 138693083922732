import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SEO from '../../components/SEO';
import 'leaflet/dist/leaflet.css';
import drTerryPhoto from '../../assets/images/profile/drterry_hero.png';

// Import placeholder image
import doctorbagImage from '../../assets/images/sections/doctorbag.png';

const SydneyCBDArea = () => {
  // SEO structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "LocalBusiness", "Physician"],
    "name": "Dr. Terry Nguyen - Sydney CBD House Call Doctor",
    "description": "Premium private house call doctor providing 24/7 medical care throughout Sydney CBD, Darlinghurst, Surry Hills and Potts Point. Direct doctor access with no waiting.",
    "url": "https://homedoc.com.au/locations/sydney-cbd-area",
    "telephone": "+61412345678",
    "priceRange": "$$$",
    "areaServed": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "-33.8688",
        "longitude": "151.2093"
      },
      "geoRadius": "5km"
    }
  };

  // List of suburbs served in this area
  const suburbs = [
    "Sydney CBD", "Darlinghurst", "Surry Hills", "Potts Point", "Elizabeth Bay", "Woolloomooloo", "Pyrmont"
  ];

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      {/* SEO Component */}
      <SEO 
        title="Sydney CBD House Call Doctor | After Hours Home Visits | Dr. Terry Nguyen"
        description="I am available 24/7 for premium doctor house calls in Sydney CBD, Darlinghurst, Surry Hills and Potts Point. Discrete medical care delivered directly to your home or hotel."
        keywords={[
          'Sydney CBD house call doctor',
          'after hours doctor Sydney',
          'hotel doctor Sydney CBD',
          'private doctor Surry Hills',
          'Darlinghurst house calls',
          'Potts Point home doctor',
          'urgent medical care CBD Sydney',
          'private GP Sydney city',
          'premium medical house calls Sydney',
          'executive doctor Sydney'
        ]}
        schema={structuredData}
      />
      
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="relative">
          <div className="h-[60vh] md:h-[70vh] bg-gradient-to-b from-[#1A1A1A]/90 to-[#1A1A1A]/30" style={{
            backgroundImage: `url(${doctorbagImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay'
          }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="max-w-4xl mx-auto px-4 text-center">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-light mb-6 text-white">
                  Premium House Call Doctor in Sydney CBD
                </h1>
                <p className="text-xl md:text-2xl text-white/90 mb-8">
                  Discrete medical care delivered to your doorstep in Sydney CBD, Darlinghurst, Surry Hills & Potts Point
                </p>
                <Link
                  to="/dynamic-booking"
                  state={{ service: "Sydney CBD House Call" }}
                  className="inline-flex items-center px-8 py-4 text-lg border-2 border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                >
                  Book a House Call
                  <svg className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* 24/7 Availability Banner */}
        <div className="bg-[#DDC6A8] text-[#1A1A1A] py-4">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-2xl font-medium">
              I am available 24/7 for house calls and after hours home visits in Sydney CBD
            </h2>
            <p className="mt-2 text-[#1A1A1A]/80 font-medium">
              Immediate attention • No waiting rooms • Direct doctor access • Call now
            </p>
          </div>
        </div>

        {/* Basic placeholder container */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-4xl mx-auto px-4">
            <div className="prose prose-lg prose-invert max-w-none">
              <p className="text-xl text-[#DDC6A8]">
                Dr. Terry Nguyen provides premium private medical care through discreet house calls throughout Sydney CBD and inner city areas. With special attention to the unique needs of city residents and visitors, Dr. Terry brings comprehensive, personalized healthcare directly to your residence, hotel, or corporate accommodation in Sydney CBD, Darlinghurst, Surry Hills, Potts Point, and surrounding neighborhoods.
              </p>
              <p className="text-white opacity-95">
                More detailed content for this page will be added soon...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SydneyCBDArea;
