import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEO from '../../components/SEO';
import drTerryPhoto from '../../assets/images/profile/drterry_hero.png';

// Import Waterloo clinic image when available
// Replace with actual clinic image
import waterlooClinicImage from '../../assets/images/sections/doctorbag.png';

const WaterlooClinic = () => {
  // Waterloo clinic schema.org structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "LocalBusiness", "MedicalClinic"],
    "name": "Dr. Terry Nguyen - Waterloo Private Medical Clinic",
    "description": "Premium private medical clinic in Waterloo, Sydney offering personalized care with minimal wait times. By appointment only with Dr. Terry Nguyen.",
    "url": "https://homedoc.com.au/locations/waterloo-clinic",
    "image": "https://homedoc.com.au/images/waterloo-clinic.jpg",
    "telephone": "+61412345678",
    "priceRange": "$$$",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Suite 66/6 Lachlan St",
      "addressLocality": "Waterloo",
      "addressRegion": "NSW",
      "postalCode": "2018",
      "addressCountry": "AU"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-33.9045",
      "longitude": "151.2015"
    },
    "medicalSpecialty": ["FamilyMedicine", "PrimaryCare", "MensHealth"],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "Comprehensive Health Assessment",
        "description": "Thorough health evaluation with personalized care plans."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Men's Health Consultations",
        "description": "Specialized care for men's health concerns."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Diagnostic Services",
        "description": "Advanced diagnostic testing and assessment."
      }
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "09:00",
        "closes": "17:00"
      }
    ]
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      {/* SEO Component */}
      <SEO 
        title="Premier Private Doctor Waterloo | Medical Clinic Sydney | Dr. Terry Nguyen"
        description="Experience Sydney's finest private medical clinic in Waterloo. Modern facilities, comprehensive care, and minimal wait times with Dr. Terry Nguyen. By appointment only."
        keywords={[
          'Waterloo private doctor',
          'premium medical clinic Sydney',
          'Dr Terry Nguyen Waterloo',
          'appointment only doctor',
          'exclusive GP clinic',
          'men\'s health Waterloo',
          'private billing doctor',
          'minimal wait time clinic',
          'best doctor Waterloo',
          'executive health clinic'
        ]}
        schema={structuredData}
      />
      
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="relative">
          <div className="h-[60vh] md:h-[70vh] bg-gradient-to-b from-[#1A1A1A]/90 to-[#1A1A1A]/30" style={{
            backgroundImage: `url(${waterlooClinicImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay'
          }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="max-w-4xl mx-auto px-4 text-center">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-light mb-6 text-white">
                  Sydney's Premier Private Medical Clinic in Waterloo
                </h1>
                <p className="text-xl md:text-2xl text-white/90 mb-8">
                  By appointment only. Limited spots. Exceptional care.
                </p>
                <Link
                  to="/dynamic-booking"
                  state={{ service: "Waterloo Clinic Visit" }}
                  className="inline-flex items-center px-8 py-4 text-lg border-2 border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                >
                  Book an Appointment
                  <svg className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Introduction Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-4xl mx-auto px-4">
            <div className="prose prose-lg prose-invert max-w-none">
              <p className="text-xl text-[#DDC6A8]">
                Dr. Terry Nguyen's exclusive Waterloo clinic represents a new standard in premium healthcare for Sydney's discerning patients. Unlike conventional medical practices, our clinic operates strictly by appointment, ensuring each patient receives unhurried, focused attention in a serene, private environment.
              </p>
              <p className="text-white opacity-95">
                Located in the heart of Waterloo at Suite 66/6 Lachlan Street, our clinic maintains a deliberately limited patient roster, allowing Dr. Terry to provide truly personalized care without the constraints of the traditional medical system. With state-of-the-art facilities and comprehensive services, we offer an exclusive healthcare experience for those who value their wellbeing and time equally.
              </p>
              <p className="text-white opacity-95">
                As a private billing-only practice, we ensure minimal wait times and extended consultations that address not just immediate health concerns, but your long-term wellness journey. Experience the difference that true medical attention—without rushed appointments or crowded waiting rooms—can make to your health outcomes.
              </p>
            </div>
          </div>
        </div>

        {/* Clinic Features Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-10 text-center">The Waterloo Clinic Experience</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="text-center mb-6">
                  <div className="bg-[#DDC6A8] rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                    <svg className="w-8 h-8 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8]">Extended Consultations</h3>
                </div>
                <p className="text-[#DDC6A8]/80 text-center">
                  Our appointments are never rushed. Standard consultations are 30 minutes, with complex assessments allocated up to 60 minutes, allowing for thorough discussion and comprehensive care.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="text-center mb-6">
                  <div className="bg-[#DDC6A8] rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                    <svg className="w-8 h-8 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8]">Absolute Privacy</h3>
                </div>
                <p className="text-[#DDC6A8]/80 text-center">
                  Our discrete setting and strict appointment system ensure complete privacy. You'll never encounter other patients in waiting areas, with private entry and exit pathways for sensitive consultations.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="text-center mb-6">
                  <div className="bg-[#DDC6A8] rounded-full p-4 w-16 h-16 mx-auto mb-4 flex items-center justify-center">
                    <svg className="w-8 h-8 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8]">Advanced Diagnostics</h3>
                </div>
                <p className="text-[#DDC6A8]/80 text-center">
                  Our clinic features state-of-the-art diagnostic equipment, allowing for immediate assessment of many conditions without referrals to external facilities, saving you time and inconvenience.
                </p>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start">
                  <div className="mr-6 bg-[#DDC6A8] rounded-full p-3 flex-shrink-0">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-3 text-[#DDC6A8]">Exclusive Atmosphere</h3>
                    <p className="text-[#DDC6A8]/80">
                      Our boutique clinic environment offers a calming space designed with acoustic privacy, premium furnishings, and thoughtful amenities that make every visit comfortable and stress-free.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start">
                  <div className="mr-6 bg-[#DDC6A8] rounded-full p-3 flex-shrink-0">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-3 text-[#DDC6A8]">Punctual Appointments</h3>
                    <p className="text-[#DDC6A8]/80">
                      We respect your time. With our carefully managed schedule and limited appointments per day, you'll be seen at your appointed time, eliminating the frustration of long waiting periods common in other practices.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start">
                  <div className="mr-6 bg-[#DDC6A8] rounded-full p-3 flex-shrink-0">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-3 text-[#DDC6A8]">Direct Doctor Communication</h3>
                    <p className="text-[#DDC6A8]/80">
                      As a patient of our clinic, you'll have direct communication channels to Dr. Terry, including a private phone line for urgent concerns and secure messaging for non-urgent follow-ups.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start">
                  <div className="mr-6 bg-[#DDC6A8] rounded-full p-3 flex-shrink-0">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-3 text-[#DDC6A8]">Continuity of Care</h3>
                    <p className="text-[#DDC6A8]/80">
                      You'll always see Dr. Terry, ensuring true continuity in your healthcare journey. This personal relationship allows for better clinical outcomes, as every aspect of your health history is thoroughly understood.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Services Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-10 text-center">Premium Medical Services at Waterloo Clinic</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Executive Health Assessments</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Comprehensive evaluations designed for busy professionals, featuring advanced screenings, detailed health risk assessments, and personalized wellness strategies that fit your lifestyle and career demands.
                </p>
                <Link to="/executive-health" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Men's Health Specialization</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Confidential consultations addressing the unique health concerns of men, including hormonal optimization, sexual health, preventative screenings, and mental wellbeing in a discrete, judgment-free environment.
                </p>
                <Link to="/mens-health" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Chronic Disease Management</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Sophisticated, evidence-based approaches to managing ongoing health conditions, with personalized treatment plans, regular monitoring, and proactive adjustments to optimize your health and quality of life.
                </p>
                <Link to="/chronic-disease-management" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Travel Medicine</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Comprehensive pre-travel consultations with destination-specific health advice, vaccinations, preventative medications, and personalized health kits. Includes post-travel follow-up for returning travelers.
                </p>
                <Link to="/travel-medicine" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all md:col-span-2">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Integrative Health Consultations</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  A holistic approach that combines traditional medicine with evidence-based complementary therapies, addressing the root causes of health concerns rather than just managing symptoms. Includes nutritional medicine, stress management strategies, and lifestyle optimization.
                </p>
                <Link to="/integrative-health" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
            
            <div className="text-center">
              <Link
                to="/dynamic-booking"
                state={{ service: "Waterloo Clinic Visit" }}
                className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
              >
                Book Your Waterloo Clinic Appointment
                <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
        
        {/* Location Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-8 text-center">Waterloo Clinic Location</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-16">
              <div>
                <div className="h-[400px] rounded-xl overflow-hidden">
                  {/* Replace with an actual map component or embed */}
                  <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3310.9612135513815!2d151.19932391520915!3d-33.90444798064547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b1d5b7c5c4f9%3A0x1c0f4a9d14e2b43!2s6%20Lachlan%20St%2C%20Waterloo%20NSW%202017!5e0!3m2!1sen!2sau!4v1616391953641!5m2!1sen!2sau" 
                    width="100%" 
                    height="100%" 
                    style={{ border: 0 }} 
                    allowFullScreen="" 
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              
              <div>
                <div className="prose prose-lg prose-invert max-w-none">
                  <h3 className="text-2xl font-light text-[#DDC6A8]">Suite 66/6 Lachlan St, Waterloo NSW 2018</h3>
                  
                  <h4 className="text-xl font-light text-[#DDC6A8] mt-8">Getting Here</h4>
                  <p>
                    Our clinic is centrally located in Waterloo with excellent access from throughout Sydney's inner suburbs. The clinic entrance is discreetly positioned for privacy.
                  </p>
                  
                  <h4 className="text-xl font-light text-[#DDC6A8] mt-6">Parking</h4>
                  <p>
                    Secure private parking is available for our patients at no additional charge. Simply inform our team when booking your appointment to receive access instructions.
                  </p>
                  
                  <h4 className="text-xl font-light text-[#DDC6A8] mt-6">Public Transport</h4>
                  <p>
                    The clinic is a short walk from Green Square station and multiple bus routes. For patients preferring not to use public transport, we can arrange discrete car service upon request.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Private Billing Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Private Billing for Premium Care</h2>
            
            <div className="bg-[#DDC6A8]/5 p-8 rounded-xl mb-12">
              <div className="prose prose-lg prose-invert max-w-none mb-6">
                <p>
                  Our Waterloo clinic operates exclusively on a private billing model, allowing us to deliver an uncompromised standard of healthcare without the constraints of the Medicare system. This approach enables us to offer extended consultations, minimal wait times, and personalized care that simply isn't possible in bulk-billing practices.
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Consultation Fees</h3>
                  <ul className="space-y-4">
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Standard Consultation (30 min)</span>
                      <span className="font-medium">$150</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Extended Consultation (45 min)</span>
                      <span className="font-medium">$210</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Comprehensive Assessment (60 min)</span>
                      <span className="font-medium">$280</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span>Executive Health Assessment (90+ min)</span>
                      <span className="font-medium">$450</span>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Additional Services</h3>
                  <ul className="space-y-4">
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Minor Procedures</span>
                      <span className="font-medium">from $120</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Telehealth Follow-up</span>
                      <span className="font-medium">$85</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Medical Certificates</span>
                      <span className="font-medium">Included</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span>Prescription Requests (existing patients)</span>
                      <span className="font-medium">$45</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="mt-8">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Payment Information</h3>
                <div className="prose prose-lg prose-invert max-w-none">
                  <p>
                    Payment is required at the time of service. We accept all major credit cards, EFTPOS, and secure digital payment methods. For your convenience, we can provide detailed receipts for private health insurance claims where applicable.
                  </p>
                  <p>
                    Some services may attract a Medicare rebate which can be processed on-site, returning a portion of your consultation fee immediately. Our team will advise which services are eligible for rebates.
                  </p>
                  <p>
                    For patients requiring regular care, we offer membership options that provide priority access and preferential rates. Please inquire with our team for details.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* FAQ Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Frequently Asked Questions About Our Waterloo Clinic</h2>
            
            <div className="space-y-6 mb-10">
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How do I secure an appointment at your Waterloo clinic?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Our Waterloo clinic operates strictly by appointment only. You can book by calling our dedicated line at (02) 9123 4567, through our online booking system, or by contacting us via email. As we maintain a limited patient roster to ensure premium care, we recommend booking 1-2 weeks in advance for routine appointments.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">What's included in your executive health assessment?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Our executive health assessment is a comprehensive evaluation that includes advanced pathology testing, cardiovascular assessment, respiratory function testing, body composition analysis, and detailed health risk stratification. Following your assessment, you'll receive a thorough report and personalized health optimization plan, with a follow-up consultation to discuss implementation strategies.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Do you offer after-hours appointments?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Yes, for existing patients, we offer a limited number of early morning (from 7:30am) and evening appointments (until 7:00pm) on select days. These appointments are reserved for working professionals who cannot attend during standard hours. For urgent after-hours care, Dr. Terry also provides house calls to existing Waterloo clinic patients.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How does your clinic differ from standard GP practices?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Unlike conventional practices that typically allocate 5-15 minutes per patient, our appointments start at 30 minutes, allowing for thorough discussion and comprehensive care. We maintain a strictly limited patient roster, ensuring personalized attention and minimal wait times. Our exclusively private billing model enables us to provide a level of service and continuity that bulk-billing practices simply cannot match.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Do you provide specialist referrals?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Yes, Dr. Terry maintains professional relationships with Sydney's leading specialists across all fields of medicine. When a referral is necessary, we coordinate with specialists who share our commitment to excellence and patient care. For urgent specialist assessments, we can often facilitate priority appointments through our professional network.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Can I transfer my medical records to your practice?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Absolutely. We facilitate a seamless transfer of your medical records from your previous healthcare providers. Simply provide us with their contact information, and our team will handle the secure transfer of your health information. For new patients, we recommend scheduling an extended first appointment to thoroughly review your medical history and establish a comprehensive baseline for your care.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="text-center">
              <p className="mb-6 text-[#DDC6A8]/90">
                Have more questions about our Waterloo clinic?
              </p>
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        
        {/* About Dr. Terry Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Meet Your Waterloo Clinic Doctor</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
              <div className="md:col-span-1">
                <img src={drTerryPhoto} alt="Dr. Terry Nguyen" className="rounded-xl shadow-2xl w-full" />
              </div>
              
              <div className="md:col-span-2">
                <div className="prose prose-lg prose-invert max-w-none">
                  <h3>Dr. Terry Nguyen</h3>
                  <p>
                    Dr. Terry Nguyen founded this exclusive Waterloo clinic to provide a level of medical care that transcends the constraints of conventional practice. With extensive experience across emergency medicine, general practice, and specialized men's health, Dr. Terry brings a comprehensive approach to each patient's care.
                  </p>
                  <p>
                    Having trained at the University of Sydney Medical School and completed additional qualifications in preventative medicine, Dr. Terry combines clinical excellence with a deeply personal approach to healthcare. He deliberately maintains a limited patient roster to ensure he can provide the attention and continuity that optimal healthcare demands.
                  </p>
                  <p>
                    When not seeing patients at the Waterloo clinic, Dr. Terry also conducts house calls for eligible patients and contributes to medical research and education. His commitment to advancing healthcare extends beyond individual consultations to improving medical practice more broadly.
                  </p>
                  <Link to="/about" className="text-[#DDC6A8] hover:text-white no-underline">
                    Learn more about Dr. Terry's qualifications and approach
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Final CTA Section */}
        <div className="py-16 bg-gradient-to-b from-[#1A1A1A] to-[#000]">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-light mb-6">
              Experience Sydney's Premier Private Medical Practice
            </h2>
            
            <p className="text-xl text-[#DDC6A8]/90 mb-10 max-w-3xl mx-auto">
              Join our exclusive patient community at the Waterloo clinic. Limited spots available for those who value personalized, unhurried, and exceptional medical care.
            </p>
            
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
              <Link
                to="/dynamic-booking"
                state={{ service: "Waterloo Clinic Visit" }}
                className="inline-flex items-center px-8 py-4 bg-[#DDC6A8] text-[#1A1A1A] rounded-full hover:bg-white transition-all text-lg font-medium"
              >
                Request an Appointment
                <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
              
              <Link
                to="/contact"
                className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all text-lg font-medium"
              >
                Contact Dr. Terry
                <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterlooClinic; 