import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * SEO component for managing all meta tags and structured data
 * @param {Object} props - The component props
 * @param {string} props.title - The page title
 * @param {string} props.description - Meta description
 * @param {string} props.canonical - Canonical URL
 * @param {string} props.ogImage - Open Graph image URL
 * @param {string} props.ogType - Open Graph type (default: website)
 * @param {Array} props.keywords - SEO keywords
 * @param {Object} props.schema - JSON-LD schema data
 * @param {Object} props.location - Current location object
 * @param {string} props.pageType - Type of page (e.g., 'telemedicine', 'house-call', 'clinic', etc.)
 */
const SEO = ({
  title = 'House Call Doctor Sydney | After Hours Home Doctor | Dr. Terry Nguyen',
  description = 'Expert house call doctor in Sydney\'s Eastern Suburbs. 24/7 after-hours home visits & Medicare bulk-billed telemedicine across Australia. Private doctor services by Dr. Terry Nguyen.',
  canonical,
  ogImage = '/src/assets/images/profile/drterry_hero.png',
  ogType = 'website',
  keywords = [
    'house call doctor',
    'after hours doctor',
    'home doctor',
    'doctor near me',
    'Eastern Suburbs doctor',
    'telemedicine Australia',
    'private doctor Sydney',
    'urgent care at home',
    'Medicare bulk bill doctor',
    'home visits doctor',
    'mobile doctor',
    'Dr Terry Nguyen',
    'best doctor Sydney',
    'comprehensive health check'
  ],
  schema,
  location,
  pageType
}) => {
  const siteUrl = 'https://homedoc.com.au';
  const canonicalUrl = canonical ? `${siteUrl}${canonical}` : window.location.href;
  const ogImageUrl = ogImage.startsWith('http') ? ogImage : `${siteUrl}${ogImage}`;

  // Default schema
  const defaultSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalBusiness",
    "name": "Dr. Terry Nguyen - House Call Doctor Sydney",
    "url": siteUrl,
    "logo": `${siteUrl}/logo.png`,
    "image": ogImageUrl,
    "description": description,
    "telephone": "+61290000000",
    "email": "info@homedoc.com.au",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Suite 66/6 Lachlan St",
      "addressLocality": "Waterloo",
      "addressRegion": "NSW",
      "postalCode": "2018",
      "addressCountry": "AU"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-33.9045",
      "longitude": "151.2015"
    },
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "09:00",
        "closes": "17:00"
      }
    ],
    "sameAs": [
      "https://www.facebook.com/homedocau",
      "https://www.instagram.com/homedocau",
      "https://twitter.com/homedocau",
      "https://www.linkedin.com/company/homedocau"
    ]
  };

  // Enhanced telemedicine schema
  const telemedicineSchema = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "MedicalClinic", "HealthAndBeautyBusiness"],
    "name": "Dr. Terry Nguyen - Telemedicine Services Australia",
    "url": `${siteUrl}/telemedicine`,
    "logo": `${siteUrl}/logo.png`,
    "image": ogImageUrl,
    "description": "Premium telemedicine consultations available Australia-wide with Dr. Terry Nguyen. Secure video consultations, online prescriptions, and personalized care.",
    "telephone": "+61290000000",
    "email": "telehealth@homedoc.com.au",
    "medicalSpecialty": [
      "PrimaryCare", 
      "FamilyMedicine", 
      "TelehealthServices"
    ],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "Video Consultations",
        "description": "Secure video consultations with Dr. Terry Nguyen."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Online Prescriptions",
        "description": "Electronic prescriptions sent directly to your preferred pharmacy."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Telehealth Assessments",
        "description": "Comprehensive medical assessments via secure video platform."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Digital Medical Certificates",
        "description": "Official medical certificates issued electronically."
      }
    ],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Telemedicine Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Standard Telemedicine Consultation",
            "description": "15-20 minute video consultation for general health concerns"
          },
          "price": "85.00",
          "priceCurrency": "AUD"
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Extended Telemedicine Consultation",
            "description": "30-45 minute video consultation for complex health issues"
          },
          "price": "150.00",
          "priceCurrency": "AUD"
        }
      ]
    },
    "serviceArea": {
      "@type": "GeoShape",
      "name": "Australia-wide",
      "description": "Telemedicine services available across all Australian states and territories"
    },
    "potentialAction": {
      "@type": "ReserveAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `${siteUrl}/dynamic-booking`,
        "inLanguage": "en-AU",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/IOSPlatform",
          "http://schema.org/AndroidPlatform"
        ]
      },
      "result": {
        "@type": "Reservation",
        "name": "Telemedicine Appointment Booking"
      }
    }
  };

  // Enhanced house call schema
  const houseCallSchema = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "Physician"],
    "name": "Dr. Terry Nguyen - House Call Doctor Sydney",
    "url": `${siteUrl}/house-calls`,
    "logo": `${siteUrl}/logo.png`,
    "image": ogImageUrl,
    "description": "Premium house call doctor service in Sydney's Eastern Suburbs. 24/7 medical care in the comfort of your home with Dr. Terry Nguyen.",
    "telephone": "+61290000000",
    "email": "housecalls@homedoc.com.au",
    "medicalSpecialty": [
      "HouseCallMedicine", 
      "FamilyMedicine", 
      "UrgentCare"
    ],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "Urgent Medical Care",
        "description": "Treatment for non-emergency but urgent medical conditions in your home."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Mobile Diagnostic Services",
        "description": "Comprehensive diagnostic assessment with portable medical equipment."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Home Medication Management",
        "description": "Review and optimization of medication regimens in your home."
      }
    ],
    "serviceArea": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "-33.8882",
        "longitude": "151.2637"
      },
      "geoRadius": "15km"
    },
    "areaServed": [
      {
        "@type": "City",
        "name": "Bondi",
        "containedInPlace": {
          "@type": "State",
          "name": "NSW"
        }
      },
      {
        "@type": "City",
        "name": "Double Bay",
        "containedInPlace": {
          "@type": "State",
          "name": "NSW"
        }
      },
      {
        "@type": "City",
        "name": "Paddington",
        "containedInPlace": {
          "@type": "State",
          "name": "NSW"
        }
      },
      {
        "@type": "City",
        "name": "Randwick",
        "containedInPlace": {
          "@type": "State",
          "name": "NSW"
        }
      }
    ],
    "potentialAction": {
      "@type": "ReserveAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `${siteUrl}/dynamic-booking`,
        "inLanguage": "en-AU",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/IOSPlatform",
          "http://schema.org/AndroidPlatform"
        ]
      },
      "result": {
        "@type": "Reservation",
        "name": "House Call Booking"
      }
    }
  };

  // Enhanced clinic schema
  const clinicSchema = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "MedicalClinic", "LocalBusiness"],
    "name": "Dr. Terry Nguyen - Waterloo Private Medical Clinic",
    "url": `${siteUrl}/locations/waterloo-clinic`,
    "logo": `${siteUrl}/logo.png`,
    "image": ogImageUrl,
    "description": "Premium private medical clinic in Waterloo, Sydney offering personalized care with minimal wait times. By appointment only with Dr. Terry Nguyen.",
    "telephone": "+61290000000",
    "email": "clinic@homedoc.com.au",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Suite 66/6 Lachlan St",
      "addressLocality": "Waterloo",
      "addressRegion": "NSW",
      "postalCode": "2018",
      "addressCountry": "AU"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "-33.9045",
      "longitude": "151.2015"
    },
    "medicalSpecialty": [
      "FamilyMedicine", 
      "PrimaryCare", 
      "MensHealth"
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        "opens": "09:00",
        "closes": "17:00"
      }
    ],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "Comprehensive Health Assessment",
        "description": "Thorough health evaluation with personalized care plans."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Men's Health Consultations",
        "description": "Specialized care for men's health concerns."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Diagnostic Services",
        "description": "Advanced diagnostic testing and assessment."
      }
    ],
    "potentialAction": {
      "@type": "ReserveAction",
      "target": {
        "@type": "EntryPoint",
        "urlTemplate": `${siteUrl}/dynamic-booking`,
        "inLanguage": "en-AU",
        "actionPlatform": [
          "http://schema.org/DesktopWebPlatform",
          "http://schema.org/IOSPlatform",
          "http://schema.org/AndroidPlatform"
        ]
      },
      "result": {
        "@type": "Reservation",
        "name": "Clinic Appointment Booking"
      }
    }
  };

  // Choose the appropriate schema based on pageType
  const getPageSchema = () => {
    if (schema) return schema; // If custom schema is provided, use that

    switch(pageType) {
      case 'telemedicine':
        return telemedicineSchema;
      case 'house-call':
        return houseCallSchema;
      case 'clinic':
        return clinicSchema;
      default:
        return defaultSchema;
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content={ogType} />
      <meta property="og:image" content={ogImageUrl} />
      <meta property="og:site_name" content="Dr. Terry Nguyen - House Call Doctor" />

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImageUrl} />

      {/* Locale Tags */}
      <meta property="og:locale" content="en_AU" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(getPageSchema())}
      </script>
    </Helmet>
  );
};

export default SEO; 