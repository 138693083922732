import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import bgImage from '../assets/images/Inspire2.jpg';
import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../config/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';

// We'll use the pre-initialized Stripe instance from window.stripePromise

const appointmentTypes = [
  {
    id: 'health-check',
    name: 'Health Check',
    price: 75,
    duration: '60 min',
    description: 'Complete health check-up with detailed consultation'
  },
  {
    id: 'follow-up',
    name: 'Follow-up Visit',
    price: 50,
    duration: '30 min',
    description: 'Follow-up consultation for existing patients'
  }
];

const Booking = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedType, setSelectedType] = useState(appointmentTypes[0]);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedDate || !selectedTime || !selectedType) return;

    setLoading(true);
    try {
      // Create appointment in Firestore
      const appointmentRef = await addDoc(collection(db, 'appointments'), {
        userId: currentUser.uid,
        date: Timestamp.fromDate(new Date(`${selectedDate}T${selectedTime}`)),
        type: selectedType.name,
        price: selectedType.price,
        status: 'pending',
        createdAt: Timestamp.now()
      });

      // Initialize Stripe checkout
      const stripe = window.stripePromise;
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          appointmentId: appointmentRef.id,
          appointmentType: selectedType.name,
          price: selectedType.price
        }),
      });

      const session = await response.json();
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error);
      }
    } catch (error) {
      console.error('Error booking appointment:', error);
    } finally {
      setLoading(false);
    }
  };

  // Optional redirect effect - uncomment if you want to automatically redirect after a delay
  // useEffect(() => {
  //   // Redirect to dynamic booking after 3 seconds
  //   const redirectTimer = setTimeout(() => {
  //     navigate('/dynamic-booking');
  //   }, 3000);
  //   
  //   return () => clearTimeout(redirectTimer);
  // }, [navigate]);

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Book an Appointment | HomeDoc | Medical Services</title>
        <meta name="description" content="Book an appointment with our experienced doctors. Choose from in-person clinic visits, house calls, or telehealth consultations to meet your healthcare needs." />
        <meta name="keywords" content="book doctor appointment, medical booking, telehealth booking, house call doctor, clinic appointment, HomeDoc" />
        <link rel="canonical" href="https://homedoc.com.au/booking" />
      </Helmet>
      
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">Book an Appointment</h1>
            <p className="text-xl text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Choose the appointment type that works best for you and your family's needs
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl">
              <div className="h-48 overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1586773860418-d37222d8fce3?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80" 
                  alt="Clinic visit" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-3">Clinic Visit</h2>
                <p className="text-[#DDC6A8]/80 mb-6">
                  Visit our modern clinic in Waterloo for in-person consultations with our experienced doctors.
                </p>
                <ul className="text-[#DDC6A8]/80 space-y-2 mb-6">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Full physical examinations</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Access to on-site procedures</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Medicare rebates available</span>
                  </li>
                </ul>
                <Link 
                  to="/dynamic-booking" 
                  className="w-full block text-center px-4 py-2 border border-[#DDC6A8] text-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
                >
                  Book Clinic Visit
                </Link>
              </div>
            </div>
            
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl">
              <div className="h-48 overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80" 
                  alt="House call" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-3">House Call</h2>
                <p className="text-[#DDC6A8]/80 mb-6">
                  Our doctors come to you, providing medical care in the comfort of your home.
                </p>
                <ul className="text-[#DDC6A8]/80 space-y-2 mb-6">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">No need to travel when sick</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Same-day visits available</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">After-hours services</span>
                  </li>
                </ul>
                <Link 
                  to="/dynamic-booking" 
                  className="w-full block text-center px-4 py-2 border border-[#DDC6A8] text-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
                >
                  Book House Call
                </Link>
              </div>
            </div>
            
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl">
              <div className="h-48 overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80" 
                  alt="Telehealth" 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h2 className="text-2xl font-light text-[#DDC6A8] mb-3">Telehealth</h2>
                <p className="text-[#DDC6A8]/80 mb-6">
                  Connect with our doctors via secure video consultation from anywhere.
                </p>
                <ul className="text-[#DDC6A8]/80 space-y-2 mb-6">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Convenient from home or work</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">No exposure to other illnesses</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-2">Secure and private platform</span>
                  </li>
                </ul>
                <Link 
                  to="/dynamic-booking" 
                  className="w-full block text-center px-4 py-2 border border-[#DDC6A8] text-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
                >
                  Book Telehealth
                </Link>
              </div>
            </div>
          </div>
          
          <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl p-8 mb-16">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Why Choose HomeDoc?</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <ul className="space-y-4">
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Experienced doctors</p>
                      <p className="text-[#DDC6A8]/70 text-sm">Our team consists of highly qualified and experienced medical professionals.</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Flexible options</p>
                      <p className="text-[#DDC6A8]/70 text-sm">Choose the service that best fits your needs and schedule.</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Same-day appointments</p>
                      <p className="text-[#DDC6A8]/70 text-sm">Urgent care available for when you need medical attention quickly.</p>
                    </div>
                  </li>
                </ul>
              </div>
              
              <div>
                <ul className="space-y-4">
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Medicare rebates</p>
                      <p className="text-[#DDC6A8]/70 text-sm">Medicare rebates available for eligible consultations.</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Comprehensive care</p>
                      <p className="text-[#DDC6A8]/70 text-sm">From preventive care to chronic disease management.</p>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-[#DDC6A8] font-medium">Family-friendly</p>
                      <p className="text-[#DDC6A8]/70 text-sm">Specialized care for patients of all ages, from infants to elderly.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="text-center">
            <div className="inline-block bg-[#2A2A2A]/30 backdrop-blur-sm p-8 rounded-xl shadow-lg">
              <h2 className="text-3xl font-light text-[#DDC6A8] mb-4">Ready to Book Your Appointment?</h2>
              <p className="text-[#DDC6A8]/80 max-w-2xl mx-auto mb-8">
                Choose from clinic visits, house calls, or telehealth consultations to get the care you need, when you need it.
              </p>
              <Link
                to="/dynamic-booking"
                className="inline-flex items-center justify-center px-10 py-5 border border-[#DDC6A8] text-xl font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
              >
                Book Your Appointment Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking; 