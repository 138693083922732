import { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

// Check if we're using test mode
const isTestMode = false; // Explicitly set to false for live mode
console.log('Payment page - Stripe mode:', isTestMode ? 'TEST' : 'LIVE');

// Use the pre-initialized Stripe instance from index.html
// This avoids any potential issues with environment variables
console.log('Using pre-initialized Stripe instance from window.stripePromise');

export default function Payment() {
  const { bookingId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        console.log('Payment initialization started');
        console.log('Location state:', location.state);
        console.log('Booking ID from params:', bookingId);
        
        // If state is missing, try to fetch booking details from Firestore
        let price, serviceType, patientEmail, patientName, patientPhone, patientAddress;
        
        if (!location.state?.bookingId || !location.state?.price || !location.state?.serviceType) {
          console.log('Missing data in location state, fetching from Firestore');
          if (!bookingId) {
            throw new Error('No booking ID provided');
          }
          const bookingDoc = await getDoc(doc(db, 'bookings', bookingId));
          if (!bookingDoc.exists()) {
            throw new Error('Booking not found');
          }
          const bookingData = bookingDoc.data();
          console.log('Booking data from Firestore:', bookingData);
          price = bookingData.price;
          serviceType = bookingData.service.name;
          
          // Extract patient details
          if (bookingData.patientDetails) {
            patientEmail = bookingData.patientDetails.email;
            patientName = bookingData.patientDetails.name || 
                         `${bookingData.patientDetails.firstName || ''} ${bookingData.patientDetails.lastName || ''}`.trim();
            patientPhone = bookingData.patientDetails.phone;
            patientAddress = bookingData.patientDetails.address;
            
            console.log('Patient details from Firestore:', {
              email: patientEmail,
              name: patientName,
              phone: patientPhone,
              address: patientAddress
            });
          }
        } else {
          console.log('Using data from location state');
          price = location.state.price;
          serviceType = location.state.serviceType;
          
          // If patient details are in location state
          if (location.state.patientDetails) {
            patientEmail = location.state.patientDetails.email;
            patientName = location.state.patientDetails.name || 
                         `${location.state.patientDetails.firstName || ''} ${location.state.patientDetails.lastName || ''}`.trim();
            patientPhone = location.state.patientDetails.phone;
            patientAddress = location.state.patientDetails.address;
            
            console.log('Patient details from location state:', {
              email: patientEmail,
              name: patientName,
              phone: patientPhone,
              address: patientAddress
            });
          }
        }
        
        // Validate email
        if (!patientEmail || patientEmail.trim() === '') {
          console.error('Missing patient email');
          throw new Error('Invalid email address: Patient email is required');
        }
        
        // Basic email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(patientEmail.trim())) {
          console.error('Invalid email format:', patientEmail);
          throw new Error('Invalid email address: Please provide a valid email format');
        }

        // Use pre-initialized Stripe from window
        const stripe = window.stripePromise;
        if (!stripe) {
          throw new Error('Failed to initialize Stripe - window.stripePromise is not available');
        }
        
        console.log('Using Stripe instance from window.stripePromise');

        console.log('Creating checkout session with:', {
          bookingId,
          serviceType,
          price,
          customerEmail: patientEmail,
          customerName: patientName,
          customerPhone: patientPhone,
          customerAddress: patientAddress
        });

        // Create checkout session
        const response = await fetch('/.netlify/functions/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bookingId: bookingId || location.state?.bookingId,
            serviceType,
            price,
            customerEmail: patientEmail.trim(),
            customerName: patientName,
            customerPhone: patientPhone,
            customerAddress: patientAddress,
            // Also include full patient details as a fallback
            patientDetails: {
              email: patientEmail.trim(),
              name: patientName,
              phone: patientPhone,
              address: patientAddress
            }
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response from server:', errorText);
          try {
            const errorData = JSON.parse(errorText);
            throw new Error(errorData.error || 'Failed to create checkout session');
          } catch (parseError) {
            throw new Error(`Failed to create checkout session: ${errorText}`);
          }
        }

        const session = await response.json();
        if (session.error) {
          throw new Error(session.error);
        }

        console.log('Checkout session created successfully:', session.id);

        // Redirect to Stripe checkout
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        console.error('Payment initialization error:', error);
        setError(error.message || 'Failed to initialize payment');
      } finally {
        setLoading(false);
      }
    };

    initializeCheckout();
  }, [location.state, bookingId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8]">Initializing payment...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="max-w-md w-full mx-4">
          <div className="bg-red-900/20 border border-red-500/20 rounded-lg p-6 text-center">
            <p className="text-red-400 mb-4">{error}</p>
            
            {isTestMode && (
              <div className="mt-4 p-4 bg-blue-900/20 border border-blue-500/20 rounded-lg text-left mb-4">
                <h3 className="text-blue-400 font-medium mb-2">Stripe Test Mode Information</h3>
                <p className="text-blue-300 text-sm mb-2">
                  The payment system is currently in TEST mode. To test payments, use these test card numbers:
                </p>
                <ul className="text-blue-300 text-sm list-disc pl-5 space-y-1">
                  <li>Successful payment: 4242 4242 4242 4242</li>
                  <li>Requires authentication: 4000 0025 0000 3155</li>
                  <li>Payment declined: 4000 0000 0000 9995</li>
                </ul>
                <p className="text-blue-300 text-sm mt-2">
                  Use any future expiration date, any 3-digit CVC, and any postal code.
                </p>
              </div>
            )}
            
            <button
              onClick={() => navigate('/dynamic-booking')}
              className="px-6 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg hover:bg-[#DDC6A8]/90"
            >
              Return to Booking
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
      <div className="max-w-md w-full mx-4">
        <div className="bg-[#2A2A2A] rounded-lg p-6 text-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8] mb-4">Redirecting to payment...</p>
          
          {isTestMode && (
            <div className="mt-4 p-4 bg-blue-900/20 border border-blue-500/20 rounded-lg text-left">
              <h3 className="text-blue-400 font-medium mb-2">Stripe Test Mode Information</h3>
              <p className="text-blue-300 text-sm mb-2">
                The payment system is currently in TEST mode. To test payments, use these test card numbers:
              </p>
              <ul className="text-blue-300 text-sm list-disc pl-5 space-y-1">
                <li>Successful payment: 4242 4242 4242 4242</li>
                <li>Requires authentication: 4000 0025 0000 3155</li>
                <li>Payment declined: 4000 0000 0000 9995</li>
              </ul>
              <p className="text-blue-300 text-sm mt-2">
                Use any future expiration date, any 3-digit CVC, and any postal code.
              </p>
            </div>
          )}
          
          <button
            onClick={() => navigate('/dynamic-booking')}
            className="mt-4 px-6 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg hover:bg-[#DDC6A8]/90"
          >
            Cancel and Return to Booking
          </button>
        </div>
      </div>
    </div>
  );
} 