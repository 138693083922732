import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-[#1A1A1A] border-t border-[#DDC6A8]/10 relative z-10">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Column 1: About */}
          <div>
            <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">About Dr. Terry Nguyen</h3>
            <p className="text-[#DDC6A8]/70 text-sm mb-4">
              Dr. Terry Nguyen is an experienced General Practitioner offering comprehensive medical services in-person and via telemedicine across Australia.
            </p>
            <div className="text-[#DDC6A8]/70 text-sm">
              <p>Provider No: 4932259B</p>
              <p>AHPRA: MED0001940265</p>
            </div>
          </div>
          
          {/* Column 2: Services */}
          <div>
            <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">Our Services</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/telemedicine" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Telemedicine Consultations
                </Link>
              </li>
              <li>
                <Link to="/private" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Private Medical Services
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Family Medicine
                </Link>
              </li>
              <li>
                <Link to="/mens-health" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Men's Health
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Women's Health
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Pediatric Care
                </Link>
              </li>
              <li>
                <Link to="/resources" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Chronic Disease Management
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Column 3: Useful Links */}
          <div>
            <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">Useful Links</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/dynamic-booking" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Book Appointment
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Medical Blog
                </Link>
              </li>
              <li>
                <a href="https://www.health.gov.au/" target="_blank" rel="noopener noreferrer" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Australian Department of Health
                </a>
              </li>
              <li>
                <a href="https://www.servicesaustralia.gov.au/medicare" target="_blank" rel="noopener noreferrer" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Medicare
                </a>
              </li>
              <li>
                <a href="https://www.healthdirect.gov.au/" target="_blank" rel="noopener noreferrer" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Health Direct
                </a>
              </li>
              <li>
                <Link to="/books" className="text-[#DDC6A8]/70 hover:text-[#DDC6A8] transition-colors">
                  Books
                </Link>
              </li>
            </ul>
          </div>
          
          {/* Column 4: Book Now */}
          <div className="flex flex-col items-center md:items-start justify-center">
            <h3 className="text-lg font-medium text-[#DDC6A8] mb-4">Book Now</h3>
            <Link 
              to="/dynamic-booking" 
              className="inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group text-sm"
            >
              Book Consultation
              <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </Link>
          </div>
        </div>
        
        {/* Bottom Section */}
        <div className="mt-10 pt-8 border-t border-[#DDC6A8]/10 md:flex md:items-center md:justify-between">
          <div className="text-[#DDC6A8]/60 text-sm">
            &copy; {currentYear} Dr. Terry Nguyen. All rights reserved.
            <div className="mt-2 flex items-center">
              <span className="mr-2">As Featured in:</span>
              <a 
                href="https://www.prlog.org/13069864-tired-of-waiting-rooms-new-homedoc-service-offers-australians-their-own-private-doctor.html" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="inline-flex items-center px-2 py-1 bg-[#DDC6A8]/10 rounded text-[#DDC6A8] hover:bg-[#DDC6A8]/20 transition-colors"
              >
                PRLog
              </a>
            </div>
          </div>
          <div className="mt-4 md:mt-0">
            <ul className="flex space-x-6">
              <li>
                <Link to="/privacy-policy" className="text-[#DDC6A8]/60 hover:text-[#DDC6A8] text-sm">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="text-[#DDC6A8]/60 hover:text-[#DDC6A8] text-sm">
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/sitemap" className="text-[#DDC6A8]/60 hover:text-[#DDC6A8] text-sm">
                  Sitemap
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 