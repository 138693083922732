import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BookingButtonContainer = styled.div`
  margin-top: 20px;
`;

const BookingLink = styled(Link)`
  display: inline-block;
  background-color: #43a047;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  font-size: 1.1rem;

  &:hover {
    background-color: #2e7d32;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const BookingButton = ({ text = "Book an Appointment" }) => {
  return (
    <BookingButtonContainer>
      <BookingLink to="/dynamic-booking">
        {text}
      </BookingLink>
    </BookingButtonContainer>
  );
};

export default BookingButton; 