import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../config/firebase';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  orderBy,
  limit,
  onSnapshot,
  doc,
  updateDoc,
  addDoc,
  Timestamp 
} from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { Link } from 'react-router-dom';

// File Components
const FileCard = ({ file }) => {
  const formattedDate = new Date(file.uploadDate?.seconds * 1000).toLocaleDateString();

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-duration-200">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-blue-50 rounded-lg">
            <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
          </div>
          <div>
            <h4 className="text-sm font-medium text-gray-900">{file.fileName}</h4>
            <p className="text-xs text-gray-500">{formattedDate}</p>
          </div>
        </div>
        <a
          href={file.downloadURL}
          download
          className="text-blue-600 hover:text-blue-800 text-sm font-medium"
        >
          Download
        </a>
      </div>
      {file.metadata?.description && (
        <p className="mt-2 text-sm text-gray-600">{file.metadata.description}</p>
      )}
    </div>
  );
};

const FileSection = ({ title, files, category }) => {
  const filteredFiles = category 
    ? files.filter(file => file.category === category)
    : files;

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        {filteredFiles.length > 0 && (
          <button className="text-sm text-blue-600 hover:text-blue-800">
            View all
          </button>
        )}
      </div>
      {filteredFiles.length > 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {filteredFiles.slice(0, 3).map((file) => (
            <FileCard key={file.id} file={file} />
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No files available</p>
      )}
    </div>
  );
};

export default function Dashboard() {
  const { currentUser } = useAuth();
  const [appointments, setAppointments] = useState([]);
  const [files, setFiles] = useState([]);
  const [patientInfo, setPatientInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedPatientInfo, setEditedPatientInfo] = useState(null);
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [newPatient, setNewPatient] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    address: '',
    medicare: '',
    relationship: ''
  });
  const [dependentPatients, setDependentPatients] = useState([]);

  useEffect(() => {
    if (!currentUser) {
      setLoading(false);
      return;
    }

    let unsubscribeFiles;
    let unsubscribeAppointments;

    // Fetch appointments with real-time updates
    async function subscribeToAppointments() {
      try {
        const appointmentsRef = collection(db, 'appointments');
        const q = query(
          appointmentsRef,
          where('userId', '==', currentUser.uid),
          orderBy('date', 'desc'),
          limit(5)
        );
        
        unsubscribeAppointments = onSnapshot(q, (snapshot) => {
          const appointmentList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setAppointments(appointmentList);
        });
      } catch (error) {
        console.error('Error fetching appointments:', error);
        setError('Error loading appointments. Please try again later.');
      }
    }

    // Fetch patient info and dependent patients
    async function fetchPatientInfo() {
      try {
        console.log('Fetching patient info for user:', currentUser?.email);
        const patientRef = collection(db, 'patients');
        const mainPatientQuery = query(
          patientRef, 
          where('email', '==', currentUser?.email)
        );
        const dependentQuery = query(
          patientRef, 
          where('primaryCaregiverId', '==', currentUser?.uid)
        );
        
        console.log('Executing patient queries...');
        const [mainPatientSnapshot, dependentSnapshot] = await Promise.all([
          getDocs(mainPatientQuery),
          getDocs(dependentQuery)
        ]);

        console.log('Main patient snapshot:', mainPatientSnapshot.docs.length, 'documents');
        if (!mainPatientSnapshot.empty) {
          const patientData = { 
            id: mainPatientSnapshot.docs[0].id, 
            ...mainPatientSnapshot.docs[0].data() 
          };
          console.log('Found patient data:', patientData);
          setPatientInfo(patientData);
          setEditedPatientInfo(patientData);
        } else {
          console.log('No main patient record found');
        }

        console.log('Dependent snapshot:', dependentSnapshot.docs.length, 'documents');
        const dependentList = dependentSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Dependent patients:', dependentList);
        setDependentPatients(dependentList);
      } catch (error) {
        console.error('Error fetching patient info:', error);
        console.error('Error details:', {
          code: error.code,
          message: error.message,
          stack: error.stack
        });
        setError('Error loading patient information. Please try again later.');
      }
    }

    // Subscribe to files updates
    async function subscribeToFiles() {
      try {
        const filesRef = collection(db, `users/${currentUser.uid}/files`);
        const filesQuery = query(filesRef, orderBy('uploadDate', 'desc'));
        
        unsubscribeFiles = onSnapshot(filesQuery, (snapshot) => {
          const fileList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setFiles(fileList);
          setLoading(false);
        });
      } catch (error) {
        console.error('Error fetching files:', error);
        setError('Error loading files. Please try again later.');
        setLoading(false);
      }
    }

    // Initialize all data fetching
    Promise.all([
      subscribeToAppointments(),
      fetchPatientInfo(),
      subscribeToFiles()
    ]).catch(error => {
      console.error('Error initializing dashboard:', error);
      setError('Error loading dashboard. Please try again later.');
      setLoading(false);
    });

    // Cleanup subscriptions
    return () => {
      if (unsubscribeFiles) unsubscribeFiles();
      if (unsubscribeAppointments) unsubscribeAppointments();
    };
  }, [currentUser]);

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const patientRef = doc(db, 'patients', patientInfo.id);
      await updateDoc(patientRef, {
        ...editedPatientInfo,
        updatedAt: Timestamp.now()
      });
      setPatientInfo(editedPatientInfo);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating patient info:', error);
      setError('Failed to update patient information. Please try again.');
    }
  };

  const handleAddPatient = async (e) => {
    e.preventDefault();
    try {
      const patientsRef = collection(db, 'patients');
      await addDoc(patientsRef, {
        ...newPatient,
        primaryCaregiverId: currentUser.uid,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now()
      });
      setShowAddPatient(false);
      setNewPatient({
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        phone: '',
        address: '',
        medicare: '',
        relationship: ''
      });
      // Refresh dependent patients list
      const dependentQuery = query(patientsRef, where('primaryCaregiverId', '==', currentUser.uid));
      const snapshot = await getDocs(dependentQuery);
      const dependentList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setDependentPatients(dependentList);
    } catch (error) {
      console.error('Error adding patient:', error);
      setError('Failed to add patient. Please try again.');
    }
  };

  if (!currentUser) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-lg text-gray-600">Please sign in to view your dashboard.</p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Welcome Section */}
      <div className="bg-white shadow rounded-lg p-6 mb-8">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">
              Welcome back, {currentUser?.displayName || patientInfo?.firstName}
            </h2>
            <p className="mt-1 text-sm text-gray-500">{currentUser?.email}</p>
          </div>
          <button
            onClick={() => setIsEditing(!isEditing)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            {isEditing ? 'Cancel Edit' : 'Edit Profile'}
          </button>
        </div>

        {isEditing ? (
          <form onSubmit={handleEditSubmit} className="mt-4 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  value={editedPatientInfo?.firstName || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, firstName: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  value={editedPatientInfo?.lastName || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, lastName: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                <input
                  type="date"
                  value={editedPatientInfo?.dateOfBirth || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, dateOfBirth: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  value={editedPatientInfo?.phone || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, phone: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  value={editedPatientInfo?.address || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, address: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Medicare Number</label>
                <input
                  type="text"
                  value={editedPatientInfo?.medicare || ''}
                  onChange={(e) => setEditedPatientInfo(prev => ({...prev, medicare: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Save Changes
              </button>
            </div>
          </form>
        ) : (
          patientInfo && (
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-gray-500">Date of Birth</p>
                <p className="text-sm text-gray-900">{new Date(patientInfo.dateOfBirth).toLocaleDateString()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Phone</p>
                <p className="text-sm text-gray-900">{patientInfo.phone}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Address</p>
                <p className="text-sm text-gray-900">{patientInfo.address}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Medicare Number</p>
                <p className="text-sm text-gray-900">{patientInfo.medicare || 'Not provided'}</p>
              </div>
            </div>
          )
        )}
      </div>

      {/* Dependent Patients Section */}
      <div className="bg-white shadow rounded-lg p-6 mb-8">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-gray-900">Dependent Patients</h3>
          <button
            onClick={() => setShowAddPatient(!showAddPatient)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            {showAddPatient ? 'Cancel' : 'Add Patient'}
          </button>
        </div>

        {showAddPatient && (
          <form onSubmit={handleAddPatient} className="mb-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  required
                  value={newPatient.firstName}
                  onChange={(e) => setNewPatient(prev => ({...prev, firstName: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  required
                  value={newPatient.lastName}
                  onChange={(e) => setNewPatient(prev => ({...prev, lastName: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                <input
                  type="date"
                  required
                  value={newPatient.dateOfBirth}
                  onChange={(e) => setNewPatient(prev => ({...prev, dateOfBirth: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Relationship</label>
                <input
                  type="text"
                  required
                  value={newPatient.relationship}
                  onChange={(e) => setNewPatient(prev => ({...prev, relationship: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g., Child, Spouse, Parent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  value={newPatient.phone}
                  onChange={(e) => setNewPatient(prev => ({...prev, phone: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Medicare Number</label>
                <input
                  type="text"
                  value={newPatient.medicare}
                  onChange={(e) => setNewPatient(prev => ({...prev, medicare: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700">Address</label>
                <input
                  type="text"
                  required
                  value={newPatient.address}
                  onChange={(e) => setNewPatient(prev => ({...prev, address: e.target.value}))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Add Patient
              </button>
            </div>
          </form>
        )}

        {dependentPatients.length > 0 ? (
          <div className="space-y-4">
            {dependentPatients.map((patient) => (
              <div key={patient.id} className="border rounded-lg p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="text-lg font-medium text-gray-900">
                      {patient.firstName} {patient.lastName}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {patient.relationship} • DOB: {new Date(patient.dateOfBirth).toLocaleDateString()}
                    </p>
                    <p className="text-sm text-gray-500">Medicare: {patient.medicare || 'Not provided'}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No dependent patients added yet.</p>
        )}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Column - Appointments and Quick Actions */}
        <div className="lg:col-span-1 space-y-8">
          {/* Upcoming Appointments */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Upcoming Appointments</h3>
            {appointments.length > 0 ? (
              <div className="space-y-4">
                {appointments.map((appointment) => (
                  <div
                    key={appointment.id}
                    className="border border-gray-200 rounded-lg p-4"
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {new Date(appointment.date.seconds * 1000).toLocaleDateString()}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(appointment.date.seconds * 1000).toLocaleTimeString()}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          {appointment.type}
                        </p>
                      </div>
                      <span
                        className={`px-2 py-1 text-xs font-medium rounded-full ${
                          appointment.status === 'confirmed'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {appointment.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500">No upcoming appointments</p>
            )}
          </div>

          {/* Quick Actions */}
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Quick Actions</h3>
            <div className="space-y-3">
              <button className="w-full text-left px-4 py-2 bg-gray-50 hover:bg-gray-100 rounded-lg text-sm font-medium text-gray-900">
                Request Prescription Renewal
              </button>
              <button className="w-full text-left px-4 py-2 bg-gray-50 hover:bg-gray-100 rounded-lg text-sm font-medium text-gray-900">
                Message Your Doctor
              </button>
              <button className="w-full text-left px-4 py-2 bg-gray-50 hover:bg-gray-100 rounded-lg text-sm font-medium text-gray-900">
                View Test Results
              </button>
            </div>
          </div>
        </div>

        {/* Right Column - Medical Files and Documents */}
        <div className="lg:col-span-2 space-y-8">
          {/* Recent Files */}
          <FileSection 
            title="Recent Files" 
            files={files.slice(0, 3)} 
          />

          {/* Lab Results */}
          <FileSection 
            title="Lab Results" 
            files={files} 
            category="lab_result" 
          />

          {/* Prescriptions */}
          <FileSection 
            title="Prescriptions" 
            files={files} 
            category="prescription" 
          />
        </div>
      </div>

      {/* Admin Tools Section */}
      <div className="bg-[#2A2A2A] rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold text-[#DDC6A8] mb-4">Admin Tools</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Link
            to="/admin/dashboard"
            className="flex items-center p-4 bg-[#1A1A1A] rounded-lg hover:bg-[#1A1A1A]/70 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-[#DDC6A8]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
            </svg>
            <div>
              <h3 className="font-medium text-[#DDC6A8]">Booking Dashboard</h3>
              <p className="text-sm text-[#DDC6A8]/70">Manage bookings and view analytics</p>
            </div>
          </Link>
          
          <Link
            to="/admin/google-sheets-setup"
            className="flex items-center p-4 bg-[#1A1A1A] rounded-lg hover:bg-[#1A1A1A]/70 transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 text-[#DDC6A8]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <div>
              <h3 className="font-medium text-[#DDC6A8]">Google Sheets Integration</h3>
              <p className="text-sm text-[#DDC6A8]/70">Configure Google Sheets for booking data</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
} 