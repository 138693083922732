import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  deleteDoc, 
  updateDoc, 
  serverTimestamp, 
  query, 
  orderBy 
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import generateBlogContent, { 
  BLOG_CATEGORIES, 
  TRENDING_TOPICS, 
  generateBlogIdeas 
} from '../utils/blogGenerator';
import SEO from '../components/SEO';
import AutoBlogGenerator from '../components/AutoBlogGenerator';

export default function BlogAdmin() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    excerpt: '',
    content: '',
    imageUrl: '',
    category: '',
    tags: [],
    slug: '',
    author: 'Dr. Terry Nguyen',
    authorImage: '/src/assets/images/profile/dr_terry_speaking.png',
    readTime: 5,
    newTag: ''
  });
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [blogIdeas, setBlogIdeas] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('standard');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [editorMode, setEditorMode] = useState('create');
  const [currentPostId, setCurrentPostId] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Fetch blog posts on component mount
  useEffect(() => {
    fetchPosts();
    generateInitialBlogIdeas();
  }, []);

  // Ensure user is authenticated
  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  // Generate initial blog ideas
  const generateInitialBlogIdeas = () => {
    const ideas = generateBlogIdeas(null, 5);
    setBlogIdeas(ideas);
  };

  // Fetch blog posts from Firestore
  const fetchPosts = async () => {
    try {
      setLoading(true);
      const postsRef = collection(db, 'blog_posts');
      const q = query(postsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      const postList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postList);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle topic selection
  const handleSelectTopic = (topic) => {
    setSelectedTopic(topic);
    const content = generateBlogContent(topic, selectedTemplate);
    
    setFormData({
      ...formData,
      title: content.title,
      excerpt: content.excerpt,
      content: `# ${content.title}\n\n${content.excerpt}\n\n` + 
        content.structure.sections.map(section => 
          `${'#'.repeat(section.headingLevel)} ${section.heading}\n\n[Write ${section.wordCount} words about ${section.name}]\n\n`
        ).join(''),
      category: content.category,
      slug: content.slug,
      tags: topic.keywords || [],
      readTime: content.readTime
    });
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Auto-generate slug when title changes
    if (name === 'title') {
      setFormData({
        ...formData,
        title: value,
        slug: value.toLowerCase().replace(/[^\w\s]/gi, '').replace(/\s+/g, '-')
      });
    }
  };

  // Handle category change
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    const ideas = category ? generateBlogIdeas(category, 5) : generateBlogIdeas(null, 5);
    setBlogIdeas(ideas);
    
    setFormData({
      ...formData,
      category
    });
  };

  // Handle adding a new tag
  const handleAddTag = () => {
    if (formData.newTag.trim() && !formData.tags.includes(formData.newTag.trim())) {
      setFormData({
        ...formData,
        tags: [...formData.tags, formData.newTag.trim()],
        newTag: ''
      });
    }
  };

  // Handle removing a tag
  const handleRemoveTag = (tagToRemove) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter(tag => tag !== tagToRemove)
    });
  };

  // Handle form submission to create/update a blog post
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const postData = {
        title: formData.title,
        excerpt: formData.excerpt,
        content: formData.content,
        imageUrl: formData.imageUrl,
        category: formData.category,
        tags: formData.tags,
        slug: formData.slug,
        author: formData.author,
        authorImage: formData.authorImage,
        readTime: parseInt(formData.readTime),
        updatedAt: serverTimestamp()
      };
      
      if (editorMode === 'create') {
        postData.createdAt = serverTimestamp();
        await addDoc(collection(db, 'blog_posts'), postData);
      } else {
        await updateDoc(doc(db, 'blog_posts', currentPostId), postData);
      }
      
      // Reset form and fetch updated posts
      setFormData({
        title: '',
        excerpt: '',
        content: '',
        imageUrl: '',
        category: '',
        tags: [],
        slug: '',
        author: 'Dr. Terry Nguyen',
        authorImage: '/src/assets/images/profile/dr_terry_speaking.png',
        readTime: 5,
        newTag: ''
      });
      setEditorMode('create');
      setCurrentPostId(null);
      setCreating(false);
      await fetchPosts();
      
    } catch (error) {
      console.error('Error saving blog post:', error);
    }
  };

  // Handle editing a post
  const handleEditPost = (post) => {
    setFormData({
      ...formData,
      title: post.title,
      excerpt: post.excerpt,
      content: post.content,
      imageUrl: post.imageUrl,
      category: post.category,
      tags: post.tags || [],
      slug: post.slug,
      author: post.author,
      authorImage: post.authorImage,
      readTime: post.readTime,
      newTag: ''
    });
    setEditorMode('edit');
    setCurrentPostId(post.id);
    setCreating(true);
  };

  // Handle deleting a post
  const handleDeletePost = async (postId) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      try {
        await deleteDoc(doc(db, 'blog_posts', postId));
        await fetchPosts();
      } catch (error) {
        console.error('Error deleting post:', error);
      }
    }
  };

  // Generate more blog ideas
  const handleGenerateMoreIdeas = () => {
    const ideas = selectedCategory 
      ? generateBlogIdeas(selectedCategory, 5) 
      : generateBlogIdeas(null, 5);
    setBlogIdeas(ideas);
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <SEO
          title="Blog Administration - Dr. Terry Nguyen"
          description="Admin panel for managing blog content"
          noindex={true}
        />
        <h1 className="text-3xl font-light tracking-tight text-[#DDC6A8] mb-8">
          Blog Management
        </h1>
        
        <div className="flex justify-between items-center mb-8">
          <button
            onClick={() => setCreating(!creating)}
            className="px-4 py-2 border border-[#DDC6A8] rounded-md hover:bg-[#DDC6A8]/10 focus:outline-none focus:ring-2 focus:ring-[#DDC6A8]"
          >
            {creating ? 'View Posts' : 'Create Post'}
          </button>
        </div>

        {creating ? (
          <div className="bg-[#2A2A2A] p-6 rounded-lg shadow-md mb-10">
            <h2 className="text-xl font-light mb-6">
              {editorMode === 'create' ? 'Create New Blog Post' : 'Edit Blog Post'}
            </h2>
            
            {editorMode === 'create' && (
              <div className="mb-8">
                <div className="flex flex-wrap items-center gap-4 mb-6">
                  <div className="w-full md:w-auto">
                    <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                      Blog Template
                    </label>
                    <select
                      value={selectedTemplate}
                      onChange={(e) => setSelectedTemplate(e.target.value)}
                      className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                    >
                      <option value="standard">Standard (1000 words)</option>
                      <option value="comprehensive">Comprehensive (3000 words)</option>
                      <option value="quickGuide">Quick Guide (800 words)</option>
                    </select>
                  </div>
                  
                  <div className="w-full md:w-auto">
                    <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                      Filter by Category
                    </label>
                    <select
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                    >
                      <option value="">All Categories</option>
                      {BLOG_CATEGORIES.map((category) => (
                        <option key={category.slug} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  
                  <button
                    onClick={handleGenerateMoreIdeas}
                    className="bg-[#DDC6A8]/10 hover:bg-[#DDC6A8]/20 text-[#DDC6A8] px-4 py-2 rounded-md text-sm mt-6 md:mt-0"
                  >
                    Generate More Ideas
                  </button>
                </div>
                
                <div className="grid grid-cols-1 gap-4 mb-6">
                  <h3 className="text-lg font-medium text-[#DDC6A8]">
                    SEO-Optimized Topic Suggestions
                  </h3>
                  <div className="space-y-4">
                    {blogIdeas.map((idea, index) => (
                      <div
                        key={index}
                        className={`p-4 rounded-md border cursor-pointer transition-all ${
                          selectedTopic === idea
                            ? 'border-[#DDC6A8] bg-[#DDC6A8]/10'
                            : 'border-[#DDC6A8]/30 hover:border-[#DDC6A8]/50'
                        }`}
                        onClick={() => handleSelectTopic(idea)}
                      >
                        <h4 className="font-medium text-[#DDC6A8]">
                          {idea.title}
                        </h4>
                        <div className="flex flex-wrap gap-2 mt-2">
                          <span className="text-xs px-2 py-1 bg-[#DDC6A8]/20 rounded-full">
                            {idea.category}
                          </span>
                          {idea.keywords && idea.keywords.map((keyword, idx) => (
                            <span
                              key={idx}
                              className="text-xs px-2 py-1 bg-[#1A1A1A] rounded-full"
                            >
                              {keyword}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Slug (URL)
                  </label>
                  <input
                    type="text"
                    name="slug"
                    value={formData.slug}
                    onChange={handleChange}
                    required
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Excerpt
                  </label>
                  <textarea
                    name="excerpt"
                    value={formData.excerpt}
                    onChange={handleChange}
                    required
                    rows={3}
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Content (Markdown supported)
                  </label>
                  <textarea
                    name="content"
                    value={formData.content}
                    onChange={handleChange}
                    required
                    rows={15}
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full font-mono text-sm"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Featured Image URL
                  </label>
                  <input
                    type="text"
                    name="imageUrl"
                    value={formData.imageUrl}
                    onChange={handleChange}
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Category
                  </label>
                  <select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    required
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-full"
                  >
                    <option value="">Select a category</option>
                    {BLOG_CATEGORIES.map((category) => (
                      <option key={category.slug} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Tags
                  </label>
                  <div className="flex flex-wrap gap-2 mb-2">
                    {formData.tags.map((tag, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-3 py-1 rounded-full text-sm border border-[#DDC6A8]/30 bg-[#DDC6A8]/10"
                      >
                        {tag}
                        <button
                          type="button"
                          onClick={() => handleRemoveTag(tag)}
                          className="ml-2 text-[#DDC6A8]/70 hover:text-[#DDC6A8]"
                        >
                          &times;
                        </button>
                      </span>
                    ))}
                  </div>
                  <div className="flex">
                    <input
                      type="text"
                      name="newTag"
                      value={formData.newTag}
                      onChange={handleChange}
                      placeholder="Add a tag"
                      className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-l-md px-3 py-2 flex-1"
                    />
                    <button
                      type="button"
                      onClick={handleAddTag}
                      className="bg-[#DDC6A8]/20 hover:bg-[#DDC6A8]/30 text-[#DDC6A8] px-4 py-2 rounded-r-md"
                    >
                      Add
                    </button>
                  </div>
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-[#DDC6A8]/70 mb-1">
                    Read Time (minutes)
                  </label>
                  <input
                    type="number"
                    name="readTime"
                    value={formData.readTime}
                    onChange={handleChange}
                    required
                    min="1"
                    className="bg-[#1A1A1A] text-[#DDC6A8] border border-[#DDC6A8]/30 rounded-md px-3 py-2 w-32"
                  />
                </div>
                
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => {
                      setCreating(false);
                      setEditorMode('create');
                      setCurrentPostId(null);
                    }}
                    className="px-4 py-2 border border-[#DDC6A8]/30 rounded-md text-[#DDC6A8]/70 hover:text-[#DDC6A8] hover:border-[#DDC6A8]/50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-md hover:bg-[#DDC6A8]/90"
                  >
                    {editorMode === 'create' ? 'Create Post' : 'Update Post'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="mb-10">
            <div className="bg-[#2A2A2A] rounded-lg shadow-md overflow-hidden">
              <div className="px-6 py-4 border-b border-[#DDC6A8]/10">
                <h2 className="text-xl font-light text-[#DDC6A8]">
                  Published Posts
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-[#DDC6A8]/10">
                  <thead className="bg-[#1A1A1A]">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                        Title
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                        Category
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-[#DDC6A8]/10">
                    {loading ? (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-center text-[#DDC6A8]/70">
                          Loading posts...
                        </td>
                      </tr>
                    ) : posts.length > 0 ? (
                      posts.map((post) => (
                        <tr key={post.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#DDC6A8]">
                            {post.title}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#DDC6A8]/70">
                            {post.category}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-[#DDC6A8]/70">
                            {post.createdAt?.toDate?.().toLocaleDateString() || 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => handleEditPost(post)}
                              className="text-[#DDC6A8]/80 hover:text-[#DDC6A8] mr-4"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDeletePost(post.id)}
                              className="text-red-400 hover:text-red-300"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="px-6 py-4 text-center text-[#DDC6A8]/70">
                          No blog posts found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        
        <div className="mt-12">
          <h2 className="text-xl font-light text-[#DDC6A8] mb-4">Automated Blog Generator</h2>
          <AutoBlogGenerator />
        </div>
      </div>
    </div>
  );
} 