import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogCarousel from '../components/BlogCarousel';

const Resources = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Health Resources & Medical Information | Sydney Doctor | HomeDoc</title>
        <meta name="description" content="Expert health resources and medical information from Sydney's leading house call doctor. Evidence-based guides, educational materials, and recommended books." />
        <meta name="keywords" content="medical resources sydney, health information eastern suburbs, trusted medical guides, recommended health books, evidence-based health resources, preventative health education" />
        <link rel="canonical" href="https://homedoc.com.au/resources" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalWebPage",
            "about": {
              "@type": "MedicalCondition",
              "name": "Health Education"
            },
            "audience": "Healthcare consumers and patients",
            "mainEntity": {
              "@type": "ItemList",
              "name": "Health Resources Collection",
              "description": "Curated health information and resources for Sydney residents"
            }
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">Health Resources</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8]/80 mb-6">Evidence-Based Medical Information for Sydney Residents</h2>
            <p className="text-xl text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Trusted health resources, educational materials, and recommended reading from Dr. Terry Nguyen.
              <span className="block mt-4">
                <Link to="/dynamic-booking" className="text-[#DDC6A8] underline font-medium hover:text-white">
                  Book a consultation
                </Link> to discuss personalized health recommendations.
              </span>
            </p>
          </div>

          {/* Main content placeholder */}
          <div className="text-center mb-16">
            <p className="text-[#DDC6A8]/80">
              Full content for Resources page will be implemented in subsequent edits.
            </p>
          </div>
          
          {/* Call to Action */}
          <div className="text-center">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
            >
              Book a Consultation
            </Link>
          </div>
        </div>
      </div>
      
      {/* Related Articles Carousel */}
      <div className="py-12 bg-[#1A1A1A]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-light text-[#DDC6A8] mb-8 text-center">Featured Health Articles</h2>
          <BlogCarousel />
        </div>
      </div>
    </div>
  );
};

export default Resources; 