import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const RSVInfection = () => {
  // SEO metadata
  const seoTitle = "RSV Infection in Children: Symptoms, Treatment & When to Seek Care | HomeDoc";
  const seoDescription = "Learn about RSV infection symptoms in children, home care tips, treatment options, and when to call a doctor for respiratory syncytial virus in Sydney.";
  const seoKeywords = "RSV in children, respiratory syncytial virus Sydney, RSV symptoms, bronchiolitis treatment, RSV home care, when to call doctor for RSV, house call doctor Eastern Suburbs, pediatric RSV";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">RSV Infection in Children: Symptoms, Treatment & When to Seek Care</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Respiratory Syncytial Virus (RSV) is one of the most common respiratory infections affecting children in Australia, particularly those under 2 years of age. As a <strong>house call doctor serving Sydney's Eastern Suburbs</strong>, I frequently diagnose and treat RSV infections throughout Bondi, Randwick, Double Bay, and surrounding areas, especially during the peak season from late autumn through winter.
            </p>
            <p className="text-gray-700 mb-4">
              While RSV often causes mild cold-like symptoms in healthy adults and older children, it can lead to more serious respiratory conditions like bronchiolitis and pneumonia in infants, young children, and those with compromised immune systems. Understanding RSV, recognizing its symptoms, and knowing when to seek medical care can help parents manage this common but potentially serious infection.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/kidsrsv.png" 
                alt="Child with RSV infection being examined by doctor"
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Early diagnosis of RSV helps ensure proper treatment and care
              </figcaption>
            </figure>
          </div>
        </div>
        <p className="text-gray-700 mb-6">
          This comprehensive guide covers RSV symptoms, treatment options, home care strategies, and clear guidance on when to contact a <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">house call doctor</Link> or seek emergency care for your child. With appropriate care and monitoring, most children recover well from RSV infections.
        </p>
      </section>
      
      <section id="what-is-rsv" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">What is RSV (Respiratory Syncytial Virus)?</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Respiratory Syncytial Virus (RSV) is a highly contagious virus that affects the respiratory tract, particularly the small airways (bronchioles) and lungs. In Australia, RSV infections typically peak during winter and early spring months (May to September), with Sydney's Eastern Suburbs often seeing cases as early as April due to our coastal climate.
            </p>
            <p className="text-gray-700 mb-4">
              <strong>Key facts about RSV in Sydney's Eastern Suburbs:</strong>
            </p>
            <ul className="list-disc pl-6 mb-4 text-gray-700 space-y-2">
              <li>Almost all children will have been infected with RSV by their second birthday</li>
              <li>Sydney Children's Hospital Network reports that RSV is the most common cause of bronchiolitis and pneumonia in children under 12 months</li>
              <li>Eastern Suburbs daycare centers and schools often see localized outbreaks during winter months</li>
              <li>In 2023, NSW Health reported earlier and more severe RSV seasons than in previous years</li>
              <li>The virus spreads through respiratory droplets when an infected person coughs or sneezes, or through direct contact with contaminated surfaces</li>
              <li>The virus can survive on hard surfaces for several hours and on soft surfaces like tissues for shorter periods</li>
            </ul>
            <p className="text-gray-700 mb-4">
              RSV infections tend to be more common and more severe in:
            </p>
            <ul className="list-disc pl-6 mb-6 text-gray-700 space-y-2">
              <li>Premature infants (born before 37 weeks gestation)</li>
              <li>Children under 2 years with chronic lung or heart conditions</li>
              <li>Children with weakened immune systems</li>
              <li>Infants under 6 months of age</li>
              <li>Children who attend childcare centers in Sydney's Eastern Suburbs</li>
              <li>Children exposed to second-hand smoke</li>
              <li>Older adults, especially those with chronic medical conditions</li>
            </ul>
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <p className="text-gray-700 italic">
                <strong>Sydney Eastern Suburbs Context:</strong> As a house call doctor serving Bondi, Randwick, Coogee, Double Bay, and surrounding suburbs, I've observed that RSV cases often emerge earlier in coastal suburbs compared to western Sydney, possibly due to year-round tourism and higher population density in Eastern Suburbs childcare facilities.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="rsv-symptoms" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">RSV Symptoms in Children by Age Group</h2>
        <p className="text-gray-700 mb-4">
          RSV symptoms can vary significantly based on a child's age and overall health. As a house call doctor in Sydney's Eastern Suburbs, I tailor assessment and treatment recommendations based on these age-specific presentations:
        </p>
        
        <div className="grid md:grid-cols-2 gap-6 mb-6">
          <div className="bg-indigo-50 p-5 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Symptoms in Newborns & Young Infants (0-6 months)</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Irritability and fussiness</strong> - often more pronounced than older children</li>
              <li><strong>Poor feeding</strong> - refusing feeds or tiring quickly during feeds</li>
              <li><strong>Lethargy or decreased activity</strong> - appearing unusually tired or listless</li>
              <li><strong>Apnea</strong> - brief pauses in breathing (a serious warning sign)</li>
              <li><strong>Reduced wet nappies</strong> - fewer than 4-6 wet nappies in 24 hours</li>
              <li><strong>Subtle respiratory distress</strong> - flaring nostrils, grunting, or chest retractions</li>
              <li><strong>Low-grade or absent fever</strong> - unlike older children who typically have higher fevers</li>
            </ul>
            <p className="mt-3 text-sm italic">Note: The Royal Children's Hospital Melbourne notes that very young infants may not present with typical cough and congestion but show more subtle signs of respiratory distress.</p>
          </div>
          
          <div className="bg-indigo-50 p-5 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Symptoms in Older Infants (6-12 months)</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Fever</strong> - typically higher than in younger infants</li>
              <li><strong>Wheezing</strong> - a high-pitched whistling sound when breathing out</li>
              <li><strong>Labored breathing</strong> - visible chest retractions between or below ribs</li>
              <li><strong>Persistent cough</strong> - often worse at night</li>
              <li><strong>Nasal congestion and discharge</strong></li>
              <li><strong>Decreased appetite</strong></li>
              <li><strong>Increased irritability</strong>, especially during coughing episodes</li>
            </ul>
            <p className="mt-3 text-sm italic">Sydney Children's Hospital Network reports that this age group has the highest hospitalization rates for RSV in Eastern Suburbs.</p>
          </div>
        </div>
        
        <div className="grid md:grid-cols-2 gap-6 mb-6">
          <div className="bg-indigo-50 p-5 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Symptoms in Toddlers (1-3 years)</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Cold-like symptoms</strong> - runny nose, sneezing, mild cough</li>
              <li><strong>Fever</strong> - typically 38°C or higher</li>
              <li><strong>Decreased appetite</strong></li>
              <li><strong>Sore throat</strong> - may refuse to eat or drink</li>
              <li><strong>Dry, hacking cough</strong> that may progress to a productive cough</li>
              <li><strong>Wheezing</strong> - more common in children with a history of prior RSV infection</li>
              <li><strong>Ear infections</strong> - RSV can lead to secondary middle ear infections</li>
            </ul>
            <p className="mt-3 text-sm italic">Eastern Suburbs childcare centers report RSV spreads rapidly among toddlers due to close contact and developing hygiene habits.</p>
          </div>
          
          <div className="bg-indigo-50 p-5 rounded-lg">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Symptoms in Preschool & Older Children (3+ years)</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Mild to moderate cold symptoms</strong></li>
              <li><strong>Sore throat</strong></li>
              <li><strong>Headache</strong></li>
              <li><strong>Mild fever</strong> or no fever</li>
              <li><strong>Cough</strong> - typically less severe than in younger children</li>
              <li><strong>Decreased energy</strong> but usually able to maintain normal activities</li>
              <li><strong>Symptoms typically last 5-7 days</strong> and are often milder than in younger children</li>
            </ul>
            <p className="mt-3 text-sm italic">NSW Health data shows Eastern Suburbs primary schools see RSV peaks coinciding with winter terms, though symptoms are typically less severe than in younger children.</p>
          </div>
        </div>
        
        <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-6">
          <p className="text-gray-700">
            <strong>When to be concerned:</strong> With RSV, how your child is breathing is more important than how high their fever is. As a Sydney Eastern Suburbs house call doctor, I recommend seeking immediate medical attention if your child shows any of these warning signs:
          </p>
          <ul className="list-disc pl-6 text-gray-700 mt-2 space-y-1">
            <li>Breathing more than 60 breaths per minute (infants) or 40 breaths per minute (older children)</li>
            <li>Severe chest retractions (skin pulling in around ribs) or nasal flaring</li>
            <li>Blue color to lips, tongue, or fingernails (cyanosis)</li>
            <li>Pauses in breathing (apnea)</li>
            <li>Extreme lethargy or difficulty waking</li>
            <li>Signs of dehydration (dry mouth, no tears when crying, decreased urination)</li>
          </ul>
        </div>
      </section>
      
      <section id="diagnosis" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Diagnosis of RSV Infections</h2>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              As a house call doctor serving Sydney's Eastern Suburbs, I diagnose RSV primarily through clinical assessment, though laboratory tests may be used in certain situations. The diagnosis process typically includes:
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Clinical Assessment</h3>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Medical history</strong> - including symptom progression, exposure to others with respiratory illness, and risk factors</li>
              <li><strong>Physical examination</strong> - assessment of respiratory rate, work of breathing, oxygen levels, and lung sounds</li>
              <li><strong>Respiratory status evaluation</strong> - using pulse oximetry to measure blood oxygen levels</li>
              <li><strong>Hydration assessment</strong> - checking for signs of dehydration, particularly important in infants who are feeding poorly</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Laboratory Testing</h3>
            <p className="text-gray-700 mb-4">
              While most RSV cases in Sydney's Eastern Suburbs can be diagnosed clinically, laboratory testing may be recommended in these situations:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>For hospitalized infants and children</strong> - to confirm diagnosis and guide isolation procedures</li>
              <li><strong>During outbreaks in childcare settings</strong> - to identify the specific virus for public health purposes</li>
              <li><strong>For high-risk patients</strong> - such as premature infants or children with chronic lung or heart conditions</li>
              <li><strong>When symptoms are severe or unusual</strong> - to rule out other possible causes</li>
            </ul>
            
            <p className="text-gray-700 mb-4">
              Common testing methods used in Sydney include:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Rapid antigen testing</strong> - provides results within minutes; commonly used in clinical settings in the Eastern Suburbs</li>
              <li><strong>PCR testing</strong> - highly accurate but takes longer; available at Sydney Children's Hospital and major pathology labs</li>
              <li><strong>Multiplex respiratory panels</strong> - test for RSV along with other respiratory viruses like influenza; increasingly used during winter months</li>
            </ul>
          </div>
          
          <div className="md:w-1/3">
            <div className="bg-blue-50 p-4 rounded-lg mb-4">
              <h4 className="font-semibold text-gray-900 mb-2">Sydney Eastern Suburbs Resources</h4>
              <p className="text-gray-700 mb-3 text-sm">
                Diagnostic services for RSV in the Eastern Suburbs include:
              </p>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                <li>Sydney Children's Hospital Randwick - comprehensive testing available 24/7</li>
                <li>Eastern Suburbs GP clinics - most offer rapid testing during winter months</li>
                <li>Laverty Pathology Bondi Junction - provides PCR testing with referral</li>
                <li>HomeDoc house call service - offers after-hours assessment and point-of-care testing when appropriate</li>
              </ul>
            </div>
            
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-4 py-2 border-b border-gray-300">
                <h4 className="font-semibold text-gray-900">When Is Testing Necessary?</h4>
              </div>
              <div className="p-4">
                <p className="text-gray-700 text-sm mb-3">
                  According to the Royal Children's Hospital guidelines (followed by Sydney pediatricians), testing is not necessary for:
                </p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Mild cases in otherwise healthy children</li>
                  <li>Where management won't change based on results</li>
                  <li>When diagnosis is clear from clinical assessment</li>
                </ul>
                <p className="text-gray-700 text-sm mt-3">
                  Testing is typically recommended for:
                </p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Infants under 3 months with respiratory symptoms</li>
                  <li>Children requiring hospitalization</li>
                  <li>Immunocompromised patients</li>
                  <li>Unusual or severe presentations</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="treatment-options" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Treatment Options for RSV in Children</h2>
        <p className="text-gray-700 mb-4">
          RSV treatment is primarily supportive, as antibiotics aren't effective against viral infections. Treatment approaches vary based on symptom severity and the child's age and health status.
        </p>
        
        <div className="grid md:grid-cols-2 gap-6 mb-6">
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Home Treatment for Mild Cases</h3>
            <p className="text-gray-700 mb-3">
              Most RSV infections in Sydney can be managed at home with these approaches:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Adequate hydration</strong> - small, frequent feeds for infants; clear fluids for older children</li>
              <li><strong>Fever management</strong> - use of paracetamol or ibuprofen as recommended by your doctor</li>
              <li><strong>Nasal saline drops and gentle suctioning</strong> - to clear congestion, especially before feeds</li>
              <li><strong>Humidity</strong> - cool mist humidifier or bathroom steam to ease breathing</li>
              <li><strong>Upright positioning</strong> - for older infants and children to make breathing easier</li>
              <li><strong>Rest</strong> - ensuring adequate sleep and reduced activity</li>
            </ul>
            
            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-4">
              <p className="text-gray-700 text-sm">
                <strong>Important Note:</strong> The Australian Department of Health advises against using over-the-counter cough and cold medicines for children under 6 years of age. These medications are not effective for RSV and may cause harmful side effects.
              </p>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Medical Treatment for Moderate to Severe Cases</h3>
            <p className="text-gray-700 mb-3">
              When RSV symptoms are more severe, medical intervention may include:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Oxygen therapy</strong> - for children with low oxygen levels</li>
              <li><strong>Intravenous (IV) fluids</strong> - for children who are dehydrated or unable to drink</li>
              <li><strong>Nebulized medications</strong> - in specific cases, bronchodilators may be used if there is a response</li>
              <li><strong>Respiratory support</strong> - ranging from simple oxygen to more advanced support in severe cases</li>
              <li><strong>Airway suctioning</strong> - to remove mucus from the airways</li>
              <li><strong>Close monitoring</strong> - of respiratory status, hydration, and overall condition</li>
            </ul>
            
            <div className="bg-indigo-50 p-4 rounded-lg">
              <h4 className="font-semibold text-gray-900 mb-2">Sydney Eastern Suburbs Hospital Care</h4>
              <p className="text-gray-700 text-sm">
                For severe cases requiring hospitalization, Sydney Children's Hospital Randwick provides comprehensive care including:
              </p>
              <ul className="list-disc pl-6 text-gray-700 text-sm mt-2 space-y-1">
                <li>Pediatric respiratory specialists</li>
                <li>High-flow oxygen therapy</li>
                <li>Intensive care capabilities if needed</li>
                <li>24/7 pediatric emergency department</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
          <div className="bg-indigo-600 px-6 py-4">
            <h3 className="text-xl font-semibold text-white">Sydney Eastern Suburbs Treatment Approach</h3>
          </div>
          <div className="p-6">
            <p className="text-gray-700 mb-4">
              As a house call doctor serving Sydney's Eastern Suburbs, my approach to RSV treatment follows the latest Australian clinical guidelines with consideration for local factors:
            </p>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Severity</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Symptoms</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Treatment Approach</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Care Setting</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Mild</td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Normal feeding</li>
                        <li>Mild respiratory distress</li>
                        <li>SpO2 &gt; 95%</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Symptomatic home care</li>
                        <li>Hydration support</li>
                        <li>Close monitoring</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Home with telehealth follow-up</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Moderate</td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Difficulty feeding</li>
                        <li>Moderate respiratory distress</li>
                        <li>SpO2 92-95%</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Close monitoring</li>
                        <li>Possibly oxygen therapy</li>
                        <li>Hydration assessment</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Home (with close monitoring) or hospital based on risk factors</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Severe</td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Unable to feed</li>
                        <li>Severe respiratory distress</li>
                        <li>SpO2 &lt; 92%</li>
                        <li>Apnea</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Oxygen therapy</li>
                        <li>IV fluids</li>
                        <li>Respiratory support</li>
                        <li>Continuous monitoring</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">Sydney Children's Hospital Randwick</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      
      <section id="home-care" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Home Care for Children with RSV</h2>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Most children with RSV can be managed effectively at home. Here's a comprehensive guide to caring for a child with RSV in Sydney's Eastern Suburbs:
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Hydration Management</h3>
            <p className="text-gray-700 mb-3">
              Maintaining adequate fluid intake is crucial, especially during Sydney's warm climate:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>For breastfed infants:</strong> Continue breastfeeding but offer shorter, more frequent feeds if the baby tires easily</li>
              <li><strong>For bottle-fed infants:</strong> Offer smaller amounts more frequently; consider using a syringe if the baby struggles with the bottle</li>
              <li><strong>For older children:</strong> Offer water, diluted apple juice, or electrolyte solutions like Hydralyte (widely available in Eastern Suburbs pharmacies)</li>
              <li><strong>Encourage fluid intake:</strong> Offer popsicles, ice chips, or frozen yogurt for older children</li>
              <li><strong>Monitor wet nappies/urination:</strong> Aim for at least 4-6 wet nappies in 24 hours for infants</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Respiratory Support</h3>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Nasal saline drops:</strong> Available at pharmacies throughout Bondi, Coogee, and Randwick; use before feeds and sleep</li>
              <li><strong>Gentle nasal suctioning:</strong> Use a bulb syringe or nasal aspirator after saline drops to clear mucus</li>
              <li><strong>Humidified air:</strong> Use a cool mist humidifier in the child's room, particularly useful in Sydney's drier winter months</li>
              <li><strong>Steam:</strong> Run a hot shower and sit with your child in the steamy bathroom for 10-15 minutes (do not place the child in hot water)</li>
              <li><strong>Elevated sleeping position:</strong> For children over 12 months, slightly elevate the head of the bed or mattress (never use pillows for infants under 12 months)</li>
              <li><strong>Keep air clean:</strong> Avoid smoke exposure and use air purifiers if available, especially during Sydney's bushfire season</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Comfort Measures</h3>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Fever management:</strong> Use paracetamol (Panadol) or ibuprofen (Nurofen) as directed for fever or discomfort</li>
              <li><strong>Rest:</strong> Ensure your child gets plenty of rest, but don't worry if they're having trouble sleeping due to congestion</li>
              <li><strong>Clothing:</strong> Dress in lightweight, breathable clothing, especially important in Sydney's humidity</li>
              <li><strong>Room temperature:</strong> Keep the room cool but comfortable (20-22°C is ideal)</li>
              <li><strong>Gentle chest physiotherapy:</strong> For children over 12 months, gentle patting on the back may help loosen mucus</li>
            </ul>
          </div>
          
          <div className="md:w-1/3">
            <div className="bg-green-50 p-5 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Eastern Suburbs RSV Home Care Kit</h3>
              <p className="text-gray-700 mb-3 text-sm">
                Consider preparing these items, available at pharmacies in Bondi, Randwick, and surrounding areas:
              </p>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-2">
                <li><strong>Saline nasal drops</strong> (Fess, Little Noses)</li>
                <li><strong>Nasal aspirator</strong> (bulb syringe or NoseFrida)</li>
                <li><strong>Digital thermometer</strong></li>
                <li><strong>Paracetamol</strong> (appropriate for age/weight)</li>
                <li><strong>Ibuprofen</strong> (for children over 3 months)</li>
                <li><strong>Hydralyte</strong> or similar electrolyte solution</li>
                <li><strong>Cool mist humidifier</strong></li>
                <li><strong>Petroleum jelly</strong> for irritated nose</li>
              </ul>
              <p className="text-gray-700 mt-3 text-sm italic">
                Local pharmacies like Priceline Bondi Junction, Blooms The Chemist Randwick, and Chemist Warehouse Bondi Beach stock these items.
              </p>
            </div>
            
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-4 py-2 border-b border-gray-300">
                <h4 className="font-semibold text-gray-900">Daily Monitoring Checklist</h4>
              </div>
              <div className="p-4">
                <p className="text-gray-700 text-sm mb-2">Monitor and record these signs daily:</p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Temperature (morning and evening)</li>
                  <li>Number of wet nappies/urination</li>
                  <li>Feeding amount and frequency</li>
                  <li>Breathing rate (count breaths for 1 minute while child is calm)</li>
                  <li>Sleep duration and quality</li>
                  <li>Energy level and alertness</li>
                  <li>Cough frequency and character</li>
                </ul>
                <p className="text-gray-700 text-sm mt-3 italic">
                  Share this information with your healthcare provider during follow-up consultations.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-blue-50 p-5 rounded-lg mb-6">
          <h3 className="text-xl font-semibold text-gray-800 mb-3">Managing RSV at Home During Sydney's Climate Conditions</h3>
          <div className="grid md:grid-cols-3 gap-4">
            <div>
              <h4 className="font-semibold text-gray-900 mb-2">Summer Months</h4>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                <li>Use air conditioning set to 20-22°C if available</li>
                <li>Ensure extra hydration due to increased fluid loss</li>
                <li>Use lightweight clothing and bedding</li>
                <li>Consider cool baths for fever management</li>
                <li>Be vigilant about dehydration signs</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-gray-900 mb-2">Winter Months</h4>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                <li>Use humidifiers to counter dry heating</li>
                <li>Dress in layers that can be removed if fever develops</li>
                <li>Maintain heating at 18-20°C</li>
                <li>Continue fluid intake despite less thirst</li>
                <li>Use saline drops more frequently in dry air</li>
              </ul>
            </div>
            <div>
              <h4 className="font-semibold text-gray-900 mb-2">Coastal Considerations</h4>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                <li>Be mindful of salt-laden air effects on respiratory symptoms</li>
                <li>Close windows during high wind days</li>
                <li>Consider air purifiers during high pollen days</li>
                <li>Manage humidity with dehumidifiers if needed</li>
                <li>Protect from sea breeze when recovering</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      <section id="when-to-call-doctor" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">When to Call a Doctor for RSV</h2>
        
        <div className="bg-red-50 border-l-4 border-red-500 p-5 mb-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">Emergency Warning Signs - Seek Immediate Medical Attention</h3>
          <p className="text-gray-700 mb-3">
            Call Triple Zero (000) or go to the nearest emergency department if your child shows any of these signs:
          </p>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li><strong>Severe difficulty breathing</strong> - rapid breathing, severe chest retractions, grunting</li>
            <li><strong>Blue or gray coloration</strong> of the lips, tongue, or skin (cyanosis)</li>
            <li><strong>Pauses in breathing</strong> (apnea) - particularly concerning in infants under 6 months</li>
            <li><strong>Inability to wake or extreme lethargy</strong> - difficult to rouse or unresponsive</li>
            <li><strong>Severe dehydration</strong> - no wet nappies for 6+ hours, no tears when crying, very dry mouth</li>
            <li><strong>Refusal to feed or drink</strong> for multiple feeds/hours</li>
            <li><strong>High fever with rapidly developing rash</strong></li>
            <li><strong>Signs of respiratory distress</strong> even after repositioning and clearing nasal passages</li>
          </ul>
        </div>
        
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-1/2">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">Contact Your Doctor Within 24 Hours If:</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Your child is under 3 months with any fever (38°C or higher)</li>
              <li>Your child is 3-6 months with a fever over 39°C</li>
              <li>Fever persisting more than 3 days</li>
              <li>Feeding difficulties with fewer wet nappies than normal</li>
              <li>Noticeable wheezing or rapid breathing when at rest</li>
              <li>Persistent cough that interferes with sleep or feeding</li>
              <li>Your child seems to be getting worse rather than better</li>
              <li>Your child has any underlying medical conditions (asthma, congenital heart disease, prematurity, etc.)</li>
              <li>Your child appears dehydrated despite your efforts</li>
              <li>You're concerned about your child's condition</li>
            </ul>
          </div>
          
          <div className="md:w-1/2">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">Sydney Eastern Suburbs After-Hours Options:</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-3">
              <li>
                <strong>HomeDoc House Call Service:</strong> Available evenings, overnight, and weekends in Bondi, Randwick, Coogee, Double Bay, and surrounding suburbs. Medicare bulk-billed. 
                <div className="mt-1">
                  <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">Book a house call</Link> or call 13HEALTH (13 43 25 84).
                </div>
              </li>
              <li>
                <strong>Sydney Children's Hospital Emergency Department:</strong> Open 24/7
                <div className="text-gray-600 text-sm mt-1">
                  High Street, Randwick NSW 2031<br />
                  (02) 9382 1111
                </div>
              </li>
              <li>
                <strong>Prince of Wales Hospital Emergency Department:</strong> Open 24/7
                <div className="text-gray-600 text-sm mt-1">
                  Barker Street, Randwick NSW 2031<br />
                  (02) 9382 2222
                </div>
              </li>
              <li>
                <strong>Healthdirect Helpline:</strong> 1800 022 222
                <div className="text-gray-600 text-sm mt-1">
                  Free health advice from registered nurses, 24/7
                </div>
              </li>
            </ul>
            
            <div className="bg-yellow-50 p-4 rounded-lg mt-4">
              <p className="text-gray-700 text-sm">
                <strong>Note:</strong> During RSV season (May-September), Sydney Children's Hospital Randwick may experience longer wait times. For non-emergency situations, a house call doctor can often provide faster care in your home while avoiding exposure to other illnesses in waiting rooms.
              </p>
            </div>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
          <div className="bg-indigo-600 px-6 py-4">
            <h3 className="text-xl font-semibold text-white">What to Expect During a Medical Assessment</h3>
          </div>
          <div className="p-6">
            <p className="text-gray-700 mb-4">
              When you call a house call doctor or visit a medical facility for RSV, the doctor will typically:
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Assessment Steps</h4>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Take a detailed history of symptoms and progression</li>
                  <li>Check vital signs (temperature, heart rate, breathing rate, oxygen levels)</li>
                  <li>Examine the lungs, ears, throat, and nose</li>
                  <li>Assess hydration status</li>
                  <li>Evaluate work of breathing and respiratory distress</li>
                  <li>Consider testing based on clinical presentation</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Information to Have Ready</h4>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>When symptoms started and how they've changed</li>
                  <li>Temperature readings (if taken)</li>
                  <li>Feeding/fluid intake in the last 24 hours</li>
                  <li>Number of wet nappies/urination in last 24 hours</li>
                  <li>Any medications given and response</li>
                  <li>Exposure to anyone with similar symptoms</li>
                  <li>Any underlying medical conditions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="prevention" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">RSV Prevention Strategies</h2>
        <p className="text-gray-700 mb-4">
          While RSV is highly contagious and difficult to avoid completely, these prevention strategies can help reduce the risk of infection and spread, particularly in Sydney's Eastern Suburbs:
        </p>
        
        <div className="grid md:grid-cols-2 gap-6 mb-6">
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">General Prevention Measures</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Hand hygiene:</strong> Frequent handwashing with soap and water for at least 20 seconds, especially before touching babies</li>
              <li><strong>Disinfect surfaces:</strong> Regularly clean high-touch surfaces and items like toys, especially in childcare settings</li>
              <li><strong>Avoid close contact:</strong> Keep infants away from people with cold symptoms and avoid kissing babies on the face</li>
              <li><strong>Cover coughs and sneezes:</strong> Use tissues or your elbow, not your hands</li>
              <li><strong>Limit exposure:</strong> Consider limiting visits to crowded places with young infants during RSV season</li>
              <li><strong>Breastfeeding:</strong> If possible, breastfeed as this provides antibodies that help protect against RSV</li>
              <li><strong>No smoking:</strong> Don't smoke around children and avoid exposing them to secondhand smoke</li>
            </ul>
            
            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mt-4">
              <p className="text-gray-700 text-sm">
                <strong>Note:</strong> The Royal Australian College of General Practitioners advises that common surgical masks may help prevent the spread of RSV when worn by people with symptoms or when caring for someone with RSV.
              </p>
            </div>
          </div>
          
          <div>
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Sydney Eastern Suburbs-Specific Measures</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li><strong>Childcare considerations:</strong> Sydney's Eastern Suburbs have numerous childcare centers - check their illness policies and RSV prevention protocols</li>
              <li><strong>Seasonal awareness:</strong> Be extra vigilant during May-September, when RSV peaks in Sydney</li>
              <li><strong>Beach precautions:</strong> After visits to Bondi, Coogee, or other Eastern Suburbs beaches, change children out of wet clothes promptly and rinse salt water to prevent respiratory irritation</li>
              <li><strong>Air quality monitoring:</strong> On days with poor air quality (during bushfire season), limit outdoor time for vulnerable children</li>
              <li><strong>Indoor playgrounds:</strong> Exercise caution at popular Eastern Suburbs indoor play centers during peak RSV season</li>
              <li><strong>Public transport:</strong> Consider using covers for prams on busy Eastern Suburbs bus routes or trains</li>
              <li><strong>Ventilation:</strong> Despite Sydney's generally mild climate, ensure adequate ventilation in homes and apartments to reduce virus concentration</li>
            </ul>
            
            <div className="bg-green-50 p-4 rounded-lg mt-4">
              <h4 className="font-semibold text-gray-900 mb-2">Local Resources for RSV Season</h4>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                <li>NSW Health website provides RSV activity updates</li>
                <li>Sydney Children's Hospital Network offers RSV prevention resources</li>
                <li>Eastern Suburbs Council libraries host parent information sessions during RSV season</li>
                <li>Local pharmacies in Bondi, Randwick, and surrounding areas offer prevention supplies and advice</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
          <div className="bg-indigo-600 px-6 py-4">
            <h3 className="text-xl font-semibold text-white">RSV Prevention for High-Risk Infants in Sydney</h3>
          </div>
          <div className="p-6">
            <p className="text-gray-700 mb-4">
              Some infants are at higher risk for severe RSV and may qualify for preventive measures beyond standard precautions:
            </p>
            
            <h4 className="font-semibold text-gray-900 mb-3">Palivizumab (Synagis)</h4>
            <p className="text-gray-700 mb-3">
              This is a preventive antibody medication given by injection that can help protect high-risk infants from severe RSV disease. In Australia, its use is limited and strictly regulated.
            </p>
            
            <h4 className="font-semibold text-gray-900 mb-2">Who May Qualify:</h4>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-1">
              <li>Extremely premature infants (born at or before 28 weeks gestation)</li>
              <li>Infants with chronic lung disease of prematurity requiring medical therapy</li>
              <li>Infants with significant congenital heart disease</li>
              <li>Infants with severe immunodeficiency conditions</li>
            </ul>
            
            <p className="text-gray-700 mb-3">
              If your child falls into a high-risk category, discuss with your pediatrician at Sydney Children's Hospital or your specialist whether preventive antibody therapy might be appropriate. The decision is made on a case-by-case basis according to Australian guidelines.
            </p>
            
            <div className="bg-blue-50 p-4 rounded-lg">
              <p className="text-gray-700 text-sm">
                <strong>Future developments:</strong> Research is underway on RSV vaccines. In May 2023, the FDA approved the first maternal RSV vaccine (Abrysvo) to protect infants through maternal antibodies. This is not yet available in Australia but is under consideration by the Therapeutic Goods Administration. The landscape of RSV prevention is likely to change in coming years.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="faq" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Frequently Asked Questions about RSV</h2>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-10">
          <div className="divide-y divide-gray-200">
            {/* FAQ Item 1 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">How long is RSV contagious and when can my child return to childcare in Sydney?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  People infected with RSV are typically contagious for 3-8 days, but some infants and people with weakened immune systems can continue to spread the virus for up to 4 weeks, even after symptoms have resolved.
                </p>
                <p className="mb-3">
                  According to NSW Health guidelines, children with RSV should not attend childcare or school while they have acute symptoms, particularly fever. They can generally return when:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>They're feeling well enough to participate in normal activities</li>
                  <li>They've been fever-free for at least 24 hours without fever-reducing medication</li>
                  <li>Their symptoms have significantly improved</li>
                </ul>
                <p>
                  Most Sydney Eastern Suburbs childcare centers follow these guidelines, but policies may vary, so check with your specific center. Some high-end childcare facilities in Double Bay and Bondi may have stricter policies due to the density of children in these areas.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 2 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Can my child get RSV more than once? Is there a vaccine available in Australia?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Yes, children can get RSV multiple times, even within the same season, though subsequent infections are usually less severe than the first. Natural immunity after RSV infection is incomplete and temporary.
                </p>
                <p className="mb-3">
                  Regarding vaccines, as of 2024, there is no RSV vaccine routinely available for children in Australia. However, there are significant developments:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>In July 2023, the FDA approved the first RSV vaccine for pregnant women (Abrysvo) to protect newborns</li>
                  <li>The Therapeutic Goods Administration (TGA) in Australia is currently evaluating RSV vaccines</li>
                  <li>RSV vaccines for older adults have been approved in Australia as of late 2023</li>
                </ul>
                <p>
                  Sydney pediatricians and infectious disease specialists at Sydney Children's Hospital Randwick are monitoring these developments closely. I recommend discussing the latest information about RSV prevention with your healthcare provider during your child's regular check-ups.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 3 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">How is RSV different from COVID-19 or influenza in children?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  RSV, COVID-19, and influenza are all respiratory viruses that can cause similar symptoms, making it difficult to distinguish between them without testing. However, there are some general differences:
                </p>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 mb-3">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Feature</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">RSV</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Influenza</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">COVID-19</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Main Season in Sydney</td>
                        <td className="px-4 py-3 text-sm text-gray-700">May-September (winter)</td>
                        <td className="px-4 py-3 text-sm text-gray-700">June-September (winter)</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Year-round with variant waves</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Onset</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Gradual</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Sudden</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Varies (often gradual)</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Distinctive Features</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Wheezing, bronchiolitis in infants</td>
                        <td className="px-4 py-3 text-sm text-gray-700">High fever, body aches</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Loss of taste/smell (in some cases)</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Age Group Most Affected</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Under 2 years</td>
                        <td className="px-4 py-3 text-sm text-gray-700">All ages</td>
                        <td className="px-4 py-3 text-sm text-gray-700">All ages</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Testing Available in Sydney</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Yes, specific RSV tests</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Yes, rapid and PCR</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Yes, rapid and PCR</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Vaccine Status in Australia</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Not yet available for children</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Available and recommended</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Available and recommended</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  In Sydney's Eastern Suburbs, our house call doctors can perform testing for all three viruses when clinically indicated. Testing helps guide isolation recommendations and treatment decisions, particularly for high-risk patients.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 4 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Are there any alternative or natural remedies for RSV that actually work?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  While many natural remedies are promoted for RSV, particularly in Sydney's Eastern Suburbs where complementary medicine is popular, most lack scientific evidence. The Australian Department of Health and the Royal Children's Hospital Melbourne recommend these evidence-based supportive measures:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li><strong>Honey for cough</strong> (only for children over 12 months) - has some evidence for symptom relief</li>
                  <li><strong>Saline nasal drops/spray</strong> - effective for clearing congestion</li>
                  <li><strong>Humidity</strong> - cool mist humidifiers or steam from showers can help ease breathing</li>
                  <li><strong>Hydration</strong> - breast milk, formula, water, or electrolyte solutions as age-appropriate</li>
                </ul>
                <p className="mb-3">
                  Treatments with insufficient evidence or not recommended:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li><strong>Essential oils</strong> - many are not safe for children and can cause respiratory irritation</li>
                  <li><strong>Herbal preparations</strong> - these aren't regulated for children and safety isn't established</li>
                  <li><strong>Vitamin supplements</strong> - while good nutrition is important, specific supplements haven't been proven effective for RSV</li>
                  <li><strong>Over-the-counter cough and cold medicines</strong> - not recommended for children under 6 years</li>
                </ul>
                <p>
                  If you're interested in complementary approaches, discuss them with your healthcare provider first. As a house call doctor in Sydney's Eastern Suburbs, I take an evidence-based approach while respecting families' preferences for integrative care when safe and appropriate.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 5 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Does RSV lead to asthma or long-term respiratory problems?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Research from the Sydney Children's Hospital Network and international studies suggests a complex relationship between RSV in early childhood and later respiratory health:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Children who experience severe RSV bronchiolitis in infancy have a higher risk of developing recurrent wheezing and asthma in early childhood</li>
                  <li>However, it's unclear whether RSV directly causes asthma or if both conditions share common risk factors</li>
                  <li>About 30-40% of children hospitalized with RSV bronchiolitis may experience recurrent wheezing episodes in the following years</li>
                  <li>Most children with mild RSV infections do not develop long-term respiratory problems</li>
                </ul>
                <p className="mb-3">
                  Sydney-based respiratory specialists suggest these follow-up measures after significant RSV infection:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Regular check-ups to monitor respiratory health</li>
                  <li>Prompt treatment of subsequent respiratory infections</li>
                  <li>Awareness of potential asthma symptoms (recurrent coughing, wheezing, breathing difficulty)</li>
                  <li>Reduction of environmental triggers (smoke exposure, allergens)</li>
                </ul>
                <p>
                  If your child has had severe RSV and you're concerned about long-term effects, pediatric respiratory specialists are available at Sydney Children's Hospital Randwick for consultation and follow-up care.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 6 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Is it true that breastfed babies are less likely to get severe RSV?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Yes, research supported by the Australian Breastfeeding Association and international studies consistently shows that breastfeeding provides protection against severe RSV infection:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Breast milk contains maternal antibodies that help protect against RSV</li>
                  <li>Breastfed infants generally have fewer and less severe respiratory infections, including RSV</li>
                  <li>The protection is dose-dependent – exclusively breastfed babies receive more protection than partially breastfed babies</li>
                  <li>Even partial breastfeeding offers some protection compared to exclusive formula feeding</li>
                  <li>The protective effect continues as long as breastfeeding continues</li>
                </ul>
                <p className="mb-3">
                  A study from the Sydney Children's Hospitals Network found that among infants hospitalized with bronchiolitis (often caused by RSV), those who were exclusively breastfed had shorter hospital stays and were less likely to require intensive care.
                </p>
                <p>
                  While breastfeeding doesn't completely prevent RSV infection, it can reduce severity. For mothers in Sydney's Eastern Suburbs who need breastfeeding support, resources include the Australian Breastfeeding Association's local groups in Bondi and Randwick, and lactation consultants at the Royal Hospital for Women in Randwick.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 7 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">How do Sydney's Eastern Suburbs weather conditions affect RSV risk and recovery?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Sydney's Eastern Suburbs experience unique weather patterns that can influence RSV transmission and recovery:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li><strong>Coastal humidity:</strong> The higher humidity in coastal suburbs like Bondi and Coogee can have both positive and negative effects. Moderate humidity (40-60%) may reduce virus survival on surfaces, but very high humidity can increase mold growth, potentially irritating airways</li>
                  <li><strong>Sea breezes:</strong> The salt-laden air from ocean breezes can be beneficial for some respiratory conditions but may irritate inflamed airways in children with active RSV</li>
                  <li><strong>Temperature fluctuations:</strong> Eastern Suburbs' mild winters compared to Western Sydney may contribute to longer RSV seasons, as the virus thrives in moderate temperatures</li>
                  <li><strong>Air quality:</strong> During bushfire season or when air quality is poor, children recovering from RSV may experience prolonged symptoms</li>
                </ul>
                <p className="mb-3">
                  Local considerations for Eastern Suburbs residents:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Consider using air purifiers during recovery, especially in apartments near busy roads like New South Head Road or Anzac Parade</li>
                  <li>Monitor air quality indexes, particularly during bushfire season</li>
                  <li>In summer, use air conditioning to maintain consistent temperatures and filter air</li>
                  <li>Be mindful that coastal playgrounds and beaches may expose recovering children to wind and salt spray that could irritate recovering airways</li>
                </ul>
                <p>
                  As a house call doctor practicing in Sydney's Eastern Suburbs, I often recommend adjusting recovery environments based on these local climate factors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="conclusion" className="mt-10 mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Conclusion</h2>
        <p className="text-gray-700 mb-4">
          RSV is a common respiratory infection that affects most children before the age of two. While it can cause significant concern for parents, understanding the symptoms, appropriate home care measures, and when to seek medical attention can help you navigate this common childhood illness with confidence.
        </p>
        <p className="text-gray-700 mb-4">
          For families in Sydney's Eastern Suburbs, local resources include house call doctors who can provide prompt assessment and treatment in your home, avoiding unnecessary trips to emergency departments for manageable cases. However, never hesitate to seek emergency care when warning signs are present.
        </p>
        <p className="text-gray-700 mb-6">
          Remember that most children recover well from RSV with appropriate supportive care, and each year advances are being made in prevention and treatment options. If you have concerns about your child's respiratory symptoms, <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">booking a house call doctor</Link> can provide peace of mind and expert medical care in the comfort of your home.
        </p>
        
        <div className="bg-indigo-50 p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">Need Medical Advice for RSV?</h3>
          <p className="text-gray-700 mb-4">
            If your child is showing symptoms of RSV or you have concerns about their respiratory health, our house call doctors can provide expert assessment and treatment throughout Sydney's Eastern Suburbs.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-8"
            >
              Book a House Call Doctor
            </Link>
            <Link
              to="/telemedicine"
              className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-8"
            >
              Schedule a Telehealth Consult
            </Link>
          </div>
        </div>
      </section>
    </>
  );
  
  // Placeholder for the rest of the content
  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'What is RSV (Respiratory Syncytial Virus)?', anchor: 'what-is-rsv' },
    { title: 'RSV Symptoms in Children by Age Group', anchor: 'rsv-symptoms' },
    { title: 'Diagnosis of RSV Infections', anchor: 'diagnosis' },
    { title: 'Treatment Options for RSV in Children', anchor: 'treatment-options' },
    { title: 'Home Care for Children with RSV', anchor: 'home-care' },
    { title: 'When to Call a Doctor for RSV', anchor: 'when-to-call-doctor' },
    { title: 'RSV Prevention Strategies', anchor: 'prevention' },
    { title: 'Frequently Asked Questions about RSV', anchor: 'faq' }
  ];

  const symptoms = [
    'Congestion or runny nose',
    'Dry cough that may progress to wheezing',
    'Low-grade fever (higher fevers more common in infants)',
    'Decreased appetite or difficulty feeding',
    'Irritability and fussiness',
    'Rapid or labored breathing',
    'Wheezing or grunting during breathing',
    'Cyanosis (bluish color of lips or fingernails) in severe cases',
    'Lethargy or excessive fatigue',
    'Apnea (pauses in breathing) in very young infants'
  ];

  const treatments = [
    {
      stage: 'Initial Supportive Care',
      description: 'Adequate hydration, nasal suctioning, humidified air, fever management',
      duration: 'Throughout illness (typically 7-10 days)'
    },
    {
      stage: 'Respiratory Support',
      description: 'Oxygen therapy if oxygen levels are low, monitoring of respiratory status',
      duration: 'As needed based on severity'
    },
    {
      stage: 'Medication Management',
      description: 'Paracetamol/ibuprofen for fever, possible bronchodilators in specific cases',
      duration: 'As needed for symptom management'
    },
    {
      stage: 'Hospitalization',
      description: 'For severe cases requiring oxygen, IV fluids, or intensive monitoring',
      duration: 'Typically 2-7 days for hospitalized cases'
    }
  ];

  const references = [
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Clinical Practice Guidelines: Bronchiolitis',
      journal: 'RCH Clinical Practice Guidelines',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Australian Government Department of Health',
      title: 'Respiratory Syncytial Virus (RSV) infection',
      journal: 'Department of Health',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Florin TA, Plint AC, Zorc JJ.',
      title: 'Viral bronchiolitis',
      journal: 'Lancet',
      year: '2017',
      doi: '389:211-24'
    },
    {
      authors: 'The Sydney Children\'s Hospitals Network',
      title: 'Factsheet: Bronchiolitis',
      journal: 'SCHN',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Ralston SL, et al.',
      title: 'Clinical Practice Guideline: The Diagnosis, Management, and Prevention of Bronchiolitis',
      journal: 'Pediatrics',
      year: '2014',
      doi: '134:e1474-e1502'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/kidsrsv.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/rsv-infection-children" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/kidsrsv.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/rsv-infection-children" />
      </Helmet>
      
      <MedicalBlogTemplate
        title="RSV Infection in Children: Symptoms, Treatment & When to Seek Care"
        subtitle="Sydney pediatric house call doctor's guide to recognizing, managing, and preventing respiratory syncytial virus"
        heroImage="/images/kidsrsv.png"
        publishDate="June 18, 2024"
        readTime={12}
        content={content}
        category="Child Health"
        tags={['RSV', 'Bronchiolitis', 'Child Health', 'Respiratory Infections', 'Pediatrics', 'House Call Doctor', 'Sydney']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={true}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export default RSVInfection; 