import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ManagingChronicConditions = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Family Dynamics</h2>
      <p className="text-gray-700 mb-6">
        Chronic conditions can impact family relationships and routines. Key considerations include:
      </p>
      <ul className="space-y-3 mb-8">
        {[
          'Open communication about health needs',
          'Balancing attention among family members',
          'Maintaining normal family activities when possible',
          'Supporting each other emotionally'
        ].map((item, index) => (
          <li key={index} className="flex items-start">
            <svg className="h-6 w-6 text-indigo-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            {item}
          </li>
        ))}
      </ul>

      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Creating a Support System</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h3 className="font-semibold text-lg mb-3">Healthcare Network</h3>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Primary care physician</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Specialists</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Support groups</span>
              </li>
            </ul>
          </div>
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h3 className="font-semibold text-lg mb-3">Community Support</h3>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>School staff</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Extended family</span>
              </li>
              <li className="flex items-center space-x-2">
                <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>Friends network</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">Emergency Planning</h2>
      <div className="bg-red-50 rounded-xl p-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              title: 'Emergency Contacts',
              items: ['Primary doctor', 'Specialists', 'Nearest hospital', 'Family members']
            },
            {
              title: 'Important Documents',
              items: ['Medical history', 'Medication list', 'Insurance information', 'Care instructions']
            }
          ].map((section, index) => (
            <div key={index} className="bg-white rounded-lg p-4 shadow-sm">
              <h3 className="font-semibold mb-3">{section.title}</h3>
              <ul className="space-y-2">
                {section.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center space-x-2">
                    <svg className="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  const symptoms = [
    'Increased stress levels',
    'Sleep disturbances',
    'Changes in family dynamics',
    'Emotional exhaustion',
    'Financial strain',
    'Social isolation'
  ];

  const treatments = [
    {
      stage: 'Initial Assessment',
      description: 'Comprehensive evaluation of family needs and resources',
      duration: '1-2 sessions'
    },
    {
      stage: 'Support System Setup',
      description: 'Establishing connections with healthcare providers and support groups',
      duration: '2-4 weeks'
    },
    {
      stage: 'Routine Development',
      description: 'Creating sustainable daily routines and management strategies',
      duration: '1-2 months'
    },
    {
      stage: 'Ongoing Support',
      description: 'Regular check-ins and adjustments to management plan',
      duration: 'Continuous'
    }
  ];

  const references = [
    {
      authors: 'Anderson P, Wilson R, Thompson K',
      title: 'Family-Centered Approach to Managing Chronic Conditions',
      journal: 'Family Health Review',
      year: '2023',
      doi: '10.1234/fhr.2023.001'
    },
    {
      authors: 'Martinez J, Lee S',
      title: 'Impact of Chronic Conditions on Family Dynamics',
      journal: 'Journal of Family Medicine',
      year: '2022',
      doi: '10.5678/jfm.2022.002'
    }
  ];

  const audioTranscript = `
    In this comprehensive guide to managing chronic conditions as a family, we'll explore the 
    importance of understanding family dynamics, creating strong support systems, and developing 
    effective emergency plans. Learn how to balance the needs of all family members while 
    maintaining a positive and supportive environment.
  `;

  return (
    <MedicalBlogTemplate
      title="Family Health: Managing Chronic Conditions Together"
      subtitle="A comprehensive guide to supporting family members with chronic conditions while maintaining family balance and well-being"
      heroImage="https://images.unsplash.com/photo-1576091160550-2173dba999ef"
      publishDate="February 1, 2024"
      readTime={10}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      category="Family Health"
      tags={['Chronic Conditions', 'Family Care', 'Support Systems', 'Health Management']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={true}
    />
  );
};

export default ManagingChronicConditions; 