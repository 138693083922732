import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import drTerryLogo from '../assets/images/logos/drterry_logo3.png';
import { motion, AnimatePresence } from 'framer-motion';
import LanguageToggle from './LanguageToggle';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuVariants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  return (
    <nav className="sticky top-0 w-full bg-[#1A1A1A] border-b border-[#DDC6A8]/10 z-50">
      <div className="absolute inset-0 bg-gradient-to-b from-[#1A1A1A] via-[#1A1A1A] to-transparent opacity-95"></div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center relative z-10">
            <img
              src={drTerryLogo}
              alt="The Practice of Dr Terry Nguyen"
              className="h-16 w-auto object-contain mix-blend-screen"
            />
          </Link>

          {/* Burger Menu Button (Mobile) */}
          <div className="md:hidden flex items-center relative z-10">
            <LanguageToggle />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="ml-3 px-4 py-2 rounded-full border border-[#DDC6A8] text-[#DDC6A8] bg-[#1A1A1A] hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
              aria-label="Toggle menu"
            >
              <div className="w-5 h-4 flex flex-col justify-between">
                <span className={`w-full h-0.5 bg-current transform transition-transform duration-300 ${isMenuOpen ? 'rotate-45 translate-y-1.5' : ''}`} />
                <span className={`w-full h-0.5 bg-current transition-opacity duration-300 ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`} />
                <span className={`w-full h-0.5 bg-current transform transition-transform duration-300 ${isMenuOpen ? '-rotate-45 -translate-y-1.5' : ''}`} />
              </div>
            </button>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8 relative z-10">
            <Link to="/about" className="text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors">
              ABOUT
            </Link>
            <Link to="/comprehensive-health-check" className="text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors">
              COMPLETE HEALTH CHECK
            </Link>
            <Link to="/private" className="text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors">
              PRIVATE SERVICES
            </Link>
            <Link to="/books" className="text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors">
              BOOKS
            </Link>
            <Link to="/blog" className="text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors">
              ARTICLES
            </Link>
            <Link
              to="/dynamic-booking"
              className="border border-[#DDC6A8] text-[#DDC6A8] px-6 py-2 rounded-full text-sm font-light hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
            >
              BOOK CONSULTATION
            </Link>
            <LanguageToggle />
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={menuVariants}
              className="md:hidden absolute left-0 right-0 top-full bg-[#1A1A1A] border-t border-[#DDC6A8]/10 overflow-hidden"
            >
              <div className="px-4 py-6 space-y-4">
                <Link
                  to="/about"
                  className="block text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  ABOUT
                </Link>
                <Link
                  to="/comprehensive-health-check"
                  className="block text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  COMPLETE HEALTH CHECK
                </Link>
                <Link
                  to="/private"
                  className="block text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  PRIVATE SERVICES
                </Link>
                <Link
                  to="/books"
                  className="block text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  BOOKS
                </Link>
                <Link
                  to="/blog"
                  className="block text-[#DDC6A8] hover:text-[#DDC6A8]/80 text-sm font-light transition-colors py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  ARTICLES
                </Link>
                <Link
                  to="/dynamic-booking"
                  className="block border border-[#DDC6A8] text-[#DDC6A8] px-6 py-2 rounded-full text-sm font-light hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all text-center"
                  onClick={() => setIsMenuOpen(false)}
                >
                  BOOK CONSULTATION
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar; 