import React from 'react';
import { Link } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/solid';

export default function BookingCancelled() {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-lg p-8 text-center">
        <XCircleIcon className="mx-auto h-16 w-16 text-red-500" />
        <h1 className="mt-4 text-3xl font-bold text-gray-900">Booking Cancelled</h1>
        <p className="mt-2 text-lg text-gray-600">
          Your booking has been cancelled. No payment has been processed.
        </p>
        <div className="mt-8 space-y-4">
          <Link
            to="/dynamic-booking"
            className="block bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors"
          >
            Try Booking Again
          </Link>
          <Link
            to="/"
            className="block text-blue-600 hover:text-blue-700 transition-colors"
          >
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
} 