import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faHome, faPhone, faStethoscope, faClock } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import BookingButton from '../components/BookingButton';

// Styled components
const MainContainer = styled.div`
  font-family: 'Noto Sans SC', sans-serif, 'Noto Sans', sans-serif;
`;

const HeroSection = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/doctor-with-patient.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 120px 20px;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const HeroSubtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

const CTAButton = styled(Link)`
  background-color: #1e88e5;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #1565c0;
    transform: translateY(-2px);
  }
`;

const PhoneButton = styled.a`
  background-color: #43a047;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &:hover {
    background-color: #2e7d32;
    transform: translateY(-2px);
  }
`;

const Section = styled.section`
  padding: 80px 20px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-bottom: 50px;
  font-size: 2rem;
  color: #333;
`;

const ServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
`;

const ServiceCard = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }
`;

const ServiceTitle = styled.h3`
  color: #1e88e5;
  margin-bottom: 15px;
  font-size: 1.5rem;
`;

const ServiceDescription = styled.p`
  color: #666;
  margin-bottom: 20px;
`;

const ServiceLink = styled(Link)`
  color: #1e88e5;
  text-decoration: none;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.hr`
  max-width: 200px;
  margin: 20px auto;
  border: 0;
  border-top: 3px solid #1e88e5;
`;

const UrgentCareContainer = styled.div`
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 40px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
`;

const UrgentTitle = styled.h3`
  color: #d32f2f;
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const UrgentDescription = styled.p`
  color: #555;
  margin-bottom: 30px;
  font-size: 1.1rem;
`;

const EmergencyNote = styled.p`
  color: #d32f2f;
  font-weight: bold;
  margin-top: 20px;
`;

const ChineseHomePage = () => {
  return (
    <MainContainer>
      <Helmet>
        <title>悉尼私人家庭医生 | Dr. Terry Nguyen | 中文服务</title>
        <meta name="description" content="悉尼专业私人家庭医生，提供上门出诊，通晓中文。全天候家庭医疗服务，预约简便，专业可靠。" />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;700&display=swap" rel="stylesheet" />
        
        {/* Schema.org structured data for LocalBusiness */}
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "MedicalBusiness",
            "name": "Dr. Terry Nguyen - 悉尼私人家庭医生",
            "url": "https://homedoc.com.au/chinese",
            "logo": "https://homedoc.com.au/images/logo.png",
            "description": "悉尼专业私人家庭医生，提供上门出诊服务，通晓中文。",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sydney",
              "addressRegion": "NSW",
              "postalCode": "2000",
              "addressCountry": "AU"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": -33.8688,
              "longitude": 151.2093
            },
            "telephone": "+61-XXXX-XXXX",
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
              ],
              "opens": "00:00",
              "closes": "23:59"
            },
            "sameAs": [
              "https://www.facebook.com/YourPage",
              "https://www.instagram.com/YourPage"
            ]
          }
        `}</script>
      </Helmet>

      <Navbar />

      <HeroSection>
        <HeroTitle>悉尼专业私人家庭医生</HeroTitle>
        <HeroSubtitle>
          提供全天候上门医疗服务，由经验丰富的澳大利亚医生为您服务，通晓中文
        </HeroSubtitle>
        <ButtonContainer>
          <CTAButton to="/booking">
            <FontAwesomeIcon icon={faCalendarCheck} /> 立即预约
          </CTAButton>
          <PhoneButton href="tel:+61XXXXXXXX">
            <FontAwesomeIcon icon={faPhone} /> 紧急电话预约
          </PhoneButton>
        </ButtonContainer>
        <BookingButton text="预约上门医生" />
      </HeroSection>

      <Section>
        <SectionTitle>我们的服务</SectionTitle>
        <Divider />
        <ServicesContainer>
          <ServiceCard>
            <ServiceTitle>上门医疗服务</ServiceTitle>
            <ServiceDescription>
              无需出门，我们的医生将直接到您家中提供专业医疗服务，让您在舒适的环境中得到诊治。
            </ServiceDescription>
            <ServiceLink to="/chinese-housecall">
              了解更多 →
            </ServiceLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>远程医疗咨询</ServiceTitle>
            <ServiceDescription>
              通过视频或电话进行便捷的医疗咨询，获得专业的医疗建议，适合轻微症状和随访。
            </ServiceDescription>
            <ServiceLink to="/chinese-telemedicine">
              了解更多 →
            </ServiceLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>全面健康检查</ServiceTitle>
            <ServiceDescription>
              我们提供全面的健康检查服务，帮助您及早发现健康隐患，制定个性化的健康管理方案。
            </ServiceDescription>
            <ServiceLink to="/chinese-healthcheck">
              了解更多 →
            </ServiceLink>
          </ServiceCard>
        </ServicesContainer>
      </Section>

      <Section style={{ backgroundColor: '#f5f5f5' }}>
        <SectionTitle>紧急医疗服务</SectionTitle>
        <Divider />
        <UrgentCareContainer>
          <UrgentTitle>24/7 全天候服务</UrgentTitle>
          <UrgentDescription>
            我们提供24小时医疗服务，包括夜间和周末。无论何时您需要医疗帮助，我们都可以为您提供专业的医疗护理。
          </UrgentDescription>
          <ButtonContainer>
            <CTAButton to="/chinese-nightdoctors">
              <FontAwesomeIcon icon={faClock} /> 夜间医生服务
            </CTAButton>
            <CTAButton to="/booking">
              <FontAwesomeIcon icon={faCalendarCheck} /> 预约上门医生
            </CTAButton>
          </ButtonContainer>
          <EmergencyNote>
            如遇生命危险，请立即拨打000
          </EmergencyNote>
        </UrgentCareContainer>
      </Section>

      <Section>
        <SectionTitle>服务区域</SectionTitle>
        <Divider />
        <ServicesContainer>
          <ServiceCard>
            <ServiceTitle>悉尼市中心</ServiceTitle>
            <ServiceDescription>
              覆盖悉尼CBD及周边区域，为城市中心的居民和游客提供便捷的医疗服务。
            </ServiceDescription>
            <ServiceLink to="/locations/SydneyCBD">
              了解更多 →
            </ServiceLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>东部地区</ServiceTitle>
            <ServiceDescription>
              服务范围包括Bondi、Randwick、Double Bay等悉尼东部地区，提供专业医疗上门服务。
            </ServiceDescription>
            <ServiceLink to="/locations/BondiArea">
              了解更多 →
            </ServiceLink>
          </ServiceCard>

          <ServiceCard>
            <ServiceTitle>内西区</ServiceTitle>
            <ServiceDescription>
              覆盖Waterloo、Rosebery等内西区地区，为当地居民提供便捷的医疗服务。
            </ServiceDescription>
            <ServiceLink to="/locations/Waterloo">
              了解更多 →
            </ServiceLink>
          </ServiceCard>
        </ServicesContainer>
      </Section>

      <Section>
        <SectionTitle>为什么选择我们</SectionTitle>
        <Divider />
        <UrgentCareContainer style={{ backgroundColor: 'white' }}>
          <ul style={{ textAlign: 'left', maxWidth: '800px', margin: '0 auto', lineHeight: '1.8' }}>
            <li><strong>澳大利亚注册医生</strong> - 由具有丰富临床经验的澳大利亚注册医生提供服务</li>
            <li><strong>通晓中文</strong> - 提供中文服务，消除语言障碍</li>
            <li><strong>全天候服务</strong> - 24/7全天候医疗服务，包括夜间和周末</li>
            <li><strong>上门服务</strong> - 医生直接上门，免去您奔波之苦</li>
            <li><strong>Medicare返款</strong> - 符合条件的服务可通过Medicare获得返款</li>
            <li><strong>预约灵活</strong> - 提供当天预约和预先安排的预约服务</li>
          </ul>
        </UrgentCareContainer>
      </Section>

      <Footer />
    </MainContainer>
  );
};

export default ChineseHomePage; 