import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PertussisWhoopingCough = () => {
  // SEO metadata
  const seoTitle = "Whooping Cough (Pertussis) in Children: Symptoms & Treatment | HomeDoc";
  const seoDescription = "Learn about pertussis symptoms, treatment options, and prevention strategies for whooping cough in children. Expert advice from Sydney's leading house call doctors.";
  const seoKeywords = "whooping cough, pertussis in children, pertussis treatment Sydney, whooping cough symptoms, house call doctor eastern suburbs, pertussis vaccine, whooping cough prevention";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Whooping Cough (Pertussis) in Children: Symptoms, Treatment & Prevention</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Whooping cough (pertussis) is a highly contagious respiratory infection that can be particularly severe in infants and young children. As a <strong>house call doctor serving Sydney's Eastern Suburbs</strong>, I regularly diagnose and manage pertussis cases, and have seen firsthand the importance of early recognition and appropriate treatment.
            </p>
            <p className="text-gray-700 mb-4">
              Despite Australia's high vaccination rates, pertussis outbreaks still occur cyclically, with cases rising every 3-4 years. The disease is caused by the bacteria <em>Bordetella pertussis</em> and is known for its characteristic "whoop" sound that can occur during violent coughing fits, although this classic symptom isn't always present, especially in very young babies.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/pertussis-whooping-cough.png" 
                alt="Doctor examining a child with suspected whooping cough" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Early diagnosis of whooping cough is essential, especially in infants
              </figcaption>
            </figure>
          </div>
        </div>
        <p className="text-gray-700 mb-6">
          This comprehensive guide covers pertussis symptoms, diagnosis, treatment options, and prevention strategies, including <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">when to call a house call doctor</Link> for your child. Understanding this serious but preventable disease is essential for all parents and caregivers.
        </p>
      </section>
      
      <section id="what-is-pertussis" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">What is Pertussis (Whooping Cough)?</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Pertussis, commonly known as whooping cough, is a highly contagious bacterial respiratory infection caused by <em>Bordetella pertussis</em>. Despite Australia's high vaccination rates, pertussis remains a significant public health concern, with outbreaks occurring cyclically every 3-4 years in New South Wales, including Sydney's Eastern Suburbs.
            </p>
            
            <div className="bg-indigo-50 p-5 rounded-lg mb-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Key Facts About Pertussis in Sydney</h3>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>NSW Health reports that pertussis is most dangerous for babies under 6 months of age, who are too young to be fully vaccinated</li>
                <li>In Sydney's Eastern Suburbs, pertussis cases often increase during winter and spring months</li>
                <li>The disease is spread through respiratory droplets when an infected person coughs or sneezes</li>
                <li>People are most contagious during the early stage (first 2-3 weeks) of infection</li>
                <li>A person with pertussis can infect up to 90% of susceptible household contacts</li>
                <li>In Eastern Suburbs childcare centers and schools, outbreaks can spread rapidly due to high population density</li>
                <li>According to the Sydney Children's Hospital Network, approximately 1 in 200 infants under 6 months who contract pertussis will die from the infection</li>
              </ul>
            </div>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Why Pertussis Remains a Concern in Sydney's Eastern Suburbs</h3>
            <p className="text-gray-700 mb-4">
              Despite Australia's National Immunisation Program providing free pertussis vaccines, several factors contribute to ongoing transmission in Sydney:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li><strong>Waning immunity:</strong> Protection from both vaccination and natural infection decreases over time, making booster doses essential</li>
              <li><strong>Vaccination gaps:</strong> Some areas in Sydney's Eastern Suburbs have pockets of under-vaccination</li>
              <li><strong>Delayed diagnosis:</strong> Early symptoms resemble a common cold, leading to delayed recognition and treatment</li>
              <li><strong>High population density:</strong> Eastern Suburbs' concentrated population in apartments and childcare facilities can accelerate transmission</li>
              <li><strong>International travel:</strong> The Eastern Suburbs' high proportion of international visitors can introduce pertussis from regions with different vaccination schedules</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Pertussis vs. Other Coughing Illnesses</h3>
            <p className="text-gray-700 mb-4">
              Pertussis is distinct from other respiratory infections in several important ways:
            </p>
            <div className="overflow-x-auto mb-6">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Feature</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Pertussis</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Common Cold</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Bronchiolitis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Cause</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Bacteria (<em>Bordetella pertussis</em>)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Viruses (rhinovirus, coronavirus, etc.)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Viruses (RSV, influenza, etc.)</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Duration</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Weeks to months (up to 100 days)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">7-10 days</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">1-2 weeks</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Cough characteristics</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Paroxysmal, may end with "whoop," often with vomiting</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Mild to moderate, productive</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Wheezing, crackles</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Fever</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Low-grade or absent during paroxysmal stage</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Low-grade, short duration</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Variable, often present</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Treatment</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Antibiotics (effective if given early)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Supportive care only</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Supportive care, sometimes bronchodilators</td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Prevention</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">Vaccination available and effective</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">No vaccine available</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">No specific vaccine (except influenza)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-6">
              <p className="text-gray-700">
                <strong>Sydney Eastern Suburbs Context:</strong> As a house call doctor practicing in Bondi, Randwick, Double Bay, and surrounding areas, I've observed that pertussis often presents differently in vaccinated children, who may have milder symptoms without the characteristic "whoop." This can lead to delayed diagnosis and continued community spread, particularly in areas with high childcare attendance like Bondi Junction and Randwick.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="symptoms-stages" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Stages and Symptoms of Whooping Cough</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Whooping cough progresses through three distinct stages, each with characteristic symptoms. Understanding these stages helps with early recognition and appropriate management, particularly important for Sydney's Eastern Suburbs residents where prompt treatment can prevent community spread.
            </p>
            
            <div className="grid md:grid-cols-3 gap-6 mb-6">
              <div className="bg-indigo-50 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">Stage 1: Catarrhal (Early) Stage</h3>
                <p className="text-gray-700 mb-2 text-sm">Duration: 1-2 weeks</p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Mild cold-like symptoms</li>
                  <li>Low-grade fever or no fever</li>
                  <li>Runny or stuffy nose</li>
                  <li>Mild, occasional cough</li>
                  <li>Mild sore throat</li>
                  <li>Fatigue</li>
                </ul>
                <p className="text-gray-700 mt-3 text-sm">
                  <strong>Important note:</strong> People are most contagious during this stage, but symptoms often go unrecognized as pertussis.
                </p>
              </div>
              
              <div className="bg-indigo-50 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">Stage 2: Paroxysmal Stage</h3>
                <p className="text-gray-700 mb-2 text-sm">Duration: 2-6 weeks</p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Rapid, intense coughing fits</li>
                  <li>Characteristic "whoop" sound when inhaling after coughing (more common in children than infants or adults)</li>
                  <li>Coughing fits followed by vomiting</li>
                  <li>Face turning red or blue during coughing episodes</li>
                  <li>Extreme fatigue after coughing episodes</li>
                  <li>Minimal or no fever</li>
                  <li>Symptoms worse at night</li>
                </ul>
                <p className="text-gray-700 mt-3 text-sm">
                  <strong>Sydney context:</strong> This stage often prompts parents in Eastern Suburbs to seek medical care, particularly house calls during nighttime episodes.
                </p>
              </div>
              
              <div className="bg-indigo-50 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">Stage 3: Convalescent Stage</h3>
                <p className="text-gray-700 mb-2 text-sm">Duration: 2-6 weeks or longer</p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Gradual decrease in coughing frequency and severity</li>
                  <li>Persistent cough, especially at night</li>
                  <li>Return of energy and normal activities</li>
                  <li>Susceptibility to other respiratory infections</li>
                  <li>Occasional recurrence of coughing fits with subsequent respiratory infections</li>
                </ul>
                <p className="text-gray-700 mt-3 text-sm">
                  <strong>Note:</strong> The cough may persist for up to 3 months, leading to the Chinese name for pertussis: "100-day cough."
                </p>
              </div>
            </div>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Age-Specific Symptoms of Pertussis</h3>
            <p className="text-gray-700 mb-4">
              Whooping cough presents differently depending on the age of the patient and whether they've been vaccinated. These age-specific presentations are particularly important for parents in Sydney's Eastern Suburbs to recognize:
            </p>
            
            <div className="overflow-x-auto mb-6">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Age Group</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Unique Symptoms</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Severity Risk</th>
                    <th className="px-6 py-3 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">When to Seek Care</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm font-medium text-gray-900">Newborns and Young Infants<br/>(0-6 months)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>May not develop the classic "whoop"</li>
                        <li>Apnea (brief pauses in breathing)</li>
                        <li>Difficulty feeding</li>
                        <li>Cyanosis (bluish discoloration)</li>
                        <li>Gasping or gagging</li>
                        <li>May present with seizures</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">Very High</span>
                      <p className="mt-1 text-xs">Highest risk of serious complications including pneumonia, seizures, brain damage, and death</p>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <p className="font-medium">Immediate medical attention for:</p>
                      <ul className="list-disc pl-4 space-y-1 text-xs">
                        <li>Any suspected symptoms</li>
                        <li>Difficulty breathing</li>
                        <li>Poor feeding</li>
                        <li>Lethargy</li>
                        <li>Any color changes</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm font-medium text-gray-900">Older Infants and Toddlers<br/>(6 months-4 years)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>More likely to have the characteristic "whoop"</li>
                        <li>Pronounced coughing fits</li>
                        <li>Post-cough vomiting common</li>
                        <li>May appear well between coughing episodes</li>
                        <li>Face may appear red or purple during coughing</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">Moderate to High</span>
                      <p className="mt-1 text-xs">Risk of pneumonia, ear infections, dehydration</p>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <p className="font-medium">Seek medical care for:</p>
                      <ul className="list-disc pl-4 space-y-1 text-xs">
                        <li>Prolonged coughing fits</li>
                        <li>Difficulty breathing</li>
                        <li>Signs of dehydration</li>
                        <li>Color changes during coughing</li>
                        <li>Lethargy or decreased activity</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm font-medium text-gray-900">School-age Children<br/>(5-12 years)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Often milder symptoms if vaccinated</li>
                        <li>May just have a persistent cough</li>
                        <li>"Whoop" may be less pronounced</li>
                        <li>Sleep disturbance common due to nighttime coughing</li>
                        <li>May report chest pain from coughing</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">Moderate</span>
                      <p className="mt-1 text-xs">Less likely to have severe complications, but can still experience pneumonia and rib fractures from coughing</p>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <p className="font-medium">Consult healthcare provider for:</p>
                      <ul className="list-disc pl-4 space-y-1 text-xs">
                        <li>Persistent cough lasting {'>'} 2 weeks</li>
                        <li>Coughing fits that interfere with daily activities</li>
                        <li>Post-cough vomiting</li>
                        <li>Difficulty sleeping due to cough</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm font-medium text-gray-900">Adolescents and Adults<br/>(13+ years)</td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <ul className="list-disc pl-4 space-y-1">
                        <li>Often presents as just a persistent cough</li>
                        <li>Classic "whoop" often absent</li>
                        <li>May report a choking sensation</li>
                        <li>Sleep disturbance from coughing</li>
                        <li>Sweating episodes following cough</li>
                      </ul>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">Low to Moderate</span>
                      <p className="mt-1 text-xs">Main complications include rib fractures, urinary incontinence, fainting, and sleep disturbance</p>
                    </td>
                    <td className="px-6 py-4 border-b border-gray-300 text-sm text-gray-700">
                      <p className="font-medium">Seek medical care if:</p>
                      <ul className="list-disc pl-4 space-y-1 text-xs">
                        <li>Cough persists {'>'} 3 weeks</li>
                        <li>Coughing to the point of vomiting</li>
                        <li>Difficulty breathing</li>
                        <li>Any pregnant woman with symptoms</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">Sydney Eastern Suburbs Clinical Patterns</h3>
              <p className="text-gray-700 mb-2">
                As a house call doctor working across Sydney's Eastern Suburbs, I've observed several local patterns:
              </p>
              <ul className="list-disc pl-6 text-gray-700 space-y-1">
                <li>Pertussis cases in Bondi, Coogee and surrounding beach suburbs often spike in early spring as social gathering increases</li>
                <li>Partially vaccinated children in the Eastern Suburbs typically present with milder, less characteristic symptoms that can be mistaken for persistent bronchitis</li>
                <li>Adolescents and adults often serve as reservoirs of infection, particularly in densely populated areas like Randwick and Bondi Junction</li>
                <li>The characteristic "whoop" is absent in approximately 50% of the pertussis cases I diagnose in the Eastern Suburbs</li>
                <li>Local childcare centers report that pertussis often spreads before the distinctive paroxysmal cough develops</li>
              </ul>
            </div>
            
            <div className="rounded-lg overflow-hidden shadow-md mb-6">
              <div className="relative">
                <div className="aspect-w-16 aspect-h-9">
                  <iframe 
                    className="w-full h-56 md:h-72" 
                    src="https://www.youtube.com/embed/S3oZrMGDMMw" 
                    title="What does whooping cough sound like?" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end">
                  <div className="p-4 text-white">
                    <h3 className="text-lg font-semibold">Example of Whooping Cough Sound</h3>
                    <p className="text-sm">Courtesy: Centers for Disease Control and Prevention</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="diagnosis" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Diagnosis of Pertussis</h2>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Diagnosing pertussis can be challenging, especially in vaccinated individuals or during the early stages when symptoms mimic a common cold. As a house call doctor in Sydney's Eastern Suburbs, I use a combination of clinical assessment and laboratory testing when appropriate.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Clinical Diagnosis</h3>
            <p className="text-gray-700 mb-3">
              Initial diagnosis often relies on:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>Characteristic cough pattern:</strong> Paroxysmal coughing fits, especially those followed by the distinctive "whoop" or vomiting</li>
              <li><strong>Duration of symptoms:</strong> Cough lasting more than two weeks without another clear cause</li>
              <li><strong>Exposure history:</strong> Contact with a known or suspected case of pertussis</li>
              <li><strong>Vaccination status:</strong> Incomplete vaccination or waning immunity (&gt;5 years since last booster)</li>
              <li><strong>Family/household patterns:</strong> Multiple family members with similar symptoms</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Laboratory Diagnosis</h3>
            <p className="text-gray-700 mb-3">
              Several tests are available in Sydney to confirm pertussis infection:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li><strong>PCR testing:</strong> Most common and reliable method; detects bacterial DNA from a nasopharyngeal swab</li>
              <li><strong>Culture:</strong> Growing the bacteria from a nasopharyngeal specimen; less sensitive than PCR but still used</li>
              <li><strong>Serology:</strong> Blood tests that detect antibodies; more useful in later stages of illness</li>
            </ul>
            
            <p className="text-gray-700 mb-4">
              According to NSW Health guidelines, PCR testing is most sensitive when performed within the first 3 weeks of illness. After 4 weeks, serology becomes more useful for diagnosis.
            </p>
          </div>
          
          <div className="md:w-1/3">
            <div className="bg-indigo-50 p-5 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-3">Sydney Testing Locations</h3>
              <p className="text-gray-700 mb-3 text-sm">
                In Sydney's Eastern Suburbs, pertussis testing is available at:
              </p>
              <ul className="list-disc pl-6 text-gray-700 text-sm space-y-2">
                <li><strong>Sydney Children's Hospital Randwick:</strong> Comprehensive testing available 24/7</li>
                <li><strong>Laverty Pathology:</strong> Multiple locations in Bondi, Randwick, and Double Bay</li>
                <li><strong>Douglass Hanly Moir Pathology:</strong> Locations throughout the Eastern Suburbs</li>
                <li><strong>HomeDoc house call service:</strong> Nasopharyngeal swab collection in your home</li>
              </ul>
              <p className="text-gray-700 mt-3 text-sm italic">
                Most pathology services require a referral from a GP or specialist.
              </p>
            </div>
            
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-4 py-2 border-b border-gray-300">
                <h4 className="font-semibold text-gray-900">Testing Timeline</h4>
              </div>
              <div className="p-4">
                <div className="mb-3">
                  <span className="inline-block w-24 font-medium text-gray-700">0-3 weeks:</span>
                  <span className="text-gray-700 text-sm">PCR testing most accurate</span>
                </div>
                <div className="mb-3">
                  <span className="inline-block w-24 font-medium text-gray-700">2-8 weeks:</span>
                  <span className="text-gray-700 text-sm">Culture less reliable</span>
                </div>
                <div>
                  <span className="inline-block w-24 font-medium text-gray-700">4+ weeks:</span>
                  <span className="text-gray-700 text-sm">Serology more useful</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
          <div className="bg-indigo-600 px-6 py-4">
            <h3 className="text-xl font-semibold text-white">Diagnostic Challenges in Sydney's Eastern Suburbs</h3>
          </div>
          <div className="p-6">
            <p className="text-gray-700 mb-4">
              Several factors make diagnosing pertussis particularly challenging in our local area:
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Atypical Presentations</h4>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>High vaccination rates in the Eastern Suburbs result in milder, atypical cases</li>
                  <li>Many patients present without the classic "whoop"</li>
                  <li>Symptoms often overlap with other respiratory conditions common in coastal areas</li>
                  <li>Prior antibiotic use can modify symptoms and reduce test sensitivity</li>
                </ul>
              </div>
              <div>
                <h4 className="font-semibold text-gray-900 mb-2">Testing Considerations</h4>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Delayed testing often yields false negatives</li>
                  <li>Many patients seek care after antibiotics have already been started</li>
                  <li>Testing may not change management in mild cases</li>
                  <li>Test results may take 1-3 days in busy periods</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
        <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-6">
          <p className="text-gray-700">
            <strong>Important Note:</strong> NSW Health requires notification of confirmed pertussis cases. As a medical practitioner in Sydney, I report all confirmed cases to help monitor outbreaks and protect vulnerable community members, particularly in childcare settings across the Eastern Suburbs.
          </p>
        </div>
      </section>
      
      <section id="treatment-options" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Treatment Options for Whooping Cough</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Treatment of pertussis typically involves a combination of antibiotics and supportive care. The choice of antibiotic depends on the severity of the infection and the age of the patient.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Antibiotic Treatment</h3>
            <p className="text-gray-700 mb-3">
              Macrolide antibiotics (azithromycin, clarithromycin, erythromycin) are effective in eliminating the bacteria and reducing contagiousness. The duration of treatment depends on the antibiotic choice and the severity of the infection.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Supportive Care</h3>
            <p className="text-gray-700 mb-3">
              Adequate hydration, small frequent meals, humidified air, and monitoring breathing are essential during the illness.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Cough Management</h3>
            <p className="text-gray-700 mb-3">
              Calm environment, minimizing triggers, and proper positioning during coughing fits can help manage symptoms.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Hospitalization</h3>
            <p className="text-gray-700 mb-3">
              For severe cases, especially in infants, hospitalization may be necessary for oxygen therapy, suctioning, monitoring, and IV fluids.
            </p>
          </div>
        </div>
      </section>
      
      <section id="complications" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Complications and Risk Factors</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Pertussis can lead to various complications, particularly in infants and young children. Understanding these complications and risk factors is crucial for appropriate management.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Complications</h3>
            <p className="text-gray-700 mb-3">
              Complications of pertussis can range from mild to severe and may include:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li>Pneumonia</li>
              <li>Ear infections</li>
              <li>Dehydration</li>
              <li>Seizures</li>
              <li>Brain damage</li>
              <li>Death</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Risk Factors</h3>
            <p className="text-gray-700 mb-3">
              Certain factors can increase the risk of severe complications:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li>Infants under 6 months of age</li>
              <li>Incomplete vaccination</li>
              <li>Delayed treatment</li>
              <li>Family history of pertussis</li>
              <li>Residential proximity to other cases</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="prevention-vaccination" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Pertussis Prevention and Vaccination</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Vaccination is the most effective way to prevent pertussis. The DTaP vaccine is recommended for all children, and boosters are given as part of the National Immunisation Program.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Vaccination Schedule</h3>
            <p className="text-gray-700 mb-3">
              The DTaP vaccine is given in a series of 5 doses:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li>2 months</li>
              <li>4 months</li>
              <li>6 months</li>
              <li>15-18 months</li>
              <li>4-6 years</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Booster Doses</h3>
            <p className="text-gray-700 mb-3">
              Booster doses are recommended at 12-15 months and 4-6 years.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Precautions</h3>
            <p className="text-gray-700 mb-3">
              Individuals with weakened immune systems or those who have received a single dose of DTaP should consult with their healthcare provider about vaccination.
            </p>
          </div>
        </div>
      </section>
      
      <section id="when-to-call-doctor" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">When to Call a Doctor for Whooping Cough</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              It's important to seek medical attention promptly if you suspect pertussis, especially in infants and young children. Prompt treatment can prevent serious complications and community spread.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Symptoms to Watch For</h3>
            <p className="text-gray-700 mb-3">
              If your child experiences any of the following symptoms, seek immediate medical attention:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-4 space-y-2">
              <li>Difficulty breathing</li>
              <li>Blue or gray lips or skin</li>
              <li>Seizures</li>
              <li>Fever lasting more than 48 hours</li>
              <li>Persistent vomiting</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">When to Call a Doctor</h3>
            <p className="text-gray-700 mb-3">
              Call your doctor or the local health unit if you suspect pertussis, especially if your child is under 6 months old or has not been vaccinated.
            </p>
          </div>
        </div>
      </section>
      
      <section id="faq" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Frequently Asked Questions about Pertussis</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Here are some common questions about pertussis and their answers:
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Can pertussis be fatal?</h3>
            <p className="text-gray-700 mb-3">
              Yes, pertussis can be fatal, particularly in infants under 6 months of age.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Is pertussis contagious?</h3>
            <p className="text-gray-700 mb-3">
              Pertussis is highly contagious and can be spread through respiratory droplets when an infected person coughs or sneezes.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Can pertussis be prevented with antibiotics?</h3>
            <p className="text-gray-700 mb-3">
              Antibiotics are effective in treating pertussis, but they cannot prevent the infection. Vaccination is the most effective way to prevent pertussis.
            </p>
          </div>
        </div>
      </section>
    </>
  );
  
  // Placeholder for the rest of the content
  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'What is Pertussis (Whooping Cough)?', anchor: 'what-is-pertussis' },
    { title: 'Stages and Symptoms of Whooping Cough', anchor: 'symptoms-stages' },
    { title: 'Diagnosis of Pertussis', anchor: 'diagnosis' },
    { title: 'Treatment Options for Whooping Cough', anchor: 'treatment-options' },
    { title: 'Complications and Risk Factors', anchor: 'complications' },
    { title: 'Pertussis Prevention and Vaccination', anchor: 'prevention-vaccination' },
    { title: 'When to Call a Doctor for Whooping Cough', anchor: 'when-to-call-doctor' },
    { title: 'Frequently Asked Questions about Pertussis', anchor: 'faq' }
  ];

  const symptoms = [
    'Initial cold-like symptoms (runny nose, mild cough, low-grade fever)',
    'Paroxysmal coughing fits that may end in a "whoop" sound',
    'Vomiting after coughing episodes',
    'Cyanosis (bluish discoloration) during coughing fits',
    'Apnea (breathing pauses) in infants instead of coughing',
    'Exhaustion after coughing episodes',
    'Persistent cough lasting weeks to months',
    'Minimal or no fever during paroxysmal stage',
    'More severe symptoms in infants under 6 months',
    'Gradual recovery with decreasing frequency of coughing fits'
  ];

  const treatments = [
    {
      stage: 'Antibiotic Treatment',
      description: 'Macrolide antibiotics (azithromycin, clarithromycin, erythromycin) to eliminate the bacteria and reduce contagiousness',
      duration: '5-14 days depending on antibiotic choice'
    },
    {
      stage: 'Supportive Care',
      description: 'Adequate hydration, small frequent meals, humidified air, monitoring breathing',
      duration: 'Throughout illness (especially during paroxysmal stage)'
    },
    {
      stage: 'Cough Management',
      description: 'Calm environment, minimizing triggers, proper positioning during coughing fits',
      duration: 'Throughout paroxysmal stage (1-6 weeks)'
    },
    {
      stage: 'Hospitalization',
      description: 'For severe cases, especially in infants: oxygen therapy, suctioning, monitoring, IV fluids',
      duration: 'As needed based on severity (typically 3-7 days for hospitalized cases)'
    }
  ];

  const references = [
    {
      authors: 'Australian Government Department of Health',
      title: 'Pertussis (Whooping Cough) CDNA National Guidelines for Public Health Units',
      journal: 'Communicable Diseases Network Australia',
      year: '2023',
      doi: ''
    },
    {
      authors: 'National Centre for Immunisation Research and Surveillance (NCIRS)',
      title: 'Pertussis Factsheet',
      journal: 'NCIRS',
      year: '2023',
      doi: ''
    },
    {
      authors: 'The Royal Children\'s Hospital Melbourne',
      title: 'Clinical Practice Guidelines: Pertussis',
      journal: 'RCH',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Yeung KHT, et al.',
      title: 'An update of the global burden of pertussis in children younger than 5 years: a modelling study',
      journal: 'Lancet Infectious Diseases',
      year: '2017',
      doi: '17(9):974-980'
    },
    {
      authors: 'NSW Health',
      title: 'Pertussis Control Guideline',
      journal: 'NSW Government',
      year: '2022',
      doi: ''
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/pertussis-whooping-cough.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/pertussis-whooping-cough-children" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/pertussis-whooping-cough.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/pertussis-whooping-cough-children" />
      </Helmet>
      
      <MedicalBlogTemplate
        title="Whooping Cough (Pertussis) in Children: Symptoms, Treatment & Prevention"
        subtitle="A Sydney pediatric house call doctor's guide to recognizing, managing, and preventing this serious respiratory infection"
        heroImage="/images/pertussis-whooping-cough.png"
        publishDate="June 19, 2024"
        readTime={14}
        content={content}
        category="Child Health"
        tags={['Pertussis', 'Whooping Cough', 'Respiratory Infection', 'Pediatric Care', 'Vaccination', 'House Call Doctor', 'Sydney']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={true}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export default PertussisWhoopingCough; 