import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CategoryBlogCarousel from '../components/CategoryBlogCarousel';
import DirectAnswerBlock from '../components/DirectAnswerBlock';

const PrivateServices = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Private Doctor Sydney | VIP Medical Concierge | Celebrity & Executive Healthcare</title>
        <meta name="description" content="Elite private doctor services in Sydney for high-profile individuals, business executives, and international travelers. Discreet medical care for jets, yachts, film sets, and luxury hotels. 24/7 concierge service with Dr. Terry Nguyen, Sydney's premier private physician." />
        <meta name="keywords" content="private doctor sydney, celebrity doctor australia, VIP medical services, concierge doctor, executive health program, private jet doctor, luxury healthcare, film set medical services, billionaire healthcare, yacht medical service, discrete medical care sydney, personal physician, sydney private GP, high-net-worth healthcare, premium medical concierge" />
        <link rel="canonical" href="https://homedoc.com.au/private" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalBusiness",
            "name": "HomeDoc Private Doctor Services",
            "description": "Premium private doctor services for high-profile individuals, executives, and their families in Sydney and internationally.",
            "url": "https://homedoc.com.au/private",
            "logo": "https://homedoc.com.au/images/logos/privatedoctorservices_logo.png",
            "image": "https://homedoc.com.au/images/drterrynguyen_medicaldoctor.png",
            "priceRange": "$$$",
            "telephone": "+61-2-XXXX-XXXX",
            "email": "private@homedoc.com.au",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sydney",
              "addressRegion": "NSW",
              "postcode": "2000",
              "addressCountry": "Australia"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "-33.8688",
              "longitude": "151.2093"
            },
            "medicalSpecialty": [
              "Executive Health",
              "Travel Medicine",
              "Private Medical Concierge",
              "VIP Healthcare"
            ],
            "availableService": [
              {
                "@type": "MedicalProcedure",
                "name": "Private Jet Medical Accompaniment",
                "description": "Physician travel companion services for private flights"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Event Medical Coverage",
                "description": "Discreet medical services for VIP events and productions"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Executive Health Assessment",
                "description": "Comprehensive health evaluations for high-profile individuals"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Luxury Yacht Medical Services",
                "description": "Dedicated physician care for yacht charters and maritime travel"
              },
              {
                "@type": "MedicalProcedure",
                "name": "Film and Production Medical Support",
                "description": "On-set medical services and cast clearances for productions"
              }
            ],
            "sameAs": [
              "https://www.linkedin.com/company/homedoc",
              "https://www.instagram.com/homedoc.au/"
            ]
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What services does a private doctor in Sydney provide?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "A private doctor in Sydney offers personalized, discreet healthcare including 24/7 on-call services, travel medicine, executive health assessments, and medical support for private events and productions. Dr. Terry Nguyen provides bespoke care including private jet medical accompaniment, yacht medical services, and global health coordination."
                }
              },
              {
                "@type": "Question",
                "name": "How does private doctor concierge service differ from regular healthcare?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Private doctor concierge services offer exclusive, personalized healthcare with immediate access, extended consultations, and complete privacy. Unlike regular healthcare, our service travels with you globally, provides 24/7 direct physician contact, and offers customized care tailored to high-profile individuals' unique needs and lifestyles."
                }
              },
              {
                "@type": "Question",
                "name": "Can a private doctor travel internationally with clients?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, Dr. Terry Nguyen provides international medical accompaniment services, traveling with clients on private jets, yachts, and expeditions worldwide. This includes pre-travel health assessments, managing medical conditions during travel, emergency response capabilities, and coordination with international healthcare facilities."
                }
              },
              {
                "@type": "Question",
                "name": "What is an Executive Health Program?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our Executive Health Programs provide comprehensive health management for business leaders and high-profile individuals, including advanced diagnostic assessments, personalized health optimization strategies, and continuous monitoring. These programs address the unique health challenges of executives, focusing on cognitive performance, stress management, and preventative care to enhance both personal wellbeing and professional effectiveness."
                }
              },
              {
                "@type": "Question",
                "name": "How are medical services provided for film and television productions?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Our on-set medical services include cast insurance clearances, ongoing health monitoring during production, immediate care for injuries or illness, and confidential health management for cast and crew. We work discreetly with production teams to ensure health concerns don't disrupt filming schedules, with services available throughout Sydney and across Australia for location shoots."
                }
              }
            ]
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "url": "https://homedoc.com.au/blog/private-jet-medical-services",
                "name": "Private Jet Medical Services"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "url": "https://homedoc.com.au/blog/five-star-hotel-medical-services",
                "name": "Five-Star Hotel Medical Services"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "url": "https://homedoc.com.au/blog/on-set-medical-services",
                "name": "On-Set Medical Services for Film & TV Productions"
              },
              {
                "@type": "ListItem",
                "position": 4,
                "url": "https://homedoc.com.au/blog/executive-health-programs",
                "name": "Executive Health Programs"
              },
              {
                "@type": "ListItem",
                "position": 5,
                "url": "https://homedoc.com.au/blog/yacht-medical-services",
                "name": "Yacht Medical Services"
              },
              {
                "@type": "ListItem",
                "position": 6,
                "url": "https://homedoc.com.au/blog/international-medical-support",
                "name": "International Medical Support"
              },
              {
                "@type": "ListItem",
                "position": 7,
                "url": "https://homedoc.com.au/blog/vip-home-visits",
                "name": "VIP Home Visits"
              }
            ]
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="/images/drtez_Panoramic_view_from_inside_a_penthouse_with_soft_mornin_5f661f61-add1-438b-9e3a-b76803127174_0.png" 
            alt="Private Medical Concierge Services by Dr. Terry Nguyen" 
            className="w-full h-full object-cover brightness-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-[#1A1A1A]/70 to-[#1A1A1A]"></div>
        </div>
        
        <div className="relative max-w-6xl mx-auto px-4 py-24 sm:py-32">
          <img 
            src="/images/logos/privatedoctorservices_logo.png" 
            alt="Private Doctor Services Logo" 
            className="h-24 mb-8 mx-auto"
          />
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-3/5 mb-12 md:mb-0">
              <h1 className="text-5xl font-light text-white mb-6">Elite Medical Support.<br />Wherever You Are.</h1>
              <h2 className="text-2xl font-light text-[#DDC6A8] mb-8">Private physician care for those who require discretion, mobility, and exceptional expertise.</h2>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link 
                  to="/private-consultation" 
                  className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-xl text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition-all"
                >
                  Request Immediate Consultation
                </Link>
                <a 
                  href="#services" 
                  className="inline-flex items-center justify-center px-6 py-3 border border-[#DDC6A8] text-base font-medium rounded-md text-[#DDC6A8] hover:bg-[#DDC6A8]/10 transition-all"
                >
                  View Services
                </a>
              </div>
            </div>
            <div className="md:w-2/5">
              <img 
                src="/images/drterrynguyen_medicaldoctor.png" 
                alt="Dr. Terry Nguyen - Private Medical Concierge" 
                className="w-full h-auto rounded-xl shadow-2xl"
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Rest of the content will be added in subsequent edits */}
      
      {/* Introduction Section */}
      <div className="content-wrapper relative z-10 bg-[#1A1A1A]">
        <div className="max-w-6xl mx-auto px-4 py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
            <div>
              <span className="text-[#DDC6A8] font-medium mb-2 block">GLOBAL MEDICAL CONCIERGE</span>
              <h2 className="text-3xl font-light text-white mb-6">Discreet, Personalized Medical Care for the Discerning</h2>
              <p className="text-white/80 mb-6 leading-relaxed">
                Welcome to HomeDoc Private Services, Sydney's premier provider of discreet medical care for individuals who require exceptional attention, privacy, and expertise. Led by Dr. Terry Nguyen, our bespoke medical concierge service caters to high-profile individuals, families, and international travelers seeking physician services that transcend conventional healthcare.
              </p>
              <p className="text-white/80 mb-6 leading-relaxed">
                Whether you require a personal physician for <span className="text-white">private jet travel</span>, confidential medical support for <span className="text-white">cast and crew during productions</span>, or comprehensive health management for your family while in Sydney, our service is designed to meet your unique needs with sophistication and absolute discretion.
              </p>
              <div className="flex flex-wrap gap-3 mb-8">
                <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Global Reach</span>
                <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">Absolute Discretion</span>
                <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">24/7 Availability</span>
                <span className="px-3 py-1 bg-[#DDC6A8]/10 rounded-full text-sm">World-Class Expertise</span>
              </div>
            </div>
            <div>
              <img 
                src="/images/drtez_Modern_coffee_table_with_open_MacBook_stethoscope_and_g_27ec4ad2-c21a-4429-9e1b-379509dd855a_2.png" 
                alt="Premium private doctor services in Sydney" 
                className="w-full h-auto rounded-xl shadow-2xl"
              />
            </div>
          </div>
        </div>
      </div>
      
      {/* Core Services Section */}
      <div id="services" className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16">
          <div className="text-center mb-16">
            <span className="text-[#DDC6A8] font-medium mb-2 block">BESPOKE MEDICAL SERVICES</span>
            <h2 className="text-4xl font-light text-white mb-6">Premium Private Doctor Services in Sydney</h2>
            <p className="text-xl text-white/80 max-w-3xl mx-auto">
              Our exclusive private physician offerings are designed for high-profile individuals, executives, and celebrities who value privacy, time, and exceptional medical care.
            </p>
            <div className="flex flex-wrap justify-center gap-3 mt-4">
              <span className="px-3 py-1 bg-[#DDC6A8]/10 text-[#DDC6A8] rounded-full text-sm">Executive Health Programs</span>
              <span className="px-3 py-1 bg-[#DDC6A8]/10 text-[#DDC6A8] rounded-full text-sm">Concierge Doctor</span>
              <span className="px-3 py-1 bg-[#DDC6A8]/10 text-[#DDC6A8] rounded-full text-sm">Private Jet Medical</span>
              <span className="px-3 py-1 bg-[#DDC6A8]/10 text-[#DDC6A8] rounded-full text-sm">Film Set Healthcare</span>
              <span className="px-3 py-1 bg-[#DDC6A8]/10 text-[#DDC6A8] rounded-full text-sm">Yacht Medical Services</span>
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
            {/* Service 1 */}
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:shadow-2xl transition-all duration-300">
              <div className="h-72 overflow-hidden relative">
                <img 
                  src="/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png" 
                  alt="On-Call Private Physician for travel and events" 
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent opacity-70"></div>
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">On-Call Private Physician</h3>
                <p className="text-white/80 mb-6">
                  Dr. Terry Nguyen is available for global travel with clients, providing medical support during international journeys on private jets, yachts, or expeditions. Ensuring your health is managed seamlessly while you focus on your priorities.
                </p>
                <ul className="text-white/80 space-y-3 mb-6">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Private jet medical accompaniment</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Yacht medical services</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Remote expedition medical support</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Personalized medical kit preparation</span>
                  </li>
                </ul>
              </div>
            </div>
            
            {/* Service 2 */}
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:shadow-2xl transition-all duration-300">
              <div className="h-72 overflow-hidden relative">
                <img 
                  src="/images/drtez_Modern_five-star_hotel_suite_desk_with_open_leather_fol_a6b8997e-a733-4f52-9ab0-f42800e7e2e0_3.png" 
                  alt="Event and Tour Medical Coverage" 
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent opacity-70"></div>
              </div>
              <div className="p-8">
                <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Event & Production Medical Services</h3>
                <p className="text-white/80 mb-6">
                  Comprehensive medical oversight for private events, film and television productions, and touring performers. Ensuring the health and wellbeing of cast, crew, and guests with unobtrusive, professional care.
                </p>
                <ul className="text-white/80 space-y-3 mb-6">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Cast and crew medical examinations</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">On-set emergency medical response</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">VIP event medical staffing</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3">Tour medical coordination</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          {/* Additional services will be added in subsequent edits */}
          
          {/* Secondary Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {/* Service 3 */}
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">VIP Family Health Concierge</h3>
              <p className="text-white/80 mb-4">
                Comprehensive health management for high-profile families visiting or residing in Sydney. Ensuring continuity of care with elegance and attention to detail.
              </p>
              <ul className="text-white/80 space-y-2 mb-6 text-sm">
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Family medical coordination</span>
                </li>
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Private specialist referrals</span>
                </li>
              </ul>
            </div>
            
            {/* Service 4 */}
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Cast & Crew Medical Exams</h3>
              <p className="text-white/80 mb-4">
                Specialized experience with high-profile productions, providing discreet medical clearances and on-set healthcare for film, television, and entertainment projects in Sydney.
              </p>
              <ul className="text-white/80 space-y-2 mb-6 text-sm">
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Insurance clearance exams</span>
                </li>
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Confidential health monitoring</span>
                </li>
              </ul>
            </div>
            
            {/* Service 5 */}
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">International Escort & Repatriation</h3>
              <p className="text-white/80 mb-4">
                Physician accompaniment for international medical repatriation, ensuring the highest standard of care during transit for clients requiring medical support while traveling to or from Australia.
              </p>
              <ul className="text-white/80 space-y-2 mb-6 text-sm">
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">Medical repatriation services</span>
                </li>
                <li className="flex items-start">
                  <svg className="flex-shrink-0 h-5 w-5 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="ml-3">In-flight medical monitoring</span>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
      
      {/* Common Questions Section */}
      <div className="content-wrapper relative z-10 bg-[#1A1A1A]">
        <div className="max-w-6xl mx-auto px-4 py-16">
          <div className="text-center mb-12">
            <span className="text-[#DDC6A8] font-medium mb-2 block">EXPERT INSIGHTS</span>
            <h2 className="text-3xl font-light text-white mb-6">Common Questions About Premium Medical Services</h2>
            <p className="text-lg text-white/80 max-w-3xl mx-auto">
              Direct answers to frequently asked questions about our exclusive medical concierge services.
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            <DirectAnswerBlock 
              question="What makes yacht medical services different from standard healthcare?" 
              answer="Yacht medical services address unique maritime challenges including distance from medical infrastructure, jurisdictional complexities with medications, and maintained privacy for high-profile individuals. Our specialized services include pre-journey planning, onboard medical infrastructure setup, physician accompaniment, and seamless coordination with international healthcare facilities."
              relatedLink="/blog/yacht-medical-services"
              relatedLinkText="Explore Yacht Medical Services"
            />
            
            <DirectAnswerBlock 
              question="How do executive assistants arrange VIP home visits for their principals?" 
              answer="Executive assistants can arrange VIP home visits through our dedicated private client coordinator via a priority channel, typically securing availability within 24 hours for non-urgent matters and same-day service for acute concerns. The process includes pre-visit coordination, discreet arrival protocols, comprehensive consultations, and immediate documentation with secure digital summaries."
              relatedLink="/blog/vip-home-visits"
              relatedLinkText="Learn About VIP Home Visits"
            />
            
            <DirectAnswerBlock 
              question="What international medical support is available for UHNW clients traveling globally?" 
              answer="Our international medical support includes pre-travel health planning, global provider network access, expedited specialist referrals, remote consultation services, medication management across borders, and emergency evacuation coordination. We offer continuous care regardless of location through secure telehealth platforms and ensure medical privacy across jurisdictions."
              relatedLink="/blog/international-medical-support"
              relatedLinkText="View International Support Details"
            />
          </div>
        </div>
      </div>
      
      {/* Final CTA */}
      <div className="content-wrapper relative z-10 bg-[#1A1A1A]">
        <div className="max-w-6xl mx-auto px-4 py-16 text-center">
          <h2 className="text-3xl font-light text-white mb-6">Experience Exceptional Private Medical Care</h2>
          <p className="text-xl text-white/80 max-w-3xl mx-auto mb-8">
            Contact us to discuss your requirements in complete confidence.
          </p>
          <Link
            to="/private-consultation"
            className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
          >
            Request Private Consultation
          </Link>
        </div>
      </div>
      
      {/* Featured Insights Section */}
      <div className="content-wrapper relative z-10 bg-[#1A1A1A]">
        <div className="max-w-6xl mx-auto px-4 py-20">
          <div className="text-center mb-16">
            <span className="text-[#DDC6A8] font-medium mb-2 block">EXPERT INSIGHTS</span>
            <h2 className="text-4xl font-light text-white mb-6">Featured Premium Services</h2>
            <p className="text-xl text-white/80 max-w-3xl mx-auto">
              Discover our specialty concierge medical services designed for high-profile individuals with the most demanding lifestyles and unique healthcare needs.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Featured Article 1 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/yacht-medical-services" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png" 
                    alt="Yacht Medical Services" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Maritime Healthcare</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">Yacht Medical Services: Healthcare for Maritime Luxury</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Dedicated physician services for luxury yacht owners, charterers and guests. Advanced medical care tailored for maritime environments with discreet physician accompaniment.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            
            {/* Featured Article 2 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/international-medical-support" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_Panoramic_view_from_inside_a_penthouse_with_soft_mornin_5f661f61-add1-438b-9e3a-b76803127174_0.png" 
                    alt="International Medical Support" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Global Healthcare</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">International Medical Support: Global Healthcare Coordination</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Global healthcare coordination for executives and high-profile individuals traveling internationally. Medical repatriation, emergency response, and worldwide physician network access.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            
            {/* Featured Article 3 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/vip-home-visits" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_Elegant_home_office_with_medical_bag_on_desk_and_Sydney_f6b6d90d-36c4-4de7-97e9-64b1e3afdc30_3.png" 
                    alt="VIP Home Visits" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Residence Care</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">VIP Home Visits: Medical Care in Your Private Residence</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Exclusive home visit medical services for high-profile individuals and families in Sydney. Comprehensive care delivered with discretion in the privacy of your residence.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          
          {/* New Executive Health Programs Link */}
          <div className="mt-10 text-center">
            <Link to="/blog/executive-health-programs" className="inline-flex items-center px-5 py-3 border border-[#DDC6A8] rounded-lg text-[#DDC6A8] bg-[#2A2A2A]/40 hover:bg-[#DDC6A8]/10 transition-all group">
              <span>Discover our Executive Health Programs for Business Leaders</span>
              <svg className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      
      {/* Blog Carousel Section */}
      <div className="py-16 bg-[#1A1A1A]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <span className="text-[#DDC6A8] font-medium mb-2 block">PREMIUM CONTENT</span>
            <h2 className="text-3xl font-bold text-white mb-6">Luxury Healthcare & Private Doctor Insights</h2>
            <p className="text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Discover our expert insights on private medical care, executive health programs, and exclusive concierge healthcare services for Sydney's most discerning clients.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Featured Article 1 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/private-jet-medical-services" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_private_jet_on_runway_--ar_169_--v_6.1_c580842d-d85f-49b2-9159-a5887075f3b8_3.png" 
                    alt="Private Jet Medical Services" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Travel Medicine</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">Private Jet Medical Services: In-Flight Healthcare for Global Travelers</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Expert medical accompaniment for private aviation clients worldwide. Comprehensive pre-flight assessments, in-flight medical support, and emergency response.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            
            {/* Featured Article 2 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/five-star-hotel-medical-services" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_Modern_five-star_hotel_suite_desk_with_open_leather_fol_a6b8997e-a733-4f52-9ab0-f42800e7e2e0_3.png" 
                    alt="Five-Star Hotel Medical Services" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Luxury Healthcare</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">Five-Star Hotel Medical Services in Sydney</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Exclusive medical care for guests of Sydney's premium hotels. Discreet in-room consultations, 24/7 concierge doctor services, and seamless coordination with hotel staff.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            
            {/* Featured Article 3 */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/on-set-medical-services" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/drtez_Close-up_of_a_luxury_smartphone_with_a_contact_screen_s_3879fbc9-2d8d-4acf-960e-5eb0c9efa2f5_3.png" 
                    alt="On-Set Medical Services" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Entertainment Industry</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">On-Set Medical Services for Film & TV Productions</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Specialized medical support for film and television productions in Sydney and throughout Australia. Comprehensive on-set healthcare, wellness monitoring, and emergency response.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
            
            {/* Executive Health Programs Article */}
            <div className="bg-[#2A2A2A]/60 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:translate-y-[-8px] transition-all duration-300">
              <Link to="/blog/executive-health-programs" className="block">
                <div className="h-48 overflow-hidden">
                  <img 
                    src="/images/healthcheck2.png" 
                    alt="Executive Health Programs for Business Leaders" 
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  />
                </div>
                <div className="p-6">
                  <span className="bg-[#DDC6A8]/20 text-[#DDC6A8] text-xs uppercase tracking-wide font-medium py-1 px-2 rounded-full">Executive Health</span>
                  <h3 className="text-xl font-medium text-white mt-3 mb-3 group-hover:text-[#DDC6A8] transition-colors">Executive Health Programs: Tailored Medical Care for Business Leaders</h3>
                  <p className="text-white/70 text-sm mb-4 line-clamp-3">
                    Comprehensive health management programs designed specifically for executives and business leaders. Proactive assessments, personalized care plans, and 24/7 accessibility.
                  </p>
                  <div className="flex items-center text-[#DDC6A8] text-sm font-medium">
                    Read More
                    <svg className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default PrivateServices; 