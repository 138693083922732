import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MarineInjuriesSydney = () => {
  // SEO metadata
  const seoTitle = "Marine Injuries in Sydney – First Aid and Emergency Care | HomeDoc";
  const seoDescription = "Learn how to identify and treat marine injuries like jellyfish stings, sea urchin wounds, and stingray injuries in Sydney. Immediate care tips from Dr. Terry Nguyen.";
  const seoKeywords = "marine injuries Sydney, bluebottle stings Bondi, stingray injury Eastern Suburbs, sea urchin treatment Sydney, house call doctor Bondi, telemedicine Eastern Suburbs, Dr Terry Nguyen, after hours doctor Eastern Beaches";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Marine Injuries in Sydney's Eastern Suburbs: Expert Treatment Guide</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Sydney's iconic Eastern Suburbs beaches—from the world-famous Bondi to the family-friendly shores of Coogee and the picturesque Bronte—attract thousands of swimmers, surfers, and beachgoers daily. While these stunning coastal locations offer incredible recreational opportunities, they also bring potential encounters with various marine creatures that can cause painful injuries requiring prompt medical attention.
            </p>
            <p className="text-gray-700 mb-4">
              As a dedicated <strong>house call doctor serving Sydney's Eastern Suburbs</strong> since 2018, I (<Link to="/about" className="text-indigo-600 hover:text-indigo-800 font-medium">Dr. Terry Nguyen</Link>) frequently treat marine injuries in patients' homes throughout <strong>Bondi, Bronte, Coogee, Tamarama, Maroubra, and surrounding suburbs</strong>. This specialized mobile medical service provides immediate relief without the need for uncomfortable trips to emergency departments—particularly beneficial when dealing with the intense pain of marine stings and injuries.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/sydney-beaches-marine-injuries.png" 
                alt="Marine creatures causing injuries in Sydney's Eastern Suburbs beaches" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Common marine injuries at Sydney beaches require prompt medical care
              </figcaption>
            </figure>
          </div>
        </div>
        <p className="text-gray-700 mb-6">
          This comprehensive guide outlines the most common marine injuries encountered at our local Eastern Suburbs beaches, providing expert medical advice on immediate first aid, when to seek professional care, and how <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">house call medical services</Link> or <Link to="/telemedicine" className="text-indigo-600 hover:text-indigo-800 font-medium">telemedicine consultations</Link> can help you receive prompt treatment—often within 30-60 minutes of booking.
        </p>
      </section>
      
      <section id="common-marine-injuries">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Common Marine Injuries in Sydney's Eastern Suburbs</h2>
        <p className="text-gray-700 mb-6">
          Sydney's coastal waters host a variety of marine creatures that can cause injuries. Below are the most commonly encountered marine injuries in the Eastern Suburbs beaches, along with their symptoms and immediate treatment approaches.
        </p>
      </section>
      
      <section id="jellyfish-stings">
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">Jellyfish Stings at Sydney Beaches</h3>
        <p className="text-gray-700 mb-4">
          Jellyfish stings are among the most common marine injuries in Sydney's Eastern Suburbs, particularly during summer months. While Australia's northern waters host the potentially lethal box jellyfish, Sydney waters typically contain less dangerous species, with bluebottles (Physalia species) being the most prevalent. For more information on other summer health concerns, see our guide on <Link to="/blog/child-nutrition-guidelines" className="text-indigo-600 hover:text-indigo-800">summertime health for children</Link>.
        </p>
        
        <h4 className="text-lg font-bold text-gray-800 mt-6 mb-3">Bluebottle Jellyfish (Portuguese Man-of-War)</h4>
        <p className="text-gray-700 mb-4">
          Regularly washing up on Bondi, Coogee, and Bronte beaches during summer months, bluebottles are responsible for thousands of stings annually in Sydney's Eastern Suburbs.
        </p>
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation of Bluebottle Stings</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Immediate, intense local pain where tentacles contact skin</li>
            <li>Characteristic raised red or purple linear welts</li>
            <li>Pain typically subsides within 1-2 hours but skin markings may persist for days</li>
            <li>Occasionally mild systemic symptoms (nausea, headache)</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">First Aid for Bluebottle Stings at Sydney Beaches</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Remove tentacles:</strong> Carefully remove any visible tentacles with tweezers or rinse with seawater (never freshwater)</li>
            <li><strong>Apply heat:</strong> Hot water immersion (45°C for 20 minutes) is the most effective treatment for bluebottle stings</li>
            <li><strong>Avoid vinegar:</strong> Unlike other jellyfish stings, vinegar can worsen bluebottle stings</li>
            <li><strong>Pain relief:</strong> Oral analgesics like paracetamol or ibuprofen if needed</li>
          </ol>
        </div>
      </section>
      
      <section id="stingray-injuries">
        <h3 className="text-xl font-bold text-gray-800 mt-10 mb-4">Stingray Injuries in Eastern Suburbs Waters</h3>
        <p className="text-gray-700 mb-4">
          Stingrays are commonly found in the shallow waters of Sydney's Eastern Suburbs beaches, particularly in sandy areas at Clovelly, Gordons Bay, and parts of Bondi. Injuries typically occur when beachgoers accidentally step on partially buried stingrays. Similar to other <Link to="/blog/child-head-injuries" className="text-indigo-600 hover:text-indigo-800">urgent injuries requiring immediate care</Link>, stingray wounds need prompt attention.
        </p>
        
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation of Stingray Injuries</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Immediate, excruciating pain out of proportion to the wound size</li>
            <li>Puncture wound, usually on the foot or ankle</li>
            <li>Progressive swelling and discoloration</li>
            <li>Pain typically peaks at 30-60 minutes and may last 24-48 hours</li>
            <li>Risk of secondary infection if not properly treated</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">First Aid and Treatment for Stingray Injuries</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Hot water immersion:</strong> Immerse the affected area in hot water (45°C) for up to 90 minutes—this is the most effective pain relief</li>
            <li><strong>Wound cleaning:</strong> Thoroughly clean the wound with saline once pain is managed</li>
            <li><strong>Remove foreign bodies:</strong> Any visible barb fragments must be removed</li>
            <li><strong>Medical assessment:</strong> <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">House call doctor visits</Link> are ideal for proper wound assessment, cleaning and determining if imaging is needed</li>
            <li><strong>Antibiotics:</strong> May be required to prevent infection with marine bacteria</li>
          </ol>
        </div>
        
        <p className="text-gray-700 mb-4">
          Severe stingray injuries, particularly to the abdomen or chest (uncommon but possible for surfers or swimmers in deeper waters), constitute a medical emergency requiring immediate ambulance transport.
        </p>
      </section>

      <section id="sea-urchin-injuries">
        <h3 className="text-xl font-bold text-gray-800 mt-10 mb-4">Sea Urchin Injuries at Rocky Eastern Suburbs Locations</h3>
        <p className="text-gray-700 mb-4">
          Sea urchin injuries are common around the rocky platforms and ocean pools of Sydney's Eastern Suburbs, particularly at Bondi's north end, around Bronte's bogey hole, Gordon's Bay, and Clovelly's rocky shorelines. For wound care principles that also apply to sea urchin injuries, see our article on <Link to="/blog/childhood-skin-conditions" className="text-indigo-600 hover:text-indigo-800">treating skin conditions and wounds</Link>.
        </p>
        
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation of Sea Urchin Injuries</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Multiple puncture wounds, often in a clustered pattern</li>
            <li>Moderate pain and burning sensation</li>
            <li>Dark purple or black spine fragments visible in the skin</li>
            <li>Progressive inflammation if spines are not removed</li>
            <li>Risk of granuloma formation with retained spines</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">Treatment for Sea Urchin Injuries</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Vinegar soak:</strong> Soaking in vinegar may help dissolve some superficial spines</li>
            <li><strong>Spine removal:</strong> Visible spines should be carefully removed with tweezers</li>
            <li><strong>Professional care:</strong> <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">House call medical services</Link> can provide proper extraction of deeply embedded spines</li>
            <li><strong>Imaging:</strong> Ultrasound or X-ray may be needed to locate deeply embedded spines</li>
            <li><strong>Follow-up care:</strong> Regular wound checks are important to identify and treat any developing granulomas</li>
          </ol>
        </div>
      </section>
      
      <section id="fish-spine-injuries">
        <h3 className="text-xl font-bold text-gray-800 mt-10 mb-4">Venomous Fish Injuries Around Sydney's Eastern Suburbs</h3>
        <p className="text-gray-700 mb-4">
          Several venomous fish species inhabit the waters around Sydney's Eastern Suburbs. While less common than other marine injuries, these can cause intense pain and require specific treatment approaches.
        </p>
        
        <h4 className="text-lg font-bold text-gray-800 mt-6 mb-3">Scorpionfish, Stonefish and Bullrout Injuries</h4>
        <p className="text-gray-700 mb-4">
          Though true stonefish are rare in Sydney waters, related species like scorpionfish and bullrout can be found in rock pools and around jetties in the Eastern Suburbs. Their excellent camouflage makes them easy to accidentally step on or handle.
        </p>
        
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Immediate, excruciating pain that may radiate up the limb</li>
            <li>Puncture marks from venomous dorsal spines</li>
            <li>Progressive swelling and discoloration</li>
            <li>In severe cases, systemic symptoms including nausea, vomiting, and hypotension</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">Treatment Approach</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Hot water immersion:</strong> Immerse the affected area in hot water (45°C) for up to 90 minutes</li>
            <li><strong>Pain management:</strong> Often requires strong analgesics beyond over-the-counter options</li>
            <li><strong>Wound care:</strong> Thorough cleaning and debridement</li>
            <li><strong>Medical assessment:</strong> <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">House call doctor services</Link> can provide advanced pain management in the comfort of your home</li>
            <li><strong>Antivenom consideration:</strong> In severe cases, particularly with true stonefish envenomation</li>
          </ol>
        </div>
      </section>
      
      <section id="blue-ringed-octopus">
        <h3 className="text-xl font-bold text-gray-800 mt-10 mb-4">Blue-Ringed Octopus Encounters in Sydney's Eastern Suburbs</h3>
        <p className="text-gray-700 mb-4">
          Though rare, blue-ringed octopus encounters occur in Sydney's Eastern Suburbs rock pools and shallow reef areas, particularly around Gordon's Bay, Clovelly, and the southern end of Bondi Beach. These small octopuses (5-20cm) have distinctive blue rings that become vibrant when threatened. Emergency care for these situations follows similar principles to other <Link to="/blog/managing-chronic-conditions" className="text-indigo-600 hover:text-indigo-800">medical emergencies we treat</Link>.
        </p>
        
        <div className="bg-red-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-red-800 mb-2">Important Warning</h4>
          <p className="text-gray-700">
            Blue-ringed octopus bites contain tetrodotoxin, a potent neurotoxin that can cause rapid-onset paralysis. Despite their small size, they carry enough venom to cause respiratory failure. Any suspected bite constitutes a medical emergency.
          </p>
        </div>
        
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Often painless or minimal pain at bite site</li>
            <li>Progressive numbness and tingling around the mouth and face</li>
            <li>Weakness, difficulty breathing, and potential paralysis</li>
            <li>Respiratory compromise within 30-90 minutes in severe cases</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">Emergency Management</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Call 000 immediately</strong> for any suspected blue-ringed octopus bite</li>
            <li><strong>Pressure immobilization technique:</strong> Apply a broad pressure bandage over the bite site and immobilize the limb</li>
            <li><strong>Monitor breathing:</strong> Be prepared to administer CPR if needed</li>
            <li><strong>Rapid transport:</strong> Emergency hospital care for respiratory support is critical</li>
          </ol>
        </div>
        
        <p className="text-gray-700 mb-4">
          Note that blue-ringed octopus bites are not appropriate for house call management and require immediate emergency department care.
        </p>
      </section>
      
      <section id="coral-cuts">
        <h3 className="text-xl font-bold text-gray-800 mt-10 mb-4">Coral Cuts and Scrapes in Sydney Coastal Waters</h3>
        <p className="text-gray-700 mb-4">
          While Sydney doesn't have true coral reefs like those in Northern Australia, our rocky coastal areas contain coralline algae and sharp surfaces that can cause painful cuts and scrapes, particularly around Gordon's Bay, Clovelly, and the rock platforms at Bondi and Bronte.
        </p>
        
        <div className="bg-blue-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-blue-800 mb-2">Clinical Presentation of Coral Cuts</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Irregular, sometimes jagged cuts or abrasions</li>
            <li>Immediate stinging or burning sensation</li>
            <li>Mild to moderate bleeding</li>
            <li>High risk of infection due to marine bacteria</li>
            <li>Delayed healing compared to typical cuts</li>
          </ul>
        </div>
        
        <div className="bg-green-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-green-800 mb-2">Treatment for Coral Cuts and Scrapes</h4>
          <ol className="list-decimal pl-5 text-gray-700 space-y-2">
            <li><strong>Thorough cleaning:</strong> Immediately rinse with clean seawater (not freshwater)</li>
            <li><strong>Remove debris:</strong> Carefully extract any visible rock or algae fragments</li>
            <li><strong>Disinfect:</strong> Clean with antiseptic solution as soon as possible</li>
            <li><strong>Wound care:</strong> Apply antibiotic ointment and cover with a clean, waterproof dressing</li>
            <li><strong>Medical assessment:</strong> <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">House call doctor services</Link> can provide thorough wound cleaning and assess the need for antibiotics, particularly for larger cuts or those in high-risk locations</li>
          </ol>
        </div>
      </section>
      
      <section id="prevention">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Preventing Marine Injuries at Sydney's Eastern Suburbs Beaches</h2>
        
        <div className="bg-yellow-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-yellow-800 mb-2">Essential Prevention Tips for Local Beaches</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li><strong>Bondi, Tamarama, and Bronte:</strong> Swim between the flags and check for bluebottle warnings, particularly when onshore winds are present</li>
            <li><strong>Clovelly and Gordon's Bay:</strong> Wear protective footwear when exploring rocky areas and be cautious of sea urchins and camouflaged fish</li>
            <li><strong>Rock platforms:</strong> Never turn over rocks with bare hands (blue-ringed octopus risk)</li>
            <li><strong>General beach safety:</strong> Shuffle feet when entering shallow, sandy waters to alert stingrays</li>
            <li><strong>Check conditions:</strong> Observe local signage and lifeguard warnings about marine hazards</li>
          </ul>
        </div>
      </section>
      
      <section id="when-to-seek-medical-care">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">When to Call a Doctor for Marine Injuries in the Eastern Suburbs</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-red-50 rounded-lg p-6">
            <h3 className="font-bold text-red-800 mb-2">Seek Emergency Care (000) For:</h3>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li>Suspected blue-ringed octopus bites</li>
              <li>Severe allergic reactions to marine stings</li>
              <li>Stingray injuries to the chest, abdomen, or face</li>
              <li>Difficulty breathing following any marine injury</li>
              <li>Severe bleeding that doesn't stop with pressure</li>
            </ul>
          </div>
          
          <div className="bg-amber-50 rounded-lg p-6">
            <h3 className="font-bold text-amber-800 mb-2">Contact House Call Doctor For:</h3>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li>Painful marine stings not responding to first aid</li>
              <li>Stingray injuries needing wound assessment and cleaning</li>
              <li>Multiple sea urchin spine removals</li>
              <li>Signs of infection in any marine injury</li>
              <li>Pain management needs beyond over-the-counter medications</li>
              <li><Link to="/dynamic-booking" className="text-amber-800 hover:text-amber-900 font-medium">Book a house call now</Link></li>
            </ul>
          </div>
        </div>
        
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">House Call Doctor Services for Marine Injuries in Sydney's Eastern Suburbs</h3>
        
        <div className="bg-indigo-50 rounded-lg p-6 mb-6">
          <h4 className="font-bold text-indigo-800 mb-3">Why Choose a House Call Doctor for Marine Injuries?</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li><strong>No uncomfortable travel:</strong> Avoid painful journeys to emergency departments while experiencing the intense pain of marine stings</li>
            <li><strong>Specialized equipment:</strong> Our mobile doctors carry specialized tools for marine injury treatment, including hot water immersion devices and spine removal equipment</li>
            <li><strong>Medicare bulk-billed:</strong> No out-of-pocket expenses for Medicare card holders in Bondi, Bronte, Coogee and surrounding areas</li>
            <li><strong>Rapid response:</strong> Average arrival time of 30-60 minutes throughout Sydney's Eastern Suburbs</li>
            <li><strong>Beach proximity expertise:</strong> Experience with the specific marine creatures and injuries common to local Eastern Suburbs beaches</li>
            <li><strong>After-hours service:</strong> Available 24/7, including evenings, weekends and public holidays when marine injuries commonly occur</li>
          </ul>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-gray-800 mb-3">Telemedicine Options for Marine Injuries</h4>
            <p className="text-gray-700 mb-3">
              For less severe marine injuries or initial assessment, Dr. Terry Nguyen offers secure video consultations to Eastern Suburbs residents and visitors. Telemedicine allows us to:
            </p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Visually assess marine stings and wounds</li>
              <li>Provide immediate first aid guidance</li>
              <li>Determine if in-person care is needed</li>
              <li>Arrange a house call if required</li>
              <li>Prescribe medications electronically</li>
            </ul>
            <div className="mt-4">
              <Link to="/telemedicine" className="text-indigo-600 hover:text-indigo-800 font-medium">Book a telemedicine consultation →</Link>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-gray-800 mb-3">House Call Booking Information</h4>
            <p className="text-gray-700 mb-3">
              Our house call service covers all Eastern Suburbs beaches and surrounding neighborhoods, including:
            </p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Bondi, North Bondi, and Bondi Junction</li>
              <li>Bronte, Tamarama, and Clovelly</li>
              <li>Coogee, South Coogee, and Maroubra</li>
              <li>Randwick, Waverley, and Rose Bay</li>
              <li>Double Bay, Vaucluse, and Watsons Bay</li>
            </ul>
            <div className="mt-4">
              <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">Book a house call doctor →</Link>
            </div>
          </div>
        </div>
        
        <p className="text-gray-700 mb-6">
          As a house call doctor service covering Sydney's Eastern Suburbs beaches including Bondi, Bronte, Clovelly, Coogee, and surrounding areas, we provide expert care for most marine injuries in the comfort of your home or accommodation. This eliminates the need for uncomfortable travel with painful injuries and ensures prompt, specialized treatment from Dr. Terry Nguyen, who has extensive experience treating marine injuries in the Eastern Suburbs. <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">Book a house call</Link> or call our 24/7 hotline at <a href="tel:+61290000000" className="text-indigo-600 hover:text-indigo-800 font-medium">02 9000 0000</a> for rapid response.
        </p>
      </section>
      
      <section id="conclusion">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Conclusion: Expert Care for Sydney's Beach Enthusiasts</h2>
        <p className="text-gray-700 mb-4">
          Sydney's Eastern Suburbs beaches offer world-class recreational opportunities, and with proper knowledge and precautions, marine injuries can be minimized and effectively managed. Our house call doctor service provides specialized care for marine injuries, bringing emergency department expertise directly to your door in Bondi, Bronte, Coogee, and throughout the Eastern Suburbs. For patients with special health considerations, please also review our guidance on <Link to="/blog/managing-chronic-conditions" className="text-indigo-600 hover:text-indigo-800">managing chronic conditions</Link> while enjoying beach activities.
        </p>
        <p className="text-gray-700 mb-6">
          Whether you're a local resident, regular beachgoer, or visitor to our stunning coastline, understanding the marine creatures in our waters and knowing how to respond to injuries can significantly improve outcomes. For expert assessment and treatment of marine injuries, consider our <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">specialized house call service</Link> for prompt, professional care without the emergency department wait.
        </p>
      </section>
      
      <section id="cta" className="my-12">
        <div className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-xl shadow-xl overflow-hidden">
          <div className="px-6 py-12 md:p-12 text-center md:text-left flex flex-col md:flex-row items-center">
            <div className="md:w-2/3 mb-8 md:mb-0 md:pr-8">
              <h2 className="text-3xl font-bold text-white mb-4">Experienced a Marine Injury in Sydney?</h2>
              <p className="text-blue-100 text-lg mb-6">
                If you've suffered a marine injury or sting in Sydney, call HomeDoc for an urgent house call. We'll assess and treat you in the comfort of your home, usually within 30-60 minutes — no need for painful trips to emergency departments when dealing with jellyfish stings, stingray wounds, or sea urchin injuries.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                <Link 
                  to="/dynamic-booking" 
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Book House Call Now
                </Link>
                <Link 
                  to="/telemedicine" 
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 bg-opacity-60 hover:bg-opacity-70 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Video Consultation
                </Link>
              </div>
            </div>
            <div className="md:w-1/3 flex items-center justify-center">
              <div className="bg-white p-2 rounded-full">
                <div className="bg-blue-100 rounded-full p-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-indigo-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-indigo-800 px-6 py-4">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <p className="text-indigo-200 text-sm mb-4 sm:mb-0">
                <span className="font-bold">Medicare Bulk Billed</span> - No out-of-pocket expenses
              </p>
              <a 
                href="tel:+61290000000" 
                className="inline-flex items-center space-x-2 text-white hover:text-blue-100 transition"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                <span className="font-medium">Call 24/7: 02 9000 0000</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      
      <section id="faq" className="mt-12 border-t border-gray-200 pt-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Frequently Asked Questions: Marine Injuries & House Call Doctor Services</h2>
        
        <div className="space-y-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">How quickly can a house call doctor arrive after a marine injury in Sydney's Eastern Suburbs?</h3>
            <p className="text-gray-700">
              In most cases, our house call doctor service can have a qualified physician at your location in Bondi, Bronte, Coogee or surrounding Eastern Suburbs within 30-60 minutes of booking. For severe marine injuries requiring immediate attention, we recommend calling 000 while waiting for our doctor to arrive.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Are house call doctor services for marine injuries covered by Medicare?</h3>
            <p className="text-gray-700">
              Yes, our house call doctor services for treating marine injuries throughout Sydney's Eastern Suburbs are bulk-billed for Medicare card holders, meaning there are no out-of-pocket expenses. For international visitors without Medicare, we offer competitive rates and can provide documentation for travel insurance claims.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">What equipment does a house call doctor bring to treat marine injuries?</h3>
            <p className="text-gray-700">
              Our house call doctors carry comprehensive medical kits specifically designed for treating marine injuries common in Sydney's Eastern Suburbs. This includes equipment for foreign body removal (sea urchin spines), wound cleaning and dressing supplies, pain management medications, antibiotics for marine wound infections, and diagnostic tools for thorough assessment.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Can I get a telemedicine consultation for advice about a marine injury?</h3>
            <p className="text-gray-700">
              Yes, Dr. Terry Nguyen offers telemedicine consultations for initial assessment of marine injuries throughout Sydney's Eastern Suburbs. While some marine injuries require in-person treatment, video consultations can help determine severity, provide first aid guidance, and arrange appropriate follow-up care including a house call if necessary.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">How do I differentiate between a dangerous and non-dangerous jellyfish sting at Bondi Beach?</h3>
            <p className="text-gray-700">
              At Sydney's Eastern Suburbs beaches like Bondi, most jellyfish stings are from bluebottles (Portuguese Man-of-War), which cause immediate intense pain and characteristic linear red welts. While painful, these are rarely dangerous. Signs of a more concerning envenomation include: widespread rash beyond the sting site, difficulty breathing, severe swelling, chest pain, or symptoms of anaphylaxis. When in doubt, seek immediate medical attention through our house call service or emergency care.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">What should I do if I step on a stingray at Coogee Beach?</h3>
            <p className="text-gray-700">
              If you step on a stingray at Coogee or other Eastern Suburbs beaches: 1) Exit the water carefully, 2) Immerse the affected area in hot water (45°C) for up to 90 minutes for pain relief, 3) Clean the wound gently with salt water, 4) Book a house call doctor for proper wound assessment and treatment, including antibiotics if necessary. Don't delay medical care as stingray wounds have high infection risk.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Are house call doctors available after hours for marine injuries in the Eastern Suburbs?</h3>
            <p className="text-gray-700">
              Yes, our house call doctor service operates 24/7 throughout Sydney's Eastern Suburbs, including Bondi, Bronte, Coogee, and surrounding areas. Marine injuries often occur during recreational hours, including evenings and weekends, and our after-hours service ensures you can receive expert medical care whenever needed without visiting an emergency department.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Can a house call doctor prescribe antibiotics for infected marine injury wounds?</h3>
            <p className="text-gray-700">
              Yes, our house call doctors can prescribe antibiotics specifically for marine-related wound infections throughout the Eastern Suburbs. Marine injuries have a high risk of infection due to bacteria present in seawater. Our doctors can assess the wound, perform appropriate cleaning, and prescribe targeted antibiotics if needed, with electronic prescriptions sent directly to your preferred pharmacy.
            </p>
          </div>
        </div>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Bluebottle Stings',
      description: 'Hot water immersion (45°C for 20 minutes)',
      duration: 'Pain typically resolves in 1-2 hours'
    },
    {
      stage: 'Stingray Injuries',
      description: 'Hot water immersion (45°C for up to 90 minutes), wound cleaning, possible antibiotics',
      duration: 'Pain management: 1-2 days; Healing: 1-2 weeks'
    },
    {
      stage: 'Sea Urchin Injuries',
      description: 'Vinegar soaking, spine removal, possible ultrasound guidance',
      duration: 'Initial treatment: 1-2 hours; Follow-up: 1-2 weeks'
    },
    {
      stage: 'Venomous Fish Injuries',
      description: 'Hot water immersion, pain management, wound care',
      duration: 'Acute pain: 24-48 hours; Complete healing: 1-3 weeks'
    }
  ];

  const references = [
    {
      authors: 'Berling I, Isbister GK.',
      title: 'Marine envenomations',
      journal: 'Australian Family Physician',
      year: '2015',
      doi: 'Volume 44, Issue 1, January-February 2015'
    },
    {
      authors: 'Loten C, Stokes B, Worsley D, Seymour JE, Jiang S, Isbister GK.',
      title: 'A randomised controlled trial of hot water (45°C) immersion versus ice packs for pain relief in bluebottle stings',
      journal: 'Medical Journal of Australia',
      year: '2006',
      doi: '184:329–33'
    },
    {
      authors: 'NSW Health',
      title: 'Marine Hazards',
      journal: 'NSW Government Health Website',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Fenner PJ.',
      title: 'Marine envenomation: An update – A presentation on the current status of marine envenomation first aid and medical treatments',
      journal: 'Emergency Medicine Australasia',
      year: '2000',
      doi: '12(4):295-302'
    },
    {
      authors: 'Williamson JA, Fenner PJ, Burnett JW, Rifkin JF.',
      title: 'Venomous and Poisonous Marine Animals: A Medical and Biological Handbook',
      journal: 'University of New South Wales Press',
      year: '1996',
      doi: 'Sydney, Australia'
    },
    {
      authors: 'Sutherland SK, Tibballs J.',
      title: 'Australian Animal Toxins: The Creatures, Their Toxins, and Care of the Poisoned Patient',
      journal: 'Oxford University Press',
      year: '2001',
      doi: 'Melbourne, Australia'
    }
  ];

  const symptoms = [
    'Immediate intense pain after contact with marine creature',
    'Linear raised welts (bluebottle jellyfish)',
    'Puncture wounds with disproportionate pain (stingrays)',
    'Multiple small punctures with embedded spines (sea urchins)',
    'Progressive swelling and discoloration',
    'Systemic symptoms: nausea, vomiting, headache',
    'Respiratory distress (severe envenomations)',
    'Signs of secondary infection: increasing redness, warmth, discharge'
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Jellyfish Stings at Sydney Beaches', anchor: 'jellyfish-stings' },
    { title: 'Stingray Injuries in Eastern Suburbs Waters', anchor: 'stingray-injuries' },
    { title: 'Sea Urchin Injuries at Rocky Eastern Suburbs Locations', anchor: 'sea-urchin-injuries' },
    { title: 'Venomous Fish Injuries Around Sydney\'s Eastern Suburbs', anchor: 'fish-spine-injuries' },
    { title: 'Blue-Ringed Octopus Encounters in Sydney\'s Eastern Suburbs', anchor: 'blue-ringed-octopus' },
    { title: 'Coral Cuts and Scrapes in Sydney Coastal Waters', anchor: 'coral-cuts' },
    { title: 'Preventing Marine Injuries at Sydney\'s Eastern Suburbs Beaches', anchor: 'prevention' },
    { title: 'When to Call a Doctor for Marine Injuries in the Eastern Suburbs', anchor: 'when-to-seek-medical-care' },
    { title: 'Conclusion: Expert Care for Sydney\'s Beach Enthusiasts', anchor: 'conclusion' },
    { title: 'Frequently Asked Questions: Marine Injuries & House Call Doctor Services', anchor: 'faq' }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/sydney-beaches-marine-injuries.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/marine-injuries-sydney" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/sydney-beaches-marine-injuries.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/marine-injuries-sydney" />
        
        {/* Schema.org structured data for medical article with updated heading structure */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalWebPage",
              "headline": "Marine Injuries in Sydney – First Aid and Emergency Care",
              "image": "https://homedoc.com.au/images/sydney-beaches-marine-injuries.png",
              "datePublished": "2024-06-14T08:00:00+10:00",
              "dateModified": "2024-06-14T08:00:00+10:00",
              "author": {
                "@type": "Person",
                "name": "Dr. Terry Nguyen",
                "jobTitle": "Medical Doctor",
                "image": "https://homedoc.com.au/images/drterrynguyen_medicaldoctor.png"
              },
              "publisher": {
                "@type": "Organization",
                "name": "HomeDoc House Call Doctor Service",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://homedoc.com.au/images/logo.png"
                }
              },
              "description": "Learn how to identify and treat marine injuries like jellyfish stings, sea urchin wounds, and stingray injuries in Sydney. Immediate care tips from Dr. Terry Nguyen.",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://homedoc.com.au/blog/marine-injuries-sydney"
              },
              "speakable": {
                "@type": "SpeakableSpecification",
                "cssSelector": ["#introduction", "#conclusion"]
              },
              "specialty": ["Emergency Medicine", "Marine Toxicology", "House Call Medicine"],
              "about": [
                {"@type": "MedicalCondition", "name": "Jellyfish Stings"},
                {"@type": "MedicalCondition", "name": "Stingray Injuries"},
                {"@type": "MedicalCondition", "name": "Sea Urchin Puncture Wounds"},
                {"@type": "MedicalCondition", "name": "Coral Cuts"}
              ],
              "audience": {"@type": "Audience", "audienceType": "General Public"},
              "keywords": "marine injuries Sydney, bluebottle stings Bondi, stingray Eastern Suburbs, sea urchin treatment, house call doctor"
            }
          `}
        </script>
        
        {/* Local Business structured data for house call service */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalBusiness",
              "name": "HomeDoc House Call Doctor Service",
              "image": "https://homedoc.com.au/images/logo.png",
              "url": "https://homedoc.com.au",
              "telephone": "+61-2-9000-0000",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Eastern Suburbs",
                "addressLocality": "Sydney",
                "addressRegion": "NSW",
                "postalCode": "2026",
                "addressCountry": "AU"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": -33.891844,
                "longitude": 151.274292
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              },
              "serviceArea": {
                "@type": "GeoCircle",
                "geoMidpoint": {
                  "@type": "GeoCoordinates",
                  "latitude": -33.891844,
                  "longitude": 151.274292
                },
                "geoRadius": "10000"
              },
              "sameAs": [
                "https://www.facebook.com/homedoc",
                "https://www.instagram.com/homedoc_sydney"
              ],
              "priceRange": "$$",
              "medicalSpecialty": "Emergency Medicine"
            }
          `}
        </script>
        
        {/* FAQ structured data for search results */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What do I do if I'm stung by a jellyfish in Sydney?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If stung by a bluebottle jellyfish in Sydney: 1) Rinse the area with seawater (not freshwater), 2) Remove any visible tentacles with tweezers, 3) Immerse the affected area in hot water (45°C) for 20 minutes, 4) Take over-the-counter pain relief if needed, and 5) Seek medical attention if pain persists or if you develop systemic symptoms like nausea or difficulty breathing."
                  }
                },
                {
                  "@type": "Question",
                  "name": "When should I see a doctor after a marine injury in Sydney?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Seek immediate medical attention if you experience: severe or worsening pain, signs of infection (increasing redness, warmth, swelling), fever, embedded spines or foreign material that can't be removed, wounds that aren't healing properly, or any symptoms after a blue-ringed octopus encounter. For most marine injuries, it's best to have them professionally assessed to prevent complications."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How quickly can a house call doctor arrive after a marine injury in Sydney's Eastern Suburbs?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In most cases, our house call doctor service can have a qualified physician at your location in Bondi, Bronte, Coogee or surrounding Eastern Suburbs within 30-60 minutes of booking. For severe marine injuries requiring immediate attention, we recommend calling 000 while waiting for our doctor to arrive."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are house call doctor services for marine injuries covered by Medicare?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, our house call doctor services for treating marine injuries throughout Sydney's Eastern Suburbs are bulk-billed for Medicare card holders, meaning there are no out-of-pocket expenses. For international visitors without Medicare, we offer competitive rates and can provide documentation for travel insurance claims."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What should I do if I step on a stingray at Coogee Beach?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If you step on a stingray at Coogee or other Eastern Suburbs beaches: 1) Exit the water carefully, 2) Immerse the affected area in hot water (45°C) for up to 90 minutes for pain relief, 3) Clean the wound gently with salt water, 4) Book a house call doctor for proper wound assessment and treatment, including antibiotics if necessary. Don't delay medical care as stingray wounds have high infection risk."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How do I treat sea urchin spine injuries at Sydney beaches?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "For sea urchin spine injuries around Sydney's rocky shores: 1) Soak the area in vinegar to help dissolve superficial spines, 2) Remove visible spines with tweezers (don't dig for deep ones), 3) Clean and disinfect the area, 4) Apply antibiotic ointment and cover with a clean bandage, 5) See a house call doctor for professional removal of deeply embedded spines and to assess the need for antibiotics, especially if multiple spines are present."
                  }
                }
              ]
            }
          `}
        </script>

        {/* Article markup for better appearance in search results */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "headline": "Marine Injuries in Sydney – First Aid and Emergency Care",
              "image": [
                "https://homedoc.com.au/images/sydney-beaches-marine-injuries.png"
              ],
              "datePublished": "2024-06-14T08:00:00+10:00",
              "dateModified": "2024-06-14T08:00:00+10:00",
              "author": {
                "@type": "Person",
                "name": "Dr. Terry Nguyen",
                "url": "https://homedoc.com.au/about"
              },
              "publisher": {
                "@type": "Organization",
                "name": "HomeDoc",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://homedoc.com.au/images/logo.png"
                }
              },
              "description": "Learn how to identify and treat marine injuries like jellyfish stings, sea urchin wounds, and stingray injuries in Sydney. Immediate care tips from Dr. Terry Nguyen."
            }
          `}
        </script>
      </Helmet>
      
      <MedicalBlogTemplate
        title="Marine Injuries in Sydney's Eastern Suburbs: Expert Treatment Guide from a House Call Doctor"
        subtitle="First aid and professional treatment for jellyfish stings, stingrays, sea urchins and venomous fish - from Bondi to Coogee and beyond"
        heroImage="/images/sydney-beaches-marine-injuries.png"
        publishDate="June 14, 2024"
        readTime={12}
        content={content}
        category="Emergency Care"
        tags={['Marine Injuries', 'Bluebottle Stings', 'Stingray Injuries', 'Sea Urchin', 'Blue-Ringed Octopus', 'House Call Doctor', 'Sydney Eastern Suburbs', 'Bondi Beach', 'Coogee', 'Bronte', 'After Hours Doctor', 'Medicare Bulk Billing', 'Telemedicine Sydney']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={true}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export default MarineInjuriesSydney; 