import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';

const ChildhoodSkinConditions = () => {
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Common Skin Conditions in Children: A Guide for Australian Parents</h2>
        <p className="text-gray-700 mb-4">
          As a medical practitioner working with Australian families, I've observed that skin conditions are among the most common reasons parents seek medical advice for their children. From eczema to impetigo, these conditions can cause significant discomfort and concern.
        </p>
        <p className="text-gray-700 mb-4">
          Childhood skin conditions affect approximately 30% of Australian children at some point during their early years. While most are not serious, they can impact quality of life and, in some cases, indicate underlying health issues that require attention.
        </p>
        <p className="text-gray-700 mb-6">
          This guide explores the most common pediatric skin conditions in Australia, offering evidence-based advice on recognition, management, and when to seek professional care through <Link to="/telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth consultations</Link> or in-person visits.
        </p>
      </section>

      <section id="eczema">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Atopic Dermatitis (Eczema)</h2>
        <p className="text-gray-700 mb-4">
          Eczema affects up to 20% of Australian children and is characterized by dry, itchy, and inflamed skin. The condition typically appears in the first year of life and often improves with age, though some children experience symptoms into adulthood.
        </p>
        
        <div className="bg-blue-50 rounded-xl p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Recognizing Eczema</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Red, dry, itchy patches of skin</li>
            <li>Common locations include face, neck, behind the ears, and in skin folds (elbows, knees, wrists)</li>
            <li>May weep or crust in severe cases</li>
            <li>Often worse at night, causing sleep disturbances</li>
            <li>Frequently associated with family history of allergies, asthma, or eczema</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          The Australian climate, particularly in regions with high humidity, can exacerbate eczema symptoms. Additionally, exposure to pool chlorine, certain soaps, and synthetic fabrics may trigger flare-ups in susceptible children.
        </p>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Management Approaches</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li><strong>Daily moisturizing</strong> with fragrance-free emollients, especially after bathing</li>
            <li><strong>Lukewarm baths</strong> (avoid hot water) with minimal fragrance-free soap</li>
            <li><strong>Cotton clothing</strong> rather than wool or synthetic fabrics</li>
            <li><strong>Topical corticosteroids</strong> as prescribed for flare-ups</li>
            <li><strong>Identifying and avoiding triggers</strong> specific to your child</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Through <Link to="/telehealth-appointment" className="text-indigo-600 hover:text-indigo-800 font-medium">telehealth appointments</Link>, we can assess your child's eczema, recommend appropriate treatments, and create a management plan tailored to their specific needs without necessarily requiring in-person visits for routine care.
        </p>
      </section>

      <section id="impetigo">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Impetigo (School Sores)</h2>
        <p className="text-gray-700 mb-4">
          Impetigo is a highly contagious bacterial skin infection common in Australian school-aged children, particularly during warmer months. It's caused by Staphylococcus aureus or Streptococcus pyogenes bacteria and can spread rapidly in settings where children are in close contact.
        </p>
        
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-r-xl my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Key Features of Impetigo</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Red sores that quickly rupture, ooze for a few days, then form a honey-colored crust</li>
            <li>Most commonly appears around the nose, mouth, and limbs</li>
            <li>Usually doesn't cause pain, but may be itchy</li>
            <li>Highly contagious through direct contact or sharing items like towels</li>
            <li>Can spread to other areas of the body if not treated</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Impetigo requires prompt treatment to prevent spread within families and school communities. Australian schools and childcare centers typically require children with impetigo to stay home until 24 hours after starting antibiotic treatment and when the sores are covered with dressings.
        </p>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Treatment Approach</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li><strong>Antibiotic cream</strong> for small areas of infection</li>
            <li><strong>Oral antibiotics</strong> for more widespread infection</li>
            <li><strong>Gentle washing</strong> with warm water to remove crusts</li>
            <li><strong>Keeping nails short</strong> and clean to prevent scratching and spreading</li>
            <li><strong>Covering sores</strong> with light dressings during treatment</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Through <Link to="/virtual-care" className="text-indigo-600 hover:text-indigo-800 font-medium">virtual care services</Link>, we can diagnose impetigo with high-quality photos, prescribe appropriate antibiotics, and provide follow-up care to ensure the infection resolves completely.
        </p>
      </section>

      <section id="molluscum">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Molluscum Contagiosum</h2>
        <p className="text-gray-700 mb-4">
          Molluscum contagiosum is a common viral skin infection that causes small, firm, raised bumps (papules) on the skin. It's particularly prevalent in Australian children aged 1-10 years and can persist for months to years if left untreated.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Characteristic Features</h4>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Small, flesh-colored or pink dome-shaped bumps</li>
              <li>Often have a central dimple or depression</li>
              <li>Usually painless but may be itchy</li>
              <li>Typically 2-5mm in diameter</li>
              <li>Most common on the trunk, face, and limbs</li>
            </ul>
          </div>
          
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Transmission & Risk Factors</h4>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Spread through direct skin-to-skin contact</li>
              <li>Can spread through sharing towels, clothing, or bath water</li>
              <li>Swimming pools may facilitate transmission</li>
              <li>More extensive in children with eczema or immune suppression</li>
              <li>Reinfection can occur after resolution</li>
            </ul>
          </div>
        </div>
        
        <p className="text-gray-700 mb-4">
          While molluscum is benign and often resolves without treatment, intervention may be recommended if lesions are widespread, cause distress, or are at risk of secondary infection from scratching.
        </p>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Management Options</h3>
          <p className="text-gray-700 mb-3">
            The approach to molluscum varies based on the child's age, extent of lesions, and parental preference:
          </p>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li><strong>Watchful waiting</strong> - Many cases resolve spontaneously within 12-18 months</li>
            <li><strong>Physical removal</strong> - Curettage or cryotherapy for older children</li>
            <li><strong>Topical treatments</strong> - Including podophyllotoxin or imiquimod for suitable cases</li>
            <li><strong>Prevention of spread</strong> - Covering lesions during swimming, not sharing towels</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Through <Link to="/online-doctor-consultation" className="text-indigo-600 hover:text-indigo-800 font-medium">online doctor consultations</Link>, we can assess your child's molluscum, discuss treatment options, and monitor progress with follow-up virtual visits.
        </p>
      </section>

      <section id="ringworm">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Tinea (Ringworm)</h2>
        <p className="text-gray-700 mb-4">
          Despite its name, ringworm is not caused by a worm but by a fungal infection. It presents as a red, circular rash with a clearer center, giving it the appearance of a ring. In Australia's warm, humid climate, tinea infections are particularly common.
        </p>
        
        <div className="bg-green-50 rounded-xl p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Common Types in Children</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li><strong>Tinea corporis (body)</strong> - Circular, red, scaly patches with raised borders</li>
            <li><strong>Tinea capitis (scalp)</strong> - Scaly patches and hair loss; more common in children</li>
            <li><strong>Tinea pedis (athlete's foot)</strong> - Itchy, flaky skin between toes; common in adolescents</li>
            <li><strong>Tinea cruris (jock itch)</strong> - Affects groin area; more common in adolescent boys</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Fungal infections thrive in warm, moist environments, making them more prevalent in tropical and subtropical regions of Australia. Children who participate in sports, share facilities like swimming pools, or have pets are at increased risk.
        </p>
        
        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Treatment Approaches</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li><strong>Topical antifungal creams</strong> (e.g., clotrimazole, miconazole) for most body ringworm</li>
            <li><strong>Oral antifungal medication</strong> for scalp ringworm or extensive infection</li>
            <li><strong>Regular application</strong> for 1-2 weeks after symptoms resolve to prevent recurrence</li>
            <li><strong>Hygiene measures</strong> including regular washing of bedding and clothing</li>
            <li><strong>Avoiding sharing personal items</strong> like combs, hats, or towels</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          Through <Link to="/medicare-telehealth" className="text-indigo-600 hover:text-indigo-800 font-medium">Medicare-covered telehealth consultations</Link>, we can diagnose most cases of tinea from clear photographs, prescribe appropriate treatment, and provide guidance on preventing spread and recurrence.
        </p>
      </section>

      <section id="when-to-seek-help">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">When to Seek Medical Advice</h2>
        <p className="text-gray-700 mb-4">
          While many childhood skin conditions are benign and can be managed with over-the-counter treatments, certain circumstances warrant professional medical evaluation. Based on my clinical experience, I recommend seeking medical advice in the following situations:
        </p>
        
        <div className="bg-red-50 border-l-4 border-red-400 p-6 rounded-r-xl my-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Signs That Require Medical Attention</h3>
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li>Skin condition accompanied by fever or significant pain</li>
            <li>Rapidly spreading rash or infection</li>
            <li>Signs of secondary infection (increased redness, warmth, swelling, pus)</li>
            <li>Skin conditions that significantly interfere with sleep or daily activities</li>
            <li>Rashes associated with other symptoms like joint pain or persistent illness</li>
            <li>No improvement after 1-2 weeks of appropriate home treatment</li>
            <li>Recurring skin problems requiring frequent treatment</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-4">
          With Australia's comprehensive telehealth services, many childhood skin conditions can be assessed virtually, saving families time and making healthcare more accessible, especially for those in regional areas.
        </p>
        
        <div className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white rounded-xl p-6 my-8">
          <h3 className="text-xl font-bold mb-4">Need Personalized Advice?</h3>
          <p className="mb-4">
            If you're concerned about your child's skin condition, I offer comprehensive telehealth consultations for families across Australia.
          </p>
          <Link
            to="/book-appointment"
            className="inline-block bg-white text-indigo-600 px-6 py-2 rounded-lg font-medium hover:bg-gray-100 transition-colors duration-200"
          >
            Book a Telehealth Consultation
          </Link>
        </div>
      </section>

      <section id="prevention">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Preventive Measures for Common Skin Conditions</h2>
        <p className="text-gray-700 mb-4">
          While not all childhood skin conditions can be prevented, several strategies can reduce their frequency and severity. These approaches are particularly relevant to Australia's climate and lifestyle:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Sun Protection</h4>
            <p className="text-gray-700">
              Australia has one of the highest UV radiation levels globally. Following the Slip, Slop, Slap, Seek, Slide guidelines is essential for preventing sunburn and reducing long-term skin damage. Use SPF 50+ sunscreen, reapply regularly, and be especially vigilant during peak UV hours.
            </p>
          </div>
          
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Hygiene Practices</h4>
            <p className="text-gray-700">
              Establish good hygiene routines including regular bathing with mild soap, thorough drying, and teaching children not to share personal items like towels and hats. These measures are particularly important for preventing contagious conditions like impetigo and tinea.
            </p>
          </div>
          
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Moisturizing Routines</h4>
            <p className="text-gray-700">
              Regular moisturizing helps maintain skin barrier function, which is especially important for children with eczema or dry skin. Apply fragrance-free moisturizers after bathing and during the day as needed, particularly in air-conditioned environments.
            </p>
          </div>
          
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="text-lg font-semibold text-gray-900 mb-3">Environmental Considerations</h4>
            <p className="text-gray-700">
              Be mindful of how Australia's unique environment affects skin health. This includes chlorinated pools (shower afterward), air conditioning (can cause dryness), high pollen seasons (may trigger eczema), and exposure to specific plants that can cause contact dermatitis.
            </p>
          </div>
        </div>
        
        <p className="text-gray-700 mb-6">
          Through <Link to="/virtual-consultations" className="text-indigo-600 hover:text-indigo-800 font-medium">virtual consultations</Link>, I can provide personalized preventive strategies based on your child's specific skin type, existing conditions, and your family's lifestyle needs.
        </p>
      </section>

      <section id="conclusion">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Final Thoughts: A Balanced Approach to Children's Skin Health</h2>
        <p className="text-gray-700 mb-4">
          As both a medical practitioner and a parent, I understand the concern that skin conditions can cause. It's important to remember that most childhood skin issues are manageable with appropriate care and rarely indicate serious underlying health problems.
        </p>
        
        <p className="text-gray-700 mb-4">
          The Australian healthcare system, with its integrated telehealth services, offers excellent support for families dealing with pediatric skin conditions. From Medicare-covered consultations to PBS-subsidized treatments, access to quality care is within reach for most families.
        </p>
        
        <p className="text-gray-700 mb-6">
          Whether through <Link to="/medicare-telehealth-consult" className="text-indigo-600 hover:text-indigo-800 font-medium">Medicare-covered telehealth consultations</Link> or in-person visits, I'm committed to helping Australian families navigate the often confusing world of childhood skin conditions with evidence-based, practical advice tailored to our unique environment and lifestyle.
        </p>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Diagnosis',
      description: 'Assessment of skin condition through clinical examination and history',
      duration: '15-30 minutes'
    },
    {
      stage: 'Treatment Planning',
      description: 'Development of tailored treatment approach based on condition, severity, and child factors',
      duration: '15 minutes'
    },
    {
      stage: 'Medication Management',
      description: 'Prescription of appropriate topical or oral medications if indicated',
      duration: 'Varies by condition'
    },
    {
      stage: 'Lifestyle Modifications',
      description: 'Guidance on environmental and behavioral changes to manage and prevent recurrence',
      duration: 'Ongoing'
    },
    {
      stage: 'Follow-up Care',
      description: 'Monitoring of treatment response and adjustment of approach as needed',
      duration: '15 minutes (every 2-4 weeks)'
    }
  ];

  const references = [
    {
      authors: 'Australasian College of Dermatologists',
      title: 'Common skin conditions in children',
      journal: 'ACD Clinical Guidelines',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Clinical Practice Guidelines - Dermatology',
      journal: 'RCH Clinical Guidelines',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Su JC, et al.',
      title: 'Atopic dermatitis in Australian children: an epidemiological study',
      journal: 'Australian Journal of Dermatology',
      year: '2022',
      doi: '10.1111/ajd.13342'
    },
    {
      authors: 'National Health and Medical Research Council',
      title: 'Australian Guide to Healthy Eating',
      journal: 'NHMRC',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Cancer Council Australia',
      title: 'Sun protection for children',
      journal: 'Cancer Council Guidelines',
      year: '2023',
      doi: ''
    }
  ];

  const symptoms = [
    'Rash or red patches',
    'Itchy skin',
    'Dry, scaly skin',
    'Blisters or bumps',
    'Skin discoloration',
    'Crusting or oozing',
    'Pain or tenderness',
    'Swelling of affected area'
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Eczema (Atopic Dermatitis)', anchor: 'eczema' },
    { title: 'Impetigo (School Sores)', anchor: 'impetigo' },
    { title: 'Molluscum Contagiosum', anchor: 'molluscum' },
    { title: 'Tinea (Ringworm)', anchor: 'ringworm' },
    { title: 'When to Seek Medical Advice', anchor: 'when-to-seek-help' },
    { title: 'Preventive Measures', anchor: 'prevention' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  return (
    <MedicalBlogTemplate
      title="Common Skin Conditions in Children: A Guide for Australian Parents"
      subtitle="Recognizing, treating, and preventing common pediatric skin conditions through telehealth and in-person care"
      heroImage="https://images.unsplash.com/photo-1516627145497-ae6968895b40?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3"
      publishDate="March 20, 2024"
      readTime={16}
      content={content}
      category="Child Health"
      tags={['Pediatric Dermatology', 'Eczema', 'Impetigo', 'Molluscum Contagiosum', 'Tinea', 'Telehealth', 'Prevention', 'Australian Healthcare']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      showEmergencyBox={false}
      tableOfContents={tableOfContents}
    />
  );
};

export default ChildhoodSkinConditions; 