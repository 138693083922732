import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const HormonalAcneTreatment = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Understanding Hormonal Acne</h2>
      <p className="text-gray-700 mb-6">
        Hormonal acne is a complex skin condition that primarily affects women, often persisting well into adulthood. 
        It typically appears on the lower face, jawline, and neck, and is strongly influenced by hormonal fluctuations.
      </p>

      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h3 className="text-2xl font-bold text-gray-900 mb-6">Key Clinical Features</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              title: 'Location Pattern',
              description: 'Predominantly affects lower third of face, jawline, and neck'
            },
            {
              title: 'Timing',
              description: 'Often flares premenstrually and with hormonal changes'
            },
            {
              title: 'Appearance',
              description: 'Deep, inflammatory nodules and cysts'
            },
            {
              title: 'Associated Factors',
              description: 'May be linked to PCOS and other endocrine disorders'
            }
          ].map((feature, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
              <h4 className="font-semibold text-lg mb-2">{feature.title}</h4>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">Evidence-Based Treatment Approaches</h2>
      <div className="space-y-6 mb-8">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">First-Line Treatments</h3>
          <p className="text-gray-700 mb-4">
            According to the Australasian College of Dermatologists' consensus statement (2021), 
            the following treatments have shown significant efficacy:
          </p>
          <ul className="space-y-3">
            {[
              'Topical retinoids (adapalene, tretinoin)',
              'Benzoyl peroxide',
              'Combined oral contraceptive pills (specific approved formulations)',
              'Topical antibiotics in combination with benzoyl peroxide'
            ].map((treatment, index) => (
              <li key={index} className="flex items-start">
                <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                <span>{treatment}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-gray-50 rounded-xl p-6">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">Key Research Findings</h3>
          <div className="space-y-4">
            <div className="bg-white rounded-lg p-4">
              <h4 className="font-semibold mb-2">RACGP Clinical Trial (2022)</h4>
              <p className="text-gray-600">
                A 12-month multicenter study of 327 women with hormonal acne showed:
              </p>
              <ul className="mt-2 space-y-2">
                <li className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>64% improvement with combined oral contraceptive treatment</span>
                </li>
                <li className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Significant reduction in inflammatory lesions by month 6</span>
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-lg p-4">
              <h4 className="font-semibold mb-2">Australian Dermatology Research Network Study (2023)</h4>
              <p className="text-gray-600">
                Comparative analysis of treatment modalities in 452 patients demonstrated:
              </p>
              <ul className="mt-2 space-y-2">
                <li className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>72% success rate with combined therapy approach</span>
                </li>
                <li className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Lower relapse rates with maintenance therapy</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-yellow-50 rounded-xl p-6 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">What This Means for Patients</h2>
        <div className="space-y-4">
          <p className="text-gray-700">
            The research clearly shows that hormonal acne is a treatable condition, but patience is key. 
            Most treatments take 3-6 months to show significant improvement. The most successful approach 
            is often a combination of treatments, tailored to your specific needs.
          </p>
          <p className="text-gray-700">
            While over-the-counter products may help, the evidence strongly supports seeking professional 
            medical advice for persistent hormonal acne. Your doctor can assess whether underlying 
            hormonal conditions need investigation and recommend the most appropriate treatment combination.
          </p>
          <p className="text-gray-700">
            The studies also highlight the importance of maintenance therapy to prevent relapse, with 
            many patients benefiting from ongoing treatment even after initial clearance.
          </p>
        </div>
      </div>
    </>
  );

  const symptoms = [
    'Deep, painful cysts',
    'Inflammatory nodules',
    'Premenstrual flares',
    'Lower face and jawline distribution',
    'Persistent scarring',
    'Associated hormonal symptoms'
  ];

  const treatments = [
    {
      stage: 'Initial Assessment',
      description: 'Comprehensive evaluation of acne pattern and hormonal factors',
      duration: '30-45 minutes'
    },
    {
      stage: 'First-Line Treatment',
      description: 'Topical therapy and/or oral contraceptives',
      duration: '3-6 months'
    },
    {
      stage: 'Treatment Adjustment',
      description: 'Evaluation of response and modification if needed',
      duration: '2-3 months'
    },
    {
      stage: 'Maintenance Therapy',
      description: 'Long-term management to prevent relapse',
      duration: 'Ongoing'
    }
  ];

  const references = [
    {
      authors: 'Australasian College of Dermatologists',
      title: 'Position Statement: Hormonal Acne Management',
      journal: 'Australasian Journal of Dermatology',
      year: '2021',
      doi: '10.1111/ajd.13685'
    },
    {
      authors: 'Royal Australian College of General Practitioners',
      title: 'Management of Adult Female Acne',
      journal: 'Australian Family Physician',
      year: '2022',
      doi: '10.31128/AFP-06-2022-COVID-19'
    },
    {
      authors: 'Australian Dermatology Research Network',
      title: 'Comparative Analysis of Hormonal Acne Treatments',
      journal: 'Clinical and Experimental Dermatology',
      year: '2023',
      doi: '10.1111/ced.15214'
    }
  ];

  const audioTranscript = `
    In this comprehensive guide to hormonal acne treatment, we'll explore the latest evidence-based 
    approaches from Australian clinical guidelines. Learn about the most effective treatments, 
    what the research shows, and what this means for your personal treatment journey. We'll discuss 
    both medical and lifestyle interventions, helping you understand the best path forward for 
    managing this challenging condition.
  `;

  return (
    <MedicalBlogTemplate
      title="Understanding and Treating Hormonal Acne: An Evidence-Based Approach"
      subtitle="A comprehensive guide to hormonal acne management, based on the latest Australian clinical guidelines and research"
      heroImage="https://images.unsplash.com/photo-1576086213369-97a306d36557"
      publishDate="February 3, 2024"
      readTime={15}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      category="Dermatology"
      tags={['Acne', 'Hormonal Health', 'Skin Conditions', 'Women\'s Health']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={false}
    />
  );
};

export default HormonalAcneTreatment; 