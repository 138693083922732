import React, { useRef, Suspense, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ScrollControls, useScroll, PerspectiveCamera, Center } from '@react-three/drei';
import { motion } from 'framer-motion';
import * as THREE from 'three';

function DNAModel() {
  const modelRef = useRef();
  const data = useScroll();
  const [modelLoaded, setModelLoaded] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(
      '/models/dna.glb',
      (gltf) => {
        gltf.scene.traverse((child) => {
          if (child.isMesh) {
            const material = new THREE.MeshPhysicalMaterial({
              color: new THREE.Color('#DDC6A8'),
              metalness: 0.6,
              roughness: 0.4,
              clearcoat: 0.8,
              clearcoatRoughness: 0.2,
              emissive: new THREE.Color('#DDC6A8'),
              emissiveIntensity: 0.2,
            });
            child.material = material;
            child.material.needsUpdate = true;
          }
        });

        // Center the model based on geometry bounds
        const box = new THREE.Box3().setFromObject(gltf.scene);
        const size = box.getSize(new THREE.Vector3());
        const center = box.getCenter(new THREE.Vector3());
        
        // Offset to the right and adjust for proper centering
        gltf.scene.position.set(-center.x + (size.x * 0.3), -center.y, -center.z);
        
        modelRef.current = gltf.scene;
        setModelLoaded(true);
      },
      undefined,
      (error) => console.error('Error loading DNA model:', error)
    );
  }, []);

  useFrame((state, delta) => {
    if (!modelRef.current) return;
    
    // Only rotate around Y-axis with fixed position
    modelRef.current.rotation.y += delta * 0.3; // Slower rotation
    
    // Increased scale (9x larger than original)
    const baseScale = isMobile ? 4.5 : 7.2;
    modelRef.current.scale.setScalar(baseScale);
  });

  return modelLoaded ? (
    <Center>
      <primitive
        ref={modelRef}
        object={modelRef.current}
      />
    </Center>
  ) : null;
}

function Scene() {
  return (
    <Canvas
      camera={{ position: [0, 0, 5], fov: 45 }}
      style={{ height: '100%', background: '#1A1A1A' }}
    >
      <PerspectiveCamera makeDefault position={[0, 0, 5]} fov={45}>
        <ambientLight intensity={0.6} />
        <pointLight position={[10, 10, 10]} intensity={1.5} />
        <spotLight
          position={[-10, -10, -10]}
          angle={0.3}
          penumbra={1}
          intensity={1.5}
        />
      </PerspectiveCamera>
      
      <ScrollControls pages={1} damping={3}>
        <Suspense fallback={<LoadingFallback />}>
          <DNAModel />
        </Suspense>
      </ScrollControls>
    </Canvas>
  );
}

function LoadingFallback() {
  const meshRef = useRef();
  
  useFrame((state) => {
    if (meshRef.current) {
      meshRef.current.rotation.x = state.clock.elapsedTime * 0.5;
      meshRef.current.rotation.y = state.clock.elapsedTime * 0.2;
    }
  });

  return (
    <Center>
      <mesh ref={meshRef}>
        <torusKnotGeometry args={[1, 0.3, 128, 16]} />
        <meshPhysicalMaterial
          color="#DDC6A8"
          wireframe
          metalness={0.6}
          roughness={0.4}
        />
      </mesh>
    </Center>
  );
}

export default function DNASection() {
  const [isLoading, setIsLoading] = useState(true);
  const [scrollY, setScrollY] = useState(0);
  const sectionRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        const scrollProgress = 1 - (rect.top / window.innerHeight);
        setScrollY(scrollProgress);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const calculateOpacity = (threshold) => {
    return Math.min(Math.max((scrollY - threshold) * 2, 0), 1);
  };

  return (
    <div className="relative h-screen bg-[#1A1A1A]">
      {/* DNA Canvas Container */}
      <div className="absolute inset-0">
        <Scene />
      </div>

      {/* Text Content - Clean, Personal Style */}
      <div ref={sectionRef} className="relative h-full overflow-hidden">
        <div className="relative z-10 max-w-2xl mx-auto px-6 pt-16">
          <div className="text-left space-y-8">
            <motion.h2 
              className="text-3xl md:text-4xl font-light mb-4 text-white"
              style={{ opacity: calculateOpacity(0.1) }}
            >
              Your health story begins with DNA
            </motion.h2>
            <motion.div 
              className="space-y-6 font-light"
              style={{ opacity: calculateOpacity(0.2) }}
            >
              <p className="text-base md:text-lg leading-relaxed text-white">
                Just like your fingerprint, your DNA is unique to you. It holds the key to understanding how your body works, what health challenges you might face, and most importantly – how we can help you stay ahead of them.
              </p>
              <p className="text-base md:text-lg leading-relaxed text-white">
                We use advanced genetic testing not to overwhelm you with complex data, but to create a clear picture of your health. Think of it as your personal health roadmap, helping us spot potential concerns early and create treatment plans that work specifically for you.
              </p>
              <p className="text-base md:text-lg leading-relaxed text-white">
                By understanding your genetic code, we can move beyond one-size-fits-all medicine to provide care that's truly personalized. It's about making healthcare simpler, more effective, and focused on keeping you healthy rather than just treating symptoms.
              </p>
              <div className="pt-4">
                <a
                  href="/dynamic-booking"
                  className="inline-block px-8 py-4 bg-[#DDC6A8] text-[#1A1A1A] rounded-full font-medium hover:bg-[#1A1A1A] hover:text-[#DDC6A8] border border-[#DDC6A8] transition-all duration-300"
                >
                  Book Your DNA Analysis
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Loading overlay */}
      {isLoading && (
        <div className="absolute inset-0 z-50 bg-[#1A1A1A] flex items-center justify-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin" />
        </div>
      )}
    </div>
  );
} 