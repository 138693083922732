import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CategoryBlogCarousel from '../../components/CategoryBlogCarousel';

const ProstateHealth = () => {
  // SEO metadata
  const seoTitle = "Prostate Health: A Comprehensive Guide for Australian Men | Sydney GP";
  const seoDescription = "Everything Sydney men need to know about prostate health - from prevention to screenings and treatment options with Dr. Terry Nguyen.";
  const seoKeywords = "prostate health, prostate cancer screening, men's health Sydney, Eastern Suburbs doctor, BPH treatment, prostate symptoms";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Prostate Health: A Comprehensive Guide for Australian Men</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              As a men's health specialist serving <strong>Sydney's Eastern Suburbs</strong>, I regularly discuss prostate health with my patients. This small gland plays a crucial role in male reproductive health, but it can also be the source of several common health concerns as men age.
            </p>
            <p className="text-gray-700 mb-4">
              For men in <strong>Sydney</strong>, particularly those in <strong>Bondi, Double Bay,</strong> and <strong>Paddington</strong>, understanding prostate health is an essential part of maintaining overall wellbeing. This guide covers everything from normal prostate function to common conditions and the latest screening and treatment options.
            </p>
            <p className="text-gray-700 mb-4">
              If you have concerns about your prostate health or are due for a prostate check, <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">book a confidential consultation</Link> at my practice in Sydney's Eastern Suburbs.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/drterry_menshealth5.png" 
                alt="Prostate health consultation in Sydney with Dr. Terry Nguyen" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Professional prostate health consultations available in Sydney's Eastern Suburbs
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      
      {/* TODO: Add full content sections for prostate anatomy, BPH, prostatitis, prostate cancer */}
      
      <section id="conclusion" className="mt-12">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Taking Control of Your Prostate Health</h2>
        <p className="text-gray-700 mb-4">
          Being proactive about prostate health is one of the most important steps men can take to protect their overall wellbeing. As your dedicated men's health GP in <strong>Sydney's Eastern Suburbs</strong>, I provide comprehensive prostate assessments in a comfortable, confidential environment.
        </p>
        <p className="text-gray-700 mb-4">
          Remember that many prostate conditions are highly treatable when caught early. Regular check-ups, particularly after age 50 (or earlier for those with risk factors), can make all the difference in maintaining your prostate health.
        </p>
        <div className="text-center my-8">
          <Link
            to="/dynamic-booking"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Book a Prostate Health Assessment
          </Link>
          <p className="text-gray-500 text-sm mt-2">
            Discreet, professional care for men across Sydney's Eastern Suburbs
          </p>
        </div>
      </section>
      
      <div className="mt-12 mb-6">
        <CategoryBlogCarousel category="mens-health" limit={4} />
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/drterry_menshealth5.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/prostate-health-guide-sydney" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/drterry_menshealth5.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/prostate-health-guide-sydney" />
      </Helmet>
    
      <MedicalBlogTemplate
        title="Prostate Health: A Comprehensive Guide for Australian Men"
        subtitle="A Sydney GP's guide to maintaining prostate health"
        heroImage="/images/drterry_menshealth5.png"
        publishDate="June 28, 2024"
        readTime={15}
        content={content}
        category="Men's Health"
        tags={['Prostate Health', 'Prostate Cancer', 'BPH', 'Men\'s Health', 'Sydney', 'Eastern Suburbs']}
        showEmergencyBox={false}
      />
    </>
  );
};

export default ProstateHealth; 