import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogCarousel from '../components/BlogCarousel';

const SportsMedicine = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Sports Medicine & Rehabilitation Sydney | Performance Optimization | HomeDoc</title>
        <meta name="description" content="Expert sports medicine and rehabilitation services in Sydney's Eastern Suburbs. Specialized care for injuries, performance optimization, and recovery by Dr. Terry Nguyen." />
        <meta name="keywords" content="sports medicine sydney, rehabilitation eastern suburbs, sports injury treatment, athletic performance, exercise therapy, physiotherapy, sports doctor bondi" />
        <link rel="canonical" href="https://homedoc.com.au/sports-medicine" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalSpecialty",
            "name": "Sports Medicine",
            "description": "Specialized medical care for sports injuries and athletic performance optimization in Sydney's Eastern Suburbs",
            "relevantSpecialty": {
              "@type": "MedicalSpecialty",
              "name": "Sports Medicine"
            },
            "medicineSystem": "Western Medicine",
            "recognizingAuthority": {
              "@type": "Organization",
              "name": "Australian College of Sport and Exercise Physicians"
            }
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">Sports Medicine & Rehabilitation</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8]/80 mb-6">Expert Care for Athletes in Sydney's Eastern Suburbs</h2>
            <p className="text-xl text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Specialized treatment and performance optimization for athletes of all levels, from weekend warriors to elite competitors.
              <span className="block mt-4">
                <Link to="/dynamic-booking" className="text-[#DDC6A8] underline font-medium hover:text-white">
                  Book a consultation
                </Link> with Dr. Terry Nguyen, sports medicine specialist.
              </span>
            </p>
          </div>

          {/* Main content placeholder */}
          <div className="text-center mb-16">
            <p className="text-[#DDC6A8]/80">
              Full content for Sports Medicine page will be implemented in subsequent edits.
            </p>
          </div>
          
          {/* Call to Action */}
          <div className="text-center">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
            >
              Book a Sports Medicine Consultation
            </Link>
          </div>
        </div>
      </div>
      
      {/* Related Articles Carousel */}
      <div className="py-12 bg-[#1A1A1A]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-light text-[#DDC6A8] mb-8 text-center">Related Sports Medicine Articles</h2>
          <BlogCarousel />
        </div>
      </div>
    </div>
  );
};

export default SportsMedicine; 