import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { format } from 'date-fns';

export default function BookingDetails({ bookingId, onClose }) {
  console.log("BookingDetails rendering with bookingId:", bookingId);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBooking, setEditedBooking] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    console.log("BookingDetails useEffect triggered with bookingId:", bookingId);
    
    const fetchBookingDetails = async () => {
      try {
        console.log("Fetching booking details for:", bookingId);
        const bookingRef = doc(db, 'bookings', bookingId);
        const bookingDoc = await getDoc(bookingRef);
        
        if (!bookingDoc.exists()) {
          console.log("Booking not found:", bookingId);
          setError('Booking not found');
          setLoading(false);
          return;
        }
        
        const bookingData = {
          id: bookingDoc.id,
          ...bookingDoc.data(),
          date: bookingDoc.data().date?.toDate() // Convert Firestore timestamp to JS Date
        };
        
        console.log("Booking data retrieved:", bookingId);
        
        // Check location data structure
        if (bookingData.location && typeof bookingData.location === 'object') {
          console.log("Location is an object:", bookingData.location);
          
          // Convert location object to string if needed
          if (bookingData.location.address) {
            console.log("Converting location object to address string");
            bookingData.locationDisplay = bookingData.location.address;
          } else {
            console.log("Location object without address:", bookingData.location);
            bookingData.locationDisplay = "Location details not available";
          }
        } else {
          console.log("Location is a string:", bookingData.location);
          bookingData.locationDisplay = bookingData.location;
        }
        
        // If we have locationData, use that for detailed location information
        if (bookingData.locationData) {
          console.log("Using locationData for detailed information:", bookingData.locationData);
          bookingData.locationDetails = bookingData.locationData;
        }
        
        setBooking(bookingData);
        setEditedBooking(bookingData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching booking details:', err);
        setError('Failed to load booking details');
        setLoading(false);
      }
    };

    if (bookingId) {
      fetchBookingDetails();
    }
    
    return () => {
      console.log("BookingDetails unmounting");
    };
  }, [bookingId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Input changed:", name, value);
    setEditedBooking(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePatientDetailsChange = (e) => {
    const { name, value } = e.target;
    console.log("Patient details changed:", name, value);
    setEditedBooking(prev => ({
      ...prev,
      patientDetails: {
        ...prev.patientDetails,
        [name]: value
      }
    }));
  };

  const handleSave = async () => {
    console.log("Saving booking changes:", editedBooking);
    setIsSaving(true);
    try {
      const bookingRef = doc(db, 'bookings', bookingId);
      
      // Prepare data for Firestore (remove id field)
      const { id, locationDisplay, ...bookingData } = editedBooking;
      
      console.log("Data to save:", bookingData);
      
      await updateDoc(bookingRef, {
        ...bookingData,
        updatedAt: new Date()
      });
      
      console.log("Booking updated successfully");
      setBooking(editedBooking);
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating booking:', err);
      setError('Failed to update booking');
    } finally {
      setIsSaving(false);
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Not specified';
    try {
      return format(date, 'dd/MM/yyyy h:mm a');
    } catch (err) {
      console.error("Error formatting date:", date, err);
      return String(date);
    }
  };

  if (loading) {
    console.log("BookingDetails is loading");
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#2A2A2A] rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
          <div className="text-center">
            <div className="w-12 h-12 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
            <p className="text-[#DDC6A8]">Loading booking details...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    console.log("BookingDetails encountered an error:", error);
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-[#2A2A2A] rounded-lg p-6 max-w-2xl w-full">
          <div className="text-center">
            <p className="text-red-500 mb-4">{error}</p>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!booking) {
    console.log("BookingDetails has no booking data");
    return null;
  }

  console.log("Rendering BookingDetails with mode:", isEditing ? "editing" : "viewing");

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2A2A2A] rounded-lg p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-[#DDC6A8]">
            Booking Details
          </h2>
          <button
            onClick={onClose}
            className="text-[#DDC6A8] hover:text-[#DDC6A8]/70"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {isEditing ? (
          // Edit Mode
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm text-[#DDC6A8]/70 mb-1">Service</label>
                <input
                  type="text"
                  name="serviceType"
                  value={editedBooking.service?.name || editedBooking.serviceType || ''}
                  onChange={handleInputChange}
                  className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                />
              </div>
              
              <div>
                <label className="block text-sm text-[#DDC6A8]/70 mb-1">Status</label>
                <select
                  name="status"
                  value={editedBooking.status || 'pending'}
                  onChange={handleInputChange}
                  className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                >
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
              </div>
              
              <div>
                <label className="block text-sm text-[#DDC6A8]/70 mb-1">Price ($)</label>
                <input
                  type="number"
                  name="price"
                  value={editedBooking.price || ''}
                  onChange={handleInputChange}
                  className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                />
              </div>
              
              <div>
                <label className="block text-sm text-[#DDC6A8]/70 mb-1">Location</label>
                <input
                  type="text"
                  name="location"
                  value={editedBooking.locationDisplay || ''}
                  onChange={handleInputChange}
                  className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                />
              </div>
            </div>
            
            <div className="border-t border-[#DDC6A8]/10 pt-4">
              <h3 className="text-lg font-medium text-[#DDC6A8] mb-3">Patient Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm text-[#DDC6A8]/70 mb-1">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={editedBooking.patientDetails?.firstName || ''}
                    onChange={handlePatientDetailsChange}
                    className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                  />
                </div>
                
                <div>
                  <label className="block text-sm text-[#DDC6A8]/70 mb-1">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={editedBooking.patientDetails?.lastName || ''}
                    onChange={handlePatientDetailsChange}
                    className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                  />
                </div>
                
                <div>
                  <label className="block text-sm text-[#DDC6A8]/70 mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={editedBooking.patientDetails?.email || ''}
                    onChange={handlePatientDetailsChange}
                    className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                  />
                </div>
                
                <div>
                  <label className="block text-sm text-[#DDC6A8]/70 mb-1">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={editedBooking.patientDetails?.phone || ''}
                    onChange={handlePatientDetailsChange}
                    className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                  />
                </div>
                
                <div className="md:col-span-2">
                  <label className="block text-sm text-[#DDC6A8]/70 mb-1">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={editedBooking.patientDetails?.address || ''}
                    onChange={handlePatientDetailsChange}
                    className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
                  />
                </div>
              </div>
            </div>
            
            <div className="border-t border-[#DDC6A8]/10 pt-4">
              <h3 className="text-lg font-medium text-[#DDC6A8] mb-3">Notes</h3>
              <textarea
                name="notes"
                value={editedBooking.notes || ''}
                onChange={handleInputChange}
                rows={4}
                className="w-full bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
              ></textarea>
            </div>
            
            <div className="flex justify-end space-x-3 pt-4">
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8]/10"
                disabled={isSaving}
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg hover:bg-[#DDC6A8]/90"
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>
        ) : (
          // View Mode
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Booking ID</p>
                <p className="text-[#DDC6A8]">{booking.id}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Date & Time</p>
                <p className="text-[#DDC6A8]">{formatDate(booking.date)}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Service</p>
                <p className="text-[#DDC6A8]">{booking.service?.name || booking.serviceType}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Status</p>
                <p className="text-[#DDC6A8]">{booking.status || 'pending'}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Price</p>
                <p className="text-[#DDC6A8]">${booking.price}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Location</p>
                <p className="text-[#DDC6A8]">{booking.locationDisplay || 'Not specified'}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Payment Status</p>
                <p className="text-[#DDC6A8]">{booking.paymentStatus || 'Not paid'}</p>
              </div>
              
              <div>
                <p className="text-sm text-[#DDC6A8]/70">Payment ID</p>
                <p className="text-[#DDC6A8]">{booking.paymentId || 'Not available'}</p>
              </div>
            </div>
            
            <div className="border-t border-[#DDC6A8]/10 pt-4">
              <h3 className="text-lg font-medium text-[#DDC6A8] mb-3">Patient Details</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Name</p>
                  <p className="text-[#DDC6A8]">
                    {booking.patientDetails?.firstName} {booking.patientDetails?.lastName}
                  </p>
                </div>
                
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Email</p>
                  <p className="text-[#DDC6A8]">{booking.patientDetails?.email}</p>
                </div>
                
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Phone</p>
                  <p className="text-[#DDC6A8]">{booking.patientDetails?.phone}</p>
                </div>
                
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Address</p>
                  <p className="text-[#DDC6A8]">{booking.patientDetails?.address}</p>
                </div>
              </div>
            </div>
            
            {booking.notes && (
              <div className="border-t border-[#DDC6A8]/10 pt-4">
                <h3 className="text-lg font-medium text-[#DDC6A8] mb-2">Notes</h3>
                <p className="text-[#DDC6A8]">{booking.notes}</p>
              </div>
            )}
            
            <div className="border-t border-[#DDC6A8]/10 pt-4">
              <h3 className="text-lg font-medium text-[#DDC6A8] mb-2">Timestamps</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Created</p>
                  <p className="text-[#DDC6A8]">{formatDate(booking.createdAt?.toDate())}</p>
                </div>
                
                <div>
                  <p className="text-sm text-[#DDC6A8]/70">Last Updated</p>
                  <p className="text-[#DDC6A8]">{formatDate(booking.updatedAt?.toDate())}</p>
                </div>
              </div>
            </div>
            
            <div className="flex justify-end space-x-3 pt-4">
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg hover:bg-[#DDC6A8]/90"
              >
                Edit Booking
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 