import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import Footer from '../components/Footer';

const Telemedicine = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <SEO 
        title="Telemedicine Australia | Online Doctor Consultations | Dr. Terry Nguyen"
        description="Premium telemedicine consultations available Australia-wide with Dr. Terry Nguyen. Secure video consultations, online prescriptions, and personalized care from a qualified GP."
        keywords={[
          'telemedicine Australia',
          'online doctor consultation',
          'telehealth GP',
          'video doctor appointment',
          'virtual medical consultation',
          'secure telehealth',
          'Medicare telehealth',
          'online prescription',
          'remote healthcare',
          'digital medical certificate',
          'Dr Terry Nguyen',
          'private telehealth Australia'
        ]}
        pageType="telemedicine"
      />
      
      <div className="site-background" />
      
      {/* Hero Section */}
      <div className="relative">
        <div className="absolute inset-0 bg-black/60 z-10"></div>
        <img 
          src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80" 
          alt="Doctor providing telemedicine consultation" 
          className="w-full h-[60vh] object-cover"
        />
        <div className="absolute inset-0 z-20 flex flex-col items-center justify-center text-center px-4">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-light text-white mb-6 max-w-4xl">
            Premium Telemedicine Services Australia-Wide
          </h1>
          <p className="text-xl text-white/90 max-w-2xl mb-8">
            Consult with Dr. Terry Nguyen from anywhere in Australia through our secure, high-quality video platform.
          </p>
          <Link
            to="/dynamic-booking"
            className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
          >
            Book a Telemedicine Consultation
            <svg className="ml-3 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </Link>
        </div>
      </div>
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16">
          {/* Introduction */}
          <div className="mb-16 max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Australia's Premium Telemedicine Experience</h2>
            <p className="text-lg text-[#DDC6A8]/90 mb-4">
              Dr. Terry Nguyen offers comprehensive telehealth consultations to patients across Australia, combining cutting-edge technology with personalized medical care. Whether you're in a bustling city or a remote area, our telemedicine service provides convenient access to expert healthcare.
            </p>
            <p className="text-lg text-[#DDC6A8]/90">
              With both Medicare bulk-billed options and premium private consultations available, we ensure that quality healthcare is accessible to all Australians, regardless of location.
            </p>
          </div>
          
          {/* Benefits Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-20">
            <div>
              <img 
                src="https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80" 
                alt="Doctor during a telehealth consultation" 
                className="rounded-xl shadow-2xl"
              />
            </div>
            
            <div className="space-y-6">
              <h2 className="text-3xl font-light text-[#DDC6A8]">Why Choose Our Telemedicine Service?</h2>
              
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Nationwide Access:</span> Available to all Australians, regardless of location.
                  </p>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Extended hours:</span> Appointments available outside standard office hours.
                  </p>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Continuity of Care:</span> Same doctor for all your consultations, building a trusting relationship.
                  </p>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Secure platform:</span> Military-grade encryption protects your privacy and personal information.
                  </p>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Digital Prescriptions:</span> Electronic prescriptions sent directly to your preferred pharmacy.
                  </p>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-6 w-6 text-[#DDC6A8]">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="ml-3 text-[#DDC6A8]/90">
                    <span className="font-medium text-[#DDC6A8]">Medicare Options:</span> Bulk-billing available for eligible patients.
                  </p>
                </div>
              </div>
              
              <div className="mt-10">
                <Link
                  to="/dynamic-booking"
                  className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
                >
                  Book a Telemedicine Consultation
                </Link>
              </div>
            </div>
          </div>
          
          {/* Services Section */}
          <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl mb-20">
            <div className="px-6 py-10 sm:px-10">
              <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">What Can Be Treated via Telemedicine?</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <div className="bg-[#1A1A1A]/70 p-5 rounded-lg">
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Common Illnesses</h3>
                  <ul className="text-[#DDC6A8]/90 space-y-2">
                    <li>• Cold and flu symptoms</li>
                    <li>• Sinus infections</li>
                    <li>• Allergies</li>
                    <li>• Headaches</li>
                    <li>• Digestive issues</li>
                    <li>• Skin rashes and infections</li>
                  </ul>
                </div>
                
                <div className="bg-[#1A1A1A]/70 p-5 rounded-lg">
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Children's Health</h3>
                  <ul className="text-[#DDC6A8]/90 space-y-2">
                    <li>• Common childhood illnesses</li>
                    <li>• Rashes and skin conditions</li>
                    <li>• Nutritional guidance</li>
                    <li>• Developmental concerns</li>
                    <li>• Iron deficiency assessment</li>
                    <li>• Behavioral consultations</li>
                  </ul>
                </div>
                
                <div className="bg-[#1A1A1A]/70 p-5 rounded-lg">
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Ongoing Care</h3>
                  <ul className="text-[#DDC6A8]/90 space-y-2">
                    <li>• Prescription renewals</li>
                    <li>• Mental health check-ins</li>
                    <li>• Chronic condition management</li>
                    <li>• Follow-up consultations</li>
                    <li>• Reviewing test results</li>
                    <li>• Specialist referrals</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          
          {/* How It Works */}
          <div className="text-center mb-20">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">How Our Telemedicine Service Works</h2>
            <p className="text-lg text-[#DDC6A8]/90 max-w-3xl mx-auto mb-10">
              Our telehealth service is simple, secure, and designed with your convenience in mind.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm p-6 rounded-xl">
                <div className="w-12 h-12 bg-[#DDC6A8] text-[#1A1A1A] rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">1</div>
                <h3 className="text-xl font-light text-[#DDC6A8] mb-2">Book Your Appointment</h3>
                <p className="text-[#DDC6A8]/90">
                  Select a convenient time for your telemedicine consultation through our online booking system.
                </p>
              </div>
              
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm p-6 rounded-xl">
                <div className="w-12 h-12 bg-[#DDC6A8] text-[#1A1A1A] rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">2</div>
                <h3 className="text-xl font-light text-[#DDC6A8] mb-2">Receive Instructions</h3>
                <p className="text-[#DDC6A8]/90">
                  We'll send you simple instructions and a secure link to join your video consultation.
                </p>
              </div>
              
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm p-6 rounded-xl">
                <div className="w-12 h-12 bg-[#DDC6A8] text-[#1A1A1A] rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">3</div>
                <h3 className="text-xl font-light text-[#DDC6A8] mb-2">Connect with Dr. Terry</h3>
                <p className="text-[#DDC6A8]/90">
                  At your appointment time, join the secure video call and speak directly with Dr. Terry Nguyen.
                </p>
              </div>
              
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm p-6 rounded-xl">
                <div className="w-12 h-12 bg-[#DDC6A8] text-[#1A1A1A] rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">4</div>
                <h3 className="text-xl font-light text-[#DDC6A8] mb-2">Receive Care</h3>
                <p className="text-[#DDC6A8]/90">
                  Get diagnosis, treatment plans, prescriptions, referrals, and medical certificates as needed.
                </p>
              </div>
            </div>
          </div>
          
          {/* Medicare & Private Billing */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
            <div className="bg-[#2A2A2A]/40 p-8 rounded-xl">
              <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Medicare Telehealth</h3>
              <p className="text-[#DDC6A8]/90 mb-4">
                Eligible patients can access Medicare bulk-billed telemedicine consultations, making healthcare accessible to all Australians.
              </p>
              <h4 className="text-xl font-light text-[#DDC6A8] mb-2">Eligibility Criteria:</h4>
              <ul className="text-[#DDC6A8]/90 space-y-2 mb-4">
                <li>• Valid Medicare card</li>
                <li>• Chronic disease management patients</li>
                <li>• Children under 16 years</li>
                <li>• Patients in rural and remote areas</li>
                <li>• Elderly patients (75+ years)</li>
              </ul>
              <p className="text-[#DDC6A8]/90">
                Our team will confirm your eligibility when you book your appointment.
              </p>
            </div>
            
            <div className="bg-[#2A2A2A]/40 p-8 rounded-xl">
              <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Premium Private Telemedicine</h3>
              <p className="text-[#DDC6A8]/90 mb-4">
                For patients seeking extended consultations, premium care, and enhanced convenience, our private billing options offer exceptional value.
              </p>
              <h4 className="text-xl font-light text-[#DDC6A8] mb-2">Service Fees:</h4>
              <ul className="text-[#DDC6A8]/90 space-y-2 mb-4">
                <li>• Standard Telemedicine Consultation (15-20 min): $85</li>
                <li>• Extended Telemedicine Consultation (30-45 min): $150</li>
                <li>• Mental Health Consultation (45-60 min): $190</li>
                <li>• Follow-up Consultation (10-15 min): $65</li>
              </ul>
              <p className="text-[#DDC6A8]/90">
                Payment can be made securely online at the time of booking.
              </p>
            </div>
          </div>
          
          {/* FAQ Section */}
          <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl mb-20">
            <div className="px-6 py-10 sm:px-10">
              <h2 className="text-3xl font-light text-[#DDC6A8] mb-10 text-center">Frequently Asked Questions</h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">What technology do I need for a telemedicine consultation?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    You'll need a device with a camera and microphone (smartphone, tablet, or computer), a stable internet connection, and a private, well-lit space. Our platform works on all major browsers without requiring additional software.
                  </p>
                  
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Can I access telehealth services from anywhere in Australia?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    Yes, our telemedicine services are available to patients across all Australian states and territories, including remote and rural areas. As long as you have internet access, you can connect with Dr. Terry.
                  </p>
                  
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">How do prescriptions work with telemedicine?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    Dr. Terry can issue electronic prescriptions that are sent directly to your email or phone. You can then take this to any pharmacy in Australia. For certain medications, we can coordinate with your local pharmacy for delivery.
                  </p>
                </div>
                
                <div>
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Is my telemedicine consultation private and secure?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    Absolutely. We use advanced encryption and secure video technology that complies with all Australian privacy laws and healthcare standards. Your consultation is completely private and confidential, just like an in-person visit.
                  </p>
                  
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">What if I need to see a specialist?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    Dr. Terry can provide referrals to specialists as needed. These referrals are valid throughout Australia and can be sent electronically to both you and the specialist's office to streamline the process.
                  </p>
                  
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-3">Can I get a medical certificate through telemedicine?</h3>
                  <p className="text-[#DDC6A8]/90 mb-6">
                    Yes, Dr. Terry can issue digital medical certificates during your telemedicine consultation when clinically appropriate. These certificates are legally valid and can be provided for work, school, or other purposes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          {/* CTA Section */}
          <div className="text-center">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Experience Premium Telemedicine Today</h2>
            <p className="text-lg text-[#DDC6A8]/90 max-w-3xl mx-auto mb-8">
              Connect with Dr. Terry Nguyen for personalized, high-quality healthcare from the comfort of your home, anywhere in Australia.
            </p>
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-10 py-5 border border-[#DDC6A8] text-xl font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
            >
              Book Your Telemedicine Consultation Now
            </Link>
            <p className="mt-4 text-[#DDC6A8]/60">
              Medicare rebates available for eligible consultations
            </p>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default Telemedicine; 