import { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../config/firebase';
import { collection, addDoc, Timestamp, query, where, getDocs, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';
import bgImage from '../assets/images/Inspire2.jpg';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import QuickRegister from '../components/QuickRegister';
import { useLocation, useNavigate } from 'react-router-dom';
import LocationMap from '../components/LocationMap';
import { addDays, isWeekend, format, isSunday, differenceInDays, parse, formatISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// Log using the global pre-initialized Stripe instance
console.log('DynamicBooking - Using pre-initialized Stripe instance from window.stripePromise');

// Base pricing for different service types
const serviceTypes = {
  HOUSE_CALL: {
    id: 'house-call',
    name: 'House Call',
    basePrice: 160, // Base price for Zone 1, daytime, 72+ hours in advance
    description: 'Doctor visits you at your location'
  },
  TELEMEDICINE: {
    id: 'telemedicine',
    name: 'Telemedicine',
    basePrice: 75, // Base price for daytime, 72+ hours in advance
    description: 'Video consultation with the doctor'
  },
  CLINIC_VISIT: {
    id: 'clinic-visit',
    name: 'Clinic Visit',
    basePrice: 75,
    description: 'Visit the doctor at our Waterloo clinic'
  }
};

// Location-based pricing factors
const locationFactors = {
  'Waterloo Clinic': 1.0
};

// Time-based surcharges (percentage-based surcharges, not fixed prices)
const getTimeSurcharge = (hour, zone = 1) => {
  // Base prices by zone
  const zoneBasePrice = {
    1: 160, // Zone 1 base price
    2: 170, // Zone 2 base price (updated)
    3: 180, // Zone 3 base price (updated)
    4: 200, // Zone 4 base price (added)
    5: 250, // Zone 5+ base price (added)
    'Waterloo Clinic': 75, // Clinic visit base price
    'Telemedicine': 75, // Telemedicine base price
  };

  // Get the base price for the zone
  const basePrice = zoneBasePrice[zone] || zoneBasePrice[1]; // Default to Zone 1 if zone not found

  console.log(`Calculating time surcharge for hour: ${hour}, zone: ${zone}`);

  // Calculate surcharges based on time of day (as multipliers)
  if (hour >= 0 && hour < 10) {
    // After hours: 12am-10am
    return 2.167; // 2.167x multiplier
  }
  
  if (hour >= 10 && hour < 16) {
    // Daytime: 10am-4pm - standard base price for zone
    return 1.0; // No surcharge (1.0x multiplier)
  }
  
  if (hour >= 16 && hour < 22) {
    // Evening: 4pm-10pm
    if (zone === 'Telemedicine' || zone === 'Waterloo Clinic') {
      return 1.3; // 1.3x multiplier for Telemedicine and Clinic visits
    } else {
      // Apply 1.2x multiplier for all house calls (Zone 1-5)
      return 1.2; 
    }
  }
  
  if (hour >= 22 && hour <= 23) {
    // Night: 10pm-12am
    if (zone === 'Telemedicine' || zone === 'Waterloo Clinic') {
      return 1.75; // 1.75x multiplier for Telemedicine and Clinic visits
    } else {
      // Apply 1.3x multiplier for all house calls (Zone 1-5)
      return 1.3;
    }
  }
  
  return 1.0; // Default multiplier (no surcharge)
};

// Get current time in AEST (Sydney time)
const getCurrentSydneyTime = () => {
  const sydneyTimeZone = 'Australia/Sydney';
  const now = new Date();
  
  // Format date and time in Sydney timezone
  const sydneyDate = formatInTimeZone(now, sydneyTimeZone, 'yyyy-MM-dd');
  const sydneyTime = formatInTimeZone(now, sydneyTimeZone, 'HH:mm');
  
  // Get hour in Sydney time for factor calculation
  const sydneyHour = parseInt(sydneyTime.split(':')[0], 10);
  
  return {
    date: sydneyDate,
    time: sydneyTime,
    hour: sydneyHour,
    fullTime: now
  };
};

// Urgency factors
const urgencyFactors = {
  ASAP: {
    id: 'asap',
    name: 'As Soon As Possible',
    factor: 1.3, // 30% premium
    description: 'Doctor will arrive within 30-60 minutes',
    subtext: 'For urgent medical concerns requiring immediate attention',
    eta: '30-60 minutes',
    color: '#DC2626' // red
  },
  WITHIN_24H: {
    id: 'within-24h',
    name: 'Within 24 Hours',
    factor: 1.2, // 20% premium
    description: 'Doctor will arrive within 24 hours',
    subtext: 'For pressing medical concerns that need attention soon',
    eta: 'Within 24 hours',
    color: '#F59E0B' // amber
  },
  WITHIN_72H: {
    id: 'within-72h',
    name: '24-72 Hours in Advance',
    factor: 1.1, // 10% premium
    description: 'Schedule a visit between 24-72 hours from now',
    subtext: 'For non-urgent concerns that need attention soon',
    eta: '24-72 hours',
    color: '#3B82F6' // blue
  },
  BEYOND_72H: {
    id: 'beyond-72h',
    name: 'More than 72 Hours in Advance',
    factor: 1.0, // No premium
    description: 'Schedule a visit more than 3 days in advance',
    subtext: 'For routine check-ups and non-urgent concerns',
    eta: '3+ days',
    color: '#059669' // green
  }
};

// Payment method limits
const PAYMENT_LIMITS = {
  AFTERPAY: {
    MIN: 1000,    // $10
    MAX: 200000,  // $2,000
  },
  ZIP: {
    MIN: 1000,    // $10
    MAX: 150000,  // $1,500
  }
};

// Timing factors for price adjustments
const timingFactors = {
  ASAP: 2.0, // 100% premium for ASAP bookings
  NEXT_DAY: 1.5, // 50% premium for next day
  WITHIN_3_DAYS: 1.25, // 25% premium for 2-3 days
  WITHIN_WEEK: 1.1, // 10% premium for 4-7 days
  STANDARD: 1.0, // Standard price for > 1 week
  WEEKEND: 1.2, // Additional 20% for weekends
  SUNDAY: 1.3, // Additional 30% for Sundays
  AFTER_HOURS: 1.35 // Additional 35% for after hours (before 8am or after 6pm)
};

// Business hours
const businessHours = {
  start: 8, // 8 AM
  end: 18 // 6 PM
};

export default function DynamicBooking() {
  const { currentUser } = useAuth();
  const [step, setStep] = useState(1);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedUrgency, setSelectedUrgency] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [bookings, setBookings] = useState([]);
  const [price, setPrice] = useState(0);
  const [showQuickRegister, setShowQuickRegister] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    medicare: '',
    userId: currentUser?.uid || 'anonymous'
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [formProgress, setFormProgress] = useState(0);
  
  // Script to disable non-Tuesday dates in the date picker
  useEffect(() => {
    // Function to disable all days except Tuesdays
    const disableNonTuesdays = () => {
      // Get all date inputs in the document
      const dateInputs = document.querySelectorAll('input[type="date"]');
      
      dateInputs.forEach(input => {
        if (input.classList.contains('clinic-date-picker')) {
          // Create a custom date picker controller to disable non-Tuesday dates
          input.addEventListener('input', (e) => {
            const selectedDate = new Date(e.target.value);
            if (selectedDate.getDay() !== 2) { // 2 is Tuesday
              const nextTuesday = getNextTuesday(selectedDate);
              input.value = nextTuesday.toISOString().split('T')[0];
            }
          });

          // Intercept the click to prevent opening the native date picker
          // and instead open a custom calendar that only shows Tuesdays
          input.addEventListener('click', (e) => {
            // Let the native date picker open, but prepare to filter it
            setTimeout(() => {
              // Find all date cells in the calendar popup
              const calendarCells = document.querySelectorAll('td[role="gridcell"]');
              if (calendarCells.length) {
                // For each cell, if it's not a Tuesday, disable it
                calendarCells.forEach(cell => {
                  const dateAttr = cell.getAttribute('data-date');
                  if (dateAttr) {
                    const cellDate = new Date(dateAttr);
                    if (cellDate.getDay() !== 2) { // Not a Tuesday
                      cell.setAttribute('disabled', 'disabled');
                      cell.classList.add('disabled');
                      cell.style.opacity = '0.3';
                      cell.style.pointerEvents = 'none';
                      
                      // Prevent clicks on disabled dates
                      cell.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }, true);
                    }
                  }
                });
              }
            }, 50);
          });
        }
      });
    };
    
    // Helper function to get the next Tuesday from a given date
    const getNextTuesday = (date) => {
      const day = date.getDay(); // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.
      const daysUntilTuesday = (2 + 7 - day) % 7; // Calculate days until Tuesday
      const nextTuesday = new Date(date);
      nextTuesday.setDate(date.getDate() + daysUntilTuesday);
      return nextTuesday;
    };
    
    // Call the function after the component has mounted
    if (step === 3 && selectedService?.id === 'clinic-visit') {
      setTimeout(disableNonTuesdays, 100);
    }
  }, [step, selectedService]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.4
      }
    }
  };

  const progressVariants = {
    hidden: { width: 0 },
    visible: { 
      width: `${formProgress}%`,
      transition: { duration: 0.5 }
    }
  };

  // Handle step progression
  const nextStep = () => {
    console.log("Moving to next step, current step:", step);
    console.log("Current selectedLocation:", selectedLocation);
    setError('');
    // Always go to the next step (no skipping)
    setStep(step + 1);
    
    // Update form progress based on current step
    if (step === 1) {
      setFormProgress(25);
    } else if (step === 2) {
      setFormProgress(50);
    } else if (step === 3) {
      setFormProgress(75);
    } else if (step === 4) {
      setFormProgress(100);
    }
  };

  const prevStep = () => {
    console.log("Moving to previous step, current step:", step);
    if (step === 4 && selectedUrgency?.id === 'ASAP') {
      setStep(2); // Go back to urgency selection for ASAP bookings
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (location.state?.service) {
      const serviceType = Object.values(serviceTypes).find(s => s.name === location.state.service);
      if (serviceType) {
        console.log("Setting service from location state:", serviceType);
        setSelectedService(serviceType);
      }
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setLoading(true);
      setError('');

      // Validate required fields
      if (!selectedService || !selectedLocation || !selectedUrgency) {
        throw new Error('Please fill in all required fields');
      }

      // Validate email
      if (!patientDetails.email || !patientDetails.email.trim()) {
        throw new Error('Please provide a valid email address');
      }

      // Basic email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(patientDetails.email.trim())) {
        throw new Error('Please provide a valid email address format');
      }

      console.log("Submitting booking with selectedLocation:", selectedLocation);
      console.log("Patient details being submitted:", patientDetails);

      // Check if booking is within 4 hours for urgency factor
      let effectiveUrgency = selectedUrgency;
      let isWithin4Hours = false;
      
      if (selectedDate && selectedTime && selectedUrgency && selectedUrgency.id !== 'asap') {
        try {
          const sydneyTime = getCurrentSydneyTime();
          
          // Parse the selected date using the more reliable method
          const hour = parseInt(selectedTime.split(':')[0] || '0');
          const minute = parseInt(selectedTime.split(':')[1] || '0');
          const dateParts = selectedDate.split('-');
          const year = parseInt(dateParts[0]);
          const month = parseInt(dateParts[1]) - 1; // JS months are 0-indexed
          const day = parseInt(dateParts[2]);
          
          const selectedDateTime = new Date(year, month, day, hour, minute);
          
          // Also parse the current Sydney time the same way
          const sydneyDateParts = sydneyTime.date.split('-');
          const sydneyYear = parseInt(sydneyDateParts[0]);
          const sydneyMonth = parseInt(sydneyDateParts[1]) - 1; // JS months are 0-indexed
          const sydneyDay = parseInt(sydneyDateParts[2]);
          const sydneyHour = sydneyTime.hour;
          const sydneyMinute = parseInt(sydneyTime.time.split(':')[1] || '0');
          
          const currentDateTime = new Date(sydneyYear, sydneyMonth, sydneyDay, sydneyHour, sydneyMinute);
          
          // Calculate difference in milliseconds
          const timeDifferenceMs = selectedDateTime - currentDateTime;
          const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
          
          console.log("Submit - Selected date time:", selectedDateTime);
          console.log("Submit - Current Sydney time:", currentDateTime);
          console.log("Submit - Time difference in hours:", timeDifferenceHours);
          
          // If the booking is within 4 hours, use the ASAP urgency factor
          if (timeDifferenceHours >= 0 && timeDifferenceHours <= 4) {
            console.log("Booking time is within 4 hours, using ASAP urgency factor in submit");
            isWithin4Hours = true;
            effectiveUrgency = {
              ...selectedUrgency,
              factor: urgencyFactors.ASAP.factor,
              name: `${selectedUrgency.name} (ASAP rate applied - within 4 hours)`,
              _original: selectedUrgency
            };
          }
        } catch (error) {
          console.error('Error calculating time difference in submit:', error);
        }
      }

      // Get current user or set as anonymous
      const userId = currentUser?.uid || 'anonymous';

      // Safely handle patient details
      const firstName = patientDetails?.firstName?.trim() || '';
      const lastName = patientDetails?.lastName?.trim() || '';
      const fullName = `${firstName} ${lastName}`.trim();
      const email = patientDetails?.email?.trim() || '';
      const phone = patientDetails?.phone?.trim() || '';
      const address = patientDetails?.address?.trim() || '';

      // Validate email again before proceeding
      if (!email) {
        throw new Error('Email is required');
      }

      // Handle location data structure
      const locationData = typeof selectedLocation === 'string' 
        ? {
            address: selectedLocation,
            zone: selectedLocation,
            factor: locationFactors[selectedLocation] || 1,
            coordinates: null
          }
        : { 
            address: selectedLocation?.address || '',
            zone: selectedLocation?.zone || '',
            factor: selectedLocation?.factor || 1,
            coordinates: selectedLocation?.coordinates || null
          };

      console.log("Processed locationData:", locationData);

      // Create booking data
      const bookingData = {
        userId,
        service: {
          name: selectedService.name,
          basePrice: (() => {
            // Get the appropriate base price for the zone
            const zoneBasePrice = {
              1: 160, // Zone 1 base price
              2: 170, // Zone 2 base price (updated)
              3: 180, // Zone 3 base price (updated)
              4: 200, // Zone 4 base price (added)
              5: 250, // Zone 5+ base price (added)
              'Waterloo Clinic': 75, // Clinic visit base price
              'Telemedicine': 75, // Telemedicine base price
            };
            const zone = typeof selectedLocation === 'object' ? selectedLocation.zone : selectedLocation;
            return zoneBasePrice[zone] || selectedService.basePrice;
          })()
        },
        // Store the location address as a string for display
        location: locationData.address,
        // Store the full location data in a separate field
        locationData: locationData,
        urgency: effectiveUrgency,
        date: selectedDate ? new Date(selectedDate) : new Date(),
        time: selectedTime || format(new Date(), 'HH:mm'),
        price: price || selectedService.basePrice,
        // Add pricing breakdown factors
        pricingFactors: {
          zone: typeof selectedLocation === 'object' ? selectedLocation.zone : selectedLocation,
          isWeekend: (() => {
            if (selectedDate && selectedTime) {
              try {
                const hour = parseInt(selectedTime.split(':')[0] || '0');
                
                // Use the more reliable date parsing method
                const dateParts = selectedDate.split('-');
                const year = parseInt(dateParts[0]);
                const month = parseInt(dateParts[1]) - 1; // JS months are 0-indexed
                const day = parseInt(dateParts[2]);
                const selectedDateObj = new Date(year, month, day, hour);
                
                const dayOfWeek = selectedDateObj.getDay();
                const isFridayEvening = dayOfWeek === 5 && hour >= 17; // Friday after 5pm
                const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
                
                console.log(`Submit bookingData - day of week: ${dayOfWeek}, hour: ${hour}`);
                console.log(`Submit bookingData - Is Friday evening: ${isFridayEvening}, Is weekend: ${isWeekend}`);
                
                return isFridayEvening || isWeekend;
              } catch (error) {
                console.error('Error checking weekend status:', error);
                return false;
              }
            }
            return false;
          })(),
          isWithin4Hours: isWithin4Hours
        },
        status: 'pending',
        patientDetails: {
          ...patientDetails,
          firstName,
          lastName,
          name: fullName,
          email
        },
        estimatedArrival: calculateETA(effectiveUrgency)
      };

      console.log("Final booking data:", bookingData);

      // Create booking in Firestore
      const bookingRef = await addDoc(collection(db, 'bookings'), bookingData);
      console.log('Booking created:', bookingRef.id);

      // Log the data being passed to the payment page
      console.log('Navigating to payment with data:', {
        bookingId: bookingRef.id,
        price: price || selectedService.basePrice,
        serviceType: selectedService.name,
        patientDetails: {
          firstName,
          lastName,
          name: fullName,
          email,
          phone,
          address
        }
      });

      // Navigate to payment page with booking details
      navigate(`/payment/${bookingRef.id}`, {
        state: {
          bookingId: bookingRef.id,
          price: price || selectedService.basePrice,
          serviceType: selectedService.name,
          patientDetails: {
            firstName,
            lastName,
            name: fullName,
            email,
            phone,
            address
          }
        }
      });

    } catch (error) {
      console.error('Error creating booking:', error);
      setError(error.message || 'Failed to create booking');
      setLoading(false);
    }
  };

  // Fetch existing bookings for calendar
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const bookingsRef = collection(db, 'bookings');
        const querySnapshot = await getDocs(bookingsRef);
        const bookingEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.serviceType,
            start: data.date.toDate(),
            end: new Date(data.date.toDate().getTime() + 60 * 60 * 1000), // 1 hour duration
            backgroundColor: data.urgency === 'Urgent' ? '#dc2626' : '#2563eb'
          };
        });
        setBookings(bookingEvents);
      } catch (error) {
        console.error('Error fetching bookings:', error);
        // Don't show the error to the user as it's not critical for the booking flow
      }
    };

    fetchBookings();
  }, []);

  // Calculate ETA for urgent cases
  const calculateETA = (urgency) => {
    const now = new Date();
    switch (urgency) {
      case 'EMERGENCY':
        return new Date(now.getTime() + 30 * 60000); // 30 minutes
      case 'URGENT':
        return new Date(now.getTime() + 2 * 60 * 60000); // 2 hours
      case 'SOON':
        return new Date(now.getTime() + 24 * 60 * 60000); // 24 hours
      default:
        return selectedDate || now;
    }
  };

  // Modified calculatePrice to accept an override urgency factor
  const calculatePrice = useCallback((urgencyOverride = null) => {
    if (!selectedService) return 0;

    // Start with base price
    let basePrice = selectedService.basePrice || 0;
    let totalPrice = basePrice;
    
    // Get zone from location
    let zone = 1; // Default to Zone 1
    if (selectedService.id === 'house-call' && selectedLocation) {
      if (typeof selectedLocation === 'object') {
        // Extract zone number from zone name (e.g., "Zone 3" -> 3)
        if (selectedLocation.zone && selectedLocation.zone.startsWith('Zone ')) {
          zone = parseInt(selectedLocation.zone.split(' ')[1]);
          console.log("Extracted zone number:", zone);
        } else {
          zone = selectedLocation.zone || 1;
        }
      } else {
        zone = selectedLocation;
      }
    } else if (selectedService.id === 'telemedicine') {
      zone = 'Telemedicine';
    } else if (selectedService.id === 'clinic-visit') {
      zone = 'Waterloo Clinic';
    }

    console.log("Using zone for price calculation:", zone);

    // Get the base price for the zone
    const zoneBasePrice = {
      1: 160, // Zone 1 base price
      2: 170, // Zone 2 base price (updated)
      3: 180, // Zone 3 base price (updated)
      4: 200, // Zone 4 base price (added)
      5: 250, // Zone 5+ base price (added)
      'Waterloo Clinic': 75, // Clinic visit base price
      'Telemedicine': 75, // Telemedicine base price
    };
    basePrice = zoneBasePrice[zone] || zoneBasePrice[1]; // Use the correct base price for the zone
    totalPrice = basePrice;
    console.log(`Base price for zone ${zone}: $${basePrice}`);

    // Apply time surcharge (multiplier-based)
    let timeSurchargeMultiplier = 1;
    let weekendSurcharge = false;
    
    if (selectedDate && selectedTime) {
      try {
        const hour = parseInt(selectedTime.split(':')[0] || '0');
        
        // Check if the booking is on a weekend or Friday evening
        // Use a more reliable way to create the date - ensure proper timezone handling by appending Z to make it UTC
        const dateParts = selectedDate.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // JS months are 0-indexed
        const day = parseInt(dateParts[2]);
        const selectedDateObj = new Date(year, month, day, hour);
        
        const dayOfWeek = selectedDateObj.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
        const isFridayEvening = dayOfWeek === 5 && hour >= 17; // Friday after 5pm
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
        
        console.log(`Selected date: ${selectedDate} (${year}-${month+1}-${day}), day of week: ${dayOfWeek}, hour: ${hour}`);
        console.log(`Is Friday evening: ${isFridayEvening}, Is weekend: ${isWeekend}`);
        
        weekendSurcharge = isFridayEvening || isWeekend;
        
        if (!isNaN(hour)) {
          // Get the surcharge multiplier for the time period and zone
          timeSurchargeMultiplier = getTimeSurcharge(hour, zone);
          // Apply the multiplier to the base price
          totalPrice = basePrice * timeSurchargeMultiplier;
          console.log(`Applied time surcharge multiplier of ${timeSurchargeMultiplier.toFixed(2)}x for hour ${hour} in zone ${zone}: $${totalPrice.toFixed(2)}`);
        }
        
        // Apply weekend/Friday evening surcharge (10%)
        if (weekendSurcharge) {
          totalPrice = totalPrice * 1.1; // Add 10%
          console.log(`Applied weekend/Friday evening surcharge (10%): $${totalPrice.toFixed(2)}`);
        }
      } catch (error) {
        console.error('Error parsing time or date:', error);
      }
    }

    // Apply urgency factor (multiplicative)
    // Use override urgency if provided (for within 4 hour bookings)
    const urgencyToUse = urgencyOverride || selectedUrgency;
    if (urgencyToUse) {
      totalPrice = totalPrice * urgencyToUse.factor;
      console.log(`Applied urgency factor ${urgencyToUse.factor}: $${totalPrice}`);
    }

    // Round to nearest dollar
    return { 
      price: Math.round(totalPrice),
      timeSurchargeMultiplier,
      weekendSurcharge
    };
  }, [selectedService, selectedLocation, selectedUrgency, selectedTime, selectedDate]);

  // Update price when factors change
  useEffect(() => {
    try {
      // Check if the selected date and time are within 4 hours of current time
      if (selectedDate && selectedTime && selectedUrgency && selectedUrgency.id !== 'asap') {
        const sydneyTime = getCurrentSydneyTime();
        
        // Parse the selected date using the more reliable method
        const hour = parseInt(selectedTime.split(':')[0] || '0');
        const minute = parseInt(selectedTime.split(':')[1] || '0');
        const dateParts = selectedDate.split('-');
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; // JS months are 0-indexed
        const day = parseInt(dateParts[2]);
        
        const selectedDateTime = new Date(year, month, day, hour, minute);
        
        // Also parse the current Sydney time the same way
        const sydneyDateParts = sydneyTime.date.split('-');
        const sydneyYear = parseInt(sydneyDateParts[0]);
        const sydneyMonth = parseInt(sydneyDateParts[1]) - 1; // JS months are 0-indexed
        const sydneyDay = parseInt(sydneyDateParts[2]);
        const sydneyHour = sydneyTime.hour;
        const sydneyMinute = parseInt(sydneyTime.time.split(':')[1] || '0');
        
        const currentDateTime = new Date(sydneyYear, sydneyMonth, sydneyDay, sydneyHour, sydneyMinute);
        
        // Calculate difference in milliseconds
        const timeDifferenceMs = selectedDateTime - currentDateTime;
        const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
        
        console.log("Selected date time:", selectedDateTime);
        console.log("Current Sydney time:", currentDateTime);
        console.log("Time difference in hours:", timeDifferenceHours);
        
        // If the booking is within 4 hours, use the ASAP urgency factor
        if (timeDifferenceHours >= 0 && timeDifferenceHours <= 4) {
          console.log("Booking time is within 4 hours, using ASAP urgency factor");
          // Don't update the UI but use the ASAP factor for calculation
          const result = calculatePrice(urgencyFactors.ASAP);
          setPrice(result.price);
          return;
        }
      }
      
      const result = calculatePrice();
      setPrice(result.price);
    } catch (error) {
      console.error('Error calculating price:', error);
      setPrice(0);
    }
  }, [calculatePrice, selectedDate, selectedTime, selectedUrgency]);
  
  // Urgency options
  const urgencyOptions = [
    {
      id: 'ASAP',
      name: 'As Soon As Possible',
      description: 'Doctor will arrive within 30-60 minutes',
      color: '#FF4B4B',
      factor: 1.5
    },
    {
      id: 'scheduled',
      name: 'Book in Advance',
      description: 'Schedule a visit at your preferred time',
      color: '#4CAF50',
      factor: 1.0
    }
  ];

  // Add reset function
  const resetBooking = () => {
    setStep(1);
    setSelectedService(null);
    setSelectedLocation(null);
    setSelectedUrgency(null);
    setSelectedDate(null);
    setSelectedTime(null);
    setShowCalendar(false);
    setError('');
    setPrice(0);
    setPatientDetails({
      firstName: '',
      lastName: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      medicare: '',
      userId: currentUser?.uid || 'anonymous'
    });
    setFormProgress(0);
  };

  // Update useEffect for form progress
  useEffect(() => {
    // Update form progress based on current step
    if (step === 1) {
      setFormProgress(20);
    } else if (step === 2) {
      setFormProgress(40);
    } else if (step === 3) {
      setFormProgress(60);
    } else if (step === 4) {
      setFormProgress(80);
    } else if (step === 5) {
      setFormProgress(100);
    }
  }, [step]);

  // Update the ASAP booking flow
  const handleAsapBooking = () => {
    try {
      // Get current Sydney time
      const sydneyTime = getCurrentSydneyTime();
      console.log('Current Sydney time:', sydneyTime);
      
      // Set urgency to ASAP
      setSelectedUrgency(urgencyFactors.ASAP);
      
      // Set current date and time in AEST
      setSelectedDate(sydneyTime.date);
      setSelectedTime(sydneyTime.time);
      
      // Calculate price with the new pricing model
      if (selectedService) {
        // Get zone from location
        let zone = 1; // Default to Zone 1
        if (selectedService.id === 'house-call' && selectedLocation) {
          if (typeof selectedLocation === 'object') {
            // Extract zone number from zone name (e.g., "Zone 3" -> 3)
            if (selectedLocation.zone && selectedLocation.zone.startsWith('Zone ')) {
              zone = parseInt(selectedLocation.zone.split(' ')[1]);
              console.log("Extracted zone number for ASAP booking:", zone);
            } else {
              zone = selectedLocation.zone || 1;
            }
          } else {
            zone = selectedLocation;
          }
        } else if (selectedService.id === 'telemedicine') {
          zone = 'Telemedicine';
        } else if (selectedService.id === 'clinic-visit') {
          zone = 'Waterloo Clinic';
        }
        
        console.log("Using zone for ASAP price calculation:", zone);
        
        // Get the base price for the zone
        const zoneBasePrice = {
          1: 160, // Zone 1 base price
          2: 200, // Zone 2 base price (updated)
          3: 240, // Zone 3 base price (updated)
          4: 280, // Zone 4 base price (added)
          5: 350, // Zone 5+ base price (added)
          'Waterloo Clinic': 75, // Clinic visit base price
          'Telemedicine': 75, // Telemedicine base price
        };
        const basePrice = zoneBasePrice[zone] || zoneBasePrice[1];
        console.log(`Base price for zone ${zone}: $${basePrice}`);
        
        // Get the surcharge multiplier for the time period and zone
        const surchargeMultiplier = getTimeSurcharge(sydneyTime.hour, zone);
        
        // Determine time of day description
        let timeOfDay = "standard hours";
        if (sydneyTime.hour >= 0 && sydneyTime.hour < 10) {
          timeOfDay = "morning (12am-10am)";
        } else if (sydneyTime.hour >= 10 && sydneyTime.hour < 16) {
          timeOfDay = "daytime (10am-4pm)";
        } else if (sydneyTime.hour >= 16 && sydneyTime.hour < 22) {
          timeOfDay = "evening (4pm-10pm)";
        } else if (sydneyTime.hour >= 22 && sydneyTime.hour <= 23) {
          timeOfDay = "night (10pm-12am)";
        }
        
        // Apply the multiplier to the base price
        let newPrice = basePrice * surchargeMultiplier;
        console.log(`Applied time surcharge multiplier of ${surchargeMultiplier.toFixed(2)}x for ${timeOfDay}: $${newPrice.toFixed(2)}`);
        
        // Check if it's a weekend or Friday evening
        const dayOfWeek = new Date(sydneyTime.fullTime).getDay();
        const isFridayEvening = dayOfWeek === 5 && sydneyTime.hour >= 17;
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        
        console.log(`ASAP booking - day of week: ${dayOfWeek}, hour: ${sydneyTime.hour}`);
        console.log(`ASAP booking - Is Friday evening: ${isFridayEvening}, Is weekend: ${isWeekend}`);
        
        if (isFridayEvening || isWeekend) {
          newPrice = newPrice * 1.1; // Add 10% for weekend/Friday evening
          console.log(`Applied weekend/Friday evening surcharge (10%): $${newPrice.toFixed(2)}`);
        }
        
        // Apply urgency factor (multiplicative)
        newPrice *= urgencyFactors.ASAP.factor;
        console.log(`Applied ASAP urgency factor ${urgencyFactors.ASAP.factor}: $${newPrice.toFixed(2)}`);
        
        // Round to nearest dollar
        newPrice = Math.round(newPrice);
        console.log('Calculated price for ASAP booking:', newPrice);
        setPrice(newPrice);
      }
      
      // Go to patient details step
      setStep(5);
      setFormProgress(80);
    } catch (error) {
      console.error('Error in handleAsapBooking:', error);
      setError('Failed to process your booking. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-[#DDC6A8] text-[#1A1A1A]">
      <div className="site-background" />
      
      <QuickRegister 
        isOpen={showQuickRegister} 
        onClose={() => setShowQuickRegister(false)}
        onSuccess={() => {
          // The useEffect above will handle checking if patient details are needed
        }}
      />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-4xl mx-auto px-4 py-12">
          {/* Progress Bar */}
          <div className="fixed top-0 left-0 w-full h-1 bg-[#1A1A1A]/20">
            <motion.div
              className="h-full bg-[#1A1A1A]"
              variants={progressVariants}
              initial="hidden"
              animate="visible"
            />
          </div>

          <div className="bg-[#DDC6A8]/90 backdrop-blur-sm shadow-lg rounded-2xl p-8 border border-[#1A1A1A]/10">
            <h2 className="text-3xl font-light text-[#1A1A1A] mb-8">Book an Appointment</h2>
            
            {error && (
              <div className="mb-6 p-4 bg-red-900/20 border border-red-500/20 rounded-lg">
                <p className="text-red-800">{error}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-8">
              <AnimatePresence mode="wait">
                {/* Step 1: Service Type */}
                {step === 1 && (
                  <motion.div
                    key="step1"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light text-[#1A1A1A] mb-4">What type of service do you need?</h3>
                      <p className="text-[#1A1A1A]/70">Choose the service that best fits your needs</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {Object.values(serviceTypes).map((service) => (
                        <motion.div
                          key={service.id}
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className={`relative rounded-xl border-2 p-4 cursor-pointer transition-all ${
                            selectedService?.id === service.id
                              ? 'border-[#1A1A1A] bg-[#1A1A1A]/5'
                              : 'border-[#1A1A1A]/10 hover:border-[#1A1A1A]/30'
                          }`}
                          onClick={() => {
                            setSelectedService(service);
                            nextStep();
                          }}
                        >
                          <h3 className="text-lg font-light text-[#1A1A1A]">{service.name}</h3>
                          <p className="text-[#1A1A1A]/70 mt-1">{service.description}</p>
                          <p className="text-lg font-light mt-2 text-[#1A1A1A]">From ${service.basePrice}</p>
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                )}

                {/* Step 2: Location */}
                {step === 2 && (
                  <motion.div
                    key="step2"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">Where would you like to be seen?</h3>
                      <p className="text-[#1A1A1A]/70">
                        {selectedService?.id === 'house-call' 
                          ? 'Enter your address or select a location on the map'
                          : selectedService?.id === 'clinic-visit'
                            ? 'Our clinic is located in Waterloo'
                            : 'Select your preferred option'}
                      </p>
                    </div>

                    {selectedService?.id === 'house-call' ? (
                      <LocationMap
                        onLocationSelect={(locationData) => {
                          console.log("Location selected:", locationData);
                          setSelectedLocation(locationData);
                          if (locationData.factor && selectedService) {
                            const basePrice = selectedService.basePrice;
                            const newPrice = Math.round(basePrice * locationData.factor);
                            console.log("Updated price based on location factor:", newPrice);
                            setPrice(newPrice);
                          }
                          nextStep();
                        }}
                        selectedLocation={selectedLocation ? 
                          (typeof selectedLocation === 'string' ? selectedLocation : {
                            address: selectedLocation?.address || '',
                            zone: selectedLocation?.zone || '',
                            factor: selectedLocation?.factor || 1,
                            coordinates: selectedLocation?.coordinates || null
                          }) 
                          : null
                        }
                      />
                    ) : selectedService?.id === 'clinic-visit' ? (
                      <div className="grid grid-cols-1 gap-4">
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className="relative rounded-xl border-2 p-6 cursor-pointer transition-all border-[#1A1A1A] bg-[#1A1A1A]/5"
                          onClick={() => {
                            console.log("Selected Waterloo Clinic location");
                            setSelectedLocation('Waterloo Clinic');
                            nextStep();
                          }}
                        >
                          <h3 className="text-lg font-light text-[#1A1A1A]">Waterloo Clinic</h3>
                          <p className="text-[#1A1A1A]/70 mt-1">Suite 66/6 Lachlan St, Waterloo NSW 2018</p>
                          <p className="text-[#1A1A1A]/70 mt-1">Standard rate applies</p>
                        </motion.div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-4">
                        <motion.div
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                          className="relative rounded-xl border-2 p-6 cursor-pointer transition-all border-[#1A1A1A] bg-[#1A1A1A]/5"
                          onClick={() => {
                            console.log("Selected Telemedicine location");
                            setSelectedLocation('Telemedicine');
                            nextStep();
                          }}
                        >
                          <h3 className="text-lg font-light text-[#1A1A1A]">Telemedicine</h3>
                          <p className="text-[#1A1A1A]/70 mt-1">Video consultation from your location</p>
                          <p className="text-[#1A1A1A]/70 mt-1">Standard rate applies</p>
                        </motion.div>
                      </div>
                    )}
                    
                    {selectedLocation && (
                      <div className="mt-4 p-4 bg-[#2A2A2A] rounded-lg">
                        <p>Selected Location: {typeof selectedLocation === 'string' ? selectedLocation : selectedLocation?.address || 'Not specified'}</p>
                        <p>Zone: {typeof selectedLocation === 'string' ? 'Standard' : selectedLocation?.zone || 'Not specified'}</p>
                        <p>Price Adjustment: +{typeof selectedLocation === 'string' 
                          ? '0' 
                          : (((selectedLocation?.factor || 1) - 1) * 100).toFixed(0)}%</p>
                      </div>
                    )}

                    <motion.button
                      type="button"
                      onClick={resetBooking}
                      className="mt-6 px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Start Over
                    </motion.button>
                  </motion.div>
                )}

                {/* Step 3: Modified to show urgency options for house calls and telemedicine */}
                {step === 3 && (selectedService?.id === 'house-call' || selectedService?.id === 'telemedicine') && (
                  <motion.div
                    key="step3-urgency"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">How soon do you need to see the doctor?</h3>
                      <p className="text-[#1A1A1A]/70">This helps us prioritize your care</p>
                    </div>
                    
                    <div className="space-y-4">
                      <motion.div
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                        className={`relative rounded-xl border-2 p-6 cursor-pointer transition-all ${
                          selectedUrgency?.id === 'asap'
                            ? 'border-[#DC2626] bg-[#DC2626]/5'
                            : 'border-[#1A1A1A]/10 hover:border-[#1A1A1A]/30'
                        }`}
                        onClick={() => {
                          console.log('ASAP option clicked');
                          handleAsapBooking();
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-xl font-light text-[#1A1A1A] mb-2">As Soon As Possible</h3>
                            <p className="text-[#1A1A1A]/70 text-sm mb-1">Doctor will arrive within 30-60 minutes</p>
                            <p className="text-[#1A1A1A]/50 text-sm">30% additional urgency fee</p>
                          </div>
                          <div 
                            className="w-3 h-3 rounded-full mt-2"
                            style={{ backgroundColor: '#DC2626' }}
                          />
                        </div>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                        className={`relative rounded-xl border-2 p-6 cursor-pointer transition-all ${
                          selectedUrgency?.id === 'book-in-advance'
                            ? 'border-[#059669] bg-[#059669]/5'
                            : 'border-[#1A1A1A]/10 hover:border-[#1A1A1A]/30'
                        }`}
                        onClick={() => {
                          console.log('Book in Advance option clicked');
                          // Go to the advance booking options
                          setStep(3.5);
                          setFormProgress(65);
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-xl font-light text-[#1A1A1A] mb-2">Book in Advance</h3>
                            <p className="text-[#1A1A1A]/70 text-sm mb-1">Schedule a visit at your preferred time</p>
                            <p className="text-[#1A1A1A]/50 text-sm">Lower rates for advance bookings</p>
                          </div>
                          <div 
                            className="w-3 h-3 rounded-full mt-2"
                            style={{ backgroundColor: '#059669' }}
                          />
                        </div>
                      </motion.div>
                    </div>

                    <motion.button
                      type="button"
                      onClick={resetBooking}
                      className="mt-6 px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Start Over
                    </motion.button>
                  </motion.div>
                )}

                {/* Step 3.5: Advance Booking Options */}
                {step === 3.5 && (
                  <motion.div
                    key="step3.5-advance-options"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">When would you like to book?</h3>
                      <p className="text-[#1A1A1A]/70">Select your preferred timeframe</p>
                    </div>
                    
                    <div className="space-y-4">
                      <motion.div
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                        className="relative rounded-xl border-2 p-6 cursor-pointer transition-all border-[#1A1A1A]/10 hover:border-[#F59E0B]"
                        onClick={() => {
                          console.log('Within 24 Hours option clicked');
                          setSelectedUrgency(urgencyFactors.WITHIN_24H);
                          setStep(4);
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-xl font-light text-[#1A1A1A] mb-2">Within 24 Hours</h3>
                            <p className="text-[#1A1A1A]/70 text-sm mb-1">For concerns that need attention soon</p>
                            <p className="text-[#1A1A1A]/50 text-sm">20% additional urgency fee</p>
                          </div>
                          <div 
                            className="w-3 h-3 rounded-full mt-2"
                            style={{ backgroundColor: '#F59E0B' }}
                          />
                        </div>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                        className="relative rounded-xl border-2 p-6 cursor-pointer transition-all border-[#1A1A1A]/10 hover:border-[#3B82F6]"
                        onClick={() => {
                          console.log('24-72 Hours option clicked');
                          setSelectedUrgency(urgencyFactors.WITHIN_72H);
                          setStep(4);
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-xl font-light text-[#1A1A1A] mb-2">24-72 Hours in Advance</h3>
                            <p className="text-[#1A1A1A]/70 text-sm mb-1">For non-urgent concerns</p>
                            <p className="text-[#1A1A1A]/50 text-sm">10% additional urgency fee</p>
                          </div>
                          <div 
                            className="w-3 h-3 rounded-full mt-2"
                            style={{ backgroundColor: '#3B82F6' }}
                          />
                        </div>
                      </motion.div>
                      
                      <motion.div
                        whileHover={{ scale: 1.01 }}
                        whileTap={{ scale: 0.99 }}
                        className="relative rounded-xl border-2 p-6 cursor-pointer transition-all border-[#1A1A1A]/10 hover:border-[#059669]"
                        onClick={() => {
                          console.log('More than 72 Hours option clicked');
                          setSelectedUrgency(urgencyFactors.BEYOND_72H);
                          setStep(4);
                        }}
                      >
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="text-xl font-light text-[#1A1A1A] mb-2">More than 72 Hours in Advance</h3>
                            <p className="text-[#1A1A1A]/70 text-sm mb-1">For routine check-ups</p>
                            <p className="text-[#1A1A1A]/50 text-sm">Standard rate applies</p>
                          </div>
                          <div 
                            className="w-3 h-3 rounded-full mt-2"
                            style={{ backgroundColor: '#059669' }}
                          />
                        </div>
                      </motion.div>
                    </div>

                    <div className="flex justify-between mt-6">
                      <motion.button
                        type="button"
                        onClick={() => setStep(3)}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Back
                      </motion.button>
                      <motion.button
                        type="button"
                        onClick={resetBooking}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Start Over
                      </motion.button>
                    </div>
                  </motion.div>
                )}

                {/* Step 3: Calendar for clinic visits modified to skip to date/time selection */}
                {step === 3 && selectedService?.id === 'clinic-visit' && (
                  <motion.div
                    key="step3-clinic-visit"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">Select an Available Time</h3>
                      <p className="text-[#1A1A1A]/70">Currently only available on Tuesdays</p>
                    </div>
                    <div className="space-y-6">
                      <div>
                        <label className="block text-lg font-light text-[#1A1A1A] mb-4">
                          Select a Tuesday
                        </label>
                        <div className="grid grid-cols-2 gap-3">
                          {(() => {
                            // Generate the next 4 Tuesdays
                            const tuesdays = [];
                            const today = new Date();
                            for (let i = 0; i < 4; i++) {
                              const daysUntilNextTuesday = (2 + 7 - today.getDay()) % 7;
                              const nextTuesday = new Date(today);
                              nextTuesday.setDate(today.getDate() + daysUntilNextTuesday + (i * 7));
                              
                              // Format date for display (e.g., "Tuesday, March 26")
                              const formattedDate = nextTuesday.toLocaleDateString('en-AU', {
                                weekday: 'long',
                                month: 'short',
                                day: 'numeric'
                              });
                              
                              // ISO string for value (YYYY-MM-DD)
                              const dateValue = nextTuesday.toISOString().split('T')[0];
                              
                              tuesdays.push({ display: formattedDate, value: dateValue });
                            }
                            
                            return tuesdays.map((tuesday, index) => (
                              <motion.button
                                key={index}
                                type="button"
                                whileHover={{ scale: 1.03 }}
                                whileTap={{ scale: 0.97 }}
                                className={`p-4 rounded-lg border-2 transition-all ${
                                  selectedDate === tuesday.value
                                    ? 'border-[#1A1A1A] bg-[#1A1A1A]/5'
                                    : 'border-[#1A1A1A]/30 hover:border-[#1A1A1A]/70'
                                }`}
                                onClick={() => setSelectedDate(tuesday.value)}
                              >
                                <p className="text-center font-light">{tuesday.display}</p>
                              </motion.button>
                            ));
                          })()}
                        </div>
                      </div>

                      <div>
                        <label className="block text-lg font-light text-[#1A1A1A] mb-4">
                          Select a Time
                        </label>
                        <div className="grid grid-cols-3 gap-3">
                          <motion.button
                            type="button"
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.97 }}
                            className={`p-4 rounded-lg border-2 transition-all ${
                              selectedTime === "11:00"
                                ? 'border-[#1A1A1A] bg-[#1A1A1A]/5'
                                : 'border-[#1A1A1A]/30 hover:border-[#1A1A1A]/70'
                            }`}
                            onClick={() => setSelectedTime("11:00")}
                          >
                            <p className="text-center font-light">11:00 AM</p>
                          </motion.button>
                          <motion.button
                            type="button"
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.97 }}
                            className={`p-4 rounded-lg border-2 transition-all ${
                              selectedTime === "13:30"
                                ? 'border-[#1A1A1A] bg-[#1A1A1A]/5'
                                : 'border-[#1A1A1A]/30 hover:border-[#1A1A1A]/70'
                            }`}
                            onClick={() => setSelectedTime("13:30")}
                          >
                            <p className="text-center font-light">1:30 PM</p>
                          </motion.button>
                          <motion.button
                            type="button"
                            whileHover={{ scale: 1.03 }}
                            whileTap={{ scale: 0.97 }}
                            className={`p-4 rounded-lg border-2 transition-all ${
                              selectedTime === "16:30"
                                ? 'border-[#1A1A1A] bg-[#1A1A1A]/5'
                                : 'border-[#1A1A1A]/30 hover:border-[#1A1A1A]/70'
                            }`}
                            onClick={() => setSelectedTime("16:30")}
                          >
                            <p className="text-center font-light">4:30 PM</p>
                          </motion.button>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between mt-6">
                      <motion.button
                        type="button"
                        onClick={() => setStep(2)}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Back
                      </motion.button>
                      <motion.button
                        type="button"
                        onClick={() => {
                          // Set a default time if not selected
                          if (!selectedTime) {
                            setSelectedTime("11:00");
                          }
                          
                          // Calculate time-based urgency factor for clinic visits
                          const today = new Date();
                          const selectedDateObj = new Date(selectedDate);
                          const daysDifference = Math.ceil((selectedDateObj - today) / (1000 * 60 * 60 * 24));
                          
                          console.log(`Clinic booking: ${daysDifference} days from now`);
                          
                          let urgencyFactor = 1.0; // Default factor
                          
                          if (daysDifference <= 7) {
                            // Within 1 week
                            urgencyFactor = 1.5;
                            console.log("Using urgency factor 1.5x (within 1 week)");
                          } else if (daysDifference <= 14) {
                            // Within 2 weeks
                            urgencyFactor = 1.2;
                            console.log("Using urgency factor 1.2x (within 2 weeks)");
                          } else {
                            // Beyond 2 weeks
                            console.log("Using urgency factor 1.0x (beyond 2 weeks)");
                          }
                          
                          // Create custom urgency object with the calculated factor
                          const clinicUrgency = {
                            id: 'clinic-urgency',
                            name: 'Clinic Visit',
                            factor: urgencyFactor,
                            description: 'Visit the doctor at our Waterloo clinic',
                            eta: 'At scheduled time',
                            color: '#3B82F6' // blue
                          };
                          
                          setSelectedUrgency(clinicUrgency);
                          
                          // Skip to patient details (step 5) instead of going to step 4
                          setStep(5);
                          setFormProgress(80);
                        }}
                        disabled={!selectedDate || !selectedTime}
                        className="px-4 py-2 border border-[#1A1A1A] text-[#1A1A1A] rounded-md hover:bg-[#1A1A1A] hover:text-[#DDC6A8] disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Next
                      </motion.button>
                    </div>
                  </motion.div>
                )}

                {/* Step 4: Date and Time Selection for advance bookings */}
                {step === 4 && selectedUrgency && selectedUrgency.id !== 'asap' && (
                  <motion.div
                    key="step4"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">When would you like to be seen?</h3>
                      <p className="text-[#1A1A1A]/70">Select your preferred date and time</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-lg font-light text-[#1A1A1A] mb-4">
                          Date
                        </label>
                        <input
                          type="date"
                          required
                          min={(() => {
                            const today = new Date();
                            if (selectedUrgency.id === 'within-24h') {
                              // Only today and tomorrow
                              return today.toISOString().split('T')[0];
                            } else if (selectedUrgency.id === 'within-72h') {
                              // Between 1 and 3 days from now
                              const tomorrow = new Date(today);
                              tomorrow.setDate(today.getDate() + 1);
                              return tomorrow.toISOString().split('T')[0];
                            } else {
                              // More than 3 days from now
                              const threeDaysLater = new Date(today);
                              threeDaysLater.setDate(today.getDate() + 3);
                              return threeDaysLater.toISOString().split('T')[0];
                            }
                          })()}
                          max={(() => {
                            const today = new Date();
                            if (selectedUrgency.id === 'within-24h') {
                              // Only today and tomorrow
                              const tomorrow = new Date(today);
                              tomorrow.setDate(today.getDate() + 1);
                              return tomorrow.toISOString().split('T')[0];
                            } else if (selectedUrgency.id === 'within-72h') {
                              // Between 1 and 3 days from now
                              const threeDaysLater = new Date(today);
                              threeDaysLater.setDate(today.getDate() + 3);
                              return threeDaysLater.toISOString().split('T')[0];
                            } else {
                              // No upper limit for beyond 72h
                              return null;
                            }
                          })()}
                          value={selectedDate}
                          onChange={(e) => setSelectedDate(e.target.value)}
                          className="w-full rounded-lg border border-[#1A1A1A]/10 bg-[#DDC6A8] text-[#1A1A1A] focus:border-[#1A1A1A] focus:ring-[#1A1A1A] p-3"
                        />
                      </div>

                      <div>
                        <label className="block text-lg font-light text-[#1A1A1A] mb-4">
                          Time
                        </label>
                        <select
                          required
                          value={selectedTime}
                          onChange={(e) => setSelectedTime(e.target.value)}
                          className="w-full rounded-lg border border-[#1A1A1A]/10 bg-[#DDC6A8] text-[#1A1A1A] focus:border-[#1A1A1A] focus:ring-[#1A1A1A] p-3"
                        >
                          <option value="">Select a time</option>
                          {Array.from({ length: 24 }, (_, i) => {
                            const hour = i.toString().padStart(2, '0');
                            return (
                              <option key={hour} value={`${hour}:00`}>
                                {hour}:00 {i < 12 ? 'AM' : 'PM'}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="flex justify-between mt-6">
                      <motion.button
                        type="button"
                        onClick={() => setStep(3.5)}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Back
                      </motion.button>
                      <motion.button
                        type="button"
                        onClick={nextStep}
                        disabled={!selectedDate || !selectedTime}
                        className="px-4 py-2 border border-[#1A1A1A] text-[#1A1A1A] rounded-md hover:bg-[#1A1A1A] hover:text-[#DDC6A8] disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Next
                      </motion.button>
                    </div>
                  </motion.div>
                )}

                {/* Step 5: Patient Details */}
                {step === 5 && (
                  <motion.div
                    key="step5"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">Your Details</h3>
                      <p className="text-[#1A1A1A]/70">Please provide your contact information</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          value={patientDetails.firstName}
                          onChange={(e) => {
                            const newFirstName = e.target.value;
                            setPatientDetails(prev => ({
                              ...prev,
                              firstName: newFirstName,
                              name: `${newFirstName} ${prev.lastName}`.trim()
                            }));
                          }}
                          required
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          value={patientDetails.lastName}
                          onChange={(e) => {
                            const newLastName = e.target.value;
                            setPatientDetails(prev => ({
                              ...prev,
                              lastName: newLastName,
                              name: `${prev.firstName} ${newLastName}`.trim()
                            }));
                          }}
                          required
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">Email</label>
                        <input
                          type="email"
                          placeholder="Email"
                          value={patientDetails.email}
                          onChange={(e) => setPatientDetails(prev => ({...prev, email: e.target.value}))}
                          required
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">Phone</label>
                        <input
                          type="tel"
                          placeholder="Phone"
                          value={patientDetails.phone}
                          onChange={(e) => setPatientDetails(prev => ({...prev, phone: e.target.value}))}
                          required
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">Medicare Number (Optional)</label>
                        <input
                          type="text"
                          placeholder="Medicare Number"
                          value={patientDetails.medicare}
                          onChange={(e) => setPatientDetails(prev => ({...prev, medicare: e.target.value}))}
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                      <div className="space-y-2 md:col-span-2">
                        <label className="block text-sm font-medium text-[#1A1A1A]">Full Address</label>
                        <input
                          type="text"
                          placeholder="Full Address"
                          value={patientDetails.address}
                          onChange={(e) => setPatientDetails(prev => ({...prev, address: e.target.value}))}
                          required
                          className="w-full p-3 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-lg focus:border-[#1A1A1A] focus:ring-1 focus:ring-[#1A1A1A] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
                        />
                      </div>
                    </div>

                    <div className="flex justify-between mt-6">
                      <motion.button
                        type="button"
                        onClick={() => {
                          // Go back to appropriate step based on service and urgency
                          if (selectedUrgency?.id === 'asap') {
                            setStep(3);
                          } else if (selectedService?.id === 'clinic-visit') {
                            setStep(3); // Go back to clinic visit date/time selection
                          } else {
                            setStep(4); // Go back to regular date/time selection
                          }
                        }}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Back
                      </motion.button>
                      <motion.button
                        type="button"
                        onClick={nextStep}
                        disabled={!patientDetails.firstName || !patientDetails.lastName || !patientDetails.email || !patientDetails.phone || !patientDetails.address}
                        className="px-4 py-2 border border-[#1A1A1A] text-[#1A1A1A] rounded-md hover:bg-[#1A1A1A] hover:text-[#DDC6A8] disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Next
                      </motion.button>
                    </div>
                  </motion.div>
                )}

                {/* Step 6: Review and Payment */}
                {step === 6 && (
                  <motion.div
                    key="step6"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="space-y-8"
                  >
                    <div className="text-center mb-8">
                      <h3 className="text-2xl font-light mb-4">Review Your Booking</h3>
                      <p className="text-[#1A1A1A]/70">Please confirm your booking details</p>
                    </div>

                    <div className="bg-[#DDC6A8]/80 backdrop-blur-sm border border-[#1A1A1A]/10 rounded-xl p-6">
                      <div className="space-y-4">
                        <div className="flex justify-between items-center">
                          <h4 className="text-lg font-light">Service</h4>
                          <p>{selectedService?.name || ''}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <h4 className="text-lg font-light">Location</h4>
                          <p>{typeof selectedLocation === 'string' ? selectedLocation : selectedLocation?.address || 'Not specified'}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <h4 className="text-lg font-light">Urgency</h4>
                          <p>{selectedUrgency?.name || ''}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <h4 className="text-lg font-light">Date & Time</h4>
                          <p>{selectedDate || ''} {selectedTime || ''} {selectedDate ? '(Sydney time)' : ''}</p>
                        </div>
                        <div className="border-t border-[#1A1A1A]/10 pt-4 mt-4">
                          <div className="flex justify-between items-center">
                            <div>
                              <h3 className="text-xl font-light text-[#1A1A1A]">Total Price</h3>
                              <p className="text-[#1A1A1A]/70">Including all factors</p>
                            </div>
                            <div className="text-3xl font-light text-[#1A1A1A]">${price || 0}</div>
                          </div>
                          <div className="mt-4 text-sm text-[#1A1A1A]/70">
                            {/* Price breakdown */}
                            {(() => {
                              // Get zone from location
                              let zone = 1; // Default to Zone 1
                              if (selectedService?.id === 'house-call' && selectedLocation) {
                                if (typeof selectedLocation === 'object') {
                                  // Extract zone number from zone name (e.g., "Zone 3" -> 3)
                                  if (selectedLocation.zone && selectedLocation.zone.startsWith('Zone ')) {
                                    zone = parseInt(selectedLocation.zone.split(' ')[1]);
                                  } else {
                                    zone = selectedLocation.zone || 1;
                                  }
                                } else {
                                  zone = selectedLocation;
                                }
                              } else if (selectedService?.id === 'telemedicine') {
                                zone = 'Telemedicine';
                              } else if (selectedService?.id === 'clinic-visit') {
                                zone = 'Waterloo Clinic';
                              }

                              // Get base price for the zone
                              const zoneBasePrice = {
                                1: 160, // Zone 1 base price
                                2: 170, // Zone 2 base price
                                3: 180, // Zone 3 base price
                                4: 200, // Zone 4 base price
                                5: 250, // Zone 5+ base price
                                'Waterloo Clinic': 75, // Clinic visit base price
                                'Telemedicine': 75, // Telemedicine base price
                              };
                              const basePrice = zoneBasePrice[zone] || zoneBasePrice[1];

                              // Get time surcharge multiplier
                              let timeSurchargeMultiplier = 1;
                              let timeOfDay = "standard hours";
                              if (selectedTime) {
                                try {
                                  const hour = parseInt(selectedTime.split(':')[0] || '0');
                                  if (!isNaN(hour)) {
                                    timeSurchargeMultiplier = getTimeSurcharge(hour, zone);
                                    
                                    // Determine time of day description
                                    if (hour >= 0 && hour < 10) {
                                      timeOfDay = "morning (12am-10am)";
                                    } else if (hour >= 10 && hour < 16) {
                                      timeOfDay = "daytime (10am-4pm)";
                                    } else if (hour >= 16 && hour < 22) {
                                      timeOfDay = "evening (4pm-10pm)";
                                    } else if (hour >= 22 && hour <= 23) {
                                      timeOfDay = "night (10pm-12am)";
                                    }
                                  }
                                } catch (error) {
                                  console.error('Error parsing time:', error);
                                }
                              }
                              
                              // Check if booking is on weekend or Friday evening
                              let isWeekendOrFridayEvening = false;
                              if (selectedDate && selectedTime) {
                                try {
                                  const hour = parseInt(selectedTime.split(':')[0] || '0');
                                  
                                  // Use a more reliable way to create the date
                                  const dateParts = selectedDate.split('-');
                                  const year = parseInt(dateParts[0]);
                                  const month = parseInt(dateParts[1]) - 1; // JS months are 0-indexed
                                  const day = parseInt(dateParts[2]);
                                  const selectedDateObj = new Date(year, month, day, hour);
                                  
                                  const dayOfWeek = selectedDateObj.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                                  const isFridayEvening = dayOfWeek === 5 && hour >= 17; // Friday after 5pm
                                  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
                                  
                                  console.log(`Review - Selected date: ${selectedDate} (${year}-${month+1}-${day}), day of week: ${dayOfWeek}, hour: ${hour}`);
                                  console.log(`Review - Is Friday evening: ${isFridayEvening}, Is weekend: ${isWeekend}`);
                                  
                                  isWeekendOrFridayEvening = isFridayEvening || isWeekend;
                                } catch (error) {
                                  console.error('Error checking weekend/Friday status:', error);
                                }
                              }
                              
                              // Check if booking is within 4 hours
                              let isWithin4Hours = false;
                              let effectiveUrgencyFactor = selectedUrgency?.factor || 1;
                              if (selectedDate && selectedTime && selectedUrgency && selectedUrgency.id !== 'asap') {
                                try {
                                  const sydneyTime = getCurrentSydneyTime();
                                  const selectedDateTime = new Date(`${selectedDate}T${selectedTime}`);
                                  const currentDateTime = new Date(`${sydneyTime.date}T${sydneyTime.time}`);
                                  
                                  // Calculate difference in milliseconds
                                  const timeDifferenceMs = selectedDateTime - currentDateTime;
                                  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
                                  
                                  // If the booking is within 4 hours, use the ASAP urgency factor
                                  if (timeDifferenceHours >= 0 && timeDifferenceHours <= 4) {
                                    isWithin4Hours = true;
                                    effectiveUrgencyFactor = urgencyFactors.ASAP.factor;
                                  }
                                } catch (error) {
                                  console.error('Error calculating time difference:', error);
                                }
                              }
                              
                              // Calculate price with time surcharge
                              const priceWithTimeSurcharge = Math.round(basePrice * timeSurchargeMultiplier);
                              
                              // Calculate price with weekend surcharge (if applicable)
                              const priceWithWeekendSurcharge = isWeekendOrFridayEvening ? 
                                Math.round(priceWithTimeSurcharge * 1.1) : 
                                priceWithTimeSurcharge;

                              return (
                                <>
                                  <p>Base price for {typeof zone === 'number' ? `Zone ${zone}` : zone}: ${basePrice}</p>
                                  <p>Time factor: {timeSurchargeMultiplier.toFixed(2)}x for {timeOfDay} (${priceWithTimeSurcharge})</p>
                                  {isWeekendOrFridayEvening && (
                                    <p>Weekend/Friday evening surcharge: 1.10x (${priceWithWeekendSurcharge})</p>
                                  )}
                                  {selectedUrgency && (
                                    <>
                                      <p>
                                        Urgency factor: {effectiveUrgencyFactor.toFixed(1)}x
                                        {isWithin4Hours && <span className="ml-1 text-amber-700"> (ASAP rate applied - within 4 hours)</span>}
                                        {selectedService?.id === 'clinic-visit' && selectedUrgency.id === 'clinic-urgency' && (
                                          <span className="ml-1">
                                            {effectiveUrgencyFactor === 1.5 && " (within 1 week)"}
                                            {effectiveUrgencyFactor === 1.2 && " (within 2 weeks)"}
                                            {effectiveUrgencyFactor === 1.0 && " (beyond 2 weeks)"}
                                          </span>
                                        )}
                                      </p>
                                    </>
                                  )}
                                </>
                              );
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between mt-6">
                      <motion.button
                        type="button"
                        onClick={resetBooking}
                        className="px-4 py-2 border border-[#1A1A1A]/50 text-[#1A1A1A]/50 rounded-md hover:border-[#1A1A1A] hover:text-[#1A1A1A]"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        Start Over
                      </motion.button>
                      <motion.button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="px-8 py-4 border border-[#1A1A1A] text-[#1A1A1A] rounded-full text-lg font-light hover:bg-[#1A1A1A] hover:text-[#DDC6A8] transition-all disabled:opacity-50 disabled:cursor-not-allowed"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        {loading ? 'Processing...' : 'Proceed to Payment'}
                      </motion.button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 