import React from 'react';
import PremiumBlogTemplate from '../../components/PremiumBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const PrivateJetMedicalServices = () => {
  // SEO metadata
  const seoTitle = "Private Jet Medical Services: In-Flight Healthcare for Global Travelers | HomeDoc";
  const seoDescription = "Expert medical accompaniment for private aviation clients worldwide. Comprehensive pre-flight assessments, in-flight medical support, and emergency response by Sydney's premier travel physician.";
  const seoKeywords = "private jet doctor, travel physician, in-flight medical care, aviation medicine, VIP medical services, international medical support, luxury travel healthcare, Sydney private doctor, Dr Terry Nguyen";
  
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold mb-6">Private Jet Medical Services: Healthcare Without Borders</h2>
        <div className="flex flex-col lg:flex-row gap-6 mb-6">
          <div className="lg:w-2/3">
            <p className="mb-4">
              In the realm of private aviation, where convenience, privacy, and luxury intersect, having dedicated medical support provides an essential layer of security and peace of mind. Drawing from years of experience providing medical care across continents, I've cultivated expertise in addressing the unique healthcare challenges that arise during international travel via private aircraft.
            </p>
            <p className="mb-4">
              Private jet medical services encompass far more than emergency response capabilities—they represent a comprehensive approach to maintaining health continuity during global travel. From <strong>pre-flight medical assessments</strong> to <strong>in-flight monitoring</strong> and <strong>destination healthcare coordination</strong>, these services ensure that medical considerations never constrain your travel plans.
            </p>
            <p className="mb-4">
              Whether you're a frequent international traveler departing from <strong>Sydney</strong>, an executive with a demanding global itinerary, or a family seeking reassurance during international journeys, private jet medical accompaniment provides a sophisticated solution to the healthcare complexities of air travel.
            </p>
          </div>
          <div className="lg:w-1/3">
            <figure className="rounded-xl overflow-hidden shadow-xl">
              <img 
                src="/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png" 
                alt="Luxury transportation for private jet medical services" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-[#2A2A2A]/70 px-4 py-2 text-sm text-[#DDC6A8]/80 text-center">
                Seamless medical support from ground transportation to private aviation
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section id="aviation-medical-challenges">
        <h2 className="text-3xl font-bold mb-6">Medical Challenges in Aviation: Beyond Standard Healthcare</h2>
        <p className="mb-4">
          Air travel presents unique physiological challenges that require specialized medical knowledge. While private aviation eliminates many of the inconveniences of commercial flights, it introduces its own considerations:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Cabin Pressure & Altitude Effects</h3>
            <p>
              Even in the most luxurious private jets, cabin pressure typically simulates an altitude of 6,000-8,000 feet, which reduces oxygen saturation in the blood. For travelers with cardiopulmonary conditions, this can exacerbate symptoms or create new challenges requiring medical oversight.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Aviation-Related Physiological Issues</h3>
            <p>
              Deep vein thrombosis, dehydration, and jet lag affect private travelers as much as commercial passengers. Having a physician who understands these conditions and can implement preventative measures significantly enhances comfort and safety.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Medical Emergencies at Altitude</h3>
            <p>
              When medical situations arise at 40,000 feet, immediate access to expert care makes a critical difference. A dedicated flight physician can stabilize conditions, administer emergency medications, and determine whether a diversion is necessary.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">International Healthcare Navigation</h3>
            <p>
              Each destination presents a different healthcare landscape. A physician familiar with global medical systems can coordinate with local providers and facilities before, during, and after the journey, ensuring continuity of care.
            </p>
          </div>
        </div>
        
        <p className="mb-4">
          The intersection of aviation medicine and VIP healthcare requires a unique skill set—one that combines clinical expertise with an understanding of the logistical and practical realities of global travel. This specialized knowledge ensures that medical support is seamlessly integrated into the private aviation experience.
        </p>
      </section>

      <section id="comprehensive-services">
        <h2 className="text-3xl font-bold mb-6">Comprehensive Private Jet Medical Services</h2>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Pre-Flight Medical Assessment & Preparation</h3>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-2/3">
              <p className="mb-4">
                Every journey begins with thorough preparation. For private jet travelers, this includes a comprehensive medical assessment tailored to the specific itinerary, individual health profile, and destination-specific considerations.
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-4">
                <li>Personalized pre-flight consultations to identify potential health concerns</li>
                <li>Destination-specific health briefings and preventative recommendations</li>
                <li>Travel medication review and adjustment for time zone changes</li>
                <li>Custom medical kit preparation based on passenger health profiles</li>
                <li>Coordination with regular healthcare providers to ensure continuity of care</li>
              </ul>
              <p>
                This proactive approach minimizes health risks and ensures that any necessary accommodations or arrangements are made before departure from <Link to="/" className="text-[#DDC6A8] hover:text-white">Sydney</Link> or any global location.
              </p>
            </div>
            <div className="md:w-1/3">
              <img 
                src="/images/drtez_Modern_coffee_table_with_open_MacBook_stethoscope_and_g_27ec4ad2-c21a-4429-9e1b-379509dd855a_2.png" 
                alt="Pre-flight medical assessment preparation" 
                className="w-full h-auto rounded-xl shadow-lg"
              />
            </div>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">In-Flight Medical Accompaniment</h3>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-1/3">
              <img 
                src="/images/drtez_Modern_five-star_hotel_suite_desk_with_open_leather_fol_a6b8997e-a733-4f52-9ab0-f42800e7e2e0_3.png" 
                alt="In-flight medical monitoring and care" 
                className="w-full h-auto rounded-xl shadow-lg"
              />
            </div>
            <div className="md:w-2/3">
              <p className="mb-4">
                Having a dedicated physician onboard transforms the private aviation experience, providing peace of mind for passengers and crew alike. In-flight medical services include:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-4">
                <li>Continuous monitoring of passengers with pre-existing conditions</li>
                <li>Management of in-flight medical situations, from minor discomfort to emergencies</li>
                <li>Administration of scheduled medications and treatments</li>
                <li>Strategic recommendations for mitigating jet lag and travel fatigue</li>
                <li>Real-time consultation with ground-based specialists if needed</li>
                <li>Liaison with aviation personnel regarding medical considerations</li>
              </ul>
              <p>
                The presence of a flight physician ensures that medical expertise is immediately available throughout the journey, regardless of the aircraft's location or the local time.
              </p>
            </div>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Destination & Return Healthcare Coordination</h3>
          <p className="mb-4">
            A truly comprehensive approach to travel medicine extends beyond the flight itself to encompass the entire journey. This includes:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>Pre-arranged access to premium healthcare facilities at destinations</li>
            <li>Coordination with local specialists for ongoing or emergency care</li>
            <li>Medical liaison services with hotels, residences, and venues</li>
            <li>Secure transfer of medical information when necessary</li>
            <li>Post-journey follow-up and integration with regular healthcare providers</li>
            <li>Management of repatriation arrangements if required</li>
          </ul>
          <p>
            This seamless coordination ensures that the highest standard of care is maintained throughout the entirety of your international travel experience, whether you're departing from <strong>Sydney</strong>, traveling to Europe, Asia, the Americas, or beyond.
          </p>
        </div>
      </section>

      <section id="case-studies">
        <h2 className="text-3xl font-bold mb-6">Case Studies: Excellence in Aviation Medicine</h2>
        <p className="mb-4">
          The following anonymized cases illustrate the value of comprehensive private jet medical services in real-world scenarios:
        </p>
        
        <div className="space-y-8 mb-8">
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Case 1: Transatlantic Journey with Cardiac Considerations</h3>
            <p className="mb-3">
              <strong>Scenario:</strong> An executive with a history of cardiac arrhythmia required transatlantic travel for urgent business matters.
            </p>
            <p className="mb-3">
              <strong>Approach:</strong> A comprehensive pre-flight assessment was conducted, medication timing was adjusted for the new time zone, and monitoring protocols were established. During the flight, the physician detected early signs of atrial fibrillation and administered appropriate medication, preventing progression and avoiding an emergency diversion.
            </p>
            <p>
              <strong>Outcome:</strong> The passenger completed the journey without incident and attended all scheduled meetings. The episode was documented and communicated to the passenger's regular cardiologist upon return.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Case 2: Multi-Generational Family Travel</h3>
            <p className="mb-3">
              <strong>Scenario:</strong> A family group spanning three generations, including an infant and elderly grandparents with various medical conditions, embarked on an international vacation.
            </p>
            <p className="mb-3">
              <strong>Approach:</strong> Individualized preparations were made for each family member, including specialized infant care provisions and management plans for the grandparents' chronic conditions. During the journey, the physician addressed a respiratory infection in one child and managed a temporary increase in blood pressure for one of the grandparents.
            </p>
            <p>
              <strong>Outcome:</strong> All family members received appropriate care throughout the journey without requiring external medical facilities, allowing the vacation to proceed as planned.
            </p>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/50 border-l-4 border-[#DDC6A8] p-6 rounded-r-xl mb-8">
          <p className="italic text-[#DDC6A8]/90">
            "Having Dr. Nguyen accompany our international flights has transformed our travel experience. His expertise in aviation medicine and ability to coordinate care across multiple countries provides invaluable peace of mind for our entire executive team."
          </p>
          <p className="text-right text-[#DDC6A8]/70 mt-2">— CEO, International Investment Firm</p>
        </div>
      </section>

      <section id="global-approach">
        <h2 className="text-3xl font-bold mb-6">A Global Approach to Private Aviation Healthcare</h2>
        <p className="mb-4">
          While <strong>Sydney</strong> serves as our primary base, our private jet medical services operate on a truly global scale. This international approach is facilitated by:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">International Network</h3>
            <p>
              Established relationships with premier healthcare facilities across major global destinations ensure that additional resources are readily available when needed, regardless of the aircraft's location.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Cultural Competence</h3>
            <p>
              Understanding the nuances of healthcare delivery in different regions enables seamless integration with local medical systems and effective communication with healthcare providers worldwide.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Logistical Expertise</h3>
            <p>
              Navigating the complex intersection of international medicine, aviation regulations, and cross-border healthcare requires specialized knowledge that extends beyond clinical skills to encompass practical logistical considerations.
            </p>
          </div>
        </div>
        
        <p className="mb-4">
          This global framework ensures that whether you're departing from <strong>Sydney</strong>, London, New York, or Singapore, your medical care remains consistent, personalized, and exceptional throughout your journey.
        </p>
      </section>

      <section id="client-considerations">
        <h2 className="text-3xl font-bold mb-6">Considerations for Private Aviation Clients</h2>
        <p className="mb-4">
          When evaluating private jet medical services, consider the following factors to ensure you select the most appropriate option for your needs:
        </p>
        
        <div className="overflow-x-auto mb-8">
          <table className="min-w-full bg-[#2A2A2A]/30 rounded-xl overflow-hidden">
            <thead className="bg-[#2A2A2A]/70">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8] uppercase tracking-wider">Consideration</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8] uppercase tracking-wider">Key Questions</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8] uppercase tracking-wider">Optimal Solution</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#DDC6A8]/10">
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#DDC6A8]">Physician Qualifications</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">Does the physician have specific training in aviation medicine? Are they experienced in managing care across international boundaries?</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">A physician with formal training in aviation/travel medicine and extensive international experience</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#DDC6A8]">Service Scope</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">Does the service cover pre-flight preparation, in-flight care, and destination coordination? Is there a contingency plan for medical diversions?</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">Comprehensive services that address the entire journey, not just the flight itself</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#DDC6A8]">Equipment & Medications</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">What medical equipment will be available? Are medications customized to passenger profiles and destinations?</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">Tailored medical kits that exceed standard requirements, with medications appropriate for specific passenger needs</td>
              </tr>
              <tr>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#DDC6A8]">Communication Protocols</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">How will medical information be communicated to the flight crew, destination providers, and regular physicians?</td>
                <td className="px-6 py-4 text-sm text-[#DDC6A8]/80">Secure, compliant communication systems that ensure privacy while facilitating necessary information sharing</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <p className="mb-4">
          The ideal private jet medical service prioritizes both excellence and discretion, providing sophisticated medical support that enhances rather than intrudes upon the private aviation experience.
        </p>
      </section>

      <section id="conclusion">
        <h2 className="text-3xl font-bold mb-6">Beyond Standard Care: The Future of Private Aviation Medicine</h2>
        <p className="mb-4">
          As private aviation continues to evolve, so too does the field of aviation medicine. The future of private jet medical services will likely incorporate:
        </p>
        <ul className="list-disc pl-5 space-y-2 mb-6">
          <li>Advanced telemedicine capabilities connecting in-flight physicians with global specialists</li>
          <li>Enhanced monitoring technologies that provide real-time physiological data during flights</li>
          <li>Personalized preventative protocols based on genetic and biomarker analysis</li>
          <li>Seamless integration with destination wellness and healthcare programs</li>
        </ul>
        <p className="mb-6">
          The core principle, however, remains unchanged: providing exceptional, personalized medical care that transcends geographical boundaries and enables truly global mobility without compromising health considerations.
        </p>
        <p className="mb-4">
          For international travelers departing from <strong>Sydney</strong> or any global location, having access to sophisticated medical support throughout their journey represents not merely a luxury but an essential component of comprehensive travel planning.
        </p>
        <div className="bg-[#2A2A2A]/30 rounded-xl p-6 mb-8">
          <p className="mb-4">
            To discuss how private jet medical services can be tailored to your specific travel requirements, <Link to="/private-consultation" className="text-[#DDC6A8] hover:text-white font-medium">request a confidential consultation</Link>. Our discreet approach ensures that your healthcare needs are addressed with the same attention to detail and excellence that characterizes every aspect of private aviation.
          </p>
        </div>
      </section>
    </>
  );

  const references = [
    {
      authors: 'Aerospace Medical Association',
      title: 'Medical Guidelines for Airline Travel',
      journal: 'Aviation, Space, and Environmental Medicine',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Budd L, Bell M, Warren A.',
      title: 'Taking care in the air: jet air travel and passenger health',
      journal: 'Journal of Travel Medicine',
      year: '2022',
      doi: '10.1093/jtm/taz005'
    },
    {
      authors: 'International Air Transport Association (IATA)',
      title: 'Medical Manual',
      journal: 'IATA Publications',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Silverman D, Gendreau M.',
      title: 'Medical issues associated with commercial flights',
      journal: 'The Lancet',
      year: '2022',
      doi: '10.1016/S0140-6736(08)60980-1'
    }
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Aviation Medical Challenges', anchor: 'aviation-medical-challenges' },
    { title: 'Comprehensive Services', anchor: 'comprehensive-services' },
    { title: 'Case Studies', anchor: 'case-studies' },
    { title: 'Global Approach', anchor: 'global-approach' },
    { title: 'Client Considerations', anchor: 'client-considerations' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  const relatedArticles = [
    {
      title: 'Executive Health Programs: Tailored Medical Care for Business Leaders',
      slug: 'executive-health-programs-sydney',
      excerpt: 'Comprehensive health assessments and ongoing care designed specifically for executives and business leaders in Sydney. Discreet, efficient healthcare that fits your schedule.',
      imageUrl: '/images/drtez_Close-up_of_a_luxury_smartphone_with_a_contact_screen_s_3879fbc9-2d8d-4acf-960e-5eb0c9efa2f5_3.png',
    },
    {
      title: 'Yacht Medical Services: Comprehensive Healthcare During Maritime Travel',
      slug: 'yacht-medical-services',
      excerpt: 'Specialized medical care for yacht owners, guests, and crew during maritime journeys. From Sydney Harbour to international waters, our physician services ensure health safety at sea.',
      imageUrl: '/images/drtez_Minimalist_bedside_table_with_glass_of_water_luxury_sup_85edf870-682f-4255-b700-5ef85b309e17_1.png',
    },
    {
      title: 'Health Considerations for VIPs Traveling to Australia',
      slug: 'vip-healthcare-australia',
      excerpt: 'Essential health preparations and considerations for high-profile individuals visiting Australia. Discreet medical support tailored to the unique needs of VIP travelers.',
      imageUrl: '/images/drtez_Panoramic_view_from_inside_a_penthouse_with_soft_mornin_5f661f61-add1-438b-9e3a-b76803127174_0.png',
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/private-jet-medical-services" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/private-jet-medical-services" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalWebPage",
              "headline": "${seoTitle}",
              "image": "https://homedoc.com.au/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png",
              "datePublished": "2024-07-10",
              "author": {
                "@type": "Person",
                "name": "Dr. Terry Nguyen"
              },
              "publisher": {
                "@type": "Organization",
                "name": "HomeDoc",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://homedoc.com.au/images/logos/privatedoctorservices_logo.png"
                }
              },
              "description": "${seoDescription}",
              "specialty": ["Aviation Medicine", "Travel Medicine", "VIP Healthcare"]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What medical equipment is available during private jet flights?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our private jet medical services include customized medical kits that exceed standard aviation requirements. Equipment typically includes advanced monitoring devices, emergency medications, airway management tools, cardiac support equipment, and specialized items based on passenger health profiles and flight routes."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How does cabin pressure affect pre-existing medical conditions?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Cabin pressure in private jets typically simulates an altitude of 6,000-8,000 feet, which reduces oxygen saturation in the blood by 3-5%. This can exacerbate cardiopulmonary conditions, affect glucose levels in diabetic patients, and impact other chronic conditions. Our pre-flight assessments identify these risks and implement appropriate management strategies during flight."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can medical specialists be arranged at international destinations?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, our global network includes relationships with premier healthcare providers worldwide. We can arrange appointments with specialists, coordinate with facilities for ongoing treatments, and ensure that your healthcare needs are seamlessly addressed at any international destination."
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>
    
      <PremiumBlogTemplate
        title="Private Jet Medical Services: In-Flight Healthcare for Global Travelers"
        subtitle="Expert medical accompaniment for private aviation clients worldwide"
        heroImage="/images/drtez_private_jet_on_runway_--ar_169_--v_6.1_c580842d-d85f-49b2-9159-a5887075f3b8_3.png"
        publishDate="July 10, 2024"
        readTime={15}
        content={content}
        category="Private Doctor Services"
        tags={['Private Jet Doctor', 'Aviation Medicine', 'Travel Healthcare', 'VIP Medical Services', 'International Medical Support', 'Sydney']}
        references={references}
        tableOfContents={tableOfContents}
        relatedArticles={relatedArticles}
      />
    </>
  );
};

export default PrivateJetMedicalServices; 