import React from 'react';
import { Link } from 'react-router-dom';
import BlogArticleTemplate from './BlogArticleTemplate';

const MedicalDisclaimer = () => (
  <div className="bg-yellow-50 border-l-4 border-yellow-400 p-6 rounded-r-xl mb-8">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
        </svg>
      </div>
      <div className="ml-4">
        <h4 className="text-sm font-medium text-yellow-800">Medical Disclaimer</h4>
        <p className="mt-1 text-sm text-yellow-700">
          This article is for informational purposes only and should not be considered medical advice. 
          Always consult with a qualified healthcare provider for personalized recommendations.
        </p>
      </div>
    </div>
  </div>
);

const EmergencyBox = () => (
  <div className="bg-red-50 border-l-4 border-red-400 p-6 rounded-r-xl mb-8">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg className="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>
      <div className="ml-4">
        <h4 className="text-sm font-medium text-red-800">Emergency Warning</h4>
        <p className="mt-1 text-sm text-red-700">
          If you are experiencing a medical emergency, call emergency services (000) immediately.
        </p>
      </div>
    </div>
  </div>
);

const SymptomChecker = ({ symptoms = [] }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">Common Symptoms</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {symptoms.map((symptom, index) => (
        <div key={index} className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg">
          <svg className="h-5 w-5 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span className="text-gray-700">{symptom}</span>
        </div>
      ))}
    </div>
  </div>
);

const TreatmentTimeline = ({ treatments = [] }) => (
  <div className="relative">
    <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200"></div>
    {treatments.map((treatment, index) => (
      <div key={index} className="relative pl-12 pb-8">
        <div className="absolute left-2 -translate-x-1/2 w-6 h-6 rounded-full bg-indigo-100 border-2 border-indigo-500"></div>
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h4 className="font-semibold text-gray-900">{treatment.stage}</h4>
          <p className="mt-2 text-gray-600">{treatment.description}</p>
          {treatment.duration && (
            <p className="mt-2 text-sm text-gray-500">Duration: {treatment.duration}</p>
          )}
        </div>
      </div>
    ))}
  </div>
);

const ReferencesList = ({ references = [] }) => (
  <div className="bg-gray-50 rounded-xl p-6 mt-8">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">References</h3>
    <ol className="list-decimal list-inside space-y-2">
      {references.map((reference, index) => (
        <li key={index} className="text-gray-600 text-sm">
          {reference.authors && <span className="font-medium">{reference.authors}. </span>}
          {reference.title && <span className="italic">{reference.title}. </span>}
          {reference.journal && <span>{reference.journal}. </span>}
          {reference.year && <span>({reference.year})</span>}
          {reference.doi && (
            <a 
              href={`https://doi.org/${reference.doi}`}
              className="ml-2 text-indigo-600 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              DOI: {reference.doi}
            </a>
          )}
        </li>
      ))}
    </ol>
  </div>
);

const MedicalBlogTemplate = ({
  title = '',
  subtitle = '',
  heroImage = '',
  author = "Dr. Terry Nguyen",
  authorImage = "/images/drterrynguyen_medicaldoctor.png",
  publishDate = new Date().toLocaleDateString('en-AU'),
  readTime = 5,
  audioLength,
  content = null,
  relatedArticles,
  category = '',
  tags = [],
  symptoms = [],
  treatments = [],
  references = [],
  showEmergencyBox = false,
  tableOfContents = [],
}) => {
  // Ensure all arrays are actually arrays
  const safeTags = Array.isArray(tags) ? tags : [];
  const safeSymptoms = Array.isArray(symptoms) ? symptoms : [];
  const safeTreatments = Array.isArray(treatments) ? treatments : [];
  const safeReferences = Array.isArray(references) ? references : [];
  const safeTableOfContents = Array.isArray(tableOfContents) ? tableOfContents : [];

  const wrappedContent = (
    <>
      <MedicalDisclaimer />
      {showEmergencyBox && <EmergencyBox />}
      {safeSymptoms.length > 0 && <SymptomChecker symptoms={safeSymptoms} />}
      {content}
      {safeTreatments.length > 0 && (
        <div className="my-8">
          <h3 className="text-2xl font-bold text-gray-900 mb-6">Treatment Timeline</h3>
          <TreatmentTimeline treatments={safeTreatments} />
        </div>
      )}
      {safeReferences.length > 0 && <ReferencesList references={safeReferences} />}
    </>
  );

  return (
    <BlogArticleTemplate
      title={title}
      subtitle={subtitle}
      heroImage={heroImage}
      author={author}
      authorImage={authorImage}
      publishDate={publishDate}
      readTime={readTime}
      audioLength={audioLength}
      content={wrappedContent}
      relatedArticles={relatedArticles}
      category={category}
      tags={safeTags}
      tableOfContents={safeTableOfContents}
    />
  );
};

export default MedicalBlogTemplate; 