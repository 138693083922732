import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import SEO from '../../components/SEO';
import 'leaflet/dist/leaflet.css';
import drTerryPhoto from '../../assets/images/profile/drterry_hero.png';
import L from 'leaflet';

// Import Eastern Suburbs image when available
import easternSuburbsImage from '../../assets/images/sections/doctorbag.png';

const EasternSuburbs = () => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [map, setMap] = useState(null);
  
  // Eastern Suburbs polygon coordinates (approximate - replace with more accurate boundaries)
  const easternSuburbsCoordinates = [
    [-33.8566, 151.2085], // Paddington approximate
    [-33.8964, 151.2502], // Bondi approximate
    [-33.9399, 151.2510], // Maroubra approximate
    [-33.9154, 151.2278], // Coogee approximate
    [-33.8882, 151.2190], // Randwick approximate
    [-33.8610, 151.2315], // Double Bay approximate
    [-33.8518, 151.2079], // Paddington (closing the polygon)
  ];

  // Fix Leaflet icon issue in production
  useEffect(() => {
    // Initialize Leaflet map
    if (typeof window !== "undefined") {
      // Fix Leaflet's default icon
      delete L.Icon.Default.prototype._getIconUrl;
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
      });

      setIsMapLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isMapLoaded && !map) {
      // Initialize map once component is mounted
      const leafletMap = L.map('map').setView([-33.8882, 151.2375], 13);
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(leafletMap);
      
      // Add polygon
      L.polygon(easternSuburbsCoordinates, {
        color: '#DDC6A8',
        fillOpacity: 0.2
      }).bindTooltip("Dr. Terry Nguyen's Eastern Suburbs Service Area").addTo(leafletMap);
      
      setMap(leafletMap);
      
      // Cleanup on unmount
      return () => {
        if (leafletMap) {
          leafletMap.remove();
        }
      };
    }
  }, [isMapLoaded]);

  // Eastern Suburbs schema.org structured data
  const structuredData = {
    "@context": "https://schema.org",
    "@type": ["MedicalBusiness", "LocalBusiness", "Physician"],
    "name": "Dr. Terry Nguyen - Eastern Suburbs House Call Doctor",
    "description": "Private house call doctor providing 24/7 medical care throughout Sydney's Eastern Suburbs including Bondi, Double Bay, Randwick, and surrounding areas.",
    "url": "https://homedoc.com.au/locations/eastern-suburbs",
    "image": "https://homedoc.com.au/images/dr-terry-eastern-suburbs.jpg",
    "telephone": "+61412345678",
    "priceRange": "$$$",
    "areaServed": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "-33.8882",
        "longitude": "151.2637"
      },
      "geoRadius": "8km"
    },
    "serviceArea": {
      "@type": "GeoCircle",
      "geoMidpoint": {
        "@type": "GeoCoordinates",
        "latitude": "-33.8882",
        "longitude": "151.2637"
      },
      "geoRadius": "8km"
    },
    "address": {
      "@type": "PostalAddress",
      "addressRegion": "NSW",
      "addressLocality": "Sydney",
      "addressCountry": "AU"
    },
    "medicalSpecialty": ["HouseCallMedicine", "FamilyMedicine"],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "House Call Doctor Service",
        "description": "Private medical care in your Eastern Suburbs home."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Urgent Care",
        "description": "Treatment for non-emergency but urgent conditions."
      },
      {
        "@type": "MedicalProcedure",
        "name": "Diagnostic Services",
        "description": "Assessment and diagnosis of various conditions."
      }
    ],
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        "opens": "00:00",
        "closes": "23:59"
      }
    ]
  };

  // List of Eastern Suburbs served
  const suburbs = [
    "Bellevue Hill", "Bondi", "Bondi Beach", "Bondi Junction", "Bronte", 
    "Centennial Park", "Clovelly", "Coogee", "Darling Point", "Double Bay", 
    "Dover Heights", "Edgecliff", "Elizabeth Bay", "Kings Cross", "Maroubra", 
    "Paddington", "Point Piper", "Potts Point", "Queens Park", "Randwick", 
    "Rose Bay", "Rushcutters Bay", "Tamarama", "Vaucluse", "Waverley", "Woollahra"
  ];

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      {/* SEO Component */}
      <SEO 
        title="House Call Doctor Eastern Suburbs Sydney | Private Home Visits | Dr. Terry Nguyen"
        description="Expert private house call doctor in Sydney's Eastern Suburbs. 24/7 home visits in Bondi, Double Bay, Randwick, Woollahra and surrounding areas. Dr. Terry Nguyen."
        keywords={[
          'house call doctor Eastern Suburbs',
          'private doctor Bondi',
          'home doctor Double Bay',
          'after hours doctor Randwick',
          'doctor home visits Eastern Suburbs',
          'private GP house calls Sydney',
          'Woollahra house call doctor',
          'doctor near me Eastern Suburbs',
          'premium medical home service',
          'Bondi house call doctor'
        ]}
        schema={structuredData}
      />
      
      <div className="content-wrapper">
        {/* Hero Section */}
        <div className="relative">
          <div className="h-[60vh] md:h-[70vh] bg-gradient-to-b from-[#1A1A1A]/90 to-[#1A1A1A]/30" style={{
            backgroundImage: `url(${easternSuburbsImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundBlendMode: 'overlay'
          }}>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="max-w-4xl mx-auto px-4 text-center">
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-light mb-6 text-white">
                  Private House Call Doctor in Sydney's Eastern Suburbs
                </h1>
                <p className="text-xl md:text-2xl text-white/90 mb-8">
                  Premium medical care delivered to your doorstep
                </p>
                <Link
                  to="/dynamic-booking"
                  state={{ service: "Eastern Suburbs House Call" }}
                  className="inline-flex items-center px-8 py-4 text-lg border-2 border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
                >
                  Book a House Call
                  <svg className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* 24/7 Availability Banner - NEW ADDITION */}
        <div className="bg-[#DDC6A8] text-[#1A1A1A] py-4">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-2xl font-medium">
              I am available 24/7 for house calls and after hours home visits throughout Sydney's Eastern Suburbs
            </h2>
            <p className="mt-2 text-[#1A1A1A]/80 font-medium">
              Immediate attention • No waiting rooms • Direct doctor access • Call now
            </p>
          </div>
        </div>

        {/* Introduction Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-4xl mx-auto px-4">
            <div className="prose prose-lg prose-invert max-w-none">
              <p className="text-xl text-[#DDC6A8]">
                Dr. Terry Nguyen provides exceptional private medical care through house calls throughout Sydney's Eastern Suburbs. With years of experience serving this vibrant community, Dr. Terry brings comprehensive, personalized healthcare directly to your home or accommodation in Bondi, Double Bay, Randwick, Woollahra, and all surrounding Eastern Suburbs areas.
              </p>
              <p className="text-white opacity-95">
                As a dedicated house call doctor, Dr. Terry eliminates the inconvenience of traveling when you're unwell, offering private, discreet medical consultations 24 hours a day, 7 days a week. Experience the comfort of receiving expert medical care in your own environment, with no waiting rooms and complete attention to your health needs.
              </p>
            </div>
          </div>
        </div>

        {/* Service Area Map Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-8 text-center">Eastern Suburbs Service Area</h2>
            
            <p className="text-center text-xl mb-10">
              Dr. Terry Nguyen provides private house call services throughout Sydney's Eastern Suburbs, from Paddington to Bondi and Vaucluse to Coogee.
            </p>
            
            <div className="h-[400px] rounded-xl overflow-hidden mb-12">
              {isMapLoaded ? (
                <div id="map" style={{ height: '100%', width: '100%' }}></div>
              ) : (
                <div className="h-full w-full flex items-center justify-center bg-[#1A1A1A]">
                  <div className="w-10 h-10 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin" />
                </div>
              )}
            </div>
            
            <h3 className="text-2xl font-light mb-6 text-center">Suburbs Served</h3>
            
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 mb-8">
              {suburbs.map((suburb, index) => (
                <div key={index} className="bg-[#DDC6A8]/5 p-3 rounded-lg text-center">
                  {suburb}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Advantages Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Why Choose House Call Doctor Services in the Eastern Suburbs</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">No Eastern Suburbs Traffic or Parking Hassles</h3>
                    <p className="text-[#DDC6A8]/80">
                      Avoid Eastern Suburbs traffic and challenging parking situations when you're feeling unwell. Dr. Terry comes directly to you, eliminating the stress of travel during illness.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">Private and Discreet Medical Care</h3>
                    <p className="text-[#DDC6A8]/80">
                      Receive discreet, private medical attention in your Eastern Suburbs home or accommodation, ensuring complete privacy and confidentiality for your healthcare needs.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">Same-Day Appointments Available</h3>
                    <p className="text-[#DDC6A8]/80">
                      Often able to provide same-day visits throughout the Eastern Suburbs, Dr. Terry offers prompt medical attention when you need it most, without extended waiting periods.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">No Exposure to Other Patients</h3>
                    <p className="text-[#DDC6A8]/80">
                      Avoid crowded waiting rooms and potential exposure to other illnesses. House calls in your Eastern Suburbs home provide a safer, more comfortable healthcare experience.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">Comprehensive Medical Assessment</h3>
                    <p className="text-[#DDC6A8]/80">
                      Dr. Terry brings extensive equipment and expertise to your Eastern Suburbs location, providing thorough examinations and assessments in the comfort of your own space.
                    </p>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
                <div className="flex items-start mb-4">
                  <div className="mr-4 bg-[#DDC6A8] rounded-full p-3">
                    <svg className="w-6 h-6 text-[#1A1A1A]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-light mb-2">Familiar with Eastern Suburbs Community</h3>
                    <p className="text-[#DDC6A8]/80">
                      With deep knowledge of the Eastern Suburbs community and local healthcare landscape, Dr. Terry provides contextually relevant care for the specific needs of area residents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Services Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-10 text-center">Private Medical Services Available in Your Eastern Suburbs Home</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Urgent Care</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Treatment for non-emergency but urgent medical conditions such as infections, fevers, injuries, and acute illnesses. Receive prompt attention when you need it most in Bondi, Double Bay, or anywhere in the Eastern Suburbs.
                </p>
                <Link to="/urgent-care" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Diagnostic Services</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Comprehensive assessment and diagnosis of various conditions in the comfort of your Eastern Suburbs home. Dr. Terry brings professional diagnostic equipment to evaluate your condition accurately and efficiently.
                </p>
                <Link to="/diagnostic-services" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Medication Prescriptions</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Receive necessary prescriptions during your house call, eliminating the need for additional trips to a clinic. Dr. Terry can arrange medication delivery to your Eastern Suburbs location through partner pharmacies when needed.
                </p>
                <Link to="/prescriptions" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Preventive Care</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Comprehensive health screenings and preventive assessments conducted in your Eastern Suburbs residence. Proactive care to identify potential health issues before they become serious, tailored to your specific needs.
                </p>
                <Link to="/preventive-care" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl hover:bg-[#DDC6A8]/10 transition-all md:col-span-2">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Follow-up Care</h3>
                <p className="text-[#DDC6A8]/80 mb-4">
                  Continuity of care with the same trusted doctor throughout your treatment journey. Dr. Terry provides personalized follow-up visits to Eastern Suburbs residents, monitoring progress and adjusting treatment plans as needed for optimal recovery and health outcomes.
                </p>
                <Link to="/follow-up-care" className="text-[#DDC6A8] hover:text-white text-sm font-medium inline-flex items-center">
                  Learn more
                  <svg className="ml-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </Link>
              </div>
            </div>
            
            <div className="text-center">
              <Link
                to="/dynamic-booking"
                state={{ service: "Eastern Suburbs House Call" }}
                className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all group"
              >
                Book Your Eastern Suburbs House Call
                <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
            </div>
          </div>
        </div>

        {/* Conditions Treated Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-10 text-center">Medical Conditions Treated During Eastern Suburbs House Calls</h2>
            
            <div className="prose prose-lg prose-invert max-w-none mb-10">
              <p>
                Dr. Terry Nguyen treats a wide range of conditions during house calls throughout the Eastern Suburbs. While some medical situations require emergency department care, many conditions can be effectively managed in the comfort of your home with professional medical attention.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Respiratory Infections</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Treatment for common and seasonal respiratory infections, including influenza, bronchitis, sinusitis, and throat infections, with appropriate medication prescribed during your Eastern Suburbs house call.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Gastrointestinal Issues</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Management of nausea, vomiting, diarrhea, constipation, and other digestive complaints with targeted treatments and hydration advice delivered in your Eastern Suburbs residence.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Skin Conditions</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Diagnosis and treatment of rashes, infections, allergic reactions, and other dermatological issues, with personalized care plans provided during home visits throughout Bondi, Double Bay, and all Eastern Suburbs.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Urinary Tract Infections</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Prompt assessment and treatment of UTIs and related conditions, eliminating the discomfort of travel when experiencing urinary symptoms. Available for Eastern Suburbs residents at their convenience.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Minor Injuries and Wounds</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Professional care for cuts, minor burns, sprains, and other non-emergency injuries, including wound cleaning, dressing, and follow-up care planning during your Eastern Suburbs house call.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Headaches and Migraines</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Relief from severe headaches and migraines with targeted treatments and management strategies, delivered with compassion in your quiet, comfortable Eastern Suburbs environment.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Back Pain & Musculoskeletal</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Assessment and management of back pain, joint pain, and muscle strains, with treatment plans designed for your specific needs and delivered to your Eastern Suburbs home.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Medication Management</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Review and optimization of medication regimens for chronic conditions, with personalized adjustments made during your Eastern Suburbs house call to ensure effectiveness and minimize side effects.
                </p>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">General Medical Concerns</h3>
                <p className="text-[#DDC6A8]/80 text-sm">
                  Comprehensive assessment of general health concerns, unexplained symptoms, or changes in health status, with thorough evaluation conducted in the privacy of your Eastern Suburbs home.
                </p>
              </div>
            </div>
            
            <div className="bg-yellow-100/10 border-l-4 border-yellow-400 p-6 rounded-r-lg mb-8">
              <h3 className="text-xl font-medium mb-2 text-yellow-200">When to Seek Emergency Care</h3>
              <p className="text-[#DDC6A8]/90 mb-4">
                While Dr. Terry provides comprehensive house call services throughout the Eastern Suburbs, some medical situations require immediate emergency department care:
              </p>
              <ul className="list-disc pl-5 text-[#DDC6A8]/80 space-y-1">
                <li>Chest pain or symptoms of heart attack</li>
                <li>Difficulty breathing or severe shortness of breath</li>
                <li>Signs of stroke (facial drooping, arm weakness, speech difficulties)</li>
                <li>Severe or uncontrolled bleeding</li>
                <li>Significant head injuries or loss of consciousness</li>
                <li>Severe allergic reactions</li>
              </ul>
              <p className="text-[#DDC6A8]/90 mt-4">
                In these situations, please call 000 immediately. For all other medical concerns, Dr. Terry's Eastern Suburbs house call service is available to provide professional care in your home.
              </p>
            </div>
          </div>
        </div>
        
        {/* Eastern Suburbs Healthcare Context */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-10 text-center">Healthcare in Sydney's Eastern Suburbs</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
              <div>
                <div className="prose prose-lg prose-invert max-w-none">
                  <p>
                    Sydney's Eastern Suburbs feature a diverse healthcare landscape, with several major hospitals including Prince of Wales Hospital in Randwick and St. Vincent's Hospital in Darlinghurst, alongside numerous medical practices and specialist clinics.
                  </p>
                  <p>
                    However, despite these resources, Eastern Suburbs residents often face challenges accessing prompt medical care, particularly after hours. Walk-in GP clinics in areas like Bondi Junction and Double Bay can have extensive waiting times, while navigating to medical appointments through Eastern Suburbs traffic can be challenging when unwell.
                  </p>
                  <p>
                    Dr. Terry's house call service bridges this gap by providing responsive, patient-centered care that comes to you. As a complement to the existing healthcare infrastructure, these private house calls offer convenience without compromising on quality of care.
                  </p>
                </div>
              </div>
              
              <div>
                <div className="prose prose-lg prose-invert max-w-none">
                  <p>
                    The Eastern Suburbs demographic presents unique healthcare needs. With a mix of young professionals, families, and older residents across areas from Paddington to Coogee, the community requires versatile medical services that can address diverse health concerns.
                  </p>
                  <p>
                    Additionally, the active lifestyle common among Eastern Suburbs residents—from beach activities in Bondi to the vibrant social scene in areas like Potts Point—can present specific health considerations that benefit from personalized medical attention.
                  </p>
                  <p>
                    Dr. Terry's intimate knowledge of the Eastern Suburbs community allows for tailored care that accounts for local lifestyle factors, environmental considerations, and access to follow-up services when needed.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="bg-[#DDC6A8]/5 p-8 rounded-xl">
              <h3 className="text-2xl font-light mb-6 text-center">How Dr. Terry Works with Local Healthcare Providers</h3>
              <div className="prose prose-lg prose-invert max-w-none">
                <p>
                  Dr. Terry maintains professional relationships with many Eastern Suburbs healthcare facilities, including Prince of Wales Hospital, St. Vincent's Hospital, and various specialist practices throughout the area. When appropriate, he can facilitate referrals to these institutions for advanced diagnostics or specialized care.
                </p>
                <p>
                  For patients discharged from Eastern Suburbs hospitals who require follow-up care but find it difficult to travel, Dr. Terry offers transitional care through house calls, helping to prevent readmissions and support recovery in the comfort of home.
                </p>
                <p>
                  This integrated approach ensures that patients receive comprehensive care that leverages the full spectrum of healthcare resources available in the Eastern Suburbs, with the added convenience and comfort of home-based medical attention.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Testimonials Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">What Eastern Suburbs Residents Say About Our House Call Service</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl relative">
                <svg className="absolute text-[#DDC6A8]/20 top-4 left-4 w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
                </svg>
                <div className="pl-8 pt-8">
                  <p className="text-[#DDC6A8]/90 mb-6 italic">
                    "When my son developed a high fever on a Sunday evening, Dr. Terry arrived at our Bondi home within an hour. His calm expertise and thorough assessment were exactly what we needed. The convenience of not having to take a sick child to a clinic was invaluable."
                  </p>
                  <div className="flex items-center">
                    <div className="mr-4 h-12 w-12 bg-[#DDC6A8]/30 rounded-full flex items-center justify-center text-xl font-bold">
                      M
                    </div>
                    <div>
                      <p className="font-medium">Michelle</p>
                      <p className="text-sm text-[#DDC6A8]/70">Bondi Beach resident</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl relative">
                <svg className="absolute text-[#DDC6A8]/20 top-4 left-4 w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
                </svg>
                <div className="pl-8 pt-8">
                  <p className="text-[#DDC6A8]/90 mb-6 italic">
                    "After surgery, traveling to follow-up appointments was difficult. Having Dr. Terry visit my Double Bay apartment for post-operative care was not just convenient, but essential to my recovery. His attention to detail and personalized approach far exceeded my expectations."
                  </p>
                  <div className="flex items-center">
                    <div className="mr-4 h-12 w-12 bg-[#DDC6A8]/30 rounded-full flex items-center justify-center text-xl font-bold">
                      J
                    </div>
                    <div>
                      <p className="font-medium">James</p>
                      <p className="text-sm text-[#DDC6A8]/70">Double Bay resident</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl relative">
                <svg className="absolute text-[#DDC6A8]/20 top-4 left-4 w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
                </svg>
                <div className="pl-8 pt-8">
                  <p className="text-[#DDC6A8]/90 mb-6 italic">
                    "As a busy professional living in Paddington, finding time for doctor appointments was always challenging. Dr. Terry's evening house calls fit perfectly with my schedule. The level of care and attention I received was exceptional, and the privacy of being treated at home was a significant benefit."
                  </p>
                  <div className="flex items-center">
                    <div className="mr-4 h-12 w-12 bg-[#DDC6A8]/30 rounded-full flex items-center justify-center text-xl font-bold">
                      S
                    </div>
                    <div>
                      <p className="font-medium">Sarah</p>
                      <p className="text-sm text-[#DDC6A8]/70">Paddington resident</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-8 rounded-xl relative">
                <svg className="absolute text-[#DDC6A8]/20 top-4 left-4 w-12 h-12" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 01-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"></path>
                </svg>
                <div className="pl-8 pt-8">
                  <p className="text-[#DDC6A8]/90 mb-6 italic">
                    "Living in Randwick, I've tried several medical services over the years, but none compare to Dr. Terry's house call service. The personalized attention, thorough examination, and follow-up care were outstanding. For anyone in the Eastern Suburbs needing quality medical care without the hassle, I couldn't recommend him more highly."
                  </p>
                  <div className="flex items-center">
                    <div className="mr-4 h-12 w-12 bg-[#DDC6A8]/30 rounded-full flex items-center justify-center text-xl font-bold">
                      D
                    </div>
                    <div>
                      <p className="font-medium">David</p>
                      <p className="text-sm text-[#DDC6A8]/70">Randwick resident</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pricing Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Eastern Suburbs House Call Pricing</h2>
            
            <div className="bg-[#DDC6A8]/5 p-8 rounded-xl mb-12">
              <div className="prose prose-lg prose-invert max-w-none mb-6">
                <p>
                  Dr. Terry Nguyen provides private billing services for Eastern Suburbs house calls. As a private service, fees apply for all consultations and are payable at the time of service.
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                  <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Standard House Call Fees</h3>
                  <ul className="space-y-4">
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Standard Consultation (Weekdays)</span>
                      <span className="font-medium">$220</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>After Hours (Weeknights)</span>
                      <span className="font-medium">$275</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Weekend House Calls</span>
                      <span className="font-medium">$320</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span>Public Holiday Services</span>
                      <span className="font-medium">$350</span>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Additional Services</h3>
                  <ul className="space-y-4">
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Minor Procedures</span>
                      <span className="font-medium">from $75</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Medical Certificates</span>
                      <span className="font-medium">Included</span>
                    </li>
                    <li className="flex justify-between items-center border-b border-[#DDC6A8]/20 pb-3">
                      <span>Follow-up Telemedicine</span>
                      <span className="font-medium">$85</span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span>Medication Delivery</span>
                      <span className="font-medium">$35 + cost</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="mt-8">
                <h3 className="text-xl font-medium mb-4 text-[#DDC6A8]">Payment Methods</h3>
                <div className="prose prose-lg prose-invert max-w-none">
                  <p>
                    For your convenience, we accept various payment methods during Eastern Suburbs house calls:
                  </p>
                  <ul>
                    <li>Credit/Debit Cards (including EFTPOS, Visa, Mastercard, and American Express)</li>
                    <li>Cash payments</li>
                    <li>Health fund claiming (for eligible services)</li>
                    <li>Secure online payments</li>
                  </ul>
                  <p>
                    Please note that as a private billing service, Dr. Terry does not bulk bill for house calls. Payment is required at the time of service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* FAQ Section */}
        <div className="py-16 bg-[#1A1A1A]/90">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">Frequently Asked Questions About Eastern Suburbs House Calls</h2>
            
            <div className="space-y-6 mb-10">
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How quickly can Dr. Terry arrive at my Eastern Suburbs location?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Response times vary based on current demand and your specific location within the Eastern Suburbs. For most cases, Dr. Terry can arrive within 1-3 hours of booking. Same-day appointments are often available, particularly for morning bookings. For non-urgent cases, you can also schedule house calls in advance.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">What Eastern Suburbs areas do you service?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Dr. Terry provides house calls throughout Sydney's Eastern Suburbs, including Bondi, Bondi Junction, Bronte, Clovelly, Coogee, Darling Point, Double Bay, Dover Heights, Edgecliff, Elizabeth Bay, Maroubra, Paddington, Point Piper, Potts Point, Randwick, Rose Bay, Rushcutters Bay, Tamarama, Vaucluse, Waverley, and Woollahra. If you're unsure if your location is covered, please contact us.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">What medical equipment does Dr. Terry bring to Eastern Suburbs house calls?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Dr. Terry arrives equipped with a comprehensive medical kit, including diagnostic tools such as a stethoscope, otoscope, ophthalmoscope, blood pressure monitor, pulse oximeter, thermometer, ECG machine, and basic blood testing equipment. He also carries emergency medications, common prescription medications, wound care supplies, and other essential medical equipment to provide thorough care during Eastern Suburbs house calls.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Can I get prescriptions during an Eastern Suburbs house call?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Yes, Dr. Terry can provide prescriptions during your Eastern Suburbs house call as medically appropriate. For your convenience, he can also arrange medication delivery through partner pharmacies in the Eastern Suburbs area for an additional fee. Please note that prescribing decisions are always based on medical necessity and professional judgment.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How does private billing work for house calls?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Dr. Terry operates exclusively as a private billing doctor, which enables him to provide the highest standard of care without the constraints of the Medicare system. This model allows for longer consultations, thorough examinations, and personalized attention that simply isn't possible in bulk-billing practices. Payment is required at the time of service, and we accept all major credit cards, EFTPOS, and cash. Detailed receipts are provided for potential insurance claims or for your records.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">What conditions can be treated during a house call versus requiring hospital care?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Dr. Terry can effectively treat a wide range of non-emergency conditions during house calls, including respiratory infections, gastrointestinal issues, skin conditions, urinary tract infections, minor injuries, headaches, back pain, and medication management. Conditions requiring emergency care include chest pain, severe shortness of breath, stroke symptoms, significant injuries, severe bleeding, loss of consciousness, and acute mental health crises. If you're unsure whether your condition is appropriate for a house call, our team can help assess this during booking.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How long does a typical house call appointment last?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Unlike standard clinic appointments that are often limited to 10-15 minutes, Dr. Terry's house calls typically last 30-45 minutes, depending on the complexity of your medical needs. This extended time allows for thorough assessment, detailed discussion of your concerns, comprehensive examination, and clear explanation of treatment options. For more complex cases or initial consultations, appointments may extend longer to ensure all your healthcare needs are properly addressed.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Can Dr. Terry order diagnostic tests during a house call?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Yes, Dr. Terry can arrange for a wide range of diagnostic tests during your house call. While some basic tests can be performed on-site (such as ECG, blood glucose, urinalysis), other tests require laboratory processing. Dr. Terry can write referrals for blood tests, imaging studies (X-rays, ultrasounds, CT scans), and specialist consultations. For your convenience, he works with mobile pathology services that can visit your home to collect specimens, and can recommend nearby imaging facilities with minimal wait times.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How do I receive follow-up care after a house call?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Follow-up care is an essential component of Dr. Terry's service. After your house call, you'll receive a comprehensive care plan outlining your treatment, medication instructions, and follow-up recommendations. Dr. Terry offers several follow-up options: another house call if needed, a telemedicine consultation for convenient reviews, or an appointment at his Waterloo clinic. Test results are communicated promptly via your preferred contact method, and Dr. Terry remains available to address any concerns that arise during your recovery.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Are house calls in the Eastern Suburbs covered by Medicare?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Dr. Terry operates as a private billing doctor and does not currently bulk bill or accept Medicare for house call services in the Eastern Suburbs. Payment is required at the time of service. Some private health insurance policies may provide rebates for these services - please check with your specific provider about coverage details.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">How do I prepare for a house call in my Eastern Suburbs home?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    To prepare for your Eastern Suburbs house call, please have the following ready if possible: a list of your current medications, any relevant medical history or previous test results, a list of your symptoms and concerns, and adequate lighting in the area where you'll be examined. Ensure access to your location (inform building security if applicable) and have your payment method ready. Having these items prepared helps ensure an efficient and thorough consultation.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Is there a cancellation policy for house calls?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    We understand that circumstances change, and sometimes appointments need to be rescheduled. We request at least 4 hours' notice for cancellations or reschedules whenever possible. Late cancellations (less than 2 hours before the appointment) or no-shows may incur a cancellation fee of 50% of the service charge, as this time could have been allocated to another patient. Emergency situations are always considered with understanding and flexibility.
                  </p>
                </div>
              </div>
              
              <div className="bg-[#DDC6A8]/5 p-6 rounded-xl">
                <h3 className="text-xl font-medium mb-3 text-[#DDC6A8]">Can Dr. Terry provide medical certificates during a house call?</h3>
                <div className="prose prose-invert max-w-none">
                  <p>
                    Yes, Dr. Terry can provide medical certificates, work certificates, and other documentation during your house call as appropriate. These are included in the consultation fee at no additional charge. For specialized documentation like comprehensive medical reports or insurance forms, additional fees may apply depending on the complexity and time required. All documentation is provided on official letterhead and meets standard requirements for employers, schools, and insurance providers.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="text-center">
              <p className="mb-6 text-[#DDC6A8]/90">
                Have more questions about Eastern Suburbs house calls?
              </p>
              <Link
                to="/contact"
                className="inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        
        {/* About Dr. Terry Section */}
        <div className="py-16 bg-[#1A1A1A]">
          <div className="max-w-5xl mx-auto px-4">
            <h2 className="text-3xl font-light mb-12 text-center">About Your Eastern Suburbs House Call Doctor</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
              <div className="md:col-span-1">
                <img src={drTerryPhoto} alt="Dr. Terry Nguyen" className="rounded-xl shadow-2xl w-full" />
              </div>
              
              <div className="md:col-span-2">
                <div className="prose prose-lg prose-invert max-w-none">
                  <h3>Dr. Terry Nguyen</h3>
                  <p>
                    Dr. Terry Nguyen has been providing dedicated house call services to Eastern Suburbs residents since 2015. With extensive experience in emergency medicine and general practice, Dr. Terry brings hospital-grade medical care directly to your doorstep.
                  </p>
                  <p>
                    Having worked in several major Sydney hospitals, including Prince of Wales in Randwick, Dr. Terry has an intimate understanding of the Eastern Suburbs healthcare landscape. This local knowledge allows him to provide contextually relevant care and appropriate referrals when needed.
                  </p>
                  <p>
                    Dr. Terry completed his medical training at the University of Sydney, followed by specialized training in emergency medicine. He is fully registered with AHPRA and maintains all necessary certifications for mobile medical practice.
                  </p>
                  <p>
                    As an Eastern Suburbs local himself, Dr. Terry is passionate about making quality healthcare more accessible to residents of Bondi, Double Bay, Paddington, and surrounding areas. His house call service was developed specifically to address the healthcare gaps he identified in this vibrant community.
                  </p>
                  <Link to="/about" className="text-[#DDC6A8] hover:text-white no-underline">
                    Learn more about Dr. Terry's background and qualifications
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Final CTA Section */}
        <div className="py-16 bg-gradient-to-b from-[#1A1A1A] to-[#000]">
          <div className="max-w-4xl mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-light mb-6">
              Experience Premium House Call Services<br />in Sydney's Eastern Suburbs
            </h2>
            
            <p className="text-xl text-[#DDC6A8]/90 mb-10 max-w-3xl mx-auto">
              Don't let illness or injury disrupt your busy Eastern Suburbs lifestyle. Dr. Terry brings professional medical care directly to your doorstep in Bondi, Double Bay, and throughout the Eastern Suburbs.
            </p>
            
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-6">
              <Link
                to="/dynamic-booking"
                state={{ service: "Eastern Suburbs House Call" }}
                className="inline-flex items-center px-8 py-4 bg-[#DDC6A8] text-[#1A1A1A] rounded-full hover:bg-white transition-all text-lg font-medium"
              >
                Book an Eastern Suburbs House Call
                <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </Link>
              
              <Link
                to="/contact"
                className="inline-flex items-center px-8 py-4 border border-[#DDC6A8] text-[#DDC6A8] rounded-full hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all text-lg font-medium"
              >
                Contact Dr. Terry
                <svg className="ml-2 w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EasternSuburbs; 