import { useState, useEffect, useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import { motion } from 'framer-motion';

// Center on 6 Crewe Place, Rosebery
const roseberyCenter = {
  lat: -33.9166,
  lng: 151.2094
};

const zones = [
  {
    name: 'Zone 1',
    center: roseberyCenter,
    radius: 2000,
    color: '#000000',
    factor: 1.0,
    description: 'Base price: $160. Closest area to our central location.',
    eta: '30-45 minutes'
  },
  {
    name: 'Zone 2',
    center: roseberyCenter,
    radius: 5000,
    color: '#333333',
    factor: 1.0625, // $170/$160
    description: 'Base price: $170. Extended travel time.',
    eta: '45-60 minutes'
  },
  {
    name: 'Zone 3',
    center: roseberyCenter,
    radius: 10000,
    color: '#666666',
    factor: 1.125, // $180/$160
    description: 'Base price: $180. Longer distance coverage.',
    eta: '60-90 minutes'
  },
  {
    name: 'Zone 4',
    center: roseberyCenter,
    radius: 20000,
    color: '#999999',
    factor: 1.25, // $200/$160
    description: 'Base price: $200. Maximum distance coverage.',
    eta: '90-120 minutes'
  }
];

const mapContainerStyle = {
  width: '100%',
  height: '400px'
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#333333' }]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#dddddd' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }]
    }
  ]
};

// Memoize the API key to prevent unnecessary re-renders
const googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
const libraries = ['places'];

export default function LocationMap({ onLocationSelect, selectedLocation }) {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [address, setAddress] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placesService, setPlacesService] = useState(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (map && isScriptLoaded && window.google) {
      setPlacesService(new window.google.maps.places.AutocompleteService());
    }
  }, [map, isScriptLoaded]);

  const onMapClick = useCallback((e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    
    setMarker({ lat, lng });
    getAddressFromCoords(lat, lng);
  }, []);

  const getAddressFromCoords = async (lat, lng) => {
    if (!window.google) return;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const result = await geocoder.geocode({
        location: { lat, lng }
      });
      
      if (result.results[0]) {
        const address = result.results[0].formatted_address;
        const zone = calculateZone(lat, lng);
        handleLocationSelection(address, { lat, lng }, zone);
      }
    } catch (error) {
      console.error('Geocoding error:', error);
    }
  };

  const calculateZone = useCallback((lat, lng) => {
    const distance = getDistance(lat, lng, roseberyCenter.lat, roseberyCenter.lng);
    
    for (const zone of zones) {
      if (distance <= zone.radius) {
        return zone;
      }
    }
    
    return zones[zones.length - 1]; // Outer zone by default
  }, []);

  const getDistance = useCallback((lat1, lng1, lat2, lng2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI/180;
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lng2-lng1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c;
  }, []);

  const handleAddressSearch = useCallback(async (input) => {
    setAddress(input);
    
    if (!input || !placesService) {
      setPredictions([]);
      return;
    }
    
    try {
      setIsLoading(true);
      const results = await placesService.getPlacePredictions({
        input,
        locationBias: {
          center: { lat: roseberyCenter.lat, lng: roseberyCenter.lng },
          radius: 50000 // 50km
        },
        componentRestrictions: { country: 'au' }
      });
      
      if (!input) {
        setPredictions([]);
      } else {
        setPredictions(results?.predictions || []);
      }
    } catch (error) {
      console.error('Autocomplete error:', error);
      setPredictions([]);
    } finally {
      setIsLoading(false);
    }
  }, [placesService]);

  const handleLocationSelection = useCallback((address, coordinates, zone) => {
    setSelectedZone(zone);
    setShowConfirmation(true);
    setMarker(coordinates);
    setAddress(address);
    setPredictions([]);
  }, []);

  const handlePredictionSelect = useCallback(async (prediction) => {
    if (!window.google) return;

    try {
      const geocoder = new window.google.maps.Geocoder();
      const result = await geocoder.geocode({
        placeId: prediction.place_id
      });
      
      if (result.results[0]) {
        const location = result.results[0].geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        const zone = calculateZone(lat, lng);
        
        if (map) {
          map.panTo({ lat, lng });
          map.setZoom(15);
        }
        
        handleLocationSelection(prediction.description, { lat, lng }, zone);
      }
    } catch (error) {
      console.error('Geocoding error:', error);
    }
  }, [map, calculateZone]);

  const handleConfirm = useCallback(() => {
    if (selectedZone && address) {
      onLocationSelect({
        address,
        coordinates: marker,
        zone: selectedZone.name,
        factor: selectedZone.factor
      });
      setShowConfirmation(false);
    }
  }, [selectedZone, address, marker, onLocationSelect]);

  const handleScriptLoad = useCallback(() => {
    setIsScriptLoaded(true);
  }, []);

  const handleZoneSelection = useCallback((zoneName) => {
    const zone = zones.find(z => z.name === zoneName);
    setSelectedZone(zone);
    setShowConfirmation(true);
  }, []);

  return (
    <div className="space-y-4">
      {googleMapsApiKey ? (
        <LoadScript
          googleMapsApiKey={googleMapsApiKey}
          libraries={libraries}
          onLoad={handleScriptLoad}
        >
          <div className="rounded-2xl overflow-hidden border border-[#DDC6A8]/10">
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={roseberyCenter}
              zoom={11}
              options={options}
              onClick={onMapClick}
              onLoad={setMap}
            >
              {zones.map((zone, index) => (
                <Circle
                  key={zone.name}
                  center={zone.center}
                  radius={zone.radius}
                  options={{
                    fillColor: zone.color,
                    fillOpacity: 0.1,
                    strokeColor: zone.color,
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    zIndex: zones.length - index
                  }}
                />
              ))}

              {marker && (
                <div>
                  {window.google && window.google.maps && window.google.maps.marker && (
                    <window.google.maps.marker.AdvancedMarkerElement
                      position={marker}
                      title="Selected Location"
                    />
                  )}
                </div>
              )}
            </GoogleMap>
          </div>
        </LoadScript>
      ) : (
        <div className="p-4 text-center text-[#DDC6A8]/70">
          Loading map...
        </div>
      )}

      <div className="mt-4 relative">
        <input
          type="text"
          value={address}
          onChange={(e) => handleAddressSearch(e.target.value)}
          placeholder="Enter your address"
          className="w-full p-4 rounded-xl border border-[#1A1A1A]/20 bg-[#DDC6A8] text-[#1A1A1A] placeholder-[#1A1A1A]/60"
        />
        
        {predictions.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute z-10 w-full mt-1 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-xl shadow-xl"
          >
            {predictions.map((prediction) => (
              <div
                key={prediction.place_id}
                onClick={() => handlePredictionSelect(prediction)}
                className="p-4 hover:bg-[#1A1A1A]/5 cursor-pointer text-[#1A1A1A]"
              >
                {prediction.description}
              </div>
            ))}
          </motion.div>
        )}
      </div>

      {showConfirmation && selectedZone && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-4 p-6 bg-[#DDC6A8] border border-[#1A1A1A]/10 rounded-xl space-y-4"
        >
          <h3 className="text-lg font-medium text-[#1A1A1A]">Selected Location</h3>
          <p className="text-[#1A1A1A]/80">{address}</p>
          
          <div className="py-4 border-t border-b border-[#1A1A1A]/10">
            <h4 className="font-medium text-[#1A1A1A]">{selectedZone.name}</h4>
            <p className="text-[#1A1A1A]/80 mt-2">{selectedZone.description}</p>
            <div className="mt-2 text-sm">
              <p className="text-[#1A1A1A]/70">Expected arrival time: {selectedZone.eta}</p>
              <p className="text-[#1A1A1A]/70">Travel fee: {((selectedZone.factor - 1) * 100).toFixed(0)}%</p>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowConfirmation(false)}
              className="px-4 py-2 text-[#1A1A1A]/60 hover:text-[#1A1A1A]"
            >
              Change Location
            </button>
            <button
              onClick={handleConfirm}
              className="px-6 py-2 bg-[#1A1A1A] text-[#DDC6A8] rounded-xl hover:bg-[#1A1A1A]/90"
            >
              Confirm Location
            </button>
          </div>
        </motion.div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <button
          className={`rounded-lg p-3 ${
            selectedZone === 'Zone 1'
              ? 'bg-black text-white'
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
          onClick={() => handleZoneSelection('Zone 1')}
        >
          <span className="font-semibold">Zone 1</span>
          <br />
          <span className="text-sm">Base price: $160</span>
        </button>
        <button
          className={`rounded-lg p-3 ${
            selectedZone === 'Zone 2'
              ? 'bg-black text-white'
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
          onClick={() => handleZoneSelection('Zone 2')}
        >
          <span className="font-semibold">Zone 2</span>
          <br />
          <span className="text-sm">Base price: $170</span>
        </button>
        <button
          className={`rounded-lg p-3 ${
            selectedZone === 'Zone 3'
              ? 'bg-black text-white'
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
          onClick={() => handleZoneSelection('Zone 3')}
        >
          <span className="font-semibold">Zone 3</span>
          <br />
          <span className="text-sm">Base price: $180</span>
        </button>
        <button
          className={`rounded-lg p-3 ${
            selectedZone === 'Zone 4'
              ? 'bg-black text-white'
              : 'bg-gray-100 hover:bg-gray-200'
          }`}
          onClick={() => handleZoneSelection('Zone 4')}
        >
          <span className="font-semibold">Zone 4</span>
          <br />
          <span className="text-sm">Base price: $200</span>
        </button>
      </div>
    </div>
  );
} 