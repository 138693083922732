import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Dialog } from '@headlessui/react';
import { db } from '../config/firestore';
import { auth } from '../config/firebase';
import { doc, setDoc, serverTimestamp, collection, query, where, getDocs } from 'firebase/firestore';

export default function QuickRegister({ isOpen, onClose, onSuccess }) {
  const { signInWithGoogle, signInWithEmail, signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [medicare, setMedicare] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mode, setMode] = useState('choice'); // 'choice', 'login', 'register', or 'details'

  const createPatientRecord = async (user, additionalData = {}) => {
    try {
      // Create a patient record in Firestore using client SDK
      const patientRef = doc(db, 'patients', user.uid);
      const patientData = {
        email: user.email,
        userId: user.uid,
        firstName,
        lastName,
        dateOfBirth,
        phone,
        address,
        medicare,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        ...additionalData
      };
      
      await setDoc(patientRef, patientData, { merge: true });
      
      // Send notification email
      try {
        await fetch('/.netlify/functions/send-registration-notification', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            patientDetails: patientData,
            adminEmail: 'info@homedoc.com.au'
          }),
        });
      } catch (emailError) {
        console.error('Error sending notification email:', emailError);
      }
      
      console.log('Patient record created successfully');
    } catch (error) {
      console.error('Error creating patient record:', error);
      throw error;
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError('');
      const userCredential = await signInWithGoogle();
      setMode('details');
      setEmail(userCredential.user.email);
      const names = userCredential.user.displayName?.split(' ') || ['', ''];
      setFirstName(names[0]);
      setLastName(names.slice(1).join(' '));
    } catch (error) {
      console.error('Error in Google sign in:', error);
      setError('Failed to sign in with Google');
      setLoading(false);
    }
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      
      if (mode === 'login') {
        await signInWithEmail(email, password);
        // Check if patient record exists
        const patientsRef = collection(db, 'patients');
        const q = query(patientsRef, where('email', '==', email));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          setMode('details');
        } else {
          onSuccess();
        }
      } else if (mode === 'register') {
        const userCredential = await signUp(email, password);
        setMode('details');
      } else if (mode === 'details') {
        // Validate required fields
        if (!firstName || !lastName || !dateOfBirth || !phone || !address) {
          setError('Please fill in all required fields');
          return;
        }
        
        // Create patient record
        await createPatientRecord(auth.currentUser);
        onSuccess();
      }
    } catch (error) {
      console.error('Error in email auth:', error);
      switch (error.code) {
        case 'auth/operation-not-allowed':
          setError('Email/Password sign-in is not enabled. Please use Google sign-in or contact support.');
          break;
        case 'auth/email-already-in-use':
          setError('An account already exists with this email. Please sign in instead.');
          setMode('login');
          break;
        case 'auth/invalid-email':
          setError('Please enter a valid email address.');
          break;
        case 'auth/weak-password':
          setError('Password should be at least 6 characters long.');
          break;
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          setError('Invalid email or password.');
          break;
        default:
          setError(error.message || 'Failed to process your request. Please try again.');
      }
    } finally {
      if (mode !== 'details') {
        setLoading(false);
      }
    }
  };

  const renderDetailsForm = () => (
    <form onSubmit={handleEmailAuth} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
            First Name *
          </label>
          <input
            type="text"
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
            Last Name *
          </label>
          <input
            type="text"
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
          Date of Birth *
        </label>
        <input
          type="date"
          required
          value={dateOfBirth}
          onChange={(e) => setDateOfBirth(e.target.value)}
          className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
          Phone Number *
        </label>
        <input
          type="tel"
          required
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
          Address *
        </label>
        <input
          type="text"
          required
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
          Medicare Number (Optional)
        </label>
        <input
          type="text"
          value={medicare}
          onChange={(e) => setMedicare(e.target.value)}
          className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
        />
      </div>

      <div className="flex justify-between gap-4">
        <button
          type="button"
          onClick={() => setMode('choice')}
          className="flex-1 p-3 border border-[#DDC6A8]/50 text-[#DDC6A8]/50 rounded-lg hover:border-[#DDC6A8] hover:text-[#DDC6A8] transition-all"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={loading}
          className="flex-1 p-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all disabled:opacity-50"
        >
          {loading ? 'Processing...' : 'Complete Registration'}
        </button>
      </div>
    </form>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-2xl bg-[#1A1A1A] border border-[#DDC6A8]/10 p-8">
          <Dialog.Title className="text-2xl font-light text-[#DDC6A8] mb-6">
            {mode === 'choice' ? 'Sign In or Register' : 
             mode === 'login' ? 'Sign In' : 
             mode === 'details' ? 'Complete Your Profile' : 
             'Quick Register'}
          </Dialog.Title>

          {error && (
            <div className="mb-4 p-3 bg-red-900/20 border border-red-500/20 rounded-lg">
              <p className="text-red-400 text-sm">{error}</p>
            </div>
          )}

          {mode === 'choice' ? (
            <div className="space-y-4">
              <button
                onClick={handleGoogleSignIn}
                disabled={loading}
                className="w-full p-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all disabled:opacity-50"
              >
                {loading ? 'Processing...' : 'Continue with Google'}
              </button>
              
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-[#DDC6A8]/10"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-[#1A1A1A] text-[#DDC6A8]/70">Or</span>
                </div>
              </div>

              <button
                onClick={() => setMode('login')}
                className="w-full p-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
              >
                Sign in with Email
              </button>

              <button
                onClick={() => setMode('register')}
                className="w-full p-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
              >
                Quick Register
              </button>
            </div>
          ) : mode === 'details' ? (
            renderDetailsForm()
          ) : (
            <form onSubmit={handleEmailAuth} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
                  Email
                </label>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
                  autoComplete="email"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#DDC6A8] mb-2">
                  Password
                </label>
                <input
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 rounded-lg border border-[#DDC6A8]/10 bg-[#1A1A1A] text-[#DDC6A8]"
                  autoComplete={mode === 'login' ? 'current-password' : 'new-password'}
                />
              </div>

              <div className="flex justify-between gap-4">
                <button
                  type="button"
                  onClick={() => setMode('choice')}
                  className="flex-1 p-3 border border-[#DDC6A8]/50 text-[#DDC6A8]/50 rounded-lg hover:border-[#DDC6A8] hover:text-[#DDC6A8] transition-all"
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="flex-1 p-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-lg hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all disabled:opacity-50"
                >
                  {loading ? 'Processing...' : mode === 'login' ? 'Sign In' : 'Continue'}
                </button>
              </div>
            </form>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
} 