import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ChildhoodNutrition = () => {
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Building Healthy Eating Habits: Childhood Nutrition in Australia</h2>
        <p className="text-gray-700 mb-4">
          Good nutrition during childhood is essential for healthy growth and development. The eating habits formed in the early years can influence health patterns throughout life, making this stage crucial for establishing a positive relationship with food.
        </p>
        <p className="text-gray-700 mb-4">
          Australia's dietary guidelines for children emphasize the importance of a varied diet rich in fruits, vegetables, whole grains, lean proteins, and dairy products, while limiting processed foods high in salt, sugar, and unhealthy fats.
        </p>
        <p className="text-gray-700 mb-6">
          This guide explores the nutritional needs of children at different ages, provides practical tips for parents to encourage healthy eating, and addresses common challenges in childhood nutrition.
        </p>
      </section>

      <section id="nutritional-needs">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Nutritional Needs Through Childhood</h2>
        <p className="text-gray-700 mb-4">
          Children's nutritional requirements vary with age, growth rate, and activity level. Understanding these changing needs can help parents provide appropriate nutrition at each stage of development.
        </p>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Infants (0-12 months)</h3>
        <div className="bg-blue-50 rounded-xl p-6 mb-6">
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li><strong>0-6 months:</strong> Breast milk or infant formula provides all needed nutrients. The World Health Organization and Australian health authorities recommend exclusive breastfeeding for the first 6 months when possible.</li>
            <li><strong>6-12 months:</strong> Introduction of solid foods while continuing breast milk or formula. Begin with iron-rich foods such as iron-fortified cereals, pureed meats, and legumes, followed by a variety of fruits, vegetables, and dairy.</li>
            <li>New foods should be introduced one at a time, with 2-3 days between new foods to monitor for allergic reactions.</li>
            <li>By 12 months, infants should be consuming a variety of foods from all food groups and developing the skills to self-feed.</li>
          </ul>
        </div>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Toddlers (1-3 years)</h3>
        <div className="bg-green-50 rounded-xl p-6 mb-6">
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li>Growth rate slows and appetite may fluctuate, leading to what appears to be "picky eating."</li>
            <li>Continued need for nutrient-dense foods, with emphasis on iron, calcium, and healthy fats for brain development.</li>
            <li>Full-fat dairy products are recommended until age 2, after which reduced-fat options can be introduced.</li>
            <li>Small, frequent meals and snacks often work better than three large meals.</li>
            <li>Water and milk are the best drinks; fruit juice should be limited, and sugary drinks avoided.</li>
          </ul>
        </div>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Preschoolers and School-Age Children (4-12 years)</h3>
        <div className="bg-yellow-50 rounded-xl p-6 mb-8">
          <ul className="list-disc pl-6 text-gray-700 space-y-3">
            <li>Energy needs increase to support growth and higher activity levels.</li>
            <li>Calcium requirements increase for bone development.</li>
            <li>Iron remains important, especially for girls approaching puberty.</li>
            <li>Greater independence in food choices requires education about nutrition.</li>
            <li>Balance between providing structure and allowing autonomous food decisions helps develop healthy relationships with food.</li>
          </ul>
        </div>
      </section>

      <section id="australian-dietary-guidelines">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">The Australian Dietary Guidelines for Children</h2>
        <p className="text-gray-700 mb-6">
          The Australian Dietary Guidelines provide evidence-based advice on the types and amounts of foods children should consume for health and wellbeing. These guidelines emphasize variety, balance, and moderation.
        </p>

        <div className="overflow-x-auto mb-8">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left">Food Group</th>
                <th className="py-3 px-4 text-left">Daily Serves (2-3 years)</th>
                <th className="py-3 px-4 text-left">Daily Serves (4-8 years)</th>
                <th className="py-3 px-4 text-left">Daily Serves (9-11 years)</th>
                <th className="py-3 px-4 text-left">What is a Serve?</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">Vegetables and legumes/beans</td>
                <td className="py-3 px-4">2.5</td>
                <td className="py-3 px-4">4.5</td>
                <td className="py-3 px-4">5</td>
                <td className="py-3 px-4">½ cup cooked vegetables or 1 cup salad vegetables</td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">Fruit</td>
                <td className="py-3 px-4">1</td>
                <td className="py-3 px-4">1.5</td>
                <td className="py-3 px-4">2</td>
                <td className="py-3 px-4">1 medium piece or 2 small pieces of fruit</td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">Grain (cereal) foods</td>
                <td className="py-3 px-4">4</td>
                <td className="py-3 px-4">4</td>
                <td className="py-3 px-4">4-5</td>
                <td className="py-3 px-4">1 slice bread or ½ cup cooked pasta, rice, noodles</td>
              </tr>
              <tr className="border-t border-gray-200 bg-gray-50">
                <td className="py-3 px-4 font-medium">Lean meats and poultry, fish, eggs, tofu, nuts and seeds</td>
                <td className="py-3 px-4">1</td>
                <td className="py-3 px-4">1.5</td>
                <td className="py-3 px-4">2</td>
                <td className="py-3 px-4">65g cooked lean meat or 2 eggs</td>
              </tr>
              <tr className="border-t border-gray-200">
                <td className="py-3 px-4 font-medium">Milk, yogurt, cheese and/or alternatives</td>
                <td className="py-3 px-4">1.5</td>
                <td className="py-3 px-4">2</td>
                <td className="py-3 px-4">2.5-3</td>
                <td className="py-3 px-4">1 cup milk or 2 slices (40g) cheese</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p className="text-gray-700 mb-3">Additional guidelines include:</p>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Limit intake of foods containing saturated fat, added salt, and added sugars</li>
          <li>Encourage water as the primary drink</li>
          <li>Limit fruit juice to occasional small amounts (no more than ½ cup per day)</li>
          <li>Avoid sugar-sweetened drinks</li>
          <li>Ensure food safety through proper preparation and storage</li>
        </ul>
      </section>

      <section id="encouraging-healthy-eating">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Encouraging Healthy Eating Habits</h2>
        <p className="text-gray-700 mb-5">
          Parents and caregivers play a crucial role in shaping children's eating habits. Here are some strategies to encourage healthy eating:
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {[
            {
              strategy: "Be a positive role model",
              description: "Children often mimic adults' behaviors. When they see you enjoying healthy foods, they're more likely to try them too."
            },
            {
              strategy: "Family meals",
              description: "Regular family meals provide an opportunity to model healthy eating and create positive food experiences. They're associated with better dietary intake and lower risk of disordered eating."
            },
            {
              strategy: "Division of responsibility",
              description: "Parents decide what, when, and where food is served; children decide whether and how much to eat. This approach respects children's ability to respond to their hunger and fullness cues."
            },
            {
              strategy: "Involve children in food preparation",
              description: "From garden to table, involving children in growing, shopping for, and preparing food can increase their interest in trying new foods."
            },
            {
              strategy: "Repeated exposure",
              description: "Children may need to be exposed to a new food 10-15 times before accepting it. Continue to offer new foods without pressure to eat them."
            },
            {
              strategy: "Limit distractions",
              description: "Turn off screens during mealtimes to help children focus on eating and recognize hunger and fullness cues."
            }
          ].map((item, index) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-3">{item.strategy}</h3>
              <p className="text-gray-700">{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section id="common-challenges">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Addressing Common Nutrition Challenges</h2>
        
        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Picky Eating</h3>
        <p className="text-gray-700 mb-4">
          Selective eating is a normal developmental phase for many children. It's important to approach this challenge with patience and consistency rather than pressure or punishment.
        </p>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Continue to offer a variety of foods, including those previously rejected</li>
          <li>Serve new foods alongside familiar favorites</li>
          <li>Keep mealtimes positive and pressure-free</li>
          <li>Consider presentation—creative food arrangements can increase appeal</li>
          <li>Consult a healthcare provider if picky eating is severe, persistent, or affects growth</li>
        </ul>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Food Allergies and Intolerances</h3>
        <p className="text-gray-700 mb-4">
          Food allergies affect about 1 in 20 children in Australia. Common allergens include cow's milk, eggs, peanuts, tree nuts, sesame, soy, wheat, fish, and shellfish.
        </p>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>If you suspect a food allergy, consult a healthcare provider for proper diagnosis</li>
          <li>Current evidence suggests that delaying the introduction of potential allergens does not prevent allergies</li>
          <li>For confirmed allergies, work with a dietitian to ensure nutritional needs are met while avoiding allergens</li>
          <li>Be vigilant about label reading and cross-contamination</li>
          <li>Educate caregivers, family members, and the child about the allergy and emergency procedures if needed</li>
        </ul>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4 mt-6">Overweight and Obesity</h3>
        <p className="text-gray-700 mb-4">
          Childhood obesity is a growing concern in Australia, with about 1 in 4 children affected. The focus should be on healthy behaviors rather than weight or restrictive dieting.
        </p>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Emphasize overall health rather than weight</li>
          <li>Encourage regular physical activity that the child enjoys</li>
          <li>Provide structured meal and snack times, avoiding grazing throughout the day</li>
          <li>Limit screen time, which is often associated with mindless eating and targeted food marketing</li>
          <li>Focus on the whole family adopting healthier habits rather than singling out one child</li>
          <li>Seek professional support from a healthcare provider, dietitian, or psychologist if needed</li>
        </ul>
        
        <div className="bg-indigo-50 rounded-xl p-6 mb-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-4">When to Seek Professional Help</h3>
          <p className="text-gray-700 mb-3">Consider consulting a healthcare provider if your child:</p>
          <ul className="list-disc pl-6 text-gray-700 space-y-2">
            <li>Is not growing or gaining weight as expected</li>
            <li>Has significant food restrictions that may impact nutritional adequacy</li>
            <li>Shows signs of disordered eating such as food obsessions, strict food rules, or anxiety about eating</li>
            <li>Has physical symptoms like frequent stomachaches, constipation, or diarrhea related to eating</li>
            <li>Has had a significant change in appetite or eating behavior</li>
          </ul>
        </div>
      </section>

      <section id="recipes-and-meal-ideas">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Healthy Recipe and Meal Ideas</h2>
        <p className="text-gray-700 mb-6">
          Making nutritious food appealing to children can be a creative endeavor. Here are some ideas for meals and snacks that are both child-friendly and nutritionally balanced.
        </p>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Breakfast Ideas</h3>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Wholegrain cereal with milk and sliced fruit</li>
          <li>Overnight oats with yogurt and berries</li>
          <li>Wholegrain toast with avocado and egg</li>
          <li>Smoothie with fruit, yogurt, and a handful of spinach</li>
          <li>Breakfast burrito with scrambled egg, beans, and vegetables</li>
        </ul>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Lunch Ideas</h3>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Sandwich on wholegrain bread with lean protein and veggie sticks</li>
          <li>Pasta salad with vegetables and cheese</li>
          <li>Homemade vegetable soup with a wholegrain roll</li>
          <li>Baked beans on toast with a side of fruit</li>
          <li>Rice paper rolls with vegetables and lean protein</li>
        </ul>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Dinner Ideas</h3>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Homemade pizza on a wholegrain base with vegetables and lean toppings</li>
          <li>Stir-fry with vegetables, lean protein, and brown rice</li>
          <li>Shepherd's pie with added vegetables in the filling</li>
          <li>Fish tacos with coleslaw and avocado</li>
          <li>Spaghetti bolognese with hidden vegetables in the sauce</li>
        </ul>

        <h3 className="text-2xl font-semibold text-gray-900 mb-4">Snack Ideas</h3>
        <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
          <li>Fresh fruit with yogurt dip</li>
          <li>Vegetable sticks with hummus</li>
          <li>Air-popped popcorn</li>
          <li>Cheese and wholegrain crackers</li>
          <li>Homemade muffins with fruit or vegetables</li>
          <li>Smoothie ice blocks made with fruit and yogurt</li>
        </ul>
      </section>

      <section id="conclusion">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Conclusion</h2>
        <p className="text-gray-700 mb-4">
          Good nutrition during childhood sets the foundation for lifelong healthy eating habits and optimal health. By following the Australian Dietary Guidelines, offering a variety of nutritious foods, and creating positive eating environments, parents and caregivers can help children develop a healthy relationship with food.
        </p>
        
        <p className="text-gray-700 mb-4">
          Remember that each child is unique, and approaches to nutrition should be flexible and responsive to individual needs and circumstances. Gradual improvements rather than perfection should be the goal, and the focus should be on developing healthy patterns that can be maintained throughout life.
        </p>
        
        <p className="text-gray-700 mb-6">
          If you have concerns about your child's nutrition, growth, or eating behaviors, don't hesitate to consult with a healthcare provider. Registered dietitians can provide personalized advice for children with specific nutritional needs or challenges.
        </p>
      </section>
    </>
  );

  const treatments = [
    {
      stage: 'Assessment',
      description: 'Evaluation of current eating habits, growth patterns, and nutritional concerns',
      duration: '30-45 minutes'
    },
    {
      stage: 'Nutritional Plan',
      description: 'Development of age-appropriate nutrition recommendations and meal planning strategies',
      duration: '30 minutes'
    },
    {
      stage: 'Dietary Education',
      description: 'Education on Australian Dietary Guidelines for children and practical implementation',
      duration: '30 minutes'
    },
    {
      stage: 'Behavioral Strategies',
      description: 'Techniques for managing picky eating, establishing healthy eating routines, and creating positive food environments',
      duration: '30 minutes'
    },
    {
      stage: 'Follow-up',
      description: 'Monitoring progress, adjusting recommendations, and addressing emerging nutritional needs',
      duration: '20-30 minutes'
    }
  ];

  const references = [
    {
      authors: 'National Health and Medical Research Council',
      title: 'Australian Dietary Guidelines',
      journal: 'Australian Government',
      year: '2013',
      doi: ''
    },
    {
      authors: 'National Health and Medical Research Council',
      title: 'Infant Feeding Guidelines',
      journal: 'Australian Government',
      year: '2012',
      doi: ''
    },
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Kids Health Info: Nutrition - babies and toddlers',
      journal: 'The Royal Children\'s Hospital Melbourne',
      year: '2020',
      doi: ''
    },
    {
      authors: 'Nutrition Australia',
      title: 'Healthy Eating for Children',
      journal: 'Nutrition Australia',
      year: '2021',
      doi: ''
    }
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Nutritional Needs Through Childhood', anchor: 'nutritional-needs' },
    { title: 'The Australian Dietary Guidelines for Children', anchor: 'australian-dietary-guidelines' },
    { title: 'Encouraging Healthy Eating Habits', anchor: 'encouraging-healthy-eating' },
    { title: 'Addressing Common Nutrition Challenges', anchor: 'common-challenges' },
    { title: 'Healthy Recipe and Meal Ideas', anchor: 'recipes-and-meal-ideas' },
    { title: 'Conclusion', anchor: 'conclusion' }
  ];

  return (
    <MedicalBlogTemplate
      title="Childhood Nutrition: Building Healthy Eating Habits"
      subtitle="Expert guidance on nutritional needs and strategies for healthy eating in Australian children"
      heroImage="https://images.unsplash.com/photo-1604430456280-9062fccc6d37"
      publishDate="March 15, 2024"
      readTime={18}
      content={content}
      category="Child Health"
      tags={['Nutrition', 'Diet', 'Child Development', 'Preventive Care', 'Pediatrics']}
      treatments={treatments}
      references={references}
      showEmergencyBox={false}
      tableOfContents={tableOfContents}
    />
  );
};

export default ChildhoodNutrition; 