import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogCarousel from '../components/BlogCarousel';

const Books = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Health Books by Dr. Terry Nguyen | Sydney Medical Author | HomeDoc</title>
        <meta name="description" content="Evidence-based health books authored by Sydney's Dr. Terry Nguyen. Authoritative medical guides on children's health, mental wellbeing, and preventative care." />
        <meta name="keywords" content="medical books sydney, health books australia, dr terry nguyen author, medical guides eastern suburbs, children health books, mental wellbeing publications" />
        <link rel="canonical" href="https://homedoc.com.au/books" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            "name": "Health Books by Dr. Terry Nguyen",
            "description": "Collection of evidence-based medical books by Sydney doctor Terry Nguyen",
            "itemListOrder": "Unordered",
            "numberOfItems": 3,
            "itemListElement": [
              {
                "@type": "Book",
                "position": 1,
                "name": "Children's Health: A Sydney Parent's Guide",
                "author": {
                  "@type": "Person",
                  "name": "Dr. Terry Nguyen"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "HomeDoc Publications"
                }
              }
            ]
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">Health Books by Dr. Terry Nguyen</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8]/80 mb-6">Evidence-Based Medical Guides for Sydney Families</h2>
            <p className="text-xl text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Authoritative, accessible health books written specifically for Sydney residents.
              <span className="block mt-4">
                <Link to="/dynamic-booking" className="text-[#DDC6A8] underline font-medium hover:text-white">
                  Book a consultation
                </Link> with Dr. Terry Nguyen to discuss personalized health advice.
              </span>
            </p>
          </div>

          {/* Main content placeholder */}
          <div className="text-center mb-16">
            <p className="text-[#DDC6A8]/80">
              Full content for Books page will be implemented in subsequent edits.
            </p>
          </div>
          
          {/* Call to Action */}
          <div className="text-center">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
            >
              Book a Consultation
            </Link>
          </div>
        </div>
      </div>
      
      {/* Related Articles Carousel */}
      <div className="py-12 bg-[#1A1A1A]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-light text-[#DDC6A8] mb-8 text-center">Related Health Articles</h2>
          <BlogCarousel />
        </div>
      </div>
    </div>
  );
};

export default Books; 