import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CategoryBlogCarousel from '../../components/CategoryBlogCarousel';

const MensPreventativeHealth = () => {
  // SEO metadata
  const seoTitle = "Preventative Health Screenings Every Man Should Have | Sydney GP";
  const seoDescription = "Essential health screenings for men in Sydney - what tests you need at different ages to maintain optimal health and prevent disease with Dr. Terry Nguyen.";
  const seoKeywords = "men's health screenings, preventative health men, Sydney GP men's health, Eastern Suburbs doctor, health check men, prostate screening";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Preventative Health Screenings Every Man Should Have</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              As a men's health specialist in <strong>Sydney's Eastern Suburbs</strong>, I've witnessed how regular health screenings can detect potential issues before they become serious - often before symptoms even appear.
            </p>
            <p className="text-gray-700 mb-4">
              Many men across <strong>Sydney</strong>, particularly in <strong>Bondi, Double Bay,</strong> and <strong>Paddington</strong>, tend to postpone medical check-ups until problems arise. However, preventative screenings are one of the most effective ways to protect your health and potentially save your life.
            </p>
            <p className="text-gray-700 mb-4">
              This guide outlines the essential health screenings men need at different ages, why they matter, and how regularly you should have them. If you're due for a health check, <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">book a comprehensive assessment</Link> at my practice.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/drterry_menshealth3.png" 
                alt="Men's preventative health screening in Sydney with Dr. Terry Nguyen" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Comprehensive men's health check-ups available in Sydney's Eastern Suburbs
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      
      {/* TODO: Add full content sections for screenings by age, heart health, cancer screenings, etc. */}
      
      <section id="conclusion" className="mt-12">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Your Preventative Health Action Plan</h2>
        <p className="text-gray-700 mb-4">
          Prevention is always better than cure. As your dedicated men's health GP in <strong>Sydney's Eastern Suburbs</strong>, I recommend scheduling a comprehensive health assessment to establish your baseline health status and develop a personalized screening plan.
        </p>
        <p className="text-gray-700 mb-4">
          Remember that early detection through regular screenings gives you the best chance of successful treatment if health issues are found. Many serious conditions can be prevented or managed effectively when caught early.
        </p>
        <div className="text-center my-8">
          <Link
            to="/dynamic-booking"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Book Your Health Assessment
          </Link>
          <p className="text-gray-500 text-sm mt-2">
            Comprehensive men's health care available across Sydney's Eastern Suburbs
          </p>
        </div>
      </section>
      
      <div className="mt-12 mb-6">
        <CategoryBlogCarousel category="mens-health" limit={4} />
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/drterry_menshealth3.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/mens-preventative-health-screenings-sydney" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/drterry_menshealth3.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/mens-preventative-health-screenings-sydney" />
      </Helmet>
    
      <MedicalBlogTemplate
        title="Preventative Health Screenings Every Man Should Have"
        subtitle="A Sydney GP's guide to essential health checks for men"
        heroImage="/images/drterry_menshealth3.png"
        publishDate="June 30, 2024"
        readTime={10}
        content={content}
        category="Men's Health"
        tags={['Preventative Health', 'Health Screenings', 'Men\'s Health', 'Sydney', 'Eastern Suburbs']}
        showEmergencyBox={false}
      />
    </>
  );
};

export default MensPreventativeHealth; 