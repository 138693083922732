import React from 'react';
import { Helmet } from 'react-helmet';
import PremiumBlogTemplate from '../../components/PremiumBlogTemplate';

const YachtMedicalServices = () => {
  // SEO metadata
  const seoTitle = "Yacht Medical Services | Dedicated Healthcare for Luxury Maritime Travel | Sydney";
  const seoDescription = "Comprehensive medical services for luxury yacht owners, charterers, and guests. Dedicated physician care, advanced equipment, and emergency protocols for maritime travel in Australian waters and beyond.";
  const seoKeywords = "yacht medical services, maritime healthcare, superyacht doctor, boat medical support, onboard physician, sydney private doctor yacht, luxury vessel healthcare, private maritime medicine, sea medical emergency, high-end maritime healthcare, UHNW yacht medical, superyacht healthcare, executive assistant yacht medical";
  
  const content = (
    <>
      <section id="introduction">
        <h2 className="text-3xl font-bold mb-6">Premier Medical Services for Yacht Principals and Their Guests</h2>
        
        <div className="flex flex-col lg:flex-row gap-6 mb-6">
          <div className="lg:w-2/3">
            <p className="mb-4">
              For executive assistants and family offices managing the affairs of ultra-high-net-worth principals, ensuring comprehensive healthcare during yacht journeys represents a critical responsibility. Our specialized yacht medical services provide the seamless, sophisticated healthcare solution that your principal expects, combining medical excellence with the discretion and luxury inherent to premier maritime experiences.
            </p>
            <p className="mb-4">
              When arranging yacht charters or planning your principal's maritime itinerary, healthcare contingencies can present complex logistical challenges. With Dr. Terry Nguyen's yacht medical service, you can confidently provide your employer with the assurance that their health requirements will be meticulously addressed throughout their journey, whether navigating Sydney Harbor or embarking on international waters.
            </p>
          </div>
          <div className="lg:w-1/3">
            <figure className="rounded-xl overflow-hidden shadow-xl">
              <img 
                src="/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png" 
                alt="Luxury yacht with professional medical care services" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-[#2A2A2A]/70 px-4 py-2 text-sm text-[#DDC6A8]/80 text-center">
                Premium medical services providing comprehensive healthcare for luxury yacht experiences
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      <section id="maritime-challenges">
        <h2 className="text-3xl font-bold mb-6">Addressing Critical Maritime Healthcare Challenges for VIP Clients</h2>
        
        <p className="mb-6">
          Family offices and executive assistants must anticipate several maritime-specific healthcare considerations when planning for principals who demand excellence without compromise:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Distance from Medical Infrastructure</h3>
            <p>
              Even luxury vessels with onboard facilities cannot match comprehensive medical centers. Our service bridges this gap with advanced portable equipment and physician expertise specifically adapted for maritime environments.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Jurisdictional Complexities</h3>
            <p>
              International waters present regulatory challenges regarding medication transport, administration, and potential evacuation scenarios. Our protocols navigate these complexities proactively, ensuring compliance without compromising care.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Security and Confidentiality</h3>
            <p>
              High-profile principals require absolute discretion regarding health matters. Our service employs rigorous privacy protocols, including secure communication channels and confidential documentation practices.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Continuity with Existing Medical Teams</h3>
            <p>
              VIP clients typically have established healthcare relationships. We coordinate seamlessly with your principal's existing medical providers, ensuring continuity of care without information gaps.
            </p>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-6 mb-8">
          <p className="italic">
            Executive assistants who engage our yacht medical services demonstrate exceptional foresight in risk management—a quality invariably appreciated by discerning employers who expect comprehensive contingency planning.
          </p>
        </div>
      </section>

      <section id="comprehensive-services">
        <h2 className="text-3xl font-bold mb-6">Comprehensive Maritime Medical Services for Discerning Principals</h2>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Pre-Journey Strategic Health Planning</h3>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-2/3">
              <p className="mb-4">
                For executive assistants arranging yacht journeys, our pre-departure services establish a solid foundation for maritime healthcare:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-4">
                <li>Confidential health briefings with your principal to identify specific requirements and preferences</li>
                <li>Strategic assessment of the vessel's existing medical capabilities with recommendations for enhancement</li>
                <li>Development of itinerary-specific medical protocols accounting for remoteness factors and destination regulations</li>
                <li>Liaison with the yacht's crew regarding medical equipment placement and emergency protocols</li>
                <li>Coordination of specialized medication requirements, including importation clearances for international journeys</li>
              </ul>
              <p>
                This proactive approach ensures that all healthcare contingencies are addressed before your principal embarks, providing both peace of mind and comprehensive protection.
              </p>
            </div>
            <div className="md:w-1/3">
              <img 
                src="/images/drtez_Modern_coffee_table_with_open_MacBook_stethoscope_and_g_27ec4ad2-c21a-4429-9e1b-379509dd855a_2.png" 
                alt="Pre-journey medical planning for maritime travel" 
                className="w-full h-auto rounded-xl shadow-lg"
              />
            </div>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Customized Onboard Medical Infrastructure</h3>
          <p className="mb-4">
            We collaborate with yacht management to optimize onboard medical capabilities tailored to your principal's specific needs:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>Assessment and enhancement of the vessel's medical space with consideration for privacy and functionality</li>
            <li>Installation of diagnostic equipment appropriate for the vessel size and typical journey profiles</li>
            <li>Creation of bespoke medical kits with pharmaceutical-grade medications aligned with principal-specific health profiles</li>
            <li>Integration of secure telemedicine capabilities for specialist consultation access regardless of location</li>
            <li>Implementation of medical record systems that maintain both accessibility and stringent confidentiality</li>
          </ul>
          <p>
            This customized approach ensures that the yacht's medical capabilities align with your principal's expectations for excellence in all aspects of their maritime experience.
          </p>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Physician Accompaniment for Maximum Security</h3>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-1/3">
              <img 
                src="/images/drtez_Luxury_black_Mercedes_S-Class_parked_in_front_of_a_mini_feef7c2b-a8c8-40e1-90b5-38a30ace3b76_1.png" 
                alt="Discreet transportation for yacht medical services" 
                className="w-full h-auto rounded-xl shadow-lg"
              />
            </div>
            <div className="md:w-2/3">
              <p className="mb-4">
                For executive assistants serving principals who demand the highest level of healthcare protection:
              </p>
              <ul className="list-disc pl-5 space-y-2 mb-4">
                <li>Dedicated physician presence throughout the maritime journey, integrated discreetly with yacht personnel</li>
                <li>Ongoing health monitoring for the principal and guests according to predetermined protocols</li>
                <li>Immediate medical response capability 24/7 throughout the journey</li>
                <li>Coordination with onshore specialists via secure channels when additional expertise is required</li>
                <li>Management of any health-related logistics, eliminating this burden from yacht crew and staff</li>
              </ul>
              <p>
                This premium service ensures that your principal enjoys both uncompromising medical security and complete discretion throughout their maritime experience.
              </p>
            </div>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-8 mb-8">
          <h3 className="text-2xl font-bold mb-4">Emergency Response and Evacuation Coordination</h3>
          <p className="mb-4">
            Our comprehensive emergency planning provides peace of mind for both principals and their support staff:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-4">
            <li>Development of vessel-specific emergency action plans accounting for various scenarios and locations</li>
            <li>Direct relationships with international maritime emergency services and air evacuation providers</li>
            <li>Coordination of emergency response drills with yacht crew to ensure protocol familiarity</li>
            <li>Secured arrangements with premium healthcare facilities at key ports of call</li>
            <li>Stringent privacy protocols during emergencies to protect principal confidentiality</li>
          </ul>
          <p>
            These emergency protocols ensure that your principal receives world-class care without delay, regardless of maritime location or complexity.
          </p>
        </div>
      </section>

      <section id="case-examples">
        <h2 className="text-3xl font-bold mb-6">Maritime Medical Excellence: Case Studies for Executive Staff</h2>
        
        <p className="mb-4">
          The following anonymized scenarios illustrate how our yacht medical services have supported successful maritime journeys:
        </p>
        
        <div className="space-y-6 mb-8">
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Case Study: Multi-Week Mediterranean Charter</h3>
            <p className="mb-3">
              <strong>Scenario:</strong> An executive assistant to an Australian mining magnate was tasked with arranging a six-week Mediterranean yacht charter for the principal and extended family.
            </p>
            <p className="mb-3">
              <strong>Approach:</strong> Our service provided comprehensive support, including pre-journey consultations with all family members, enhancement of the chartered vessel's medical facilities, and physician accompaniment throughout the journey. When the principal's spouse developed concerning symptoms near a remote Greek island, our physician provided immediate assessment, initiated appropriate treatment, and coordinated with local authorities for discretionary measures during a brief port call for additional diagnostics.
            </p>
            <p>
              <strong>Outcome:</strong> The situation was resolved without itinerary disruption or privacy compromises, with the executive assistant receiving particular commendation for the foresight in arranging comprehensive medical support.
            </p>
          </div>
          
          <div className="bg-[#2A2A2A]/30 rounded-xl p-6">
            <h3 className="text-xl font-bold mb-3">Case Study: Sydney Harbor Corporate Entertaining</h3>
            <p className="mb-3">
              <strong>Scenario:</strong> A family office managing affairs for an international finance executive required medical support for a series of high-profile corporate events aboard their Sydney-based yacht.
            </p>
            <p className="mb-3">
              <strong>Approach:</strong> Our service provided physician presence during all events, discreetly monitoring a guest with a known cardiac condition while maintaining complete confidentiality. When a prominent guest experienced an allergic reaction during a corporate dinner, our physician intervened immediately with appropriate treatment.
            </p>
            <p>
              <strong>Outcome:</strong> The potential emergency was averted while maintaining the event's elegant atmosphere. The family office reported that the principal specifically noted the value of having medical support that operated with the same level of sophistication and discretion as the other luxury service elements.
            </p>
          </div>
        </div>
        
        <div className="bg-[#2A2A2A]/50 border-l-4 border-[#DDC6A8] p-6 rounded-r-xl mb-8">
          <p className="italic text-[#DDC6A8]/90">
            "Having Dr. Nguyen's team integrate seamlessly with our yacht staff provided an invaluable layer of security during our extended Mediterranean journey. Their ability to handle a potentially serious situation with both medical excellence and complete discretion was remarkable—exactly the standard of service our principals expect."
          </p>
          <p className="text-right text-[#DDC6A8]/70 mt-2">— Chief of Staff, UHNW Family Office</p>
        </div>
      </section>

      <section id="conclusion">
        <h2 className="text-3xl font-bold mb-6">Arranging Exceptional Maritime Healthcare: Next Steps for Executive Assistants</h2>
        
        <p className="mb-4">
          As an executive assistant or family office professional responsible for your principal's maritime experiences, incorporating sophisticated healthcare protocols demonstrates exceptional attention to detail and risk management—qualities invariably valued by discerning employers.
        </p>
        
        <p className="mb-4">
          Our yacht medical services can be engaged as standalone support or integrated into comprehensive travel arrangements. The initial consultation process is streamlined for efficiency, recognizing the multiple responsibilities facing executive support staff. To discuss specific requirements for an upcoming yacht journey, contact our dedicated client services coordinator, who specializes in working with executive assistants and family offices to create seamless medical support plans.
        </p>
        
        <div className="bg-[#2A2A2A]/30 rounded-xl p-6 mb-8">
          <p className="mb-4">
            For executive assistants preparing for upcoming maritime journeys, we offer confidential pre-arrangement consultations to identify specific medical considerations based on itinerary, vessel specifications, and principal requirements.
          </p>
          <p>
            Ensure your principal's maritime experience reflects the same excellence in healthcare that they expect in every other aspect of their lifestyle. Your attention to this critical detail will not go unnoticed.
          </p>
        </div>
      </section>
    </>
  );
  
  const references = [
    {
      authors: 'International Maritime Organization',
      title: 'Medical Guide for Ships: Healthcare Standards and Recommendations',
      journal: 'IMO Publications',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Richardson WJ, Somerville LK.',
      title: 'Remote maritime healthcare: Challenges and solutions for high-net-worth principals',
      journal: 'Journal of Travel Medicine',
      year: '2022',
      doi: '10.1093/jtm/tac048'
    },
    {
      authors: 'Australasian College of Emergency Medicine',
      title: 'Guidelines for Maritime Medical Emergency Planning',
      journal: 'ACEM Clinical Guidelines',
      year: '2023',
      doi: ''
    }
  ];

  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Maritime Healthcare Challenges', anchor: 'maritime-challenges' },
    { title: 'Comprehensive Services', anchor: 'comprehensive-services' },
    { title: 'Case Studies', anchor: 'case-examples' },
    { title: 'Next Steps', anchor: 'conclusion' }
  ];

  const relatedArticles = [
    {
      title: 'Private Jet Medical Services: In-Flight Healthcare for Global Travelers',
      slug: 'private-jet-medical-services',
      excerpt: 'Expert medical accompaniment for private aviation clients worldwide. Comprehensive pre-flight assessments, in-flight medical support, and emergency response.',
      imageUrl: '/images/drtez_private_jet_on_runway_--ar_169_--v_6.1_c580842d-d85f-49b2-9159-a5887075f3b8_3.png',
    },
    {
      title: 'International Medical Support: Global Healthcare Coordination',
      slug: 'international-medical-support',
      excerpt: 'Comprehensive international medical support for executives, families, and high-profile individuals traveling globally. Personalized healthcare coordination and emergency services.',
      imageUrl: '/images/drtez_Panoramic_view_from_inside_a_penthouse_with_soft_mornin_5f661f61-add1-438b-9e3a-b76803127174_0.png',
    },
    {
      title: 'Executive Health Programs: Tailored Medical Care for Business Leaders',
      slug: 'executive-health-programs-sydney',
      excerpt: 'Comprehensive health assessments and ongoing care designed specifically for executives and business leaders in Sydney.',
      imageUrl: '/images/drtez_Modern_coffee_table_with_open_MacBook_stethoscope_and_g_27ec4ad2-c21a-4429-9e1b-379509dd855a_2.png',
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/yacht-medical-services" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/yacht-medical-services" />
      </Helmet>
    
      <PremiumBlogTemplate
        title="Yacht Medical Services: Comprehensive Healthcare for Maritime Luxury"
        subtitle="Premium medical support for yacht owners, charterers, and guests in Australian waters and beyond"
        heroImage="/images/drtez_private_yacht_cinematic_mood._--ar_169_--v_6.1_b73e189a-a93a-4ed0-bb43-49acc8471446_1.png"
        publishDate="July 22, 2024"
        readTime={12}
        content={content}
        category="Private Doctor Services"
        tags={['Yacht Medical Services', 'Maritime Healthcare', 'Superyacht Doctor', 'VIP Medical Support', 'Sydney']}
        references={references}
        tableOfContents={tableOfContents}
        relatedArticles={relatedArticles}
      />
    </>
  );
};

export default YachtMedicalServices; 