import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, orderBy, query, limit, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { motion } from 'framer-motion';

// Static blog entries for fallback - ensure these are child-appropriate
const staticBlogEntries = [
  {
    id: 'static-rsv',
    title: 'RSV Infection in Children: Symptoms, Treatment & When to Call a Doctor',
    slug: 'rsv-infection-children',
    excerpt: 'Understanding respiratory syncytial virus (RSV) in children - symptoms, treatments, and when to seek medical care from Sydney\'s leading house call doctors.',
    category: 'Child Health',
    imageUrl: '/images/kidsrsv.png',
    createdAt: new Date('2024-06-25'),
    isStatic: true
  },
  {
    id: 'static-pertussis',
    title: 'Whooping Cough (Pertussis) in Children: Symptoms & Treatment Guide',
    slug: 'pertussis-whooping-cough',
    excerpt: 'Expert guide on recognizing and treating pertussis in children, including prevention strategies and when to seek medical attention in Sydney.',
    category: 'Child Health',
    imageUrl: '/images/pertussis-whooping-cough.png',
    createdAt: new Date('2024-06-22'),
    isStatic: true
  },
  {
    id: 'static-hfmd',
    title: 'Hand, Foot and Mouth Disease: A Parent\'s Guide to Symptoms & Treatment',
    slug: 'hand-foot-mouth-disease',
    excerpt: 'Essential information about hand, foot and mouth disease in children - symptoms, home care, and when to call a doctor in Sydney\'s Eastern Suburbs.',
    category: 'Child Health',
    imageUrl: '/images/hand-foot-mouth-disease.png',
    createdAt: new Date('2024-06-20'),
    isStatic: true
  },
  {
    id: 'static-marine',
    title: 'Marine Injuries in Sydney\'s Eastern Suburbs: Expert Treatment Guide',
    slug: 'marine-injuries-sydney',
    excerpt: 'Learn professional treatment for jellyfish stings, stingrays, sea urchins and other marine injuries common at Sydney\'s beaches with our expert house call doctor guide.',
    category: 'Emergency Care',
    imageUrl: '/images/sydney-beaches-marine-injuries.png',
    createdAt: new Date('2024-06-14'),
    isStatic: true
  },
  {
    id: 'static-skin',
    title: 'Common Skin Conditions in Children: A Guide for Australian Parents',
    slug: 'childhood-skin-conditions',
    excerpt: 'Recognizing, treating, and preventing common pediatric skin conditions through telehealth and in-person care',
    category: 'Child Health',
    imageUrl: 'https://images.unsplash.com/photo-1516627145497-ae6968895b40?auto=format&fit=crop&q=80&w=2340&ixlib=rb-4.0.3',
    createdAt: new Date('2024-03-20'),
    isStatic: true
  }
];

// Filter out categories not appropriate for the homepage
const excludedCategories = ["Men's Health"];

const BlogCarousel = () => {
  const [posts, setPosts] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef(null);

  useEffect(() => {
    async function fetchPosts() {
      try {
        const postsRef = collection(db, 'blog_posts');
        
        // Query posts but exclude Men's Health category
        const q = query(
          postsRef, 
          where('category', 'not-in', excludedCategories),
          orderBy('createdAt', 'desc'), 
          limit(5)
        );
        
        const querySnapshot = await getDocs(q);
        const postList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        // If we have results from Firestore
        if (postList.length > 0) {
          setPosts(postList);
        } else {
          // If no posts in Firestore, use the static entries
          console.log('No posts found in Firestore, using static blog entries');
          // Filter out any Men's Health static entries as well
          const filteredStaticEntries = staticBlogEntries.filter(
            entry => !excludedCategories.includes(entry.category)
          );
          setPosts(filteredStaticEntries);
        }
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        // Use static entries as fallback, but still filter out Men's Health
        console.log('Error fetching from Firestore, using static blog entries');
        const filteredStaticEntries = staticBlogEntries.filter(
          entry => !excludedCategories.includes(entry.category)
        );
        setPosts(filteredStaticEntries);
      }
    }

    fetchPosts();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!carouselRef.current) return;
      
      const scrollLeft = carouselRef.current.scrollLeft;
      const itemWidth = carouselRef.current.offsetWidth;
      const newActiveSlide = Math.round(scrollLeft / itemWidth);
      
      setActiveSlide(newActiveSlide);
    };

    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleDotClick = (index) => {
    if (!carouselRef.current) return;
    
    const itemWidth = carouselRef.current.offsetWidth;
    carouselRef.current.scrollTo({
      left: itemWidth * index,
      behavior: 'smooth'
    });
  };

  return (
    <section className="blog-carousel py-16 bg-[#1A1A1A]">
      <div className="container mx-auto px-4 md:px-6">
        <div className="stagger-animation mb-12">
          <motion.h2 
            className="text-3xl font-light text-[#DDC6A8] mb-3 tracking-tight"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Latest Health Insights
          </motion.h2>
        </div>
        
        <div 
          ref={carouselRef}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide pl-4 md:pl-8 lg:pl-16 -mr-4 md:-mr-8 lg:-mr-16"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {posts.map((post, index) => (
            <div
              key={post.id}
              className="min-w-[85vw] md:min-w-[40vw] lg:min-w-[33vw] flex-shrink-0 snap-center px-2 md:px-3"
            >
              <div className="relative aspect-[3/4] rounded-2xl overflow-hidden bg-[#1A1A1A] group">
                <div className="absolute inset-0">
                  <img
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                  />
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                  <div className="absolute bottom-0 left-0 p-6 md:p-8 lg:p-10">
                    <div className="flex items-center space-x-2 mb-4">
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-light bg-[#DDC6A8]/10 text-[#DDC6A8]">
                        {post.category}
                      </span>
                      <time className="text-sm text-[#DDC6A8]/60">
                        {post.createdAt?.toDate ? post.createdAt.toDate().toLocaleDateString() : 
                         post.createdAt instanceof Date ? post.createdAt.toLocaleDateString() : 
                         typeof post.createdAt === 'string' ? new Date(post.createdAt).toLocaleDateString() : 
                         'Undated'}
                      </time>
                    </div>
                    <h3 className="text-2xl md:text-3xl lg:text-4xl font-light mb-4 text-white">{post.title}</h3>
                    <p className="text-base md:text-lg text-white/90 mb-6">{post.excerpt}</p>
                    <Link
                      to={`/blog/${post.slug}`}
                      className="inline-flex items-center px-4 py-2 text-sm md:text-base font-medium border border-white text-white rounded-full hover:bg-white hover:text-black transition-all group"
                    >
                      Read Article
                      <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Scroll Indicator */}
        <div className="flex justify-center space-x-2 mt-6">
          {posts.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                activeSlide === index 
                  ? 'bg-[#DDC6A8] scale-110' 
                  : 'bg-[#DDC6A8]/30 hover:bg-[#DDC6A8]/50'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogCarousel; 