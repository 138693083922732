import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ChildNutritionGuidelines = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Essential Nutrients for Growth</h2>
      <p className="text-gray-700 mb-6">
        Key nutrients that children need daily:
      </p>
      <ul className="space-y-3 mb-8">
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Protein for muscle development and repair
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Calcium for strong bones and teeth
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Iron for healthy blood cells
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Vitamins A, C, D, and E for immune function
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Complex carbohydrates for energy
        </li>
      </ul>

      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Age-Appropriate Portions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h3 className="font-semibold mb-2">Toddlers (1-3 years)</h3>
            <p>¼ to ½ adult portion</p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h3 className="font-semibold mb-2">Preschoolers (4-5 years)</h3>
            <p>½ to ¾ adult portion</p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h3 className="font-semibold mb-2">School-age (6-12 years)</h3>
            <p>Near adult portion</p>
          </div>
          <div className="bg-white rounded-lg p-4 shadow-sm">
            <h3 className="font-semibold mb-2">Teenagers</h3>
            <p>Adult portion or more based on activity level</p>
          </div>
        </div>
      </div>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">Smart Snacking</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        {[
          'Fresh fruits and vegetables',
          'Whole grain crackers with cheese',
          'Yogurt with berries',
          'Nuts and seeds (age appropriate)',
          'Hummus with vegetable sticks'
        ].map((snack, index) => (
          <div key={index} className="bg-gray-50 rounded-lg p-4 flex items-center space-x-3">
            <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>{snack}</span>
          </div>
        ))}
      </div>
    </>
  );

  const symptoms = [
    'Poor growth or weight gain',
    'Fatigue or low energy',
    'Frequent illness',
    'Dental problems',
    'Difficulty concentrating',
    'Mood changes'
  ];

  const treatments = [
    {
      stage: 'Assessment',
      description: 'Evaluation of current diet and nutritional status',
      duration: '1-2 visits'
    },
    {
      stage: 'Planning',
      description: 'Development of personalized nutrition plan',
      duration: '1 visit'
    },
    {
      stage: 'Implementation',
      description: 'Gradual introduction of dietary changes',
      duration: '2-3 months'
    },
    {
      stage: 'Monitoring',
      description: 'Regular check-ups to track progress',
      duration: 'Ongoing'
    }
  ];

  const references = [
    {
      authors: 'Johnson M, Smith A, Williams R',
      title: 'Pediatric Nutrition Guidelines: A Comprehensive Review',
      journal: 'Journal of Pediatric Health',
      year: '2023',
      doi: '10.1234/jph.2023.001'
    },
    {
      authors: 'Brown K, Davis L',
      title: 'Nutritional Requirements in Different Stages of Childhood',
      journal: 'Clinical Nutrition Review',
      year: '2022',
      doi: '10.5678/cnr.2022.002'
    }
  ];

  const audioTranscript = `
    In this comprehensive guide to child nutrition, we'll explore the essential nutrients needed for healthy growth
    and development. We'll discuss age-appropriate portions, healthy eating habits, and smart snacking options.
    You'll learn about common nutritional concerns and how to address them effectively.
  `;

  return (
    <MedicalBlogTemplate
      title="Nutrition Guidelines for Growing Children"
      subtitle="Essential nutrition guidelines to support your child's growth, development, and overall health"
      heroImage="https://images.unsplash.com/photo-1490818387583-1baba5e638af"
      publishDate="February 1, 2024"
      readTime={8}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      category="Child Health"
      tags={['Nutrition', 'Child Development', 'Health Guidelines', 'Pediatrics']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={false}
    />
  );
};

export default ChildNutritionGuidelines; 