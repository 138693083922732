import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import CategoryBlogCarousel from '../components/CategoryBlogCarousel';

const ComprehensiveHealthCheck = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Premium Health Assessments Sydney | Executive Health Check | HomeDoc</title>
        <meta name="description" content="Unlock your health potential with comprehensive executive health assessments in Sydney's Eastern Suburbs. Data-driven insights, personalized care plans, and preventative screening for optimal performance." />
        <meta name="keywords" content="comprehensive health check sydney, executive health assessment, preventative screening, full body check-up eastern suburbs, medical examination paddington, bondi doctor checkup" />
        <link rel="canonical" href="https://homedoc.com.au/comprehensive-health-check" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalTest",
            "name": "Comprehensive Health Assessment",
            "description": "Complete physical evaluation and preventative screening service in Sydney's Eastern Suburbs",
            "usesDevice": [
              "Blood pressure monitor",
              "ECG",
              "Spirometer" 
            ],
            "preparation": "Fasting may be required for some blood tests",
            "normalRange": "Results are personalized based on age, gender, and health history",
            "affectedBy": [
              "Age",
              "Lifestyle factors",
              "Family history"
            ],
            "usedToDiagnose": [
              "Cardiovascular disease risk",
              "Metabolic disorders",
              "Early stage cancers",
              "Hormonal imbalances"
            ],
            "relevantSpecialty": "Preventative Medicine"
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center text-[#DDC6A8] overflow-hidden">
        <div className="absolute inset-0 z-0">
          <img 
            src="/images/healthcheck.png" 
            alt="Executive Health Assessment" 
            className="w-full h-full object-cover brightness-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-[#1A1A1A]/90 to-[#1A1A1A]"></div>
        </div>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10 py-32">
          <div className="max-w-4xl mx-auto text-center">
            <motion.h1 
              className="text-5xl sm:text-6xl md:text-7xl font-light tracking-tight mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              Time for your prime
            </motion.h1>
            <motion.p 
              className="text-xl sm:text-2xl md:text-3xl font-light mb-12 text-[#DDC6A8]/80"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              HomeDoc provides Sydney's leading program for comprehensive health assessments. We believe optimal health is achievable at any age.
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <Link 
                to="/dynamic-booking" 
                className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
              >
                BOOK ASSESSMENT
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* Three Pillar Section */}
      <section className="py-24 bg-[#1A1A1A]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto text-center mb-20">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-4">Diagnostics. Evaluation. Treatment.</h2>
            <p className="text-xl text-[#DDC6A8]/80">Our pillars for modern health assessment</p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm p-6 rounded-xl transition duration-300 hover:shadow-xl">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6 text-[#DDC6A8]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">DIAGNOSTICS</h3>
              <p className="text-[#DDC6A8]/80">Get a clear picture of where your health is at with comprehensive laboratory testing and advanced screening.</p>
              <Link to="/blog/executive-health-check-sydney" className="inline-block mt-6 text-[#DDC6A8] font-medium hover:text-[#DDC6A8]/60">
                Learn more
              </Link>
            </div>
            
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm p-6 rounded-xl transition duration-300 hover:shadow-xl">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6 text-[#DDC6A8]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">EVALUATION</h3>
              <p className="text-[#DDC6A8]/80">Expert medical interpretation of your results with personalized recommendations tailored to your lifestyle.</p>
              <Link to="/blog/executive-health-check-sydney" className="inline-block mt-6 text-[#DDC6A8] font-medium hover:text-[#DDC6A8]/60">
                Learn more
              </Link>
            </div>
            
            <div className="bg-[#2A2A2A]/50 backdrop-blur-sm p-6 rounded-xl transition duration-300 hover:shadow-xl">
              <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6 text-[#DDC6A8]">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-light text-[#DDC6A8] mb-4">TREATMENT</h3>
              <p className="text-[#DDC6A8]/80">Implement evidence-based interventions and ongoing care plans to optimize your health and performance.</p>
              <Link to="/blog/executive-health-check-sydney" className="inline-block mt-6 text-[#DDC6A8] font-medium hover:text-[#DDC6A8]/60">
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </section>
      
      {/* Feature Section */}
      <section className="py-24 bg-[#2A2A2A]/30">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="md:w-1/2">
                <img 
                  src="/images/healthcheck4.png" 
                  alt="Health assessment results" 
                  className="w-full h-auto rounded-xl shadow-xl"
                />
              </div>
              <div className="md:w-1/2">
                <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Data-driven insights for optimal health</h2>
                <p className="text-[#DDC6A8]/80 mb-8">
                  Our comprehensive assessments give you a complete picture of your health status using advanced diagnostics and expert analysis. Identify potential health risks before they become problems.
                </p>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-[#DDC6A8]/80">Comprehensive blood analysis</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-[#DDC6A8]/80">Cardiovascular risk assessment</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-[#DDC6A8]/80">Hormonal balance evaluation</span>
                  </li>
                  <li className="flex items-start">
                    <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="ml-3 text-[#DDC6A8]/80">Detailed health report with actionable insights</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Image Left Section */}
      <section className="py-24 bg-[#1A1A1A]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="md:w-1/2 order-2 md:order-1">
                <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Our signature approach</h2>
                <p className="text-[#DDC6A8]/80 mb-6">
                  Our health check process is designed to be thorough yet efficient, providing you with a comprehensive understanding of your health status and personalized recommendations.
                </p>
                <div className="space-y-4">
                  <div className="border-l-4 border-[#DDC6A8] pl-4">
                    <h3 className="text-xl font-medium text-[#DDC6A8]">1. Comprehensive Assessment</h3>
                    <p className="text-[#DDC6A8]/80 mt-2">
                      A detailed evaluation of your current health status, medical history, and lifestyle factors.
                    </p>
                  </div>
                  <div className="border-l-4 border-[#DDC6A8] pl-4">
                    <h3 className="text-xl font-medium text-[#DDC6A8]">2. Advanced Diagnostics</h3>
                    <p className="text-[#DDC6A8]/80 mt-2">
                      State-of-the-art testing to provide objective data about your health status.
                    </p>
                  </div>
                  <div className="border-l-4 border-[#DDC6A8] pl-4">
                    <h3 className="text-xl font-medium text-[#DDC6A8]">3. Personalized Plan</h3>
                    <p className="text-[#DDC6A8]/80 mt-2">
                      A tailored health optimization strategy developed specifically for your unique needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 order-1 md:order-2">
                <img 
                  src="/images/healthcheck5.png" 
                  alt="Signature approach to health assessment" 
                  className="w-full h-auto rounded-xl shadow-xl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Expert Team Section */}
      <section className="py-24 bg-indigo-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Meet Your Healthcare Expert</h2>
            <p className="text-xl text-gray-600">Dedicated to providing exceptional care and personalized health solutions</p>
          </div>
          
          <div className="max-w-3xl mx-auto">
            <div className="bg-white rounded-xl shadow-xl overflow-hidden">
              <div className="md:flex">
                <div className="md:w-1/3">
                  <img 
                    src="/images/healthcheck3.png" 
                    alt="Dr. Terry Nguyen" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="md:w-2/3 p-8">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">Dr. Terry Nguyen</h3>
                  <p className="text-indigo-600 mb-4">Health Assessment Specialist</p>
                  <p className="text-gray-600 mb-6">
                    Dr. Terry Nguyen leads HomeDoc's comprehensive health assessment program, bringing extensive expertise in preventative medicine and executive health. With a passion for helping busy professionals optimize their health, Dr. Nguyen combines medical excellence with a deep understanding of the unique health challenges faced by Sydney's high-performers.
                  </p>
                  <Link to="/about" className="text-indigo-600 font-medium hover:text-indigo-800">Learn more about Dr. Terry</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Testimonials */}
      <section className="py-24 bg-[#2A2A2A]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">What our clients say</h2>
            <p className="text-[#DDC6A8]/80 max-w-2xl mx-auto">
              Hear from professionals who have experienced our comprehensive health assessments and the impact it has made on their lives.
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-[#1A1A1A] p-8 rounded-xl shadow-md">
              <div className="flex items-center mb-4">
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
              <p className="text-[#DDC6A8]/80 mb-4">
                "As a finance executive with little time for personal health, the assessment was eye-opening. Dr. Nguyen identified early signs of metabolic syndrome that I was unaware of. Six months later, my numbers have improved dramatically."
              </p>
              <div className="flex items-center">
                <div className="ml-4">
                  <p className="text-[#DDC6A8] font-medium">Michael T.</p>
                  <p className="text-[#DDC6A8]/60 text-sm">Investment Director, 47</p>
                </div>
              </div>
            </div>
            <div className="bg-[#1A1A1A] p-8 rounded-xl shadow-md">
              <div className="flex items-center mb-4">
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                <svg className="h-5 w-5 text-[#DDC6A8]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </div>
              <p className="text-[#DDC6A8]/80 mb-4">
                "The comprehensive health check identified my low hormone levels which explained my chronic fatigue. Dr. Nguyen's personalized treatment plan has restored my energy and focus. I'm performing better at work and at home."
              </p>
              <div className="flex items-center">
                <div className="ml-4">
                  <p className="text-[#DDC6A8] font-medium">Sarah L.</p>
                  <p className="text-[#DDC6A8]/60 text-sm">Law Partner, 42</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Blog Articles */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Knowledge is power</h2>
            <p className="text-xl text-gray-600">Explore our resources on health optimization and preventative care</p>
          </div>
          <CategoryBlogCarousel category="Comprehensive Health" limit={3} title="Featured Health Articles" />
        </div>
      </section>
      
      {/* CTA Section */}
      <section className="py-24 bg-[#2A2A2A]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto bg-[#1A1A1A] p-12 rounded-2xl shadow-xl">
            <div className="flex flex-col md:flex-row md:items-center gap-8">
              <div className="md:w-2/3">
                <h2 className="text-3xl font-light text-[#DDC6A8] mb-4">Ready to prioritize your health?</h2>
                <p className="text-[#DDC6A8]/80 mb-6">
                  Schedule your comprehensive health assessment today and take the first step towards optimizing your health and performance.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <a 
                    href="tel:+61223456789" 
                    className="inline-block px-6 py-3 bg-transparent text-[#DDC6A8] border border-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-colors duration-300 text-center"
                  >
                    Call for Appointment
                  </a>
                  <a 
                    href="mailto:enquiries@drterrynguyen.com" 
                    className="inline-block px-6 py-3 bg-[#DDC6A8] text-[#1A1A1A] rounded-md hover:bg-[#DDC6A8]/90 transition-colors duration-300 text-center"
                  >
                    Email Enquiry
                  </a>
                </div>
              </div>
              <div className="md:w-1/3 flex justify-center md:justify-end">
                <img 
                  src="/images/healthcheck-logo-icon.png" 
                  alt="Comprehensive Health Assessment logo" 
                  className="w-48 h-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* FAQ Section */}
      <section className="py-24 bg-[#1A1A1A]">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-5xl mx-auto">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-light text-[#DDC6A8] mb-6">Frequently Asked Questions</h2>
              <p className="text-[#DDC6A8]/80 max-w-2xl mx-auto">
                Find answers to common questions about our comprehensive health assessments.
              </p>
            </div>
            <div className="divide-y divide-[#DDC6A8]/20">
              <div className="py-6">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-[#DDC6A8]">
                    <span>How long does a comprehensive health assessment take?</span>
                    <span className="transition group-open:rotate-180">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                    </span>
                  </summary>
                  <p className="text-[#DDC6A8]/70 mt-3 group-open:animate-fadeIn">
                    Our comprehensive health assessments typically take 1-2 hours, depending on the specific tests included. We value efficiency and understand that your time is valuable. The assessment is thorough but streamlined to provide you with comprehensive insights without unnecessary delays.
                  </p>
                </details>
              </div>
              <div className="py-6">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-[#DDC6A8]">
                    <span>What types of tests are included in the assessment?</span>
                    <span className="transition group-open:rotate-180">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                    </span>
                  </summary>
                  <p className="text-[#DDC6A8]/70 mt-3 group-open:animate-fadeIn">
                    Our comprehensive assessments include advanced blood panels, cardiovascular risk assessment, metabolic testing, hormonal evaluation, and body composition analysis. The specific tests are tailored to your individual needs, health history, and concerns. All tests are evidence-based and selected to provide meaningful, actionable data.
                  </p>
                </details>
              </div>
              <div className="py-6">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-[#DDC6A8]">
                    <span>How often should I schedule a comprehensive health assessment?</span>
                    <span className="transition group-open:rotate-180">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                    </span>
                  </summary>
                  <p className="text-[#DDC6A8]/70 mt-3 group-open:animate-fadeIn">
                    For most individuals, an annual assessment provides optimal health monitoring. However, this can vary based on your age, existing health conditions, family history, and personal health goals. During your initial consultation, we'll recommend an appropriate schedule for your situation. Some patients with specific health concerns may benefit from more frequent assessments.
                  </p>
                </details>
              </div>
              <div className="py-6">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-[#DDC6A8]">
                    <span>What happens after my assessment is complete?</span>
                    <span className="transition group-open:rotate-180">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                    </span>
                  </summary>
                  <p className="text-[#DDC6A8]/70 mt-3 group-open:animate-fadeIn">
                    After your assessment, you'll receive a comprehensive report detailing your results with clear explanations. This is followed by a consultation with Dr. Nguyen to discuss findings and develop a personalized health optimization plan. We provide ongoing support to help you implement your plan effectively and can adjust recommendations as needed based on your progress and feedback.
                  </p>
                </details>
              </div>
              <div className="py-6">
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none text-[#DDC6A8]">
                    <span>Can I use private health insurance for my assessment?</span>
                    <span className="transition group-open:rotate-180">
                      <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path></svg>
                    </span>
                  </summary>
                  <p className="text-[#DDC6A8]/70 mt-3 group-open:animate-fadeIn">
                    Certain components of our comprehensive health assessments may be eligible for private health insurance rebates, depending on your specific plan and level of cover. Medicare rebates are available for eligible services. Our team can provide detailed information about potential costs and rebates prior to your appointment to ensure transparency.
                  </p>
                </details>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Final CTA */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Results over rhetoric</h2>
            <Link 
              to="/dynamic-booking" 
              className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 transition duration-300 shadow-xl"
            >
              BOOK YOUR ASSESSMENT
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComprehensiveHealthCheck; 