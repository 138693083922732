import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import AutoBlogGenerator from '../../components/AutoBlogGenerator';

const BlogGenerationDashboard = () => {
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalAutoGenerated: 0,
    lastGenerated: null,
    averageWordCount: 0,
    topCategories: []
  });
  const [loading, setLoading] = useState(true);
  const [recentLogs, setRecentLogs] = useState([]);

  useEffect(() => {
    if (!currentUser) return;
    
    const fetchBlogStats = async () => {
      try {
        setLoading(true);
        const postsRef = collection(db, 'blog_posts');
        const q = query(
          postsRef,
          where('autoGenerated', '==', true),
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const posts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate?.() || new Date()
        }));
        
        // Calculate statistics
        if (posts.length > 0) {
          const totalWords = posts.reduce((sum, post) => sum + (post.wordCount || 0), 0);
          const avgWords = Math.round(totalWords / posts.length);
          
          // Get top categories
          const categories = posts.map(post => post.category);
          const categoryCounts = categories.reduce((acc, category) => {
            acc[category] = (acc[category] || 0) + 1;
            return acc;
          }, {});
          
          const topCategories = Object.entries(categoryCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 3)
            .map(([category, count]) => ({ category, count }));
            
          setStats({
            totalAutoGenerated: posts.length,
            lastGenerated: posts.length > 0 ? posts[0].createdAt : null,
            averageWordCount: avgWords,
            topCategories: topCategories
          });
        }
        
        // Simulate getting logs (in a real app, you would fetch these from a logs collection)
        setRecentLogs([
          {
            id: 'log1',
            timestamp: new Date(),
            type: 'info',
            message: 'Blog generation system is operational'
          },
          {
            id: 'log2',
            timestamp: new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
            type: 'success',
            message: 'Last scheduled generation completed successfully'
          }
        ]);
      } catch (err) {
        console.error('Error fetching blog statistics:', err);
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogStats();
  }, [currentUser]);

  // Format date for display
  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('en-AU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8] px-4 py-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-light mb-8">Blog Generation Dashboard</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Stats Cards */}
          <div className="bg-[#2A2A2A] border border-[#DDC6A8]/20 rounded-lg shadow p-6">
            <h2 className="text-xl font-medium text-[#DDC6A8] mb-4">System Statistics</h2>
            
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-1/2 mb-4"></div>
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-5/6 mb-4"></div>
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-2/3"></div>
              </div>
            ) : (
              <div className="space-y-4">
                <div>
                  <p className="text-sm text-[#DDC6A8]/60">Total Auto-Generated Posts</p>
                  <p className="text-2xl font-light">{stats.totalAutoGenerated}</p>
                </div>
                <div>
                  <p className="text-sm text-[#DDC6A8]/60">Last Generated</p>
                  <p className="text-lg">{formatDate(stats.lastGenerated)}</p>
                </div>
                <div>
                  <p className="text-sm text-[#DDC6A8]/60">Average Word Count</p>
                  <p className="text-xl">{stats.averageWordCount} words</p>
                </div>
                <div>
                  <p className="text-sm text-[#DDC6A8]/60">Top Categories</p>
                  <div className="mt-2 space-y-2">
                    {stats.topCategories.map((item, index) => (
                      <div key={index} className="flex items-center">
                        <div className="w-2 h-2 rounded-full bg-[#DDC6A8] mr-2"></div>
                        <span>{item.category} ({item.count})</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          
          {/* Recent Logs */}
          <div className="bg-[#2A2A2A] border border-[#DDC6A8]/20 rounded-lg shadow p-6">
            <h2 className="text-xl font-medium text-[#DDC6A8] mb-4">Recent System Logs</h2>
            
            {loading ? (
              <div className="animate-pulse">
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-full mb-4"></div>
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-full mb-4"></div>
                <div className="h-4 bg-[#DDC6A8]/20 rounded w-full"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {recentLogs.length === 0 ? (
                  <p className="text-[#DDC6A8]/60">No recent logs available.</p>
                ) : (
                  recentLogs.map(log => (
                    <div key={log.id} className="border-l-2 border-[#DDC6A8] pl-4 py-1">
                      <p className="text-xs text-[#DDC6A8]/60">{formatDate(log.timestamp)}</p>
                      <p className="text-sm">
                        <span className={`inline-block w-16 text-${log.type === 'info' ? 'blue' : log.type === 'success' ? 'green' : 'red'}-400`}>
                          [{log.type.toUpperCase()}]
                        </span>
                        {log.message}
                      </p>
                    </div>
                  ))
                )}
                <div className="text-sm text-center mt-4">
                  <a
                    href="https://app.netlify.com/sites/drterry/logs/functions"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#DDC6A8] hover:text-[#DDC6A8]/70 underline"
                  >
                    View Full Netlify Function Logs
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        
        {/* Generator Component */}
        <AutoBlogGenerator />
        
        {/* Schedule Configuration */}
        <div className="mt-8 bg-[#2A2A2A] border border-[#DDC6A8]/20 rounded-lg shadow p-6">
          <h2 className="text-xl font-medium text-[#DDC6A8] mb-4">Automated Generation Schedule</h2>
          <p className="text-[#DDC6A8]/70 mb-4">
            Blog posts are currently scheduled to be automatically generated every Monday at 3:00 AM Australian Eastern Standard Time.
          </p>
          <div className="bg-[#1A1A1A] p-4 rounded-md border border-[#DDC6A8]/10 mb-4">
            <p className="text-sm font-mono text-[#DDC6A8]/80">
              Current cron schedule: <span className="text-green-400">0 3 * * 1</span>
            </p>
          </div>
          <p className="text-sm text-[#DDC6A8]/60">
            To modify the generation schedule, please update the Netlify scheduled functions configuration.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogGenerationDashboard; 