import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import BookingsAdmin from './BookingsAdmin';
import BookingAnalytics from '../components/BookingAnalytics';
import SEODashboard from './admin/SEODashboard';

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("AdminDashboard Error Boundary caught an error:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
          <div className="max-w-md w-full bg-[#2A2A2A] rounded-lg p-6 text-center">
            <h2 className="text-red-500 mb-4">Something went wrong</h2>
            <details className="text-left text-[#DDC6A8] mb-4">
              <summary>Error details</summary>
              <pre className="mt-2 text-xs overflow-auto">{this.state.error && this.state.error.toString()}</pre>
            </details>
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg inline-block"
            >
              Reload Page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default function AdminDashboard() {
  console.log("AdminDashboard rendering");
  const [activeTab, setActiveTab] = useState('bookings');
  const { currentUser } = useAuth();

  useEffect(() => {
    console.log("AdminDashboard mounted, currentUser:", currentUser ? currentUser.email : "No user");
    
    return () => {
      console.log("AdminDashboard unmounting");
    };
  }, [currentUser]);

  useEffect(() => {
    console.log("Active tab changed to:", activeTab);
  }, [activeTab]);

  if (!currentUser) {
    console.log("No user found, showing login prompt");
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="max-w-md w-full bg-[#2A2A2A] rounded-lg p-6 text-center">
          <p className="text-red-500 mb-4">You must be logged in to view this page</p>
          <a
            href="/login"
            className="px-4 py-2 bg-[#DDC6A8] text-[#1A1A1A] rounded-lg inline-block"
          >
            Go to Login
          </a>
        </div>
      </div>
    );
  }

  console.log("Rendering AdminDashboard with activeTab:", activeTab);

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
          <h1 className="text-3xl font-bold mb-4 md:mb-0">Admin Dashboard</h1>
          
          <div className="flex space-x-2 bg-[#2A2A2A] rounded-lg p-1">
            <button
              onClick={() => setActiveTab('bookings')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'bookings'
                  ? 'bg-[#DDC6A8] text-[#1A1A1A]'
                  : 'text-[#DDC6A8] hover:bg-[#1A1A1A]/50'
              }`}
            >
              Bookings
            </button>
            <button
              onClick={() => setActiveTab('analytics')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'analytics'
                  ? 'bg-[#DDC6A8] text-[#1A1A1A]'
                  : 'text-[#DDC6A8] hover:bg-[#1A1A1A]/50'
              }`}
            >
              Analytics
            </button>
            <button
              onClick={() => setActiveTab('seo')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'seo'
                  ? 'bg-[#DDC6A8] text-[#1A1A1A]'
                  : 'text-[#DDC6A8] hover:bg-[#1A1A1A]/50'
              }`}
            >
              SEO
            </button>
            <button
              onClick={() => setActiveTab('content')}
              className={`px-4 py-2 rounded-md ${
                activeTab === 'content'
                  ? 'bg-[#DDC6A8] text-[#1A1A1A]'
                  : 'text-[#DDC6A8] hover:bg-[#1A1A1A]/50'
              }`}
            >
              Content
            </button>
          </div>
        </div>
        
        <ErrorBoundary>
          {activeTab === 'bookings' && <BookingsAdmin />}
          {activeTab === 'analytics' && <BookingAnalytics />}
          {activeTab === 'seo' && <SEODashboard />}
          {activeTab === 'content' && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="bg-[#2A2A2A] rounded-lg p-6 shadow">
                <h2 className="text-xl font-medium mb-4">Blog Management</h2>
                <p className="text-[#DDC6A8]/70 mb-4">
                  Create, edit, and publish blog articles manually.
                </p>
                <a 
                  href="/admin/blog" 
                  className="inline-flex items-center px-4 py-2 border border-[#DDC6A8]/30 rounded-md bg-[#DDC6A8]/10 text-[#DDC6A8] hover:bg-[#DDC6A8]/20"
                >
                  Manage Blog
                </a>
              </div>
              
              <div className="bg-[#2A2A2A] rounded-lg p-6 shadow">
                <h2 className="text-xl font-medium mb-4">AI Blog Generator</h2>
                <p className="text-[#DDC6A8]/70 mb-4">
                  Manage the automated blog generation system powered by AI.
                </p>
                <a 
                  href="/admin/blog-generation" 
                  className="inline-flex items-center px-4 py-2 border border-[#DDC6A8]/30 rounded-md bg-[#DDC6A8]/10 text-[#DDC6A8] hover:bg-[#DDC6A8]/20"
                >
                  Open Blog Generator
                </a>
              </div>
              
              <div className="bg-[#2A2A2A] rounded-lg p-6 shadow">
                <h2 className="text-xl font-medium mb-4">Netlify Functions</h2>
                <p className="text-[#DDC6A8]/70 mb-4">
                  Monitor and manage serverless functions for blog generation.
                </p>
                <a 
                  href="https://app.netlify.com/sites/drterry/functions" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-4 py-2 border border-[#DDC6A8]/30 rounded-md bg-[#DDC6A8]/10 text-[#DDC6A8] hover:bg-[#DDC6A8]/20"
                >
                  View Netlify Dashboard
                </a>
              </div>
            </div>
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
} 