import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const Sitemap = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <SEO
        title="Site Map - Dr. Terry Nguyen"
        description="Browse all pages on Dr. Terry Nguyen's medical practice website. Find information about telemedicine consultations, family medicine, and other healthcare services in Australia."
        keywords="sitemap, doctor website, telemedicine australia, medical services, dr terry nguyen"
        canonicalUrl="/sitemap"
        structuredData={{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Sitemap - Dr. Terry Nguyen Medical Practice",
          "description": "Complete listing of all pages on Dr. Terry Nguyen's medical practice website.",
          "publisher": {
            "@type": "Organization",
            "name": "Dr. Terry Nguyen Medical Practice",
            "logo": {
              "@type": "ImageObject",
              "url": `${window.location.origin}/logo.png`
            }
          }
        }}
      />
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Site Map</h1>
      
      <div className="space-y-12">
        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Main Pages</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <li>
              <Link 
                to="/" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Home - Dr. Terry Nguyen - Family Doctor in Australia</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/about" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">About Dr. Terry Nguyen - Experience & Expertise</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/telemedicine" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Telemedicine Consultations - Medicare Bulk Billing Available</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/private" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Private Medical Services - Global Concierge & Bespoke Care</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/family-medicine" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Family Medicine Services - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/mens-health" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Men's Health Services - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/womens-health" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Women's Health Services - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/pediatric-care" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Pediatric Care Services - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/chronic-disease-management" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Chronic Disease Management - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/blog" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Medical Blog - Health Tips & Medical Information</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/booking" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Book an Appointment - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/contact" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Contact Us - Dr. Terry Nguyen</span>
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Blog Posts</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <li>
              <Link 
                to="/blog/navigating-telehealth-in-australia" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Navigating Telehealth in Australia: Medicare Benefits & Best Practices</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/blog/understanding-medicare-mental-health-changes" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Understanding the New Medicare Mental Health Changes in Australia</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/blog/managing-diabetes-in-australia" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Managing Diabetes in Australia: Latest Treatment Options</span>
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Ultimate Guides</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <li>
              <Link 
                to="/guides/urgent-house-call" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">The Ultimate Guide to Urgent House Call Medical Services in Sydney</span>
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Legal Pages</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <li>
              <Link 
                to="/privacy-policy" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Privacy Policy - Dr. Terry Nguyen</span>
              </Link>
            </li>
            <li>
              <Link 
                to="/terms-of-service" 
                className="block p-4 border border-gray-200 rounded-lg hover:bg-gray-50 transition-colors"
              >
                <span className="font-medium text-indigo-600">Terms of Service - Dr. Terry Nguyen</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sitemap; 