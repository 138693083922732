import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, updateDoc, where } from 'firebase/firestore';
import { db } from '../config/firebase';
import { format } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';
import BookingDetails from '../components/BookingDetails';

export default function BookingsAdmin() {
  console.log("BookingsAdmin rendering");
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusFilter, setStatusFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('upcoming');
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    console.log("BookingsAdmin mounted, currentUser:", currentUser ? currentUser.email : "No user");
    
    if (!currentUser) {
      console.log("No user found in BookingsAdmin");
      setError('You must be logged in to view this page');
      setLoading(false);
      return;
    }

    console.log("Setting up Firestore listener with filters:", { statusFilter, dateFilter });
    setLoading(true);
    
    // Create query based on filters
    let bookingsQuery = collection(db, 'bookings');
    
    // Add status filter if not 'all'
    if (statusFilter !== 'all') {
      bookingsQuery = query(bookingsQuery, where('status', '==', statusFilter));
    }
    
    // Add date filter
    if (dateFilter === 'upcoming') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      bookingsQuery = query(bookingsQuery, where('date', '>=', today));
    } else if (dateFilter === 'past') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      bookingsQuery = query(bookingsQuery, where('date', '<', today));
    }
    
    // Always order by date descending
    bookingsQuery = query(bookingsQuery, orderBy('date', 'desc'));
    
    // Set up real-time listener
    const unsubscribe = onSnapshot(
      bookingsQuery,
      (snapshot) => {
        console.log("Firestore snapshot received, docs count:", snapshot.docs.length);
        const bookingsData = snapshot.docs.map(doc => {
          const data = doc.data();
          // Process location data to ensure it's safe for rendering
          let locationDisplay = 'Not specified';
          
          if (data.location) {
            if (typeof data.location === 'string') {
              locationDisplay = data.location;
            } else if (typeof data.location === 'object' && data.location.address) {
              locationDisplay = data.location.address;
            }
          }
          
          return {
            id: doc.id,
            ...data,
            locationDisplay,
            date: data.date?.toDate() // Convert Firestore timestamp to JS Date
          };
        });
        console.log("Processed bookings data:", bookingsData.length);
        setBookings(bookingsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching bookings:', err);
        setError('Failed to load bookings');
        setLoading(false);
      }
    );
    
    // Clean up listener on unmount
    return () => {
      console.log("BookingsAdmin unmounting, unsubscribing from Firestore");
      unsubscribe();
    };
  }, [currentUser, statusFilter, dateFilter]);

  const updateBookingStatus = async (bookingId, newStatus) => {
    try {
      console.log("Updating booking status:", bookingId, newStatus);
      const bookingRef = doc(db, 'bookings', bookingId);
      await updateDoc(bookingRef, {
        status: newStatus,
        updatedAt: new Date()
      });
      console.log("Booking status updated successfully");
    } catch (err) {
      console.error('Error updating booking status:', err);
      setError('Failed to update booking status');
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Not specified';
    try {
      return format(date, 'dd/MM/yyyy h:mm a');
    } catch (err) {
      console.error("Error formatting date:", date, err);
      return String(date);
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'confirmed':
        return 'bg-green-100 text-green-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleViewBooking = (bookingId) => {
    console.log("Viewing booking details:", bookingId);
    setSelectedBookingId(bookingId);
  };

  const handleCloseModal = () => {
    console.log("Closing booking details modal");
    setSelectedBookingId(null);
  };

  if (loading) {
    console.log("BookingsAdmin is loading");
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8]">Loading bookings...</p>
        </div>
      </div>
    );
  }

  if (error) {
    console.log("BookingsAdmin encountered an error:", error);
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center">
        <div className="max-w-md w-full bg-[#2A2A2A] rounded-lg p-6 text-center">
          <p className="text-red-500 mb-4">{error}</p>
        </div>
      </div>
    );
  }

  console.log("Rendering BookingsAdmin with bookings:", bookings.length);

  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8] p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-6">Bookings Management</h1>
        
        {/* Filters */}
        <div className="bg-[#2A2A2A] rounded-lg p-4 mb-6 flex flex-wrap gap-4">
          <div>
            <label htmlFor="statusFilter" className="block text-sm mb-1">Status</label>
            <select
              id="statusFilter"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
            >
              <option value="all">All Statuses</option>
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="dateFilter" className="block text-sm mb-1">Date</label>
            <select
              id="dateFilter"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="bg-[#1A1A1A] border border-[#DDC6A8]/30 rounded px-3 py-2 text-[#DDC6A8]"
            >
              <option value="all">All Dates</option>
              <option value="upcoming">Upcoming</option>
              <option value="past">Past</option>
            </select>
          </div>
        </div>
        
        {/* Stats Summary */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
          <div className="bg-[#2A2A2A] rounded-lg p-4">
            <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Total Bookings</h3>
            <p className="text-2xl font-bold text-[#DDC6A8]">{bookings.length}</p>
          </div>
          
          <div className="bg-[#2A2A2A] rounded-lg p-4">
            <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Pending</h3>
            <p className="text-2xl font-bold text-[#DDC6A8]">
              {bookings.filter(b => b.status === 'pending').length}
            </p>
          </div>
          
          <div className="bg-[#2A2A2A] rounded-lg p-4">
            <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Confirmed</h3>
            <p className="text-2xl font-bold text-[#DDC6A8]">
              {bookings.filter(b => b.status === 'confirmed').length}
            </p>
          </div>
          
          <div className="bg-[#2A2A2A] rounded-lg p-4">
            <h3 className="text-sm text-[#DDC6A8]/70 mb-1">Completed</h3>
            <p className="text-2xl font-bold text-[#DDC6A8]">
              {bookings.filter(b => b.status === 'completed').length}
            </p>
          </div>
        </div>
        
        {/* Bookings Table */}
        <div className="bg-[#2A2A2A] rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-[#DDC6A8]/10">
              <thead className="bg-[#1A1A1A]">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Date & Time
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Patient
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Service
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-[#DDC6A8]/70 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-[#DDC6A8]/10">
                {bookings.length === 0 ? (
                  <tr>
                    <td colSpan="7" className="px-6 py-4 text-center text-[#DDC6A8]/50">
                      No bookings found
                    </td>
                  </tr>
                ) : (
                  bookings.map((booking) => (
                    <tr key={booking.id} className="hover:bg-[#1A1A1A]/30">
                      <td className="px-6 py-4 whitespace-nowrap">
                        {formatDate(booking.date)}
                      </td>
                      <td className="px-6 py-4">
                        <div className="font-medium">
                          {booking.patientDetails?.firstName} {booking.patientDetails?.lastName}
                        </div>
                        <div className="text-sm text-[#DDC6A8]/70">
                          {booking.patientDetails?.email}
                        </div>
                        <div className="text-sm text-[#DDC6A8]/70">
                          {booking.patientDetails?.phone}
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        {booking.service?.name || booking.serviceType}
                      </td>
                      <td className="px-6 py-4">
                        {booking.locationDisplay}
                      </td>
                      <td className="px-6 py-4">
                        ${booking.price}
                      </td>
                      <td className="px-6 py-4">
                        <span className={`px-2 py-1 text-xs rounded-full ${getStatusBadgeClass(booking.status)}`}>
                          {booking.status || 'pending'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleViewBooking(booking.id)}
                            className="text-[#DDC6A8] hover:text-[#DDC6A8]/70"
                          >
                            View
                          </button>
                          {booking.status !== 'confirmed' && (
                            <button
                              onClick={() => updateBookingStatus(booking.id, 'confirmed')}
                              className="text-green-500 hover:text-green-700"
                            >
                              Confirm
                            </button>
                          )}
                          {booking.status !== 'completed' && booking.status !== 'cancelled' && (
                            <button
                              onClick={() => updateBookingStatus(booking.id, 'completed')}
                              className="text-blue-500 hover:text-blue-700"
                            >
                              Complete
                            </button>
                          )}
                          {booking.status !== 'cancelled' && (
                            <button
                              onClick={() => updateBookingStatus(booking.id, 'cancelled')}
                              className="text-red-500 hover:text-red-700"
                            >
                              Cancel
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      {/* Booking Details Modal */}
      {selectedBookingId && (
        <BookingDetails 
          bookingId={selectedBookingId} 
          onClose={handleCloseModal} 
        />
      )}
    </div>
  );
} 