import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogCarousel from '../components/BlogCarousel';
import CategoryBlogCarousel from '../components/CategoryBlogCarousel';

const MensHealth = () => {
  return (
    <div className="min-h-screen bg-[#1A1A1A] text-[#DDC6A8]">
      <Helmet>
        <title>Men's Health Services Sydney | Eastern Suburbs Male GP | HomeDoc</title>
        <meta name="description" content="Specialized men's health services in Sydney's Eastern Suburbs with Dr. Terry Nguyen. Confidential consultations for sexual health, mental wellbeing, preventative screenings & more." />
        <meta name="keywords" content="men's health Sydney, male doctor Eastern Suburbs, testosterone, prostate health, erectile dysfunction, mental health men, Sydney men's clinic, HomeDoc, Dr Terry Nguyen" />
        <link rel="canonical" href="https://homedoc.com.au/mens-health" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalWebPage",
            "about": {
              "@type": "MedicalSpecialty",
              "name": "Men's Health"
            },
            "audience": "Men seeking healthcare",
            "mainEntity": {
              "@type": "MedicalBusiness",
              "name": "HomeDoc Men's Health Services",
              "description": "Specialized healthcare services for men in Sydney's Eastern Suburbs",
              "medicalSpecialty": "Men's Health",
              "address": {
                "@type": "PostalAddress",
                "addressLocality": "Sydney",
                "addressRegion": "NSW",
                "addressCountry": "Australia"
              },
              "telephone": "+61-2-1234-5678"
            },
            "lastReviewed": "2025-07-03",
            "author": {
              "@type": "Person",
              "name": "Dr. Terry Nguyen",
              "jobTitle": "General Practitioner"
            }
          })}
        </script>
      </Helmet>
      
      <div className="site-background" />
      
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0">
          <img 
            src="/images/drterry_menshealth1.png" 
            alt="Sydney Men's Health Specialist Dr. Terry Nguyen" 
            className="w-full h-full object-cover brightness-50"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-[#1A1A1A]/70 to-[#1A1A1A]"></div>
        </div>
        
        <div className="relative max-w-6xl mx-auto px-4 py-24 sm:py-32">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-light text-white mb-6">Men's Health Services</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8] mb-8">Specialized Care for Men in Sydney's Eastern Suburbs</h2>
            <p className="text-xl text-white/80 mb-8">
              Confidential, compassionate healthcare designed specifically for men's unique health needs. From preventative screenings to specialized treatments, my approach prioritizes your wellbeing and comfort.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link 
                to="/dynamic-booking" 
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-xl text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition-all"
              >
                Book a Private Consultation
              </Link>
              <a 
                href="#services" 
                className="inline-flex items-center justify-center px-6 py-3 border border-[#DDC6A8] text-base font-medium rounded-md text-[#DDC6A8] hover:bg-[#DDC6A8]/10 transition-all"
              >
                Explore Services
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Doctor Introduction Section */}
      <div className="content-wrapper relative z-10 bg-[#1A1A1A]">
        <div className="max-w-6xl mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row items-center gap-12">
            <div className="md:w-1/3">
              <img 
                src="/images/drterry_menhealth6.png" 
                alt="Dr. Terry Nguyen - Men's Health Specialist in Sydney" 
                className="w-full h-auto rounded-xl shadow-2xl"
              />
            </div>
            <div className="md:w-2/3">
              <span className="text-[#DDC6A8] font-medium mb-2 block">MEET YOUR DOCTOR</span>
              <h2 className="text-3xl font-light text-white mb-6">Dr. Terry Nguyen</h2>
              <p className="text-white/80 mb-4">
                As a dedicated men's health specialist serving Sydney's Eastern Suburbs, I understand that discussing certain health concerns can be challenging for many men. My practice offers a comfortable, confidential environment where your concerns are addressed with compassion and expertise.
              </p>
              <p className="text-white/80 mb-6">
                With specialized training in men's health issues, I provide comprehensive care that acknowledges the unique physiological and psychological aspects of men's wellbeing. From preventative screenings to specialized treatments, my approach is both evidence-based and personalized to your specific needs.
              </p>
              <Link 
                to="/about-dr-terry" 
                className="text-[#DDC6A8] font-medium hover:text-white transition-colors flex items-center"
              >
                Learn more about my approach
                <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      <div className="content-wrapper relative z-10">
        <div className="max-w-6xl mx-auto px-4 py-16 sm:py-24">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-light text-[#DDC6A8] mb-6">Men's Health Services</h1>
            <h2 className="text-2xl font-light text-[#DDC6A8]/80 mb-6">Specialized Care for Men in Sydney's Eastern Suburbs</h2>
            <p className="text-xl text-[#DDC6A8]/80 max-w-3xl mx-auto">
              Confidential, compassionate healthcare designed specifically for men's unique health needs.
              <span className="block mt-4">
                <Link to="/dynamic-booking" className="text-[#DDC6A8] underline font-medium hover:text-white">
                  Book a private consultation
                </Link> with Dr. Terry Nguyen today.
              </span>
            </p>
          </div>
          
          {/* Main Services Grid */}
          <div id="services" className="content-wrapper relative z-10 bg-[#1A1A1A]">
            <div className="max-w-6xl mx-auto px-4 py-16">
              <div className="text-center mb-16">
                <span className="text-[#DDC6A8] font-medium mb-2 block">COMPREHENSIVE CARE</span>
                <h2 className="text-4xl font-light text-white mb-6">Specialized Men's Health Services</h2>
                <p className="text-xl text-white/80 max-w-3xl mx-auto">
                  A comprehensive approach to men's health offering both preventative care and targeted treatments for specific conditions.
                </p>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
                <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:shadow-2xl transition-all duration-300">
                  <div className="h-72 overflow-hidden relative">
                    <img 
                      src="/images/drterry_menshealth3.png" 
                      alt="Comprehensive men's health check in Sydney" 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent opacity-70"></div>
                  </div>
                  <div className="p-8">
                    <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Comprehensive Health Assessments</h3>
                    <p className="text-white/80 mb-6">
                      Complete physical evaluations tailored to men's specific health needs. Early detection is key to managing potential health concerns. Our Sydney clinic provides thorough assessments for men of all ages.
                    </p>
                    <ul className="text-white/80 space-y-3 mb-6">
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Cardiovascular risk assessment</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Prostate health screening</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Metabolic health markers</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Testosterone level evaluation</span>
                      </li>
                    </ul>
                    <Link 
                      to="/comprehensive-health-check" 
                      className="group inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
                    >
                      Learn More
                      <svg className="w-5 h-5 ml-2 transform transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
                
                <div className="bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl group hover:shadow-2xl transition-all duration-300">
                  <div className="h-72 overflow-hidden relative">
                    <img 
                      src="/images/drterry_menshealth2.png" 
                      alt="Men's mental health services in Sydney" 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1A1A1A] to-transparent opacity-70"></div>
                  </div>
                  <div className="p-8">
                    <h3 className="text-2xl font-light text-[#DDC6A8] mb-4">Mental Health & Wellbeing</h3>
                    <p className="text-white/80 mb-6">
                      Confidential support for mental health concerns specific to men. Our Eastern Suburbs practice provides a safe space to discuss stress, anxiety, depression, and work-life balance.
                    </p>
                    <ul className="text-white/80 space-y-3 mb-6">
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Stress and burnout management</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Depression and anxiety treatment</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Executive health coaching</span>
                      </li>
                      <li className="flex items-start">
                        <svg className="flex-shrink-0 h-6 w-6 text-[#DDC6A8] mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <span className="ml-3">Work-life balance guidance</span>
                      </li>
                    </ul>
                    <Link 
                      to="/mental-health" 
                      className="group inline-flex items-center px-6 py-3 border border-[#DDC6A8] text-[#DDC6A8] rounded-md hover:bg-[#DDC6A8] hover:text-[#1A1A1A] transition-all"
                    >
                      Learn More
                      <svg className="w-5 h-5 ml-2 transform transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              
              {/* Secondary Services Grid */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
                <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                  <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                    <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Sexual Health</h3>
                  <p className="text-white/80 mb-4">
                    Discreet consultations for sexual health concerns including erectile dysfunction, testosterone levels, and STI screening in our Sydney clinic.
                  </p>
                  <Link to="/dynamic-booking" className="text-[#DDC6A8] inline-flex items-center hover:text-white transition-colors">
                    Book a confidential consultation
                    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </Link>
                </div>
                
                <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                  <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                    <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Sports Medicine & Performance</h3>
                  <p className="text-white/80 mb-4">
                    Specialized care for sports injuries, performance optimization, and rehabilitation for active men across Sydney's Eastern Suburbs.
                  </p>
                  <Link to="/sports-medicine" className="text-[#DDC6A8] inline-flex items-center hover:text-white transition-colors">
                    Learn about performance medicine
                    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </Link>
                </div>
                
                <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                  <div className="w-16 h-16 bg-[#DDC6A8]/20 rounded-full flex items-center justify-center mb-6">
                    <svg className="w-8 h-8 text-[#DDC6A8]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Chronic Disease Management</h3>
                  <p className="text-white/80 mb-4">
                    Comprehensive care for conditions including diabetes, hypertension, and high cholesterol with our Sydney house call doctor service.
                  </p>
                  <Link to="/dynamic-booking" className="text-[#DDC6A8] inline-flex items-center hover:text-white transition-colors">
                    Schedule a consultation
                    <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          
          {/* Testimonial Section */}
          <div className="mb-20">
            <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl overflow-hidden">
              <div className="md:flex">
                <div className="md:w-1/2 relative">
                  <img 
                    src="/images/drterry_menshealth5.png" 
                    alt="Patient testimonial for Dr. Terry Nguyen's men's health services in Sydney" 
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-r from-transparent to-[#1A1A1A] hidden md:block"></div>
                </div>
                <div className="md:w-1/2 p-8 md:p-12 flex items-center">
                  <div>
                    <svg className="w-12 h-12 text-[#DDC6A8]/30 mb-6" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                    </svg>
                    <blockquote className="text-2xl text-white/90 font-light italic mb-6">
                      "Dr. Terry's approach to men's health is refreshingly thorough. For the first time, I felt a doctor really listened and developed a treatment plan specifically for my situation. The convenience of his Eastern Suburbs practice made it easy to prioritize my health."
                    </blockquote>
                    <div className="flex items-center">
                      <div className="ml-3">
                        <p className="text-[#DDC6A8] font-medium">Michael R.</p>
                        <p className="text-white/60 text-sm">Double Bay, Sydney</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* SEO Rich Content Section with Local References */}
          <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 md:p-12 mb-16">
            <div className="md:flex gap-12 items-center">
              <div className="md:w-2/3">
                <span className="text-[#DDC6A8] font-medium mb-2 block">SERVING THE EASTERN SUBURBS</span>
                <h2 className="text-3xl font-light text-white mb-6">Men's Health in Sydney's Eastern Suburbs</h2>
                <div className="text-white/80 space-y-4">
                  <p>
                    At HomeDoc, I understand that men in <strong className="text-white">Sydney's Eastern Suburbs</strong> face unique health challenges. From the high-pressure corporate environments of <strong className="text-white">Double Bay</strong> and <strong className="text-white">Paddington</strong> to the active lifestyles common in <strong className="text-white">Bondi</strong> and <strong className="text-white">Coogee</strong>, my tailored men's health services address the specific needs of our local community.
                  </p>
                  <p>
                    Many men in the Eastern Suburbs put off seeking medical care due to busy schedules or hesitation to discuss sensitive health concerns. My <Link to="/dynamic-booking" className="text-[#DDC6A8] hover:text-white transition-colors">house call doctor service</Link> and extended hours appointments make it convenient to prioritize your health without disrupting your professional commitments.
                  </p>
                  <p>
                    Whether you're an executive in <strong className="text-white">Point Piper</strong>, a fitness enthusiast in <strong className="text-white">Bronte</strong>, or a family man in <strong className="text-white">Woollahra</strong>, I bring specialized men's health expertise directly to you. My comprehensive approach combines preventative screening, lifestyle medicine, and evidence-based treatments to help you achieve optimal health.
                  </p>
                </div>
              </div>
              <div className="md:w-1/3 mt-8 md:mt-0">
                <img 
                  src="/images/drterry_menshealth7.png" 
                  alt="Men's health services in Sydney's Eastern Suburbs" 
                  className="w-full h-auto rounded-xl shadow-lg"
                />
              </div>
            </div>
          </div>

          {/* Prominent CTA */}
          <div className="relative overflow-hidden rounded-xl shadow-2xl mb-20">
            <div className="absolute inset-0">
              <img 
                src="/images/drterry_menshealth4.png" 
                alt="Men's health consultation in Sydney with Dr. Terry Nguyen" 
                className="w-full h-full object-cover brightness-50"
              />
              <div className="absolute inset-0 bg-gradient-to-r from-[#1A1A1A] to-transparent"></div>
            </div>
            <div className="relative p-8 md:p-12">
              <div className="md:max-w-xl">
                <h2 className="text-3xl font-light text-white mb-4">Ready to prioritize your health?</h2>
                <p className="text-xl text-white/80 mb-8">
                  Book a confidential men's health consultation with Dr. Terry Nguyen in Sydney's Eastern Suburbs. House calls, telehealth, and clinic appointments available.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Link
                    to="/dynamic-booking"
                    className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
                  >
                    Book a Men's Health Consultation
                  </Link>
                  <Link
                    to="/telemedicine"
                    className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#DDC6A8] hover:bg-[#DDC6A8]/10 transition"
                  >
                    Learn About Telehealth Options
                  </Link>
                </div>
              </div>
            </div>
          </div>
          
          {/* FAQ Section for SEO */}
          <div className="mb-20">
            <div className="text-center mb-12">
              <span className="text-[#DDC6A8] font-medium mb-2 block">COMMON QUESTIONS</span>
              <h2 className="text-3xl font-light text-white mb-6">Frequently Asked Questions</h2>
              <p className="text-white/80 max-w-3xl mx-auto">
                Answers to common questions about men's health services in Sydney's Eastern Suburbs.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">How often should men have a comprehensive health check?</h3>
                <p className="text-white/80">
                  I recommend annual comprehensive health checks for men over 40 in Sydney, and every 2-3 years for men under 40 with no health concerns. More frequent assessments may be advised based on family history, lifestyle factors, or existing health conditions.
                </p>
              </div>
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">What specific health issues affect men in Sydney's Eastern Suburbs?</h3>
                <p className="text-white/80">
                  Men in Sydney's Eastern Suburbs commonly face health challenges related to high-stress corporate environments, including cardiovascular risks, sleep disorders, and mental health concerns. Active lifestyle injuries, skin damage from beach activities, and diet-related issues are also prevalent in our local community.
                </p>
              </div>
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">Are telehealth appointments available for men's health consultations?</h3>
                <p className="text-white/80">
                  Yes, I offer secure and confidential telehealth consultations for many men's health concerns. This service is particularly popular among busy professionals in Paddington, Double Bay, and surrounding areas. Initial consultations, follow-ups, mental health support, and certain screenings can all be conducted via our <Link to="/telemedicine" className="text-[#DDC6A8] hover:text-white transition-colors">telehealth platform</Link>.
                </p>
              </div>
              <div className="bg-[#2A2A2A]/30 backdrop-blur-sm rounded-xl p-8 hover:bg-[#2A2A2A]/50 transition-all duration-300">
                <h3 className="text-xl font-light text-[#DDC6A8] mb-4">How confidential are men's health consultations?</h3>
                <p className="text-white/80">
                  All consultations at HomeDoc are strictly confidential. I understand the sensitive nature of many men's health concerns and maintain the highest standards of privacy. Your medical information is protected by doctor-patient confidentiality and secured in accordance with Australian privacy laws.
                </p>
              </div>
            </div>
          </div>

          {/* Call to Action */}
          <div className="text-center mb-20">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-8 py-4 border border-[#DDC6A8] text-base font-medium rounded-md text-[#1A1A1A] bg-[#DDC6A8] hover:bg-[#DDC6A8]/90 transition shadow-lg hover:shadow-xl"
            >
              Book a Men's Health Consultation
            </Link>
          </div>
        </div>
      </div>
      
      {/* Related Articles Carousel */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <span className="text-indigo-600 font-medium mb-2 block">HEALTH INSIGHTS</span>
            <h2 className="text-3xl font-bold text-gray-900 mb-6">Men's Health Articles</h2>
            <p className="text-gray-600 max-w-3xl mx-auto">
              Evidence-based articles on men's health issues, treatment options, and preventative strategies.
            </p>
          </div>
          <CategoryBlogCarousel category="Men's Health" limit={4} />
        </div>
      </div>
    </div>
  );
};

export default MensHealth; 