import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const UrgentCareAtHome = () => {
  // SEO metadata
  const seoTitle = "Urgent Care at Home in Sydney: What Can Be Treated Without Hospital | HomeDoc";
  const seoDescription = "Learn which urgent medical conditions can be safely treated at home by a house call doctor in Sydney. Expert advice on when to stay home vs. when to go to hospital.";
  const seoKeywords = "urgent care at home Sydney, house call doctor Eastern Suburbs, after hours GP Bondi, mobile doctor Sydney, avoid hospital visit, medical emergency at home, urgent care without hospital, Dr Terry Nguyen";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Urgent Care at Home in Sydney: What Can Be Treated Without Going to Hospital</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              When urgent medical concerns arise, many Sydney residents automatically think they need to visit a hospital emergency department. However, a significant number of urgent medical conditions can be safely and effectively treated in the comfort of your own home by a qualified house call doctor—saving you hours of waiting, reducing exposure to other illnesses, and alleviating the stress of hospital visits.
            </p>
            <p className="text-gray-700 mb-4">
              As <Link to="/about" className="text-indigo-600 hover:text-indigo-800 font-medium">Dr. Terry Nguyen</Link>, a house call doctor serving <strong>Sydney&apos;s Eastern Suburbs since 2018</strong>, I&apos;ve successfully treated thousands of patients with urgent medical needs in their homes across <strong>Bondi, Randwick, Double Bay, Woollahra</strong> and surrounding suburbs. This specialized mobile medical service provides prompt medical care without the need for emergency department visits for many non-life-threatening but urgent conditions.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/urgent-care-at-home-sydney.png" 
                alt="House call doctor providing urgent care at patient's home in Sydney" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                Urgent medical care delivered in the comfort of your home in Sydney
              </figcaption>
            </figure>
          </div>
        </div>
        <p className="text-gray-700 mb-6">
          This comprehensive guide details which urgent medical conditions can be safely managed through <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">house call doctor services</Link> in Sydney, when hospital care is necessary, and how to access rapid medical care when you need it. Based on Australian medical guidelines and my extensive experience providing house call services throughout Sydney&apos;s Eastern Suburbs, this information will help you make informed decisions during urgent health situations.
        </p>
      </section>
      
      <section id="common-conditions">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Common Urgent Conditions Treatable at Home in Sydney</h2>
        <p className="text-gray-700 mb-6">
          Many urgent medical conditions that prompt Sydneysiders to visit emergency departments can actually be managed safely and effectively through a house call doctor visit. Based on data from the Sydney Eastern Suburbs house call service and aligned with Royal Australian College of General Practitioners (RACGP) guidelines, here are the most common urgent conditions suitable for at-home treatment:
        </p>
        
        <div className="bg-indigo-50 rounded-lg p-6 mb-8">
          <h3 className="font-bold text-indigo-800 mb-4 text-lg">Most Common Urgent Conditions Treated by House Call Doctors in Sydney</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li><strong>Respiratory infections</strong> (bronchitis, sinusitis, pneumonia)</li>
              <li><strong>Gastrointestinal issues</strong> (gastroenteritis, food poisoning)</li>
              <li><strong>Urinary tract infections</strong> and kidney infections</li>
              <li><strong>Skin infections</strong> (cellulitis, abscesses, infected wounds)</li>
              <li><strong>Acute pain</strong> (back pain, migraine, dental pain)</li>
              <li><strong>Ear infections</strong> in adults and children</li>
            </ul>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li><strong>Allergic reactions</strong> (non-anaphylactic)</li>
              <li><strong>Mild-moderate asthma</strong> exacerbations</li>
              <li><strong>Minor injuries</strong> requiring assessment or treatment</li>
              <li><strong>Dehydration</strong> requiring assessment and management</li>
              <li><strong>Viral illnesses</strong> with concerning symptoms</li>
              <li><strong>Exacerbations of chronic conditions</strong></li>
            </ul>
          </div>
        </div>
        
        <p className="text-gray-700 mb-4">
          Sydney residents are often surprised to learn that many conditions they might have gone to hospital for can be managed effectively at home. According to data from the Australian Institute of Health and Welfare, up to 40% of emergency department presentations could potentially be managed in primary care settings, including house call services.
        </p>
        
        <p className="text-gray-700 mb-6">
          Let&apos;s explore the most common categories of urgent conditions that can be safely treated at home by a house call doctor in Sydney&apos;s Eastern Suburbs, from Bondi to Double Bay and surrounding areas.
        </p>
      </section>
      
      <section id="respiratory-conditions">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Respiratory Infections & Breathing Issues</h2>
        <p className="text-gray-700 mb-4">
          Respiratory concerns are among the most common reasons Sydneysiders seek urgent care, particularly during winter months and change of seasons. Many respiratory conditions that feel severe enough to warrant a hospital visit can actually be effectively managed by a house call doctor, similar to how we approach <Link to="/blog/marine-injuries-sydney" className="text-indigo-600 hover:text-indigo-800">treatment for other urgent conditions</Link>.
        </p>
        
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">Treatable Respiratory Conditions</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Upper Respiratory Infections</h4>
            <p className="text-gray-700 mb-3">
              Includes sinusitis, pharyngitis, tonsillitis, and severe colds with concerning symptoms.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Thorough examination of ears, throat, sinuses</li>
                <li>Prescription of appropriate antibiotics when bacterial infection is suspected</li>
                <li>Prescription of targeted symptom relief medications</li>
                <li>Advice on home management strategies</li>
                <li>Assessment of red flags requiring hospital referral</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Lower Respiratory Infections</h4>
            <p className="text-gray-700 mb-3">
              Includes bronchitis, community-acquired pneumonia (mild to moderate), and exacerbations of asthma or COPD.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Comprehensive chest examination and vital signs monitoring</li>
                <li>Oxygen saturation testing</li>
                <li>Administration of nebulizer treatments when needed</li>
                <li>Prescription of appropriate antibiotics and/or steroids</li>
                <li>Development of management plan with clear return criteria</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-yellow-50 rounded-lg p-6 mb-6 border-l-4 border-yellow-500">
          <h4 className="font-bold text-yellow-800 mb-2">When to Go to Hospital Instead:</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Severe shortness of breath where you struggle to speak in full sentences</li>
            <li>Bluish discoloration of lips or face</li>
            <li>Oxygen saturation below 92% (if you have a pulse oximeter)</li>
            <li>Coughing up significant amounts of blood</li>
            <li>Chest pain that's severe or crushing in nature</li>
            <li>Severe asthma attack not responding to reliever medication</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-6">
          Sydney's Eastern Suburbs house call doctors are equipped with medications, nebulizers, and diagnostic equipment to effectively treat most respiratory conditions that don't require hospitalization. Using the Australian Therapeutic Guidelines, we can provide appropriate antibiotic therapy when necessary and develop management plans aligned with current best practices.
        </p>
      </section>
      
      <section id="gi-issues">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Gastrointestinal Problems</h2>
        <p className="text-gray-700 mb-4">
          Acute gastrointestinal (GI) issues can be extremely uncomfortable and often prompt Sydney residents to seek emergency care, particularly after hours when regular GP clinics are closed. Many of these conditions can be effectively managed at home by a house call doctor, providing relief without the need for a hospital visit.
        </p>
        
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">Treatable Gastrointestinal Conditions</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Gastroenteritis & Food Poisoning</h4>
            <p className="text-gray-700 mb-3">
              Includes viral and bacterial gastroenteritis, food poisoning, and associated dehydration.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Assess hydration status and severity</li>
                <li>Administer anti-nausea injections for severe vomiting</li>
                <li>Prescribe appropriate medications for symptom control</li>
                <li>Provide oral rehydration guidance</li>
                <li>Arrange follow-up care if needed</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Abdominal Pain & Related Conditions</h4>
            <p className="text-gray-700 mb-3">
              Includes uncomplicated urinary tract infections, mild diverticulitis, IBS flares, and non-severe abdominal pain.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Comprehensive abdominal examination</li>
                <li>Urinalysis for suspected UTIs</li>
                <li>Prescription of appropriate antibiotics when indicated</li>
                <li>Pain management with appropriate medications</li>
                <li>Referral for imaging or specialist care when needed</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-yellow-50 rounded-lg p-6 mb-6 border-l-4 border-yellow-500">
          <h4 className="font-bold text-yellow-800 mb-2">When to Go to Hospital Instead:</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Severe, persistent abdominal pain, especially if localized to the right lower quadrant</li>
            <li>Significant blood in vomit or stool</li>
            <li>Signs of severe dehydration (very dry mouth, minimal urination, extreme dizziness)</li>
            <li>Abdominal pain with fever over 39°C</li>
            <li>Rigid, board-like abdomen or severe tenderness with guarding</li>
            <li>Recent abdominal trauma</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-6">
          For residents in Bondi, Randwick, Double Bay and other Eastern Suburbs areas, house call doctors can effectively manage most gastrointestinal conditions that fall below the threshold for hospital admission. Our doctors carry anti-nausea medications, pain relief, and can prescribe appropriate treatments while monitoring for any signs that would require escalation of care.
        </p>
      </section>
      
      <section id="skin-conditions">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Skin Infections & Wounds</h2>
        <p className="text-gray-700 mb-4">
          Skin infections, wounds, and related concerns are ideally suited for house call doctor management. In fact, for many skin conditions, at-home care can actually be preferable to hospital care as it reduces the risk of exposure to hospital-acquired infections. For more information on wound care principles, you can also see our article on <Link to="/blog/childhood-skin-conditions" className="text-indigo-600 hover:text-indigo-800">treating skin conditions and wounds</Link>.
        </p>
        
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">Treatable Skin & Wound Conditions</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Skin Infections</h4>
            <p className="text-gray-700 mb-3">
              Includes cellulitis, infected wounds, boils, abscesses, and mild to moderate bacterial skin infections.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Thorough assessment of the infection's extent and severity</li>
                <li>Incision and drainage of small abscesses when appropriate</li>
                <li>Prescription of appropriate antibiotics based on Australian guidelines</li>
                <li>Wound care and dressing</li>
                <li>Arrangement of follow-up care when needed</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Wounds & Minor Injuries</h4>
            <p className="text-gray-700 mb-3">
              Includes lacerations requiring closure, wound assessment, foreign body removal, and minor burns.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Wound cleaning and assessment</li>
                <li>Local anesthesia administration</li>
                <li>Suturing of appropriate wounds</li>
                <li>Application of skin adhesive or Steri-strips</li>
                <li>Tetanus prophylaxis when indicated</li>
                <li>Detailed wound care instructions</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-yellow-50 rounded-lg p-6 mb-6 border-l-4 border-yellow-500">
          <h4 className="font-bold text-yellow-800 mb-2">When to Go to Hospital Instead:</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Very deep wounds, especially those affecting tendons, nerves, or arteries</li>
            <li>Rapidly spreading infection with high fever or systemic symptoms</li>
            <li>Burns that are deep, large (greater than 5% body surface area), or on the face/hands/genitals</li>
            <li>Wounds with embedded large foreign bodies</li>
            <li>Animal bites to the face or hands</li>
            <li>Signs of necrotizing fasciitis (severe pain out of proportion to appearance, rapid spreading, skin discoloration)</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-6">
          House call doctors in Sydney's Eastern Suburbs come equipped with wound care supplies, suturing kits, and the necessary tools to manage most skin and wound emergencies. This is particularly valuable for residents in beachside areas like Bondi, Bronte and Coogee where marine stings, cuts from rock platforms, and beach-related injuries are common.
        </p>
      </section>
      
      <section id="pain-management">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Pain Management</h2>
        <p className="text-gray-700 mb-4">
          Acute pain is one of the most common reasons Sydney residents seek urgent medical care, particularly after hours. House call doctors can effectively manage many painful conditions, providing assessment, diagnosis, and treatment in the comfort of your home.
        </p>
        
        <h3 className="text-xl font-bold text-gray-800 mt-8 mb-4">Treatable Painful Conditions</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Musculoskeletal Pain</h4>
            <p className="text-gray-700 mb-3">
              Includes acute back pain, neck pain, joint pain, sprains, strains, and non-displaced fractures.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Comprehensive physical examination</li>
                <li>Prescription of appropriate pain relief medications</li>
                <li>Initial management with splinting when appropriate</li>
                <li>Referral for imaging when indicated</li>
                <li>Development of a management plan</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Headaches & Neuralgic Pain</h4>
            <p className="text-gray-700 mb-3">
              Includes migraines, cluster headaches, tension headaches, and nerve pain exacerbations.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Neurological assessment</li>
                <li>Administration of acute migraine treatments</li>
                <li>Prescription of appropriate pain medications</li>
                <li>Trigger identification and management advice</li>
                <li>Follow-up planning with your regular GP or specialist</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Dental Pain</h4>
            <p className="text-gray-700 mb-3">
              Includes severe toothache, dental abscess, post-extraction pain, and temporomandibular joint pain.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Assessment of dental issues causing pain</li>
                <li>Prescription of appropriate pain medications</li>
                <li>Antibiotics for dental infections when indicated</li>
                <li>Temporary management until dental care can be arranged</li>
                <li>Referral to emergency dental services when needed</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h4 className="font-bold text-indigo-700 mb-3">Other Painful Conditions</h4>
            <p className="text-gray-700 mb-3">
              Includes renal colic (kidney stones), ear pain, gout flares, and post-operative pain.
            </p>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h5 className="font-bold text-blue-800 mb-2">What Your House Call Doctor Can Do:</h5>
              <ul className="list-disc pl-5 text-gray-700 space-y-1">
                <li>Condition-specific assessment</li>
                <li>Targeted pain management strategies</li>
                <li>Anti-inflammatory medications when appropriate</li>
                <li>Coordination with your usual healthcare providers</li>
                <li>Clear guidance on when to escalate care if needed</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="bg-yellow-50 rounded-lg p-6 mb-6 border-l-4 border-yellow-500">
          <h4 className="font-bold text-yellow-800 mb-2">When to Go to Hospital Instead:</h4>
          <ul className="list-disc pl-5 text-gray-700 space-y-2">
            <li>Sudden, severe headache described as "worst ever" (possible subarachnoid hemorrhage)</li>
            <li>Chest pain, especially if crushing or radiating to arm/jaw (possible heart attack)</li>
            <li>Pain after significant trauma or with obvious deformity (possible fracture)</li>
            <li>Abdominal pain with rigid abdomen or signs of peritonitis</li>
            <li>Severe pain with altered consciousness or confusion</li>
            <li>Pain accompanied by paralysis, weakness, or other neurological symptoms</li>
          </ul>
        </div>
        
        <p className="text-gray-700 mb-6">
          For Eastern Suburbs residents, having a house call doctor assess and treat painful conditions at home can be particularly beneficial for those who would find travel difficult, such as the elderly or those with mobility issues. Our doctors carry appropriate medications for pain relief and can develop management plans to address both immediate pain and underlying causes.
        </p>
      </section>
      
      <section id="hospital-vs-house-call">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">When to Go to Hospital vs. Call a House Doctor</h2>
        <p className="text-gray-700 mb-6">
          Making the right decision about where to seek care can be challenging during urgent medical situations. Based on Australian clinical guidelines and extensive experience with house call medicine in Sydney's Eastern Suburbs, here's a clear guide to help you decide:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="bg-red-50 rounded-lg p-6 shadow-md">
            <h3 className="text-xl font-bold text-red-800 mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
              </svg>
              Go to Hospital/Call 000 For:
            </h3>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li><strong>Chest pain</strong> or symptoms suggesting heart attack</li>
              <li><strong>Stroke symptoms</strong> (FAST: facial drooping, arm weakness, speech difficulties, time to call 000)</li>
              <li><strong>Severe breathing difficulty</strong> where you can't speak in full sentences</li>
              <li><strong>Major trauma</strong> or serious injuries</li>
              <li><strong>Severe bleeding</strong> that won't stop with pressure</li>
              <li><strong>Loss of consciousness</strong> or significant altered mental state</li>
              <li><strong>Severe allergic reactions</strong> (anaphylaxis)</li>
              <li><strong>Poisoning</strong> or overdose</li>
              <li><strong>Suicidal thoughts</strong> with immediate plan or intent</li>
              <li><strong>Sudden, severe headache</strong> described as "worst ever"</li>
              <li><strong>Seizures</strong>, especially if prolonged or first-time</li>
              <li><strong>Sudden severe abdominal pain</strong> with rigid abdomen</li>
              <li>Any situation where you feel the condition is <strong>immediately life-threatening</strong></li>
            </ul>
          </div>
          
          <div className="bg-green-50 rounded-lg p-6 shadow-md">
            <h3 className="text-xl font-bold text-green-800 mb-4 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>
              Suitable for House Call Doctor:
            </h3>
            <ul className="list-disc pl-5 text-gray-700 space-y-2">
              <li><strong>Infections</strong> requiring assessment (respiratory, skin, urinary)</li>
              <li><strong>Fever</strong> that's concerning but not extreme (below 39.5°C)</li>
              <li><strong>Vomiting and diarrhea</strong> with mild-moderate dehydration</li>
              <li><strong>Minor injuries</strong> needing assessment or treatment</li>
              <li><strong>Moderate pain</strong> requiring diagnosis and management</li>
              <li><strong>Skin infections</strong> without severe systemic symptoms</li>
              <li><strong>Asthma</strong> exacerbations (mild to moderate)</li>
              <li><strong>Allergic reactions</strong> without airway compromise</li>
              <li><strong>Urinary tract infections</strong> and symptoms</li>
              <li><strong>Acute ear pain or infections</strong></li>
              <li><strong>Minor procedures</strong> like wound care or abscess drainage</li>
              <li><strong>Medication issues</strong> requiring urgent attention</li>
              <li><strong>Medical certificates</strong> for acute illness</li>
            </ul>
          </div>
        </div>
        
        <div className="bg-blue-50 p-6 rounded-lg mb-6">
          <h3 className="text-lg font-bold text-blue-800 mb-3">When In Doubt: Phone Triage</h3>
          <p className="text-gray-700 mb-3">
            If you're unsure whether your situation is suitable for a house call or requires hospital care, our 24/7 medical phone triage service can help assess your needs. Our trained staff can:
          </p>
          <ul className="list-disc pl-5 text-gray-700 space-y-1">
            <li>Ask appropriate clinical questions to determine urgency</li>
            <li>Advise whether a house call doctor is appropriate for your condition</li>
            <li>Direct you to emergency services if your condition requires it</li>
            <li>Provide interim advice while a doctor is on the way</li>
          </ul>
          <p className="text-gray-700 mt-3">
            Call our 24/7 triage line at <a href="tel:+61290000000" className="text-indigo-600 hover:text-indigo-800 font-medium">02 9000 0000</a> for immediate assistance.
          </p>
        </div>
      </section>
      
      <section id="benefits">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">Benefits of At-Home Urgent Care in Sydney</h2>
        <p className="text-gray-700 mb-6">
          For many urgent but non-life-threatening conditions, receiving medical care at home from a qualified house call doctor offers significant advantages for Sydney residents, particularly those in the Eastern Suburbs:
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-indigo-600 px-4 py-2">
              <h3 className="text-lg font-bold text-white">Convenience & Comfort</h3>
            </div>
            <div className="p-5">
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>No traveling when you're feeling unwell</li>
                <li>No exposure to other illnesses in waiting rooms</li>
                <li>Comfortable familiar environment reduces stress</li>
                <li>Particularly beneficial for elderly, children, or those with mobility issues</li>
                <li>No need for parking or transportation arrangements</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-indigo-600 px-4 py-2">
              <h3 className="text-lg font-bold text-white">Time & Efficiency</h3>
            </div>
            <div className="p-5">
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Average wait time of 30-60 minutes in Sydney's Eastern Suburbs</li>
                <li>No emergency department waiting (average 4-6+ hours for non-critical cases)</li>
                <li>Dedicated one-on-one doctor time</li>
                <li>Electronic prescriptions sent directly to your pharmacy</li>
                <li>Medical certificates provided on the spot</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-indigo-600 px-4 py-2">
              <h3 className="text-lg font-bold text-white">Quality Care</h3>
            </div>
            <div className="p-5">
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Qualified doctors with emergency and general practice experience</li>
                <li>Comprehensive medical kits with essential equipment</li>
                <li>Access to your home environment provides valuable clinical context</li>
                <li>More time for thorough assessment and explanation</li>
                <li>Coordination with your regular healthcare providers</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-indigo-600 px-4 py-2">
              <h3 className="text-lg font-bold text-white">Accessibility</h3>
            </div>
            <div className="p-5">
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Available 24/7, including after hours, weekends and public holidays</li>
                <li>Accessible to those who can't easily travel (elderly, disabled, parents with young children)</li>
                <li>Service throughout Sydney's Eastern Suburbs including Bondi, Randwick, Double Bay, Vaucluse, Rose Bay, and Paddington</li>
                <li>Bulk-billed for Medicare card holders with no out-of-pocket expenses</li>
                <li>Telehealth options available for initial assessment</li>
              </ul>
            </div>
          </div>
          
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="bg-indigo-600 px-4 py-2">
              <h3 className="text-lg font-bold text-white">System Benefits</h3>
            </div>
            <div className="p-5">
              <ul className="list-disc pl-5 text-gray-700 space-y-2">
                <li>Reduces emergency department overcrowding</li>
                <li>Frees hospital resources for true emergencies</li>
                <li>Lower healthcare costs for the system overall</li>
                <li>Reduces ambulance utilization for non-emergency transport</li>
                <li>Improves continuity of care with detailed records sent to regular GP</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      
      <section id="how-it-works">
        <h2 className="text-2xl font-bold text-gray-900 mt-10 mb-4">How Sydney House Call Doctors Provide Urgent Care</h2>
        <p className="text-gray-700 mb-6">
          At HomeDoc, our house call doctors serving Sydney&apos;s Eastern Suburbs provide comprehensive urgent care through a streamlined process designed to deliver quality medical care when and where you need it:
        </p>
        
        <div className="space-y-4 mb-8">
          <div className="flex items-start">
            <div className="bg-indigo-600 rounded-full p-3 mt-1 mr-4 flex-shrink-0">
              <span className="text-white font-bold">1</span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Booking & Triage</h3>
              <p className="text-gray-700">
                Call our 24/7 booking line at <a href="tel:+61290000000" className="text-indigo-600 hover:text-indigo-800 font-medium">02 9000 0000</a>, book online through our <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">secure booking system</Link>, or use our mobile app. Our medical triage team will collect essential information about your condition to ensure appropriate prioritization and preparation.
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="bg-indigo-600 rounded-full p-3 mt-1 mr-4 flex-shrink-0">
              <span className="text-white font-bold">2</span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Doctor Dispatch & Arrival</h3>
              <p className="text-gray-700">
                Based on your location in Sydney&apos;s Eastern Suburbs and the nature of your condition, the closest available doctor will be dispatched to your home. You&apos;ll receive real-time updates on their estimated arrival time. Our doctors carry comprehensive medical kits equipped to handle a wide range of urgent conditions.
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="bg-indigo-600 rounded-full p-3 mt-1 mr-4 flex-shrink-0">
              <span className="text-white font-bold">3</span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Clinical Assessment</h3>
              <p className="text-gray-700">
                Upon arrival, your doctor will take a comprehensive medical history and perform a thorough examination relevant to your condition. Our house call doctors have access to portable diagnostic equipment including stethoscopes, otoscopes, blood pressure monitors, pulse oximeters, ECG machines, and point-of-care testing for conditions like urinary tract infections.
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="bg-indigo-600 rounded-full p-3 mt-1 mr-4 flex-shrink-0">
              <span className="text-white font-bold">4</span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Treatment & Medications</h3>
              <p className="text-gray-700">
                Based on your assessment, your doctor will provide appropriate treatment which may include prescription medications, administering injections, performing procedures like wound care or abscess drainage, or providing nebulizer treatments for respiratory conditions. Eastern Suburbs house call doctors carry commonly needed medications and can also send electronic prescriptions to your preferred pharmacy.
              </p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="bg-indigo-600 rounded-full p-3 mt-1 mr-4 flex-shrink-0">
              <span className="text-white font-bold">5</span>
            </div>
            <div>
              <h3 className="text-xl font-bold text-gray-800 mb-2">Follow-Up Planning</h3>
              <p className="text-gray-700">
                Your doctor will provide clear instructions for ongoing care, including when to seek additional medical attention if needed. With your permission, detailed clinical notes will be sent to your regular GP to ensure continuity of care. For conditions requiring monitoring, we can arrange follow-up visits or telehealth consultations.
              </p>
            </div>
          </div>
        </div>
        
        <div className="bg-indigo-50 p-6 rounded-lg mb-8">
          <h3 className="text-lg font-bold text-indigo-800 mb-3">What Our House Call Doctors Bring</h3>
          <p className="text-gray-700 mb-3">
            HomeDoc doctors serving Sydney&apos;s Eastern Suburbs carry well-equipped medical kits that allow them to handle a wide range of urgent conditions, including:
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Diagnostic equipment</li>
              <li>Minor surgical instruments</li>
              <li>Wound care supplies</li>
              <li>Common injectable medications</li>
              <li>Nebulizers for respiratory conditions</li>
            </ul>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>IV fluids for dehydration</li>
              <li>Pain management medications</li>
              <li>Antibiotics for initial doses</li>
              <li>Point-of-care testing kits</li>
              <li>Suturing and splinting materials</li>
            </ul>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Portable ECG machine</li>
              <li>Pulse oximeter</li>
              <li>Electronic prescription system</li>
              <li>Secure medical records access</li>
              <li>Emergency medications</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="cta" className="my-12">
        <div className="bg-gradient-to-r from-blue-600 to-indigo-700 rounded-xl shadow-xl overflow-hidden">
          <div className="px-6 py-12 md:p-12 text-center md:text-left flex flex-col md:flex-row items-center">
            <div className="md:w-2/3 mb-8 md:mb-0 md:pr-8">
              <h2 className="text-3xl font-bold text-white mb-4">Need Urgent Medical Care in Sydney?</h2>
              <p className="text-blue-100 text-lg mb-6">
                If you're experiencing an urgent medical condition in Sydney&apos;s Eastern Suburbs, our house call doctors can provide expert care at your home—usually within 30-60 minutes. Save time, avoid hospital waiting rooms, and receive quality care in the comfort of your own space.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4">
                <Link 
                  to="/dynamic-booking" 
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-white hover:bg-indigo-50 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Book House Call Now
                </Link>
                <Link 
                  to="/telemedicine" 
                  className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 bg-opacity-60 hover:bg-opacity-70 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
                >
                  Video Consultation
                </Link>
              </div>
            </div>
            <div className="md:w-1/3 flex items-center justify-center">
              <div className="bg-white p-2 rounded-full">
                <div className="bg-blue-100 rounded-full p-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 text-indigo-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-indigo-800 px-6 py-4">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              <p className="text-indigo-200 text-sm mb-4 sm:mb-0">
                <span className="font-bold">Medicare Bulk Billed</span> - No out-of-pocket expenses
              </p>
              <a 
                href="tel:+61290000000" 
                className="inline-flex items-center space-x-2 text-white hover:text-blue-100 transition"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                </svg>
                <span className="font-medium">Call 24/7: 02 9000 0000</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      
      <section id="faq" className="mt-12 border-t border-gray-200 pt-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Frequently Asked Questions about Urgent Care at Home</h2>
        
        <div className="space-y-6">
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Which urgent conditions can be treated at home instead of going to hospital?</h3>
            <p className="text-gray-700">
              Many non-life-threatening urgent conditions can be treated at home by a house call doctor, including: respiratory infections (bronchitis, sinusitis, mild pneumonia), gastrointestinal issues, urinary tract infections, skin infections and cellulitis, acute pain (back pain, migraines), ear infections, non-severe allergic reactions, mild-moderate asthma flare-ups, minor injuries needing assessment, and dehydration requiring treatment.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">How quickly can a house call doctor arrive at my home in Sydney&apos;s Eastern Suburbs?</h3>
            <p className="text-gray-700">
              In Bondi, Randwick, Double Bay, and surrounding Eastern Suburbs areas, our house call doctors typically arrive within 30-60 minutes of booking. During peak periods, wait times may be longer but still significantly shorter than most emergency department waiting times. For urgent conditions requiring immediate attention, our triage process ensures priority response.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">When should I go to hospital instead of calling a house doctor?</h3>
            <p className="text-gray-700">
              You should call 000 or go directly to hospital for: chest pain or symptoms of heart attack, stroke symptoms (facial drooping, arm weakness, speech difficulties), severe breathing difficulty, major trauma or serious injuries, severe bleeding, loss of consciousness, severe allergic reactions (anaphylaxis), poisoning, severe mental health crises, and any situation where you feel the condition is immediately life-threatening.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Are house call doctor services in Sydney covered by Medicare?</h3>
            <p className="text-gray-700">
              Yes, our urgent care house call services throughout Sydney&apos;s Eastern Suburbs are bulk-billed for Medicare card holders, meaning there are no out-of-pocket expenses. We provide this service 24/7, including after-hours, weekends, and public holidays at no additional cost to Medicare card holders. For international visitors, we offer competitive rates and can provide documentation for travel insurance claims.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">What treatments can a house call doctor provide for urgent care at home?</h3>
            <p className="text-gray-700">
              House call doctors can provide comprehensive urgent care treatments including: clinical examinations, prescribing medications (including antibiotics and pain relief), administering medications via injection when needed, providing IV fluids for dehydration, performing minor procedures (wound care, abscess drainage, suturing), arranging pathology tests, providing medical certificates, and coordinating follow-up care with specialists when needed.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">How do I book a house call doctor in Sydney&apos;s Eastern Suburbs?</h3>
            <p className="text-gray-700">
              You can book a house call doctor in Sydney&apos;s Eastern Suburbs through our website booking system at homedoc.com.au, by calling our 24/7 booking line at 02 9000 0000, or via our mobile app. Our service covers Bondi, Randwick, Double Bay, Woollahra, and all surrounding Eastern Suburbs areas with typical arrival times of 30-60 minutes.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">Can house call doctors provide prescriptions?</h3>
            <p className="text-gray-700">
              Yes, HomeDoc house call doctors can write prescriptions for all necessary medications, including antibiotics, pain relief, and other urgent treatments. We use electronic prescribing, which means your prescription can be sent directly to your preferred pharmacy in Sydney&apos;s Eastern Suburbs, making the process more efficient, especially after hours when many pharmacies have limited operations.
            </p>
          </div>
          
          <div className="bg-white shadow rounded-lg p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-3">What areas of Sydney do your house call doctors cover?</h3>
            <p className="text-gray-700">
              Our house call doctors service all of Sydney&apos;s Eastern Suburbs, including but not limited to: Bondi, Bondi Junction, North Bondi, Bronte, Tamarama, Clovelly, Randwick, Coogee, Maroubra, Double Bay, Rose Bay, Vaucluse, Point Piper, Woollahra, Paddington, Darlinghurst, and surrounding areas. We can confirm service availability for your specific location when you call to book.
            </p>
          </div>
        </div>
      </section>
      
      <section id="conclusion" className="my-12">
        <div className="bg-gradient-to-r from-indigo-50 to-blue-50 rounded-lg p-8 shadow-md">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">Conclusion: Making Informed Decisions About Urgent Care</h2>
          <p className="text-gray-700 mb-4">
            For many urgent medical conditions, Sydney residents now have a practical alternative to long emergency department waits. House call doctors can safely and effectively treat a wide range of conditions in the comfort of your home throughout the Eastern Suburbs, from Bondi to Double Bay and surrounding areas.
          </p>
          <p className="text-gray-700 mb-4">
            Understanding which conditions are appropriate for at-home treatment versus those that require emergency department care is crucial for making informed healthcare decisions. This guide has outlined the most common conditions suitable for house call management, the benefits of at-home urgent care, and clear guidance on when hospital care is necessary.
          </p>
          <p className="text-gray-700 mb-4">
            As a house call doctor serving Sydney&apos;s Eastern Suburbs since 2018, I&apos;ve seen firsthand how this service can provide timely, high-quality care while reducing unnecessary emergency department visits. Our house call doctors are equipped with the tools, medications, and expertise to handle a wide range of urgent conditions, providing Eastern Suburbs residents with convenient access to medical care whenever they need it.
          </p>
          <div className="mt-6">
            <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center">
              Book a house call doctor now
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
              </svg>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
  
  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'Common Urgent Conditions Treatable at Home', anchor: 'common-conditions' },
    { title: 'Respiratory Infections & Breathing Issues', anchor: 'respiratory-conditions' },
    { title: 'Gastrointestinal Problems', anchor: 'gi-issues' },
    { title: 'Skin Infections & Wounds', anchor: 'skin-conditions' },
    { title: 'Pain Management', anchor: 'pain-management' },
    { title: 'When to Go to Hospital vs. Call a House Doctor', anchor: 'hospital-vs-house-call' },
    { title: 'Benefits of At-Home Urgent Care in Sydney', anchor: 'benefits' },
    { title: 'How Sydney House Call Doctors Provide Urgent Care', anchor: 'how-it-works' },
    { title: 'Frequently Asked Questions about Urgent Care at Home', anchor: 'faq' }
  ];

  const symptoms = [
    'Fever above 38°C that&apos;s not responding to medication',
    'Moderate dehydration from vomiting or diarrhea',
    'Suspected infections requiring antibiotics',
    'Moderate to severe pain requiring prescription medication',
    'Minor to moderate breathing difficulties',
    'Non-life-threatening injuries requiring medical assessment',
    'Skin infections, rashes, or allergic reactions',
    'Urinary tract infections and symptoms',
    'Ear pain or infections requiring prompt treatment',
    'Exacerbation of chronic conditions like asthma or COPD'
  ];

  const treatments = [
    {
      stage: 'Respiratory Infections',
      description: 'Clinical assessment, prescription antibiotics when indicated, nebulizer treatments, management guidance',
      duration: 'Typical recovery: 3-10 days depending on infection type'
    },
    {
      stage: 'Gastrointestinal Issues',
      description: 'Anti-nausea medications, rehydration therapy, prescription treatment for infections',
      duration: 'Symptom relief: 24-72 hours; Full recovery: 3-7 days'
    },
    {
      stage: 'Skin Infections & Wounds',
      description: 'Wound cleaning, stitches, abscess drainage, antibiotic prescriptions',
      duration: 'Initial healing: 2-7 days; Complete resolution: 1-3 weeks'
    },
    {
      stage: 'Pain Management',
      description: 'Assessment, pain relief prescriptions, referrals when needed',
      duration: 'Acute pain control: 1-3 days; Management: varies by condition'
    }
  ];

  const references = [
    {
      authors: 'Royal Australian College of General Practitioners (RACGP)',
      title: 'Standards for general practices (5th edition)',
      journal: 'RACGP',
      year: '2020',
      doi: ''
    },
    {
      authors: 'NSW Health',
      title: 'Emergency Department Care in NSW',
      journal: 'NSW Government Health',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Australasian College for Emergency Medicine (ACEM)',
      title: 'Statement on Primary Care in Emergency Departments',
      journal: 'ACEM',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Desai S, et al.',
      title: 'The role of mobile health solutions in developing community based primary healthcare',
      journal: 'Australian Family Physician',
      year: '2021',
      doi: '50(5):250-255'
    },
    {
      authors: 'Australian Institute of Health and Welfare',
      title: 'Emergency department care',
      journal: 'AIHW',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Therapeutic Guidelines Limited',
      title: 'eTG complete: Antibiotic prescribing in primary care',
      journal: 'Melbourne: Therapeutic Guidelines Limited',
      year: '2023',
      doi: ''
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/urgent-care-at-home-sydney.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/urgent-care-at-home" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/urgent-care-at-home-sydney.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/urgent-care-at-home" />
        
        {/* Schema.org structured data for medical article */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalWebPage",
              "headline": "Urgent Care at Home in Sydney: What Can Be Treated Without Hospital",
              "image": "https://homedoc.com.au/images/urgent-care-at-home-sydney.png",
              "datePublished": "2024-06-17T08:00:00+10:00",
              "dateModified": "2024-06-17T08:00:00+10:00",
              "author": {
                "@type": "Person",
                "name": "Dr. Terry Nguyen",
                "jobTitle": "Medical Doctor",
                "image": "https://homedoc.com.au/images/drterrynguyen_medicaldoctor.png"
              },
              "publisher": {
                "@type": "Organization",
                "name": "HomeDoc House Call Doctor Service",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://homedoc.com.au/images/logo.png"
                }
              },
              "description": "Learn which urgent medical conditions can be safely treated at home by a house call doctor in Sydney. Expert advice on when to stay home vs. when to go to hospital.",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://homedoc.com.au/blog/urgent-care-at-home"
              },
              "speakable": {
                "@type": "SpeakableSpecification",
                "cssSelector": ["#introduction", "#conclusion"]
              },
              "specialty": ["Emergency Medicine", "General Practice", "House Call Medicine"],
              "about": [
                {"@type": "MedicalCondition", "name": "Respiratory Infections"},
                {"@type": "MedicalCondition", "name": "Gastrointestinal Problems"},
                {"@type": "MedicalCondition", "name": "Skin Infections & Wounds"},
                {"@type": "MedicalCondition", "name": "Pain Management"}
              ],
              "audience": {"@type": "Audience", "audienceType": "General Public"},
              "keywords": "urgent care at home Sydney, house call doctor Eastern Suburbs, after hours GP Bondi, mobile doctor Sydney"
            }
          `}
        </script>
        
        {/* Local Business structured data for house call service */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "MedicalBusiness",
              "name": "HomeDoc House Call Doctor Service",
              "image": "https://homedoc.com.au/images/logo.png",
              "url": "https://homedoc.com.au",
              "telephone": "+61-2-9000-0000",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Eastern Suburbs",
                "addressLocality": "Sydney",
                "addressRegion": "NSW",
                "postalCode": "2026",
                "addressCountry": "AU"
              },
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": -33.891844,
                "longitude": 151.274292
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "00:00",
                "closes": "23:59"
              },
              "serviceArea": {
                "@type": "GeoCircle",
                "geoMidpoint": {
                  "@type": "GeoCoordinates",
                  "latitude": -33.891844,
                  "longitude": 151.274292
                },
                "geoRadius": "10000"
              },
              "sameAs": [
                "https://www.facebook.com/homedoc",
                "https://www.instagram.com/homedoc_sydney"
              ],
              "priceRange": "$$",
              "medicalSpecialty": "Emergency Medicine"
            }
          `}
        </script>
        
        {/* FAQ structured data for search results */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Which urgent conditions can be treated at home instead of going to hospital?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Many non-life-threatening urgent conditions can be treated at home by a house call doctor, including: respiratory infections (bronchitis, sinusitis, mild pneumonia), gastrointestinal issues, urinary tract infections, skin infections and cellulitis, acute pain (back pain, migraines), ear infections, non-severe allergic reactions, mild-moderate asthma flare-ups, minor injuries needing assessment, and dehydration requiring treatment."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How quickly can a house call doctor arrive at my home in Sydney&apos;s Eastern Suburbs?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In Bondi, Randwick, Double Bay, and surrounding Eastern Suburbs areas, our house call doctors typically arrive within 30-60 minutes of booking. During peak periods, wait times may be longer but still significantly shorter than most emergency department waiting times. For urgent conditions requiring immediate attention, our triage process ensures priority response."
                  }
                },
                {
                  "@type": "Question",
                  "name": "When should I go to hospital instead of calling a house doctor?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You should call 000 or go directly to hospital for: chest pain or symptoms of heart attack, stroke symptoms (facial drooping, arm weakness, speech difficulties), severe breathing difficulty, major trauma or serious injuries, severe bleeding, loss of consciousness, severe allergic reactions (anaphylaxis), poisoning, severe mental health crises, and any situation where you feel the condition is immediately life-threatening."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are house call doctor services in Sydney covered by Medicare?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, our urgent care house call services throughout Sydney&apos;s Eastern Suburbs are bulk-billed for Medicare card holders, meaning there are no out-of-pocket expenses. We provide this service 24/7, including after-hours, weekends, and public holidays at no additional cost to Medicare card holders. For international visitors, we offer competitive rates and can provide documentation for travel insurance claims."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What treatments can a house call doctor provide for urgent care at home?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "House call doctors can provide comprehensive urgent care treatments including: clinical examinations, prescribing medications (including antibiotics and pain relief), administering medications via injection when needed, providing IV fluids for dehydration, performing minor procedures (wound care, abscess drainage, suturing), arranging pathology tests, providing medical certificates, and coordinating follow-up care with specialists when needed."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How do I book a house call doctor in Sydney&apos;s Eastern Suburbs?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can book a house call doctor in Sydney&apos;s Eastern Suburbs through our website booking system at homedoc.com.au, by calling our 24/7 booking line at 02 9000 0000, or via our mobile app. Our service covers Bondi, Randwick, Double Bay, Woollahra, and all surrounding Eastern Suburbs areas with typical arrival times of 30-60 minutes."
                  }
                }
              ]
            }
          `}
        </script>
      </Helmet>
      
      <MedicalBlogTemplate
        title="Urgent Care at Home in Sydney: What Can Be Treated Without Going to Hospital"
        subtitle="Expert guide on which urgent medical conditions can be safely treated by a house call doctor in Sydney's Eastern Suburbs"
        heroImage="/images/urgent-care-at-home-sydney.png"
        publishDate="June 17, 2024"
        readTime={14}
        category="Emergency Care"
        tags={['Urgent Care', 'House Call Doctor', 'Sydney Eastern Suburbs', 'Avoid Hospital', 'After Hours GP', 'Home Medical Care', 'Bondi', 'Randwick', 'Double Bay', 'Woollahra', 'Urgent GP']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={true}
        tableOfContents={tableOfContents}
        content={content}
      />
    </>
  );
};

export default UrgentCareAtHome; 