import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

// Firebase config for homedoc.com.au (auth)
const firebaseConfig = {
  apiKey: "AIzaSyCEyhC8VBDUAMjRuoIcaNqppiJNDbS52NE",
  authDomain: "drterrymedical-5ce97.firebaseapp.com",
  projectId: "drterrymedical-5ce97",
  storageBucket: "drterrymedical-5ce97.firebaseapp.com",
  messagingSenderId: "69655343535",
  appId: "1:69655343535:web:12d101c5f79586c28dbf5a",
  measurementId: "G-Y6SCNGJPEP"
};

let auth, db, storage, analytics, app;

try {
  console.log('Initializing Firebase with config:', {
    ...firebaseConfig,
    apiKey: '***********',
    projectId: firebaseConfig.projectId
  });
  
  console.log('Firebase Project ID:', firebaseConfig.projectId);

  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  
  // Provide clear confirmation of success
  console.log('Firebase app initialized successfully');
  
  // Initialize Auth with persistence
  auth = getAuth(app);
  auth.useDeviceLanguage(); // Set language to device default
  console.log('Firebase auth initialized');
  
  // Initialize other services
  db = getFirestore(app);
  console.log('Firestore initialized');
  
  storage = getStorage(app);
  console.log('Firebase storage initialized');
  
  // Initialize Analytics only in browser environment
  if (typeof window !== 'undefined') {
    try {
      analytics = getAnalytics(app);
      console.log('Firebase analytics initialized');
    } catch (error) {
      console.warn('Analytics initialization skipped:', error.message);
    }
  }
} catch (error) {
  console.error('Error initializing Firebase:', error);
  throw error;
}

export { auth, db, storage, analytics };
export default app; 