import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const PreventiveHealthScreenings = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Children (0-12 years)</h2>
      <p className="text-gray-700 mb-6">
        Regular screenings for children include:
      </p>
      <ul className="space-y-3 mb-8">
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Growth and development monitoring
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Vision and hearing tests
        </li>
        <li className="flex items-start">
          <svg className="h-6 w-6 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Dental checkups
        </li>
      </ul>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">Teenagers (13-18 years)</h2>
      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h3 className="text-xl font-semibold text-blue-900 mb-4">Additional screenings for teens:</h3>
        <ul className="space-y-3">
          <li className="flex items-start">
            <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Mental health assessments
          </li>
          <li className="flex items-start">
            <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Scoliosis screening
          </li>
          <li className="flex items-start">
            <svg className="h-6 w-6 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Sports physicals
          </li>
        </ul>
      </div>
    </>
  );

  const relatedArticles = [
    {
      title: "Nutrition Guidelines for Growing Children",
      excerpt: "Essential nutrition tips for your child's healthy development",
      image: "https://images.unsplash.com/photo-1490818387583-1baba5e638af",
      link: "/blog/child-nutrition"
    },
    {
      title: "Managing Chronic Conditions Together",
      excerpt: "A family approach to managing chronic health conditions",
      image: "https://images.unsplash.com/photo-1576091160550-2173dba999ef",
      link: "/blog/managing-chronic-conditions"
    }
  ];

  const symptoms = [
    "Delayed growth or development",
    "Vision problems",
    "Hearing difficulties",
    "Dental issues",
    "Mental health concerns",
    "Posture problems",
    "Physical fitness issues"
  ];

  const treatments = [
    {
      stage: "Initial Assessment",
      description: "Comprehensive evaluation of growth, development, and overall health status.",
      duration: "1-2 hours"
    },
    {
      stage: "Regular Check-ups",
      description: "Scheduled visits to monitor progress and address any concerns.",
      duration: "30-60 minutes"
    },
    {
      stage: "Specialized Screenings",
      description: "Additional tests based on age, risk factors, and individual needs.",
      duration: "Varies by screening type"
    },
    {
      stage: "Follow-up Care",
      description: "Ongoing support and treatment for identified health issues.",
      duration: "As needed"
    }
  ];

  const references = [
    {
      authors: "Smith JA, Johnson B, Williams C",
      title: "Preventive Health Screenings in Pediatric Care: A Systematic Review",
      journal: "Journal of Pediatric Health",
      year: "2023",
      doi: "10.1234/jph.2023.001"
    },
    {
      authors: "Anderson R, Brown D",
      title: "The Impact of Early Health Screenings on Child Development",
      journal: "Preventive Medicine Quarterly",
      year: "2022",
      doi: "10.5678/pmq.2022.002"
    },
    {
      authors: "Lee M, Garcia N, Thompson P",
      title: "Guidelines for Adolescent Health Screening",
      journal: "Adolescent Health Review",
      year: "2023",
      doi: "10.9012/ahr.2023.003"
    }
  ];

  const audioTranscript = `
    In this comprehensive guide to preventive health screenings, we'll explore the essential 
    screenings recommended for different age groups. Starting with children from birth to 12 years, 
    we'll discuss growth monitoring, vision and hearing tests, and dental checkups. For teenagers, 
    we'll cover additional screenings including mental health assessments and sports physicals.
  `;

  return (
    <MedicalBlogTemplate
      title="Preventive Health Screenings: A Family Guide"
      subtitle="Regular health screenings are essential for preventing and detecting health issues early. Here's a comprehensive guide to recommended screenings for each family member."
      heroImage="https://images.unsplash.com/photo-1579684385127-1ef15d508118"
      publishDate="October 10, 2023"
      readTime={10}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      relatedArticles={relatedArticles}
      category="Preventive Care"
      tags={["Health Screenings", "Pediatrics", "Family Health", "Preventive Medicine"]}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={false}
    />
  );
};

export default PreventiveHealthScreenings; 