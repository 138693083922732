import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { CheckCircleIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export default function BookingSuccess() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const bookingId = searchParams.get('booking_id');
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState('pending'); // 'pending', 'success', 'error'

  useEffect(() => {
    const fetchBookingDetails = async () => {
      if (!bookingId) {
        setError('No booking ID provided');
        setLoading(false);
        return;
      }

      try {
        const bookingRef = doc(db, 'bookings', bookingId);
        const bookingDoc = await getDoc(bookingRef);
        
        if (!bookingDoc.exists()) {
          setError('Booking not found');
          setLoading(false);
          return;
        }
        
        const bookingData = bookingDoc.data();
        setBookingDetails(bookingData);
        
        // Trigger notification to admin and patient
        try {
          // Only attempt to send notification if we have the required data
          if (bookingData.patientDetails?.email) {
            console.log("Attempting to send booking notifications");
            setNotificationStatus('sending');
            
            const response = await fetch('/.netlify/functions/send-booking-notifications', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                bookingId,
                doctorEmail: 'info@homedoc.com.au',
                patientEmail: bookingData.patientDetails?.email,
                bookingDetails: bookingData
              }),
            });
            
            // Log complete response for debugging
            const responseText = await response.text();
            console.log('Notification response status:', response.status);
            console.log('Notification response text:', responseText);
            
            if (!response.ok) {
              console.error(`Notification service returned an error: ${response.status}`);
              console.error('Response details:', responseText);
              setNotificationStatus('error');
              
              // Try once more
              console.log("Retrying notification...");
              
              // Small delay before retry
              await new Promise(resolve => setTimeout(resolve, 1000));
              
              const retryResponse = await fetch('/.netlify/functions/send-booking-notifications', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  bookingId,
                  doctorEmail: 'info@homedoc.com.au',
                  patientEmail: bookingData.patientDetails?.email,
                  bookingDetails: bookingData
                }),
              });
              
              // Log complete retry response
              const retryResponseText = await retryResponse.text();
              console.log('Retry response status:', retryResponse.status);
              console.log('Retry response text:', retryResponseText);
              
              if (!retryResponse.ok) {
                console.error(`Retry notification also failed: ${retryResponse.status}`);
                console.error('Retry response details:', retryResponseText);
                setNotificationStatus('error');
              } else {
                console.log("Retry notification sent successfully");
                setNotificationStatus('success');
              }
            } else {
              console.log("Notification sent successfully");
              setNotificationStatus('success');
            }
          } else {
            console.warn("Skipping notification - missing patient email");
            setNotificationStatus('error');
          }
        } catch (error) {
          console.error("Error sending notification:", error);
          setNotificationStatus('error');
        }
      } catch (err) {
        console.error('Error fetching booking details:', err);
        setError('Failed to load booking details');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [bookingId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center px-4">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-[#DDC6A8]/20 border-t-[#DDC6A8] rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-[#DDC6A8]">Loading booking details...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#1A1A1A] flex items-center justify-center px-4">
        <div className="max-w-md w-full bg-[#2A2A2A] rounded-2xl shadow-lg p-8 text-center">
          <div className="text-red-500 mx-auto h-16 w-16 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h1 className="mt-4 text-3xl font-bold text-[#DDC6A8]">Error</h1>
          <p className="mt-2 text-lg text-[#DDC6A8]/80">{error}</p>
          <div className="mt-8">
            <Link
              to="/"
              className="inline-block bg-[#DDC6A8] text-[#1A1A1A] px-6 py-3 rounded-lg font-semibold hover:bg-[#DDC6A8]/90 transition-colors"
            >
              Return to Home
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1A1A1A] flex flex-col items-center justify-center px-4 py-12">
      <div className="max-w-md w-full bg-[#2A2A2A] rounded-2xl shadow-lg p-8 text-center">
        <CheckCircleIcon className="mx-auto h-16 w-16 text-green-500" />
        <h1 className="mt-4 text-3xl font-bold text-[#DDC6A8]">Booking Confirmed!</h1>
        <p className="mt-2 text-lg text-[#DDC6A8]/80">
          Thank you for your booking. We've sent you an email with all the details.
        </p>
        
        {notificationStatus === 'success' && (
          <div className="mt-3 flex items-center justify-center text-green-500">
            <EnvelopeIcon className="h-5 w-5 mr-2" />
            <p className="text-sm">Confirmation email sent successfully</p>
          </div>
        )}
        
        {notificationStatus === 'error' && (
          <div className="mt-3 text-amber-500 text-sm">
            <p>We may have had trouble sending your confirmation email.</p>
            <p>Please check your inbox or spam folder, or contact us if you don't receive it.</p>
          </div>
        )}
        
        {bookingDetails && (
          <div className="mt-6 text-left bg-[#1A1A1A] p-4 rounded-lg">
            <h2 className="text-xl font-semibold text-[#DDC6A8] mb-3">Booking Details</h2>
            <p className="text-[#DDC6A8]/80 mb-1">
              <span className="font-medium">Service:</span> {bookingDetails.service?.name}
            </p>
            {bookingDetails.date && (
              <p className="text-[#DDC6A8]/80 mb-1">
                <span className="font-medium">Date:</span> {new Date(bookingDetails.date.toDate()).toLocaleDateString()}
              </p>
            )}
            {bookingDetails.time && (
              <p className="text-[#DDC6A8]/80 mb-1">
                <span className="font-medium">Time:</span> {bookingDetails.time}
              </p>
            )}
            <p className="text-[#DDC6A8]/80 mb-1">
              <span className="font-medium">Price:</span> ${bookingDetails.price}
            </p>
            {bookingDetails.location && (
              <p className="text-[#DDC6A8]/80 mb-1">
                <span className="font-medium">Location:</span> {typeof bookingDetails.location === 'string' 
                  ? bookingDetails.location 
                  : bookingDetails.location?.address || 'Not specified'}
              </p>
            )}
          </div>
        )}
        
        <p className="mt-4 text-sm text-[#DDC6A8]/60">
          Booking Reference: {bookingId || sessionId}
        </p>
        
        <div className="mt-8 space-y-4">
          <Link
            to="/"
            className="block w-full border border-[#DDC6A8] bg-[#DDC6A8] text-[#1A1A1A] px-6 py-4 rounded-lg font-semibold hover:bg-[#DDC6A8]/90 transition-colors"
          >
            Return to Home
          </Link>
        </div>
      </div>
    </div>
  );
} 