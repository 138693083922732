import React from 'react';
import { Link } from 'react-router-dom';

const ChildhoodVaccinations = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <Link to="/blog" className="text-indigo-600 hover:text-indigo-500 mb-8 block">
          ← Back to Blog
        </Link>
        <article className="prose lg:prose-xl">
          <h1>Top 10 Childhood Vaccination Facts Every Parent Should Know</h1>
          
          <p className="lead">
            Vaccinations are one of the most important ways to protect your child's health. 
            Here are the essential facts every parent needs to understand about childhood immunizations.
          </p>

          <h2>1. Vaccines Save Lives</h2>
          <p>
            Immunizations prevent an estimated 2-3 million deaths worldwide each year from diseases 
            like diphtheria, tetanus, pertussis, and measles.
          </p>

          <h2>2. Vaccine Safety is Thoroughly Tested</h2>
          <p>
            Before approval, vaccines undergo extensive testing through multiple clinical trials 
            and continue to be monitored for safety after release.
          </p>

          <h2>3. Timing Matters</h2>
          <p>
            The recommended vaccination schedule is designed to protect infants and children 
            when they are most vulnerable to diseases.
          </p>

          <h2>4. Herd Immunity Protects Communities</h2>
          <p>
            When enough people are vaccinated, it helps protect those who cannot receive 
            vaccines due to age or medical conditions.
          </p>

          <h2>5. Side Effects are Usually Mild</h2>
          <p>
            Most vaccine reactions are minor and short-lived, such as soreness at the 
            injection site or a low-grade fever.
          </p>

          <h2>6. Vaccines Don't Cause Autism</h2>
          <p>
            Multiple large studies have found no link between vaccines and autism spectrum disorders.
          </p>

          <h2>7. Multiple Vaccines are Safe</h2>
          <p>
            A child's immune system can safely handle receiving multiple vaccines during one visit.
          </p>

          <h2>8. Vaccines are Cost-Effective</h2>
          <p>
            Preventing diseases through vaccination is more cost-effective than treating serious illnesses.
          </p>

          <h2>9. Protection Continues into Adulthood</h2>
          <p>
            Many childhood vaccines provide protection that lasts into adulthood, though some may 
            need boosters.
          </p>

          <h2>10. Regular Updates are Important</h2>
          <p>
            Vaccine recommendations are regularly reviewed and updated based on the latest 
            medical research.
          </p>

          <div className="mt-8 p-4 bg-blue-50 rounded-lg">
            <h3>Important Note:</h3>
            <p>
              Always consult with your pediatrician about your child's specific vaccination 
              schedule and any concerns you may have.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ChildhoodVaccinations; 