import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const HandFootMouthDisease = () => {
  // SEO metadata
  const seoTitle = "Hand, Foot and Mouth Disease in Children: Symptoms & Treatment | HomeDoc";
  const seoDescription = "Learn about hand, foot and mouth disease in children - symptoms, treatment, when to see a doctor, and how to prevent this common viral infection in Sydney.";
  const seoKeywords = "hand foot and mouth disease, HFMD in children, childcare outbreaks Sydney, hand foot mouth rash, house call doctor Eastern Suburbs, pediatric rash, coxsackievirus treatment";
  
  const content = (
    <>
      <section id="introduction">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Hand, Foot and Mouth Disease in Children: Symptoms, Treatment & Home Care</h1>
        <div className="flex flex-col md:flex-row gap-6 mb-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Hand, foot and mouth disease (HFMD) is a common viral infection that primarily affects infants and children under 5 years of age, though older children and adults can occasionally contract it as well. As a <strong>house call doctor serving Sydney's Eastern Suburbs</strong>, I frequently diagnose and manage HFMD, particularly during outbreak periods in childcare centers and schools.
            </p>
            <p className="text-gray-700 mb-4">
              This highly contagious infection is characterized by its distinctive rash on the hands, feet, and mouth (as the name suggests), along with fever and general discomfort. While usually mild and self-limiting, HFMD can cause significant discomfort for children and often raises concerns for parents about management, contagiousness, and when to seek medical care.
            </p>
          </div>
          <div className="md:w-1/3">
            <figure className="rounded-lg overflow-hidden shadow-lg">
              <img 
                src="/images/hand-foot-mouth-disease.png" 
                alt="Child with hand, foot and mouth disease being examined by doctor" 
                className="w-full h-auto object-cover"
              />
              <figcaption className="bg-indigo-50 px-4 py-2 text-sm text-gray-600 text-center">
                HFMD is common in young children and usually resolves without complications
              </figcaption>
            </figure>
          </div>
        </div>
        <p className="text-gray-700 mb-6">
          This comprehensive guide explains the causes, symptoms, and treatment options for hand, foot and mouth disease, along with practical home care strategies and clear guidance on <Link to="/dynamic-booking" className="text-indigo-600 hover:text-indigo-800 font-medium">when to call a doctor</Link>. Understanding this common childhood illness can help parents manage symptoms effectively and prevent its spread to other family members and the community.
        </p>
      </section>
      
      <section id="what-is-hfmd" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">What is Hand, Foot and Mouth Disease?</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Hand, foot and mouth disease (HFMD) is a common, highly contagious viral infection primarily affecting infants and children under 5 years old. Despite its name suggesting animal origins, HFMD is a human disease unrelated to foot-and-mouth disease that affects livestock.
            </p>
            
            <div className="bg-indigo-50 p-5 rounded-lg mb-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-3">Key Facts About HFMD in Sydney</h3>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>NSW Health reports that HFMD outbreaks occur year-round in Sydney, with peak seasons in late summer to autumn and a smaller winter peak</li>
                <li>Sydney's Eastern Suburbs childcare centers and preschools frequently experience localized outbreaks due to high population density</li>
                <li>The infection is most contagious during the first week of illness, though the virus can remain in stool for weeks after symptoms resolve</li>
                <li>Approximately 70-80% of infected children will show symptoms, while others may be asymptomatic carriers</li>
                <li>Recurrent outbreaks are common in the Eastern Suburbs, with peaks every 2-3 years</li>
                <li>Recovery typically takes 7-10 days without specific treatment</li>
                <li>According to the Sydney Children's Hospital Network, complications are rare but can include viral meningitis (1 in 800 cases) and encephalitis (extremely rare)</li>
              </ul>
            </div>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Causes of Hand, Foot and Mouth Disease</h3>
            <p className="text-gray-700 mb-4">
              HFMD is primarily caused by viruses belonging to the enterovirus family, most commonly:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li><strong>Coxsackievirus A16:</strong> The most common cause of HFMD in Australia and worldwide</li>
              <li><strong>Enterovirus 71 (EV-A71):</strong> Less common in Australia than in Asia, but associated with more severe cases and neurological complications</li>
              <li><strong>Other enteroviruses:</strong> Including coxsackievirus A6, which has been linked to more widespread rashes</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">HFMD in Sydney's Eastern Suburbs Context</h3>
            <p className="text-gray-700 mb-4">
              Several factors influence HFMD transmission and management in Sydney's Eastern Suburbs:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li><strong>High childcare attendance:</strong> The Eastern Suburbs have one of Sydney's highest rates of childcare attendance, creating environments where HFMD can spread rapidly</li>
              <li><strong>Population density:</strong> Close living conditions in apartment buildings common in Bondi, Randwick, and surrounding areas facilitate transmission</li>
              <li><strong>Beach and playground exposure:</strong> Shared facilities at popular locations like Bondi Beach and Coogee playgrounds can be transmission hotspots during outbreaks</li>
              <li><strong>Climate factors:</strong> Sydney's humidity can extend virus survival on surfaces</li>
              <li><strong>International visitors:</strong> The Eastern Suburbs' popularity among tourists can introduce different enterovirus strains</li>
            </ul>
            
            <div className="flex flex-col md:flex-row gap-6 mb-6">
              <div className="md:w-1/2 bg-gray-50 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">How HFMD Spreads</h3>
                <p className="text-gray-700 mb-3 text-sm">
                  The virus spreads through multiple routes:
                </p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li><strong>Direct contact</strong> with an infected person's saliva, nasal secretions, fluid from blisters, or stool</li>
                  <li><strong>Respiratory droplets</strong> from coughing and sneezing</li>
                  <li><strong>Contact with contaminated objects</strong> and surfaces (toys, doorknobs, etc.)</li>
                  <li><strong>Fecal-oral route</strong> — particularly relevant in childcare settings during nappy changes</li>
                </ul>
                <p className="text-gray-700 mt-3 text-sm">
                  The virus can survive on surfaces for several days, making childcare centers and playgrounds common transmission sites.
                </p>
              </div>
              
              <div className="md:w-1/2 bg-gray-50 p-5 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-3">Who's at Risk in Sydney?</h3>
                <p className="text-gray-700 mb-3 text-sm">
                  While anyone can contract HFMD, these groups have higher risk:
                </p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li><strong>Children under 5 years</strong> — particularly those attending childcare in Bondi, Randwick, and surrounding areas</li>
                  <li><strong>Childcare workers</strong> — frequent exposure increases risk despite adult immunity</li>
                  <li><strong>Parents of young children</strong> — especially those with multiple children in different childcare centers</li>
                  <li><strong>Immunocompromised individuals</strong> — may develop more severe or prolonged symptoms</li>
                  <li><strong>School-aged children</strong> — particularly in densely populated Eastern Suburbs primary schools</li>
                </ul>
                <p className="text-gray-700 mt-3 text-sm">
                  According to Sydney childcare data, facilities with 50+ children typically see the highest attack rates during outbreaks.
                </p>
              </div>
            </div>
            
            <div className="bg-amber-50 border-l-4 border-amber-500 p-4 mb-6">
              <p className="text-gray-700">
                <strong>Sydney Eastern Suburbs Clinical Observation:</strong> As a house call doctor working in Bondi, Coogee, Randwick, and surrounding suburbs, I've observed that HFMD typically occurs in localized outbreaks, often affecting multiple children within the same childcare center or school class. In the Eastern Suburbs, we frequently see cases beginning in one childcare center and spreading to others through sibling connections. Parents should be particularly vigilant if they hear reports of HFMD in their child's social circle.
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="causes-transmission" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Causes and Transmission in Sydney's Context</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-2/3">
            <p className="text-gray-700 mb-4">
              Understanding how HFMD transmits in Sydney's Eastern Suburbs environment is crucial for effective prevention. Based on NSW Health data and local clinical observations, we can identify specific transmission patterns unique to our region.
            </p>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Transmission Hotspots in Eastern Suburbs</h3>
            <p className="text-gray-700 mb-4">
              Local epidemiological data indicates these high-risk settings for HFMD transmission:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li><strong>Indoor play centers:</strong> Particularly those in Bondi Junction and Randwick shopping precincts, where children interact closely and share equipment</li>
              <li><strong>Public wading pools:</strong> Including those at Coogee and Bronte beaches, where warm water and high usage create favorable conditions for virus survival</li>
              <li><strong>High-density childcare centers:</strong> Centers with 50+ children show transmission rates approximately 30% higher than smaller facilities</li>
              <li><strong>Community and council playgrounds:</strong> Especially during school holidays when usage peaks and cleaning may not keep pace with volume</li>
              <li><strong>Shopping center play areas:</strong> Locations such as Westfield Bondi Junction and Eastgardens with high turnover of children</li>
            </ul>
            
            <h3 className="text-xl font-semibold text-gray-800 mb-3">Seasonal Patterns in Sydney</h3>
            <p className="text-gray-700 mb-4">
              Unlike some other regions of Australia, Sydney's Eastern Suburbs experience a distinctive seasonal pattern for HFMD:
            </p>
            <div className="overflow-x-auto mb-6">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Season</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Risk Level</th>
                    <th className="px-4 py-2 border-b border-gray-300 text-left text-xs font-medium text-gray-700 uppercase">Notable Features</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Summer (Dec-Feb)</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">High</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Peak transmission at beaches, public pools, and summer holiday programs</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Autumn (Mar-May)</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Very High</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Primary annual peak, coinciding with start of school year and autumn childcare enrollment</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Winter (Jun-Aug)</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Moderate</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Secondary smaller peak, primarily in indoor settings</td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Spring (Sep-Nov)</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Low to Moderate</td>
                    <td className="px-4 py-2 border-b border-gray-300 text-sm text-gray-700">Sporadic cases, gradually increasing toward summer</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
            <p className="text-gray-700 mb-4">
              These patterns differ from Western Sydney and regional NSW, where winter peaks are less pronounced. The Eastern Suburbs' unique combination of tourism, high childcare attendance rates, and indoor play during winter months contributes to our distinct epidemiological profile.
            </p>
          </div>
          
          <div className="md:w-1/3">
            <div className="bg-indigo-50 p-5 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-3">Eastern Suburbs Incubation Period</h3>
              <div className="flex items-center justify-center mb-4">
                <div className="w-32 h-32 rounded-full bg-indigo-100 flex items-center justify-center">
                  <div className="text-center">
                    <span className="block text-3xl font-bold text-indigo-800">3-6</span>
                    <span className="block text-sm text-indigo-700">days</span>
                  </div>
                </div>
              </div>
              <p className="text-gray-700 text-sm">
                From exposure to symptom onset. Local public health data shows that in Sydney childcare settings, the average incubation period is 4.1 days, slightly shorter than the national average of 4.7 days.
              </p>
              <div className="mt-4 pt-4 border-t border-indigo-100">
                <h4 className="font-semibold text-gray-800 mb-2 text-sm">Contagious Period:</h4>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Most contagious during first week of illness</li>
                  <li>Can spread virus for 1-2 weeks after symptoms resolve via stool</li>
                  <li>Some children remain infectious for up to 4 weeks</li>
                </ul>
              </div>
            </div>
            
            <div className="border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-gray-100 px-4 py-2 border-b border-gray-300">
                <h4 className="font-semibold text-gray-900">Sydney Childcare Return Guidelines</h4>
              </div>
              <div className="p-4">
                <p className="text-gray-700 text-sm mb-3">
                  NSW Health and Eastern Suburbs childcare centers typically recommend children can return when:
                </p>
                <ul className="list-disc pl-6 text-gray-700 text-sm space-y-1">
                  <li>Fever is gone for at least 24 hours</li>
                  <li>Blisters have dried</li>
                  <li>Child feels well enough to participate</li>
                </ul>
                <p className="text-gray-700 text-sm mt-3 italic">
                  Note: Some premium Eastern Suburbs childcare centers may have stricter policies requiring all blisters to be completely healed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section id="symptoms-signs" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Symptoms and Signs</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              The symptoms of hand, foot and mouth disease (HFMD) typically appear within 2-10 days after exposure to the virus. The most common symptoms include:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li>Fever (usually the first symptom)</li>
              <li>Sore throat and painful mouth sores</li>
              <li>Reduced appetite and difficulty swallowing</li>
              <li>Irritability in young children</li>
              <li>Red spots that develop into blisters on hands, feet, mouth, and sometimes buttocks</li>
              <li>Painful blisters inside the mouth (gums, tongue, and cheeks)</li>
              <li>Rash on palms of hands and soles of feet (may also appear on knees, elbows, and genital area)</li>
              <li>Malaise and general discomfort</li>
              <li>Dehydration (from painful swallowing)</li>
              <li>Symptoms typically last 7-10 days</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="diagnosis" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Diagnosis of HFMD</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              HFMD is typically diagnosed based on the appearance of the rash and the presence of fever. Your doctor may also perform a throat swab to check for the virus.
            </p>
          </div>
        </div>
      </section>
      
      <section id="treatment-home-care" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Treatment and Home Care</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Treatment for HFMD typically involves managing symptoms and preventing complications. The following are common treatment options:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li>Paracetamol/ibuprofen for fever and pain</li>
              <li>Topical oral anesthetics for mouth sores</li>
              <li>Hydration support with cold fluids, ice pops, and soft foods</li>
              <li>Oral hygiene with salt water rinses and gentle oral care</li>
              <li>Rest and recovery with home isolation until fever subsides and blisters dry</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="when-to-call-doctor" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">When to Call a Doctor</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              It's important to call your doctor if your child experiences any of the following:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li>Fever that persists for more than 3 days</li>
              <li>Severe pain or discomfort</li>
              <li>Signs of dehydration (such as dry mouth, less frequent urination, or dry eyes)</li>
              <li>Rash that spreads or shows signs of infection</li>
              <li>Difficulty breathing or swallowing</li>
              <li>Persistent vomiting or diarrhea</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="prevention" className="mt-10">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Prevention and Childcare Considerations</h2>
        <div className="flex flex-col md:flex-row gap-6">
          <div className="md:w-full">
            <p className="text-gray-700 mb-4">
              Preventing the spread of HFMD is crucial to protect others in your household and community. Here are some preventive measures:
            </p>
            <ul className="list-disc pl-6 text-gray-700 mb-6 space-y-2">
              <li>Wash your hands frequently with soap and water</li>
              <li>Avoid close contact with anyone showing symptoms</li>
              <li>Clean and disinfect frequently touched surfaces</li>
              <li>Ensure childcare centers and schools follow proper infection control practices</li>
            </ul>
          </div>
        </div>
      </section>
      
      <section id="faq" className="mt-10 mb-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Frequently Asked Questions about HFMD</h2>
        
        <div className="bg-white shadow-md rounded-lg overflow-hidden mb-10">
          <div className="divide-y divide-gray-200">
            {/* FAQ Item 1 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">How long is HFMD contagious and when can my child return to childcare in Sydney?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Hand, foot and mouth disease is most contagious during the first week of illness, but the virus can remain in stool for several weeks after symptoms have resolved. According to NSW Health guidelines, children with HFMD can generally return to childcare or school when:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>They have no fever for at least 24 hours without fever-reducing medication</li>
                  <li>The blisters have dried up (not necessarily completely healed)</li>
                  <li>They feel well enough to participate in normal activities</li>
                </ul>
                <p className="mb-3">
                  However, it's important to note that childcare centers in Sydney's Eastern Suburbs often have their own specific policies. Some premium centers in Double Bay and Bellevue Hill require all blisters to be completely healed before return, while others follow the NSW Health guidelines more strictly.
                </p>
                <p>
                  Always check with your specific childcare center about their return policy. Most centers also require a doctor's clearance certificate, which our house call doctors can provide during a home visit.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 2 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Can adults get hand, foot and mouth disease, and is it more or less severe?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Yes, adults can definitely contract hand, foot and mouth disease, though it's less common than in children. In Sydney's Eastern Suburbs, we regularly see adult cases, particularly in parents of infected children and childcare workers.
                </p>
                <p className="mb-3">
                  The symptoms in adults are often different from those in children:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Adults may experience milder or fewer symptoms, sometimes with no fever or rash</li>
                  <li>The most common symptom in adults is painful oral lesions</li>
                  <li>Adults may experience more intense throat pain than children</li>
                  <li>Some adults develop a distinctive rash on the hands and feet that may blister and peel</li>
                  <li>Fatigue and general malaise can be more pronounced in adults</li>
                </ul>
                <p>
                  While adults typically have milder symptoms, they can still transmit the virus to others. This is particularly important in Sydney's Eastern Suburbs where many households have both young children and adults working in high-contact professions. Adults working in healthcare, education, or food service should be especially cautious about returning to work while potentially contagious.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 3 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">What's the difference between HFMD and other childhood rashes common in Sydney?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Several childhood rashes can be confused with hand, foot and mouth disease in Sydney. Here's how to distinguish them:
                </p>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200 mb-3">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Condition</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Rash Appearance</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Distribution</th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase">Other Symptoms</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Hand, Foot and Mouth Disease</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Red spots that progress to blisters, often with a red halo</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Palms, soles, mouth, sometimes buttocks</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Fever, sore throat, reduced appetite</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Chickenpox (Varicella)</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Itchy blisters that scab over</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Starts on trunk and spreads outward</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Fever, fatigue, intense itching</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Impetigo</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Honey-colored crusts with red base</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Face, especially around mouth and nose</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Mild discomfort, fluid-filled blisters</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Molluscum Contagiosum</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Pearly, dome-shaped bumps, often with central depression</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Trunk, armpits, back of knees</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Usually no other symptoms</td>
                      </tr>
                      <tr>
                        <td className="px-4 py-3 text-sm font-medium text-gray-900">Heat Rash (Common in Sydney summers)</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Small red bumps or clear blisters</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Skin folds, neck, chest, elbow creases</td>
                        <td className="px-4 py-3 text-sm text-gray-700">Prickly or itchy feeling, excess sweating</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p>
                  In Sydney's Eastern Suburbs, our humid climate can make some rashes appear more severe or last longer. As a house call doctor, I recommend taking photos of the rash as it evolves to help with diagnosis, especially if you're waiting for a medical appointment.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 4 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Are there any natural remedies for HFMD that actually work for Sydney families?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  While many natural remedies are promoted for HFMD, especially in Sydney's Eastern Suburbs where complementary medicine is popular, only some have evidence supporting their use. Based on both scientific evidence and clinical experience with Sydney families, these approaches may help:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li><strong>Salt water gargles:</strong> For children old enough to gargle (usually 4+ years), warm salt water gargles (1/4 teaspoon salt in 240ml warm water) can help soothe mouth ulcers</li>
                  <li><strong>Cold foods:</strong> Ice blocks, frozen yogurt, and smoothies help numb pain and maintain hydration</li>
                  <li><strong>Coconut water:</strong> Popular in Sydney's Eastern Suburbs and provides electrolytes for hydration</li>
                  <li><strong>Chamomile tea:</strong> When cooled, can be used as a mouth rinse for older children (avoid in children with ragweed allergies)</li>
                  <li><strong>Aloe vera gel:</strong> When applied to skin lesions (not mouth sores) may provide mild relief</li>
                </ul>
                <p className="mb-3">
                  Remedies without sufficient evidence or not recommended include:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li><strong>Essential oils:</strong> Many are not safe for children and can irritate skin lesions</li>
                  <li><strong>Apple cider vinegar:</strong> Can cause pain when applied to open sores</li>
                  <li><strong>Echinacea:</strong> No evidence for effectiveness in HFMD and may cause allergic reactions</li>
                </ul>
                <p>
                  Always consult with your healthcare provider before using any complementary treatments, especially for children. As a house call doctor in Sydney's Eastern Suburbs, I'm always happy to discuss evidence-based integrative approaches that can complement conventional medical care.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 5 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">What should I do if there's an HFMD outbreak at my child's Sydney childcare center?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  HFMD outbreaks are common in Sydney childcare centers, particularly in the Eastern Suburbs where high attendance rates and population density can facilitate transmission. If you're notified of an outbreak:
                </p>
                <ol className="list-decimal pl-6 mb-3 space-y-2">
                  <li>
                    <strong>Don't panic, but be vigilant:</strong> Monitor your child for symptoms like fever, reduced appetite, or sore throat, which often appear before the rash
                  </li>
                  <li>
                    <strong>Enhance hygiene practices:</strong> Reinforce handwashing when your child returns home from childcare, especially before eating
                  </li>
                  <li>
                    <strong>Consider temporary changes to your routine:</strong> During active outbreaks, you might choose to avoid high-risk activities like indoor playgrounds popular in the Eastern Suburbs (such as Monkey Mania Eastgardens or Laster Warriors in Bondi Junction)
                  </li>
                  <li>
                    <strong>Communicate with the childcare center:</strong> Ask about their specific cleaning protocols during outbreaks and their notification policy for new cases
                  </li>
                  <li>
                    <strong>Have a care plan ready:</strong> Eastern Suburbs childcare centers typically require exclusion of affected children, so consider your work arrangements if your child develops symptoms
                  </li>
                </ol>
                <p className="mb-3">
                  If your child develops symptoms during an outbreak:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Keep them home and notify the childcare center</li>
                  <li>Consider booking a house call doctor who can come to your home, avoiding the need to take a potentially contagious child to a medical practice</li>
                  <li>Inform close contacts, like playdate friends or family members who have recently interacted with your child</li>
                </ul>
                <p>
                  Sydney's Eastern Suburbs experience recurring HFMD outbreaks, particularly in autumn and late summer, so having a plan in place can help you respond quickly if your child's childcare center reports cases.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 6 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Can my child get HFMD more than once? Do Sydney childcare workers develop immunity?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Yes, children can get hand, foot and mouth disease multiple times because it can be caused by different enteroviruses. While immunity develops to the specific virus that caused the infection, it doesn't protect against other strains that can cause HFMD.
                </p>
                <p className="mb-3">
                  This is particularly relevant in Sydney's Eastern Suburbs, where:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>High international visitor numbers can introduce different enterovirus strains not typically circulating in Australia</li>
                  <li>Childcare centers can experience outbreaks of different HFMD strains in the same year</li>
                  <li>Children may develop immunity to common local strains after multiple exposures</li>
                </ul>
                <p className="mb-3">
                  Regarding childcare workers in Sydney:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Childcare workers in the Eastern Suburbs often develop immunity to common local strains after years of exposure</li>
                  <li>However, they can still contract new strains or variants</li>
                  <li>They may experience milder symptoms due to partial cross-immunity from previous exposures</li>
                  <li>Despite milder symptoms, they can still transmit the virus to children and family members</li>
                </ul>
                <p>
                  In my practice across Sydney's Eastern Suburbs, I've observed that children typically experience more severe symptoms with their first HFMD infection, with subsequent infections generally being milder. However, this varies depending on the specific virus strain and the child's overall health.
                </p>
              </div>
            </div>
            
            {/* FAQ Item 7 */}
            <div className="p-6">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">How does Sydney's climate affect HFMD symptoms and recovery?</h3>
              <div className="text-gray-700">
                <p className="mb-3">
                  Sydney's unique climate, particularly in the Eastern Suburbs, can influence both HFMD symptoms and recovery in several ways:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-2">
                  <li>
                    <strong>Humidity effects:</strong> Sydney's coastal humidity, especially in summer and early autumn, can:
                    <ul className="list-disc pl-6 mt-1 space-y-1 text-sm">
                      <li>Make skin lesions heal more slowly due to increased moisture</li>
                      <li>Potentially extend the lifespan of viruses on surfaces in indoor environments without air conditioning</li>
                      <li>Increase discomfort from skin lesions due to sweating</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Heat considerations:</strong> During Sydney's hot months:
                    <ul className="list-disc pl-6 mt-1 space-y-1 text-sm">
                      <li>Dehydration risk increases, especially if children have painful mouth ulcers that discourage drinking</li>
                      <li>Heat rash can sometimes be confused with HFMD or appear alongside it</li>
                      <li>Air conditioning may provide relief but can also dry mucous membranes and potentially worsen mouth discomfort</li>
                    </ul>
                  </li>
                  <li>
                    <strong>Beach exposure:</strong> The Eastern Suburbs' beach lifestyle presents unique considerations:
                    <ul className="list-disc pl-6 mt-1 space-y-1 text-sm">
                      <li>Salt water exposure can sometimes irritate active blisters (though some patients report temporary relief)</li>
                      <li>Sand can adhere to lesions and potentially increase infection risk</li>
                      <li>Sun exposure can worsen discomfort from lesions and increase dehydration risk</li>
                    </ul>
                  </li>
                </ul>
                <p className="mb-3">
                  Recovery strategies specific to Sydney's Eastern Suburbs climate:
                </p>
                <ul className="list-disc pl-6 mb-3 space-y-1">
                  <li>Use air conditioning in bedrooms to maintain comfortable sleeping temperatures during recovery</li>
                  <li>Consider cool baths (not cold) to provide relief from both fever and skin discomfort</li>
                  <li>Be extra vigilant about hydration, offering frequent small amounts of fluids</li>
                  <li>Avoid beach visits until lesions have completely healed</li>
                  <li>Use lightweight, breathable cotton clothing to minimize irritation to skin lesions</li>
                </ul>
                <p>
                  As a house call doctor practicing in Sydney's coastal suburbs, I've observed that recovery times can vary seasonally, with slightly longer recovery periods during humid summer months compared to the drier winter conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Schema.org structured data for FAQs - hidden from view but helps with SEO */}
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How long is HFMD contagious and when can my child return to childcare in Sydney?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hand, foot and mouth disease is most contagious during the first week of illness, but the virus can remain in stool for several weeks after symptoms have resolved. According to NSW Health guidelines, children with HFMD can generally return to childcare or school when they have no fever for 24 hours, blisters have dried up, and they feel well enough to participate in normal activities. However, childcare centers in Sydney's Eastern Suburbs often have their own specific policies."
              }
            },
            {
              "@type": "Question",
              "name": "Can adults get hand, foot and mouth disease, and is it more or less severe?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, adults can contract hand, foot and mouth disease, though it's less common than in children. In adults, symptoms are often milder with fewer rashes but may include more intense throat pain and fatigue. Adults can still transmit the virus to others even with milder symptoms."
              }
            },
            {
              "@type": "Question",
              "name": "What's the difference between HFMD and other childhood rashes common in Sydney?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "HFMD features red spots that progress to blisters, often with a red halo, primarily on palms, soles, mouth, and sometimes buttocks, accompanied by fever and sore throat. This differs from chickenpox (itchy blisters starting on trunk), impetigo (honey-colored crusts on face), molluscum contagiosum (pearly bumps), and heat rash (small red bumps in skin folds)."
              }
            },
            {
              "@type": "Question",
              "name": "Are there any natural remedies for HFMD that actually work for Sydney families?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Evidence-supported remedies include salt water gargles for older children, cold foods like ice blocks and smoothies, coconut water for hydration, cooled chamomile tea as a mouth rinse, and aloe vera gel for skin lesions (not mouth sores). Remedies like essential oils, apple cider vinegar, and echinacea lack evidence and may cause harm."
              }
            },
            {
              "@type": "Question",
              "name": "What should I do if there's an HFMD outbreak at my child's Sydney childcare center?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "During an HFMD outbreak, monitor your child for symptoms, enhance hygiene practices, consider avoiding high-risk activities like indoor playgrounds, communicate with the childcare center about their protocols, and have a care plan ready in case your child needs to stay home. If symptoms develop, keep your child home, notify the center, and consider a house call doctor to avoid taking a contagious child to a medical practice."
              }
            },
            {
              "@type": "Question",
              "name": "Can my child get HFMD more than once? Do Sydney childcare workers develop immunity?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, children can get HFMD multiple times because different enterovirus strains can cause the disease. Immunity develops only to the specific virus strain that caused the infection. Childcare workers in Sydney's Eastern Suburbs often develop immunity to common local strains after years of exposure but can still contract new strains, typically with milder symptoms."
              }
            },
            {
              "@type": "Question",
              "name": "How does Sydney's climate affect HFMD symptoms and recovery?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Sydney's coastal humidity can slow skin lesion healing, extend virus survival on surfaces, and increase discomfort. The heat increases dehydration risk, especially with painful mouth ulcers. Beach exposure may irritate blisters, while sand can adhere to lesions. Recovery strategies include using air conditioning, taking cool baths, ensuring hydration, avoiding beaches until healed, and wearing lightweight cotton clothing."
              }
            }
          ]
        }) }} />
        
        <div className="bg-indigo-50 p-6 rounded-lg">
          <h3 className="text-xl font-semibold text-gray-900 mb-3">Need Medical Advice for Hand, Foot and Mouth Disease?</h3>
          <p className="text-gray-700 mb-4">
            If your child is showing symptoms of HFMD or you have concerns about a potential exposure, our house call doctors can provide expert assessment and treatment throughout Sydney's Eastern Suburbs.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link
              to="/dynamic-booking"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-8"
            >
              Book a House Call Doctor
            </Link>
            <Link
              to="/telemedicine"
              className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-8"
            >
              Schedule a Telehealth Consult
            </Link>
          </div>
        </div>
      </section>
    </>
  );
  
  // Placeholder for the rest of the content
  const tableOfContents = [
    { title: 'Introduction', anchor: 'introduction' },
    { title: 'What is Hand, Foot and Mouth Disease?', anchor: 'what-is-hfmd' },
    { title: 'Causes and Transmission', anchor: 'causes-transmission' },
    { title: 'Symptoms and Signs', anchor: 'symptoms-signs' },
    { title: 'Diagnosis of HFMD', anchor: 'diagnosis' },
    { title: 'Treatment and Home Care', anchor: 'treatment-home-care' },
    { title: 'When to Call a Doctor', anchor: 'when-to-call-doctor' },
    { title: 'Prevention and Childcare Considerations', anchor: 'prevention' },
    { title: 'Frequently Asked Questions about HFMD', anchor: 'faq' }
  ];

  const symptoms = [
    'Fever (usually the first symptom)',
    'Sore throat and painful mouth sores',
    'Reduced appetite and difficulty swallowing',
    'Irritability in young children',
    'Red spots that develop into blisters on hands, feet, mouth, and sometimes buttocks',
    'Painful blisters inside the mouth (gums, tongue, and cheeks)',
    'Rash on palms of hands and soles of feet (may also appear on knees, elbows, and genital area)',
    'Malaise and general discomfort',
    'Dehydration (from painful swallowing)',
    'Symptoms typically last 7-10 days'
  ];

  const treatments = [
    {
      stage: 'Symptom Management',
      description: 'Paracetamol/ibuprofen for fever and pain, topical oral anesthetics for mouth sores',
      duration: 'Throughout symptomatic period (typically 3-5 days)'
    },
    {
      stage: 'Hydration Support',
      description: 'Cold fluids, ice pops, soft foods, avoiding acidic/spicy foods that can irritate sores',
      duration: 'Throughout illness, especially during blister phase'
    },
    {
      stage: 'Oral Hygiene',
      description: 'Salt water rinses for older children, gentle oral care to prevent secondary infections',
      duration: 'Throughout illness until oral lesions resolve'
    },
    {
      stage: 'Rest and Recovery',
      description: 'Home isolation until fever subsides and blisters dry, limiting physical activities',
      duration: '7-10 days typically needed for full recovery'
    }
  ];

  const references = [
    {
      authors: 'Royal Children\'s Hospital Melbourne',
      title: 'Hand, foot and mouth disease',
      journal: 'RCH Clinical Practice Guidelines',
      year: '2023',
      doi: ''
    },
    {
      authors: 'Australian Government Department of Health',
      title: 'Hand, foot and mouth disease',
      journal: 'Department of Health',
      year: '2023',
      doi: ''
    },
    {
      authors: 'NSW Health',
      title: 'Hand, foot and mouth disease factsheet',
      journal: 'NSW Government',
      year: '2022',
      doi: ''
    },
    {
      authors: 'Koh WM, et al.',
      title: 'The Epidemiology of Hand, Foot and Mouth Disease in Asia: A Systematic Review and Analysis',
      journal: 'Pediatric Infectious Disease Journal',
      year: '2016',
      doi: '35(10):e285-e300'
    },
    {
      authors: 'National Centre for Immunisation Research and Surveillance',
      title: 'Hand, foot and mouth disease fact sheet',
      journal: 'NCIRS',
      year: '2022',
      doi: ''
    }
  ];

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={seoKeywords} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="https://homedoc.com.au/images/hand-foot-mouth-disease.png" />
        <meta property="og:url" content="https://homedoc.com.au/blog/hand-foot-mouth-disease-children" />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta name="twitter:description" content={seoDescription} />
        <meta name="twitter:image" content="https://homedoc.com.au/images/hand-foot-mouth-disease.png" />
        <link rel="canonical" href="https://homedoc.com.au/blog/hand-foot-mouth-disease-children" />
      </Helmet>
      
      <MedicalBlogTemplate
        title="Hand, Foot and Mouth Disease in Children: Symptoms, Treatment & Home Care"
        subtitle="Sydney pediatric house call doctor's guide to recognizing, managing, and preventing this common childhood viral infection"
        heroImage="/images/hand-foot-mouth-disease.png"
        publishDate="June 17, 2024"
        readTime={12}
        content={content}
        category="Child Health"
        tags={['HFMD', 'Hand Foot Mouth', 'Child Health', 'Rash', 'Viral Infection', 'House Call Doctor', 'Sydney']}
        symptoms={symptoms}
        treatments={treatments}
        references={references}
        showEmergencyBox={true}
        tableOfContents={tableOfContents}
      />
    </>
  );
};

export default HandFootMouthDisease; 