import React from 'react';
import { Link } from 'react-router-dom';

const DirectAnswerBlock = ({ 
  question, 
  answer, 
  relatedLink = null, 
  relatedLinkText = "Learn more", 
  className = "" 
}) => {
  return (
    <div className={`bg-[#2A2A2A]/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-xl mb-8 ${className}`}>
      <div className="border-l-4 border-[#DDC6A8]">
        <div className="p-6">
          <h3 className="text-xl font-medium text-white mb-3">{question}</h3>
          <div className="text-[#F0E6DD] mb-4 prose prose-sm max-w-none">
            {typeof answer === 'string' ? (
              <p>{answer}</p>
            ) : (
              answer
            )}
          </div>
          
          {relatedLink && (
            <div className="mt-4">
              <Link
                to={relatedLink}
                className="inline-flex items-center text-[#DDC6A8] hover:text-white transition-colors"
              >
                {relatedLinkText}
                <svg className="ml-2 w-4 h-4 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DirectAnswerBlock; 