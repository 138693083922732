import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { getAuth } from 'firebase/auth';

// Register the chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SEODashboard = () => {
  const [searchData, setSearchData] = useState(null);
  const [topKeywords, setTopKeywords] = useState([]);
  const [topPages, setTopPages] = useState([]);
  const [trafficStats, setTrafficStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('month');
  const [showGSCInstructions, setShowGSCInstructions] = useState(false);
  const [showGAInstructions, setShowGAInstructions] = useState(false);
  
  // Mock data for the initial dashboard
  // This would be replaced with actual API data in production
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      
      try {
        // In a real implementation, this would fetch data from your 
        // backend which would use the Google Search Console and Analytics APIs
        
        // For now, using mock data to show the dashboard layout
        const mockSearchData = {
          dates: ['Jan 1', 'Jan 8', 'Jan 15', 'Jan 22', 'Jan 29', 'Feb 5', 'Feb 12', 'Feb 19', 'Feb 26', 'Mar 4', 'Mar 11', 'Mar 18', 'Mar 25', 'Apr 1'],
          clicks: [5, 12, 18, 25, 30, 42, 48, 52, 58, 65, 72, 78, 85, 92],
          impressions: [120, 180, 240, 320, 380, 450, 520, 580, 640, 720, 780, 850, 920, 980],
        };
        
        const mockTopKeywords = [
          { keyword: 'house call doctor sydney', position: 8, change: +2, clicks: 32, impressions: 380 },
          { keyword: 'after hours doctor bondi', position: 12, change: +5, clicks: 28, impressions: 320 },
          { keyword: 'sydney night doctors', position: 15, change: 'new', clicks: 25, impressions: 290 },
          { keyword: 'hotel doctor sydney cbd', position: 18, change: 'new', clicks: 20, impressions: 260 },
          { keyword: 'double bay house call doctor', position: 14, change: +3, clicks: 18, impressions: 220 },
          { keyword: 'private doctor eastern suburbs', position: 10, change: +1, clicks: 16, impressions: 210 },
          { keyword: 'telemedicine for rural patients', position: 9, change: 'new', clicks: 14, impressions: 180 },
          { keyword: 'waterloo after hours doctor', position: 19, change: 'new', clicks: 12, impressions: 170 },
        ];
        
        const mockTopPages = [
          { page: '/night-doctors', clicks: 120, impressions: 1580, position: 8.2 },
          { page: '/locations/bondi-area', clicks: 98, impressions: 1320, position: 9.1 },
          { page: '/locations/double-bay-area', clicks: 85, impressions: 1180, position: 10.2 },
          { page: '/locations/eastern-suburbs', clicks: 78, impressions: 980, position: 7.8 },
          { page: '/blog/telemedicine-rural-remote', clicks: 65, impressions: 880, position: 8.9 },
          { page: '/locations/sydney-cbd-area', clicks: 58, impressions: 760, position: 11.2 },
        ];
        
        const mockTrafficStats = {
          totalUsers: 1240,
          newUsers: 865,
          sessions: 1820,
          bounceRate: 42.5,
          avgSessionDuration: '2:48',
          topSources: [
            { source: 'Google', sessions: 980, percentage: 53.8 },
            { source: 'Direct', sessions: 420, percentage: 23.1 },
            { source: 'Bing', sessions: 180, percentage: 9.9 },
            { source: 'Social', sessions: 150, percentage: 8.2 },
            { source: 'Referral', sessions: 90, percentage: 4.9 },
          ]
        };
        
        setSearchData(mockSearchData);
        setTopKeywords(mockTopKeywords);
        setTopPages(mockTopPages);
        setTrafficStats(mockTrafficStats);
      } catch (error) {
        console.error('Error fetching SEO data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, [timeframe]);
  
  // Chart data configuration
  const chartData = {
    labels: searchData?.dates || [],
    datasets: [
      {
        label: 'Clicks',
        data: searchData?.clicks || [],
        borderColor: '#4CAF50',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        tension: 0.3,
        yAxisID: 'y',
      },
      {
        label: 'Impressions',
        data: searchData?.impressions || [],
        borderColor: '#2196F3',
        backgroundColor: 'rgba(33, 150, 243, 0.1)',
        tension: 0.3,
        yAxisID: 'y1',
      },
    ],
  };
  
  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Clicks',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Impressions',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const getChangeColor = (change) => {
    if (change === 'new') return 'text-blue-600';
    return change > 0 ? 'text-green-600' : change < 0 ? 'text-red-600' : 'text-gray-500';
  };

  const getChangeIcon = (change) => {
    if (change === 'new') return '🆕';
    return change > 0 ? '▲' : change < 0 ? '▼' : '◆';
  };
  
  return (
    <div className="px-4 py-6 bg-[#222] min-h-screen">
      <h1 className="text-3xl font-light text-[#DDC6A8] mb-6">SEO Dashboard</h1>
      
      {/* Setup Instructions */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="bg-[#333] p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-medium text-white mb-2">Google Search Console</h2>
            <button 
              onClick={() => setShowGSCInstructions(!showGSCInstructions)}
              className="text-sm px-3 py-1 bg-[#444] hover:bg-[#555] rounded-lg text-white"
            >
              {showGSCInstructions ? 'Hide Instructions' : 'Show Instructions'}
            </button>
          </div>
          {showGSCInstructions && (
            <div className="mt-4 bg-[#3A3A3A] p-4 rounded-lg text-gray-300 text-sm">
              <ol className="list-decimal ml-5 space-y-2">
                <li>Go to <a href="https://search.google.com/search-console" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Google Search Console</a></li>
                <li>Add your property (URL prefix method is easiest)</li>
                <li>Verify ownership using one of these methods:
                  <ul className="list-disc ml-5 mt-1 space-y-1">
                    <li>HTML file upload to your site</li>
                    <li>HTML tag in your site header</li>
                    <li>DNS record</li>
                    <li>Google Analytics connection</li>
                  </ul>
                </li>
                <li>Wait for data to populate (may take a few days)</li>
                <li>Once verified, connect to this dashboard through your admin settings</li>
              </ol>
            </div>
          )}
        </div>
        
        <div className="bg-[#333] p-6 rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-medium text-white mb-2">Google Analytics</h2>
            <button 
              onClick={() => setShowGAInstructions(!showGAInstructions)}
              className="text-sm px-3 py-1 bg-[#444] hover:bg-[#555] rounded-lg text-white"
            >
              {showGAInstructions ? 'Hide Instructions' : 'Show Instructions'}
            </button>
          </div>
          {showGAInstructions && (
            <div className="mt-4 bg-[#3A3A3A] p-4 rounded-lg text-gray-300 text-sm">
              <ol className="list-decimal ml-5 space-y-2">
                <li>Go to <a href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">Google Analytics</a></li>
                <li>Create a new property for your website</li>
                <li>Set up a data stream (Web)</li>
                <li>Copy the measurement ID and tracking code</li>
                <li>Add the tracking code to your website's <code>&lt;head&gt;</code> section</li>
                <li>Wait for data to populate (usually starts within 24 hours)</li>
                <li>Connect your Analytics account to this dashboard in admin settings</li>
              </ol>
            </div>
          )}
        </div>
      </div>
      
      {/* Timeframe Selector */}
      <div className="flex mb-6">
        <div className="bg-[#333] rounded-lg overflow-hidden flex">
          {['week', 'month', '3months', '6months', 'year'].map((period) => (
            <button
              key={period}
              className={`px-4 py-2 ${timeframe === period ? 'bg-[#DDC6A8] text-[#222]' : 'text-white'}`}
              onClick={() => setTimeframe(period)}
            >
              {period === 'week' ? '7 Days' : 
               period === 'month' ? '30 Days' : 
               period === '3months' ? '3 Months' : 
               period === '6months' ? '6 Months' : '1 Year'}
            </button>
          ))}
        </div>
      </div>
      
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#DDC6A8]"></div>
        </div>
      ) : (
        <>
          {/* Performance Chart */}
          <div className="bg-[#333] p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-medium text-white mb-4">Search Performance</h2>
            <div className="h-80">
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
          
          {/* SEO Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            {/* Top Keywords */}
            <div className="bg-[#333] p-6 rounded-lg shadow-md md:col-span-2">
              <h2 className="text-xl font-medium text-white mb-4">Top Performing Keywords</h2>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="text-left text-gray-400 border-b border-gray-700">
                      <th className="pb-2">Keyword</th>
                      <th className="pb-2">Position</th>
                      <th className="pb-2">Change</th>
                      <th className="pb-2">Clicks</th>
                      <th className="pb-2">Impressions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {topKeywords.map((keyword, index) => (
                      <tr key={index} className="border-b border-gray-700 text-gray-300">
                        <td className="py-3 text-white">{keyword.keyword}</td>
                        <td className="py-3">{keyword.position}</td>
                        <td className={`py-3 ${getChangeColor(keyword.change)}`}>
                          {getChangeIcon(keyword.change)} {keyword.change === 'new' ? 'New' : keyword.change}
                        </td>
                        <td className="py-3">{keyword.clicks}</td>
                        <td className="py-3">{keyword.impressions}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Traffic Stats */}
            <div className="bg-[#333] p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-medium text-white mb-4">Traffic Overview</h2>
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-[#3A3A3A] p-4 rounded-lg">
                    <div className="text-gray-400 text-sm">Total Users</div>
                    <div className="text-white text-2xl font-medium">{trafficStats?.totalUsers.toLocaleString()}</div>
                  </div>
                  <div className="bg-[#3A3A3A] p-4 rounded-lg">
                    <div className="text-gray-400 text-sm">New Users</div>
                    <div className="text-white text-2xl font-medium">{trafficStats?.newUsers.toLocaleString()}</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-[#3A3A3A] p-4 rounded-lg">
                    <div className="text-gray-400 text-sm">Sessions</div>
                    <div className="text-white text-2xl font-medium">{trafficStats?.sessions.toLocaleString()}</div>
                  </div>
                  <div className="bg-[#3A3A3A] p-4 rounded-lg">
                    <div className="text-gray-400 text-sm">Bounce Rate</div>
                    <div className="text-white text-2xl font-medium">{trafficStats?.bounceRate}%</div>
                  </div>
                </div>
                <div className="bg-[#3A3A3A] p-4 rounded-lg">
                  <div className="text-gray-400 text-sm">Avg. Session Duration</div>
                  <div className="text-white text-2xl font-medium">{trafficStats?.avgSessionDuration}</div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Top Pages */}
          <div className="bg-[#333] p-6 rounded-lg shadow-md mb-6">
            <h2 className="text-xl font-medium text-white mb-4">Top Performing Pages</h2>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-left text-gray-400 border-b border-gray-700">
                    <th className="pb-2">Page</th>
                    <th className="pb-2">Clicks</th>
                    <th className="pb-2">Impressions</th>
                    <th className="pb-2">Avg. Position</th>
                  </tr>
                </thead>
                <tbody>
                  {topPages.map((page, index) => (
                    <tr key={index} className="border-b border-gray-700 text-gray-300">
                      <td className="py-3 text-blue-400">{page.page}</td>
                      <td className="py-3">{page.clicks}</td>
                      <td className="py-3">{page.impressions}</td>
                      <td className="py-3">{page.position.toFixed(1)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Traffic Sources */}
          <div className="bg-[#333] p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium text-white mb-4">Traffic Sources</h2>
            <div className="space-y-4">
              {trafficStats?.topSources.map((source, index) => (
                <div key={index} className="bg-[#3A3A3A] p-4 rounded-lg">
                  <div className="flex justify-between mb-2">
                    <div className="text-white">{source.source}</div>
                    <div className="text-gray-400">{source.sessions} sessions ({source.percentage}%)</div>
                  </div>
                  <div className="w-full bg-[#222] rounded-full h-2.5">
                    <div 
                      className="bg-[#DDC6A8] h-2.5 rounded-full" 
                      style={{ width: `${source.percentage}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          {/* Helpful Resources */}
          <div className="mt-8">
            <h2 className="text-xl font-medium text-white mb-4">Helpful Resources</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <a href="https://search.google.com/search-console" target="_blank" rel="noopener noreferrer" className="bg-[#333] p-6 rounded-lg shadow-md hover:bg-[#3A3A3A] transition">
                <h3 className="text-lg font-medium text-white mb-2">Google Search Console</h3>
                <p className="text-gray-400">Monitor your site's presence in Google Search results</p>
              </a>
              <a href="https://analytics.google.com/" target="_blank" rel="noopener noreferrer" className="bg-[#333] p-6 rounded-lg shadow-md hover:bg-[#3A3A3A] transition">
                <h3 className="text-lg font-medium text-white mb-2">Google Analytics</h3>
                <p className="text-gray-400">Track and analyze your website traffic</p>
              </a>
              <a href="https://pagespeed.web.dev/" target="_blank" rel="noopener noreferrer" className="bg-[#333] p-6 rounded-lg shadow-md hover:bg-[#3A3A3A] transition">
                <h3 className="text-lg font-medium text-white mb-2">PageSpeed Insights</h3>
                <p className="text-gray-400">Analyze your page performance</p>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SEODashboard; 