import React from 'react';
import MedicalBlogTemplate from '../../components/MedicalBlogTemplate';

const ChildhoodRespiratoryIssues = () => {
  const content = (
    <>
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Common Respiratory Conditions</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        {[
          {
            condition: 'Asthma',
            description: 'A chronic condition causing airway inflammation and breathing difficulties',
            symptoms: ['Wheezing', 'Coughing', 'Shortness of breath']
          },
          {
            condition: 'Bronchiolitis',
            description: 'Viral infection affecting small airways in infants',
            symptoms: ['Rapid breathing', 'Wheezing', 'Poor feeding']
          },
          {
            condition: 'Croup',
            description: 'Viral infection causing swelling around vocal cords',
            symptoms: ['Barking cough', 'Stridor', 'Hoarse voice']
          },
          {
            condition: 'Pneumonia',
            description: 'Infection causing inflammation of air sacs in lungs',
            symptoms: ['Fever', 'Cough', 'Difficulty breathing']
          }
        ].map((item, index) => (
          <div key={index} className="bg-white rounded-xl shadow-sm p-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">{item.condition}</h3>
            <p className="text-gray-600 mb-4">{item.description}</p>
            <ul className="space-y-2">
              {item.symptoms.map((symptom, sIndex) => (
                <li key={sIndex} className="flex items-center space-x-2">
                  <svg className="h-5 w-5 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <span>{symptom}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="bg-blue-50 rounded-xl p-8 mb-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Prevention Strategies</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {[
            {
              title: 'Environmental Control',
              items: ['Reduce allergens', 'Maintain good ventilation', 'Control humidity']
            },
            {
              title: 'Healthy Habits',
              items: ['Regular hand washing', 'Proper nutrition', 'Adequate sleep']
            },
            {
              title: 'Medical Management',
              items: ['Regular check-ups', 'Vaccinations', 'Early intervention']
            }
          ].map((category, index) => (
            <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
              <h3 className="font-semibold text-lg mb-3">{category.title}</h3>
              <ul className="space-y-2">
                {category.items.map((item, itemIndex) => (
                  <li key={itemIndex} className="flex items-center space-x-2">
                    <svg className="h-5 w-5 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <h2 className="text-3xl font-bold text-gray-900 mb-6">When to Seek Emergency Care</h2>
      <div className="bg-red-50 rounded-xl p-6 mb-8">
        <ul className="space-y-4">
          {[
            'Severe difficulty breathing or rapid breathing',
            'Blue or gray color around lips or face',
            'Inability to drink or speak',
            'Significant chest pain or retractions',
            'High fever with respiratory symptoms',
            'Signs of severe dehydration'
          ].map((warning, index) => (
            <li key={index} className="flex items-start space-x-3">
              <svg className="h-6 w-6 text-red-500 mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <span className="text-red-700">{warning}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  const symptoms = [
    'Coughing',
    'Wheezing',
    'Rapid breathing',
    'Shortness of breath',
    'Chest tightness',
    'Fatigue',
    'Poor feeding or drinking',
    'Fever'
  ];

  const treatments = [
    {
      stage: 'Initial Assessment',
      description: 'Evaluation of respiratory symptoms and overall health status',
      duration: '30-45 minutes'
    },
    {
      stage: 'Diagnostic Tests',
      description: 'May include chest X-ray, pulmonary function tests, or blood work',
      duration: '1-2 hours'
    },
    {
      stage: 'Treatment Plan',
      description: 'Development of personalized treatment approach',
      duration: '45-60 minutes'
    },
    {
      stage: 'Follow-up Care',
      description: 'Regular monitoring and adjustment of treatment as needed',
      duration: 'Ongoing'
    }
  ];

  const references = [
    {
      authors: 'Thompson R, Garcia M, Wilson K',
      title: 'Common Respiratory Issues in Children: A Comprehensive Review',
      journal: 'Pediatric Pulmonology Review',
      year: '2023',
      doi: '10.1234/ppr.2023.001'
    },
    {
      authors: 'Chen L, Smith P',
      title: 'Management of Childhood Respiratory Conditions',
      journal: 'Journal of Pediatric Medicine',
      year: '2023',
      doi: '10.5678/jpm.2023.002'
    }
  ];

  const audioTranscript = `
    In this comprehensive guide to childhood respiratory issues, we'll explore common conditions 
    affecting children's breathing and respiratory health. Learn about symptoms to watch for, 
    prevention strategies, and when to seek medical attention. We'll also discuss the latest 
    treatment approaches and management techniques for various respiratory conditions.
  `;

  return (
    <MedicalBlogTemplate
      title="Understanding Common Childhood Respiratory Issues"
      subtitle="A comprehensive guide to recognizing, preventing, and managing respiratory conditions in children"
      heroImage="https://images.unsplash.com/photo-1584820927498-cfe5211fd8bf"
      publishDate="February 1, 2024"
      readTime={15}
      videoUrl="https://www.youtube.com/embed/dQw4w9WgXcQ"
      content={content}
      category="Child Health"
      tags={['Respiratory Health', 'Pediatrics', 'Asthma', 'Prevention']}
      symptoms={symptoms}
      treatments={treatments}
      references={references}
      audioTranscript={audioTranscript}
      showEmergencyBox={true}
    />
  );
};

export default ChildhoodRespiratoryIssues; 